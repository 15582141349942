import HeaderPadrao from 'layouts/HeaderPadrao';
import { Route } from 'react-router-dom';
import DocumentosAssinados from './../pages/RecepcaoDashboard/DocumentosAssinados';
import Guias from './../pages/RecepcaoDashboard/Guias';
import AgendaPacienteResponsavel from './../pages/ResponsavelDashboard/AgendaPaciente';
import NewAgendaPacienteResponsavel from './../pages/ResponsavelDashboard/AgendaPaciente/index.new';
import ResponsavelDashboard from './../pages/ResponsavelDashboard/Dashboard';
import DocumentosPendentesResponsavel from './../pages/ResponsavelDashboard/Dashboard/DocumentosPendentes';
import DocumentosTerapeuticos from './../pages/ResponsavelDashboard/Dashboard/DocumentosTerapeuticos';
import PacientesResponsavel from './../pages/ResponsavelDashboard/Pacientes';
import PerfilPacienteResponsavel from './../pages/ResponsavelDashboard/Pacientes/Perfil';
import TerapeutasResponsavel from './../pages/ResponsavelDashboard/Terapeutas';
import CurriculoTerapeuta from './../pages/ResponsavelDashboard/Terapeutas/CurriculoTerapeuta';
import AtendimentosDiaPaciente from 'pages/ResponsavelDashboard/AtendimentoDiaPaciente';


const routes = [
	{ path: '/dashboard/responsavel', element: <ResponsavelDashboard /> },
	{ path: '/dashboard/responsavel/documentos-assinados', element: <DocumentosAssinados /> },
	{ path: '/dashboard/responsavel/documentos-pendentes/:id', element: <DocumentosPendentesResponsavel /> },
	{ path: '/dashboard/responsavel/documentos-terapeuticos', element: <DocumentosTerapeuticos /> },
	{ path: '/dashboard/responsavel/guias', element: <Guias /> },
	{ path: '/dashboard/responsavel/terapeutas', element: <TerapeutasResponsavel /> },
	{ path: '/dashboard/responsavel/terapeuta/curriculo/:id', element: <CurriculoTerapeuta /> },
	{ path: '/dashboard/responsavel/pacientes', element: <PacientesResponsavel /> },
	{ path: '/dashboard/responsavel/pacientes/perfil/:id', element: <PerfilPacienteResponsavel /> },
	{ path: '/dashboard/responsavel/agenda/paciente/:id', element: <AgendaPacienteResponsavel /> },
	{ path: '/dashboard/responsavel/nova/agenda/paciente/:id', element: <NewAgendaPacienteResponsavel /> },
	{ path: '/dashboard/responsavel/atendimentos/paciente/:id', element: <AtendimentosDiaPaciente /> },
];

const ResponsavelRoutes = () => {
	return routes.map((route) => (
		<Route key={route.path} path={route.path} element={route.element} />
	));
};

const getResponsavelPaths = () => {
	return routes.map(route => route.path);
};

export { getResponsavelPaths, ResponsavelRoutes };
