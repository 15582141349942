import React from 'react';
import { BsLock } from 'react-icons/bs';
import { CgLock } from 'react-icons/cg';
import { FaLock } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { GoLock } from 'react-icons/go';
import { HiLockClosed } from 'react-icons/hi';
import { IoMdLock } from 'react-icons/io';
import { MdLock } from 'react-icons/md';
import { RiLock2Fill, RiLock2Line, RiLockLine } from 'react-icons/ri';

const BloqueiaArea = ({ children, bloqueado }) => {
    const estiloBloqueado = {
        pointerEvents: 'none',
        opacity: 0.5,
        userSelect: 'none',
        cursor: 'not-allowed'
    };

    const estiloNormal = {
        pointerEvents: 'auto',
        opacity: 1,
        userSelect: 'auto'
    };

    return (
        <>
            {bloqueado && (
                <div>
                    <hr className='w-100per bg-roxo-dark font-roxo-dark'></hr>
                    <div className='d-flex align-items-center justify-content-center mb-4 position-relative bg-white w-content h-content mx-auto' style={{ marginTop: '-28px' }}>
                        <span className='ms-2 mb-1'>
                            <RiLock2Line className='font-roxo-dark' size={20} color={"purple"} />
                        </span>
                        <span className='mz-2 font-roxo-escuro'>Área bloqueada</span>
                    </div>
                </div>
            )}
            <div style={bloqueado ? estiloBloqueado : estiloNormal}>
                {children}
            </div>
            {bloqueado && (
                <div>
                    <hr className='w-100per bg-roxo-dark font-roxo-dark'></hr>
                    <div className='d-flex align-items-center justify-content-center mb-4 position-relative bg-white w-content h-content mx-auto bg-roxo' style={{ marginTop: '-28px' }}>
                        <span className='mx-1' style={{ marginTop: '-4px' }}>
                            <RiLock2Line className='font-roxo-dark' size={30} color={"purple"} />
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default BloqueiaArea;