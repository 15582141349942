import { Form } from '@unform/web';
import Button from 'components/Button';
import Input, { SimpleInput } from 'components/Input';
import ModalBase from 'components/ModalBase';
import Select from 'components/Select';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import useResponsaveisData from 'context/responsavel';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { toPng } from 'html-to-image';
import html2PDF from 'jspdf-html2canvas';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BsPrinter, BsSearch } from 'react-icons/bs';
import { CgExport } from 'react-icons/cg';
import { FaBroom, FaFile, FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { MdDragHandle } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { ClipLoader } from 'react-spinners';
import { ChangeCase } from 'services/ChangeCase';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

const RelatoriosResponsaveis = () => {
    //#region VARIAVEIS
    usePageBase({
        title: 'Relatório de Responsaveis',
        description: 'Tela onde é possível visualizar os responsaveis cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/responsaveis'
        ]
    })
    const [responsaveis, setResponsaveis] = useState([]);
    const [responsaveisOriginal, setResponsaveisOriginal] = useState([]);
    const [loadingListas, setLoadingListas] = useState(true);
    const [loading, setLoading] = useState(false);
    const statusTerapeuta = [
        { value: 'Ativo', label: 'Ativo' },
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Bloqueado', label: 'Bloqueado' },
    ]
    const { fetchResponsaveisFiltradosData } = useResponsaveisData();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    // Outra Opções de exportação
    const [plus55ToNumber, setPlus55ToNumber] = useState(false);
    const [limiteLInhasDocumento, setLimiteLinhasDocumento] = useState(null);
    const [nomeArquivo, setNomeArquivo] = useState('relatorio_responsaveis');

    // Refs
    const baixandoDocsRef = useRef(null);
    // Filtros
    const defaultFilters = {
        nome: '',
        parentesco: '',
        cpf: '',
        rg: '',
        telefone1: '',
        telefone2: '',
        endereco: '',
        estado: '',
        bairro: '',
        cep: '',
        created_at: '',
        updated_at: '',
        user_id: '',
        email: '',
        numero: '',
        complemento: '',
        responsavel_fiscal: '',
        data_nascimento: '',
        cidade: '',
        paciente_id: '',
        paciente_ativo: true,
        paciente_nome: '',
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [selectedColumns, setSelectedColumns] = useState({
        nome: true,
        email: true,
        cpf: true,
        rg: false,
        telefone1: false,
        telefone2: false,
        endereco: false,
        estado: false,
        bairro: false,
        cep: false,
        created_at: false,
        user_id: false,
        numero: false,
        complemento: false,
        responsavel_fiscal: false,
        data_nascimento: false,
        cidade: false,
        paciente_nome: false,
        paciente_identificador: false,
    });

    const toExportRef = useRef(null);
    const [columnOrder, setColumnOrder] = useState(Object.keys(selectedColumns));
    //#endregion

    //#region FUNCOES
    const exportToPrint = () => {
        const printContents = toExportRef.current.outerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    const exportToImage = () => {
        toPng(toExportRef.current).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = `${nomeArquivo}.png`;
            link.href = dataUrl;
            link.click();
        });
    };

    const exportToExcel = async () => {
        let currentPage = 1;
        let currentRow = 0;
        let wb = XLSX.utils.book_new();
        let wsData = [
            columnOrder.filter(col => selectedColumns[col]).map(col => col.charAt(0).toUpperCase() + col.slice(1))
        ];

        responsaveis.forEach((responsavel, index) => {
            if (limiteLInhasDocumento && limiteLInhasDocumento > 0 && currentRow >= limiteLInhasDocumento) {
                const ws = XLSX.utils.aoa_to_sheet(wsData);
                XLSX.utils.book_append_sheet(wb, ws, `${nomeArquivo} - Página ${currentPage}`);
                XLSX.writeFile(wb, `${nomeArquivo}(${currentPage}).xlsx`);
                wb = XLSX.utils.book_new();
                wsData = [
                    columnOrder.filter(col => selectedColumns[col]).map(col => col.charAt(0).toUpperCase() + col.slice(1))
                ];
                currentPage++;
                currentRow = 0;
            }

            const row = [];
            columnOrder.forEach(col => {
                if (selectedColumns[col]) {
                    row.push(responsavel[col]);
                }
            });
            wsData.push(row);
            currentRow++;
        });

        if (wsData.length > 1) {
            const ws = XLSX.utils.aoa_to_sheet(wsData);
            XLSX.utils.book_append_sheet(wb, ws, `${nomeArquivo} (${currentPage})`);
            XLSX.writeFile(wb, `${nomeArquivo}(${currentPage}).xlsx`);
        }
    };

    const exportToCSV = async () => {
        let currentPage = 1;
        let currentRow = 0;
        let csvContent = columnOrder
            .filter(col => selectedColumns[col])
            .map(col => col.charAt(0).toUpperCase() + col.slice(1))
            .join(",") + "\n";

        responsaveis.forEach((responsavel, index) => {
            if (limiteLInhasDocumento && limiteLInhasDocumento > 0 && currentRow >= limiteLInhasDocumento) {
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                saveAs(blob, `${nomeArquivo}(${currentPage}).csv`);
                csvContent = columnOrder
                    .filter(col => selectedColumns[col])
                    .map(col => col.charAt(0).toUpperCase() + col.slice(1))
                    .join(",") + "\n";
                currentPage++;
                currentRow = 0;
            }

            const row = columnOrder
                .filter(col => selectedColumns[col])
                .map(col => responsavel[col])
                .join(",");
            csvContent += row + "\n";
            currentRow++;
        });

        if (csvContent.length > 1) {
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `${nomeArquivo}(${currentPage}).csv`);
        }
    };

    const exportToPdf = () => {
        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
            html2canvas: { scale: 2, useCORS: true, logging: true },
            image: { type: 'jpeg', quality: 0.98 },
            output: `${nomeArquivo}.pdf`
        };
        html2PDF(toExportRef.current, newOptions);
    };

    const exportToWord = () => {
        const htmlString = toExportRef.current.innerHTML;
        const converted = htmlDocx.asBlob(htmlString);
        saveAs(converted, `${nomeArquivo}.docx`);
    };
    //#endregion

    //#region HANDLES
    const handleChangeNomeArquivo = (e) => {
        const forbiddenChars = /[<>:"/\\|?*]/g;
        const sanitizedValue = e.target.value.replace(forbiddenChars, '');
        setNomeArquivo(sanitizedValue);
    };
    const handleExportDocument = async (type) => {
        if (nomeArquivo === '') {
            Swal.fire({
                title: 'Nome do arquivo não pode ser vazio',
                icon: 'error'
            });
            return;
        }
        baixandoDocsRef.current.open();
        switch (type) {
            case 'pdf':
                exportToPdf();
                break;
            case 'csv':
                exportToCSV();
                break;
            case 'excel':
                exportToExcel();
                break;
            case 'word':
                exportToWord();
                break;
            case 'image':
                exportToImage();
                break;
            case 'print':
                exportToPrint();
                break;
            default:
                break;
        }
        baixandoDocsRef.current.close();
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(columnOrder);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setColumnOrder(items);
    };
    const handleFiltraResponsaveis = async () => {
        try {
            const response = await fetchResponsaveisFiltradosData(filters);
            const newResponsaveis = response?.map((responsavel) => {
                const created_at = responsavel.created_at ? moment.utc(responsavel.created_at).format('DD/MM/YYYY') : '';

                return {
                    ...responsavel,
                    created_at,
                    paciente_nome: responsavel.paciente_nome || ''
                };
            });
            setResponsaveisOriginal(newResponsaveis);
            setResponsaveis(newResponsaveis);
        } catch (error) {
            console.error('Erro ao buscar responsaveis:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleChangeHabilitaSucesso = (e) => {
        const { id, checked } = e.target;
        setSelectedColumns((prev) => ({
            ...prev,
            [id]: checked
        }));
    };

    const handlePlus55ToNumber = (e) => {
        const { checked } = e.target;
        setPlus55ToNumber(checked);

        if (checked) {
            const newResponsaveis = responsaveisOriginal.map((responsavel) => {
                const telefone1 = responsavel.telefone1 ? `55${responsavel.telefone1}` : '';
                const telefone2 = responsavel.telefone2 ? `55${responsavel.telefone2}` : '';
                return ({
                    ...responsavel,
                    telefone1,
                    telefone2
                })
            });
            setResponsaveis(newResponsaveis);
        } else {
            setResponsaveis(responsaveisOriginal);
        }
    };

    const handleChangeLimiteLinhasDocumento = (e) => {
        const { value } = e.target;

        setLimiteLinhasDocumento(value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const noFiltersApplied = Object.values(filters).every(value => value === '' || value === null);

        if (noFiltersApplied) {
            const confirmProceed = await Swal.fire({
                title: 'Nenhum filtro foi aplicado',
                text: 'Deseja continuar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            });
            if (!confirmProceed.isConfirmed) {
                setLoading(false);
                return;
            }
        }

        try {
            await handleFiltraResponsaveis();
        } catch (error) {
            console.error('Erro ao buscar responsaveis:', error);
        } finally {
            setLoading(false);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        setLoadingListas(true);
        fetchPacienteOptionsData();
        setLoadingListas(false);
    }, []);
    //#endregion

    const limpaFiltros = () => {
        setFilters(defaultFilters);
    };

    if (loadingListas) {
        return <div>Carregando...</div>;
    }

    return (
        <div>
            <style jsx>{`
                .draggable-item {
                    cursor: pointer;
                    padding: 4px 8px 4px 8px;
                    margin: 6px 8px 6px 8px;
                    border: 1px solid black;
                    border-radius: 4px;
                    background-color: white;
                }
            `}</style>
            <ModalBase width={'300px'} backgroundOpacity={0.2} ref={baixandoDocsRef} title='Teste de modal' hasCloseButton={true}>
                Baixando documentos...
                <ClipLoader color={'#123abc'} loading={true} size={50} />
            </ModalBase>
            <Form onSubmit={handleSubmit} className='row d-flex flex-wrap gy-4 p-4'>
                <fieldset className="ns-fieldset col-sm-12 col-lg-6">
                    <legend>Informações do Responsável</legend>
                    <div className='row'>
                        <div className='col-lg-3 col-sm-12' id='filter_nome'>
                            <Input type="text" title="Nome:" label="Nome:" id="nome" name="nome" value={filters.nome} onChange={handleChange} />
                        </div>
                        <div className='col-lg-3 col-sm-12' id='filter_email'>
                            <Input type="text" title="Email:" label="Email:" id="email" name="email" value={filters.email} onChange={handleChange} />
                        </div>
                        <div className='col-lg-3 col-sm-12' id='filter_cpf'>
                            <Input type="text" title="CPF:" label="CPF:" id="cpf" name="cpf" value={filters.cpf} onChange={handleChange} />
                        </div>
                        <div className='col-lg-3 col-sm-12' id='filter_status'>
                            <Select id="status" name="status" label="Status:" title="Status:" options={statusTerapeuta} value={statusTerapeuta.find(find => find.value === filters.status)} onChange={(e) => handleChange({ target: { id: 'status', name: 'status', value: e.value } })} isClearable={true} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="ns-fieldset col-sm-12 col-lg-6">
                    <legend>Informações do Paciente</legend>
                    <div className='row'>
                        <div className='col-lg-5 col-sm-12' id='filter_paciente_id'>
                            <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} value={pacienteOptions.find((find) => find.value === filters.paciente_id)} onChange={(e) => handleChange({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} isClearable={true} />
                        </div>
                        <div className='col-lg-4 col-sm-12' id='filter_paciente_nome'>
                            <Input type="text" title="Nome do Paciente:" label="Nome do Paciente:" id="paciente_nome" name="paciente_nome" value={filters.paciente_nome} onChange={handleChange} />
                        </div>
                        <div className='col-lg-3 col-sm-12 d-flex align-items-bottom' id='filter_paciente_ativo'>
                            <div className='ns-checkbox reverse'>
                                <label htmlFor="paciente_ativo">
                                    Apenas pacientes ativos
                                </label>
                                <input type="checkbox" id="paciente_ativo" name="paciente_ativo" checked={filters.paciente_ativo} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className='col-12 mt-2 d-flex justify-content-end'>
                    <Button type="submit" className={'w-fit-content-i searching'}>
                        <BsSearch className='icone' size={30} /> Pesquisar
                    </Button>
                    <Button type="button" onClick={limpaFiltros} className={`ms-2 broom-sweep w-fit-content-i ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} >
                        <FaBroom size={30} type="button" className="icone" /> Limpar Filtros
                    </Button>
                </div>
            </Form>
            {loading
                ? <div className='d-flex justify-content-center mt-4'>
                    <ClipLoader color={'#123abc'} loading={true} size={100} />
                </div>
                : (responsaveisOriginal && responsaveisOriginal.length > 0)
                    ? (
                        <div className='d-flex'>
                            <div style={{ width: '10%' }}>
                                <fieldset className="ns-fieldset w-100">
                                    <legend>Selecionar Colunas</legend>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="columns">
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps} className="d-flex flex-wrap justify-content-center w-100">
                                                    {columnOrder.map((col, index) => (
                                                        <Draggable key={col} draggableId={col} index={index}>
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="w-100per mt-2 mb-2 draggable-item">
                                                                    <div className="form-check-reverse form-switch me-2 justify-content-between w-100 d-flex align-items-center">
                                                                        <MdDragHandle size={28} />
                                                                        <span className='d-flex align-items-center'>
                                                                            <label className="form-check-label" htmlFor={col} style={{ textDecoration: selectedColumns[col] ? 'none' : 'line-through' }}>
                                                                                {ChangeCase.toTitleCase(col)}
                                                                            </label>
                                                                            <input className="form-check-input switch-roxo ms-2" type="checkbox" role="switch" id={col} checked={selectedColumns[col]} onChange={handleChangeHabilitaSucesso} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </fieldset>
                            </div>
                            <div ref={toExportRef} style={{ width: '90%' }}>
                                <div className='w-100per d-flex'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-col'>
                                            <fieldset className="ns-fieldset">
                                                <legend>Opções de Extra</legend>
                                                <div className='d-flex'>
                                                    <div className='col-6 d-flex align-items-end'>
                                                        <div className="ns-checkbox reverse">
                                                            <label htmlFor="plus_55">
                                                                Adicionar '55' ao Telefone
                                                            </label>
                                                            <input type="checkbox" role="switch" id="plus_55" checked={plus55ToNumber} onChange={handlePlus55ToNumber} />
                                                        </div>
                                                    </div>
                                                    <Form className='col-6'>
                                                        <Input type="number" id="limite_linhas" name="limite_linhas" label="Limite de Linhas por Página" value={limiteLInhasDocumento} onChange={handleChangeLimiteLinhasDocumento} />
                                                    </Form>
                                                </div>
                                            </fieldset>
                                            <fieldset className="ns-fieldset">
                                                <legend>Opções de Exportação</legend>
                                                <div className='d-flex flex-wrap flex-col'>
                                                    <Form className='w-100 d-flex'>
                                                        <Input id="nome_arquivo" name="nome_arquivo" className="w-500px-i" label="Nome do Arquivo" value={nomeArquivo} onChange={handleChangeNomeArquivo} maxLength={28} icon={FaFile} />
                                                    </Form>
                                                    <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                        <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={() => handleExportDocument('print')}>
                                                            <BsPrinter size={30} />Imprimir
                                                        </Button>
                                                        <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={() => handleExportDocument('image')}>
                                                            <CgExport size={30} />Imagem
                                                        </Button>
                                                        <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={() => handleExportDocument('excel')}>
                                                            <RiFileExcel2Fill size={30} />Excel
                                                        </Button>
                                                        <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={() => handleExportDocument('pdf')}>
                                                            <FaFilePdf size={30} />PDF
                                                        </Button>
                                                        <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={() => handleExportDocument('word')}>
                                                            <FaFilePdf size={30} />Word
                                                        </Button>
                                                        <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={() => handleExportDocument('csv')}>
                                                            <FaFileCsv size={30} />CSV
                                                        </Button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <fieldset className="ns-fieldset">
                                            <legend>Informações</legend>
                                            <div className='d-grid'>
                                                <div className=''>
                                                    Filtros Utilizados:
                                                    <ul>
                                                        <li>
                                                            Nome: {filters.nome}
                                                        </li>
                                                        <li>
                                                            Email: {filters.email}
                                                        </li>
                                                        <li>
                                                            CPF: {filters.cpf}
                                                        </li>
                                                        <li>
                                                            Status: {filters.status}
                                                        </li>
                                                        <li>
                                                            Paciente: {filters.paciente_nome}
                                                        </li>
                                                        <li>
                                                            Paciente Ativo: {filters.paciente_ativo ? 'Sim' : 'Não'}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className=''>
                                                    Quantidade de Responsaveis: {responsaveis.length}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <table className='table table-striped table-hover'>
                                    <thead>
                                        <tr>
                                            {columnOrder.map(col => selectedColumns[col] && <th key={col}>{col.charAt(0).toUpperCase() + col.slice(1).replace('_', ' ')}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {responsaveis.map((terapeuta) => (
                                            <tr key={terapeuta.id}>
                                                {columnOrder.map(col => selectedColumns[col] && <td key={col}>{terapeuta[col]}</td>)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                    : <div>Sem responsaveis para exibir</div>
            }
        </div>
    );
};

export default RelatoriosResponsaveis;
