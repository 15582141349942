import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import { cloneElement, useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import { AtendimentoCard, CardList, Container } from './styles';
import Button from 'components/Button';
import useIcons from 'context/icons';

export default function AtendimentosDiaPaciente(props, ref) {
    const { id } = useParams();
    const { iconArray } = useIcons();
    const snackbar = useSnackbar();
    const [atendimentos, setAtendimentos] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [loading, setLoading] = useState(false);
    //#region CARREGADORES
    const carregaPaciente = () => {
        setLoading(true);
        api.get(`api/atendimentos/paciente/${id}`)
            .then((response) => {
                const { atendimentos, paciente } = response.data;
                if (response.status === 200) {
                    const sortedAtendimentos = atendimentos.sort((a, b) => moment(a.hora_atendimento, 'HH:mm').diff(moment(b.hora_atendimento, 'HH:mm')));
                    setAtendimentos(sortedAtendimentos);
                    setPaciente(paciente);
                }
            })
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao carregar paciente', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        carregaPaciente();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Container>
            <div className='d-flex justify-content-center'>
                <Link to={'/dashboard/responsavel/agenda/paciente/' + paciente?.id}>
                    <label className='selecionado'>
                        {paciente?.nome}
                    </label>
                </Link>
            </div>
            <div className='d-flex justify-content-center'>
                <Button className="w-fit-content-i" onClick={carregaPaciente}>
                    {cloneElement(iconArray['atualizar'], { size: 22, style: { marginRight: 15 }, className: loading ? 'rodando' : '' })} Recarregar Atendimentos
                </Button>
            </div>
            {/* ATENDIMENTOS */}
            <CardList>
                {atendimentos.length > 0 ? (
                    atendimentos.map((atendimento, index) => (
                        <AtendimentoCard key={index}>
                            <div className='card-body'>
                                <span className='col-3'>{atendimento.hora_atendimento.slice(0, -3)}</span>
                                <span className='col-9'>{atendimento.sala} / {atendimento.estacao}</span>
                                <span className='col-12'>{atendimento.terapeuta_nome}</span>
                                <span className='col-12'>{atendimento.terapia}</span>
                            </div>
                        </AtendimentoCard>
                    ))
                ) : (
                    <span>Sem atendimentos para hoje...</span>
                )}
            </CardList>
        </Container>
    );
    //#endregion
}