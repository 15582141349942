//#region IMPORTAÇÕES
import React from 'react';
import { AuthProvider } from './auth';
import { PageBaseProvider } from './page-base';
import { WebSocketProvider } from './websocket';
//#endregion

/**
 * Componente de provedor de contexto para a aplicação.
 * Envolve os componentes filhos com os provedores de autenticação e de página base.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Componentes filhos a serem envolvidos pelos provedores.
 * @returns {JSX.Element} Elemento JSX que envolve os filhos com os provedores de contexto.
 */
const AppProvider = ({ children }) => (
  <AuthProvider>
    <WebSocketProvider>
      <PageBaseProvider>
        {children}
      </PageBaseProvider>
    </WebSocketProvider>
  </AuthProvider>
);

export default AppProvider;
