import React from 'react';

/**
 * AssinaturaA4 component renders a formatted date and a signature line.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.tipo='clinica'||'terapeuta'] - The type of signature, either 'clinica' or 'terapeuta'.
 * @param {Object} [props.rest] - Additional props to be spread onto the root div element.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AssinaturaA4 = ({ width = null, tipo = 'clinica', nome = 'NEURO INTENSIVA - 24.928.949/0001-14', ...rest }) => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }).toUpperCase();

    return (
        <div style={{ textAlign: 'center', marginTop: tipo === 'terapeuta' ? '5px' : '50px', marginRight: tipo === 'terapeuta' ? '5px' : '0px', border: tipo === 'terapeuta' ? '1px solid black' : 'none' }} width={width} {...rest}>
            {tipo === 'terapeuta' ? <></> :
                <p style={{ margin: 0 }}>CURITIBA, {formattedDate}</p>
            }
            <br></br>
            <hr style={{ width: tipo === 'terapeuta' ? '100%' : '50%', margin: '20px auto', borderTop: '1px solid black' }} />
            <p style={{ marginTop: -18, fontWeight: 'bold', padding: tipo === 'terapeuta' ? '0 5px 0 5px' : '0px' }}>{nome}</p>
        </div>
    );
};

export default AssinaturaA4;