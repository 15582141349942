import React, { createContext, useContext, useState, useEffect, useRef } from "react";

// Criando o contexto
const ErrorLoggerContext = createContext();

// Hook para acessar o contexto facilmente
export const useErrorLogger = () => useContext(ErrorLoggerContext);

// Provedor do contexto
export const ErrorLoggerProvider = ({ children }) => {
    const [logs, setLogs] = useState([]);
    const [isEnabled, setIsEnabled] = useState(true);
    const logsRef = useRef([]);

    useEffect(() => {
        // Função para armazenar logs no estado
        const captureLog = (type, ...args) => {
            if (isEnabled) {
                logsRef.current = [...logsRef.current, { type, message: args }];
            }
        };

        // Sobrescrevendo os métodos do console
        const originalConsoleError = console.error;
        const originalConsoleWarn = console.warn;
        const originalConsoleLog = console.log;

        console.error = (...args) => {
            captureLog("error", ...args);
            originalConsoleError.apply(console, args);
        };

        console.warn = (...args) => {
            captureLog("warn", ...args);
            originalConsoleWarn.apply(console, args);
        };

        console.log = (...args) => {
            captureLog("log", ...args);
            originalConsoleLog.apply(console, args);
        };

        return () => {
            // Restaurando os métodos originais ao desmontar o componente
            console.error = originalConsoleError;
            console.warn = originalConsoleWarn;
            console.log = originalConsoleLog;
        };
    }, [isEnabled]);

    useEffect(() => {
        if (logsRef.current.length > 0) {
            setLogs((prevLogs) => [...prevLogs, ...logsRef.current]);
            logsRef.current = [];
        }
    });

    const toggleLogger = () => {
        setIsEnabled((prev) => !prev);
    };

    return (
        <ErrorLoggerContext.Provider value={{ logs, isEnabled, toggleLogger }}>
            {children}
        </ErrorLoggerContext.Provider>
    );
};
