import { useCallback, useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Container } from './styles';
import { css } from '@emotion/react';
import { usePageBase } from 'context/page-base';
import { FiFilter } from 'react-icons/fi';
import ClipLoader from 'react-spinners/ClipLoader';
import api from 'services/api';
import { ChangeCase } from 'services/ChangeCase';
import FuncionariosModal from '../../../components/Modais/FuncionariosModal';
import { useAuth } from '../../../context/auth';
import FuncionarioService from '../../../services/FuncionarioService';
import { TableFuncionarios } from './TableFuncionarios';

/**
 * Componente de listagem de funcionários.
 *
 * @component
 * @returns {JSX.Element} Retorna o componente de listagem de funcionários.
 *
 * @example
 * <Funcionarios />
 *
 * @description
 * Este componente exibe uma lista de funcionários cadastrados no sistema, permitindo filtrar por nome e tipo de usuário.
 * Também permite adicionar novos funcionários e visualizar detalhes de um funcionário específico.
 *
 * @typedef {Object} Funcionario
 * @property {number} id - ID do funcionário.
 * @property {string} tipo_user - Tipo de usuário do funcionário.
 * @property {string} name - Nome do funcionário.
 * @property {string} email - Email do funcionário.
 * @property {string} status - Status do funcionário.
 * @property {string} telefone - Telefone do funcionário.
 *
 * @typedef {Object} TipoUser
 * @property {string} value - Valor do tipo de usuário.
 * @property {string} label - Rótulo do tipo de usuário.
 *
 * @typedef {Object} Permissao
 * @property {string} name - Nome da permissão.
 *
 * @typedef {Object} Usuario
 * @property {Object} user - Objeto do usuário.
 * @property {Permissao[]} user.permissoes_por_grupo - Lista de permissões do usuário.
 *
 * @typedef {Object} ApiResponse
 * @property {Funcionario[]} data - Dados da resposta da API.
 *
 * @typedef {Object} ChangeCase
 * @property {Function} toLowerCase - Função para converter texto para minúsculas.
 * @property {Function} toUpperCase - Função para converter texto para maiúsculas.
 *
 * @typedef {Object} FuncionarioService
 * @property {Function} getByTipoUser - Função para obter funcionários por tipo de usuário.
 *
 * @typedef {Object} CSS
 * @property {Function} css - Função para definir estilos CSS.
 *
 * @typedef {Object} ClipLoader
 * @property {string} color - Cor do loader.
 * @property {boolean} loading - Estado de carregamento do loader.
 * @property {CSS} css - Estilos CSS do loader.
 * @property {number} size - Tamanho do loader.
 *
 * @typedef {Object} TableFuncionarios
 * @property {Function} callback - Função de callback para recarregar funcionários.
 * @property {Funcionario[]} source - Fonte de dados dos funcionários.
 * @property {Function} openModal - Função para abrir o modal.
 * @property {Function} setFuncionarioId - Função para definir o ID do funcionário.
 * @property {Object} permissoes - Objeto de permissões.
 * @property {JSX.Element} createButton - Botão de criação de novo funcionário.
 *
 * @typedef {Object} FuncionariosModal
 * @property {Function} setIsOpen - Função para definir o estado do modal.
 * @property {Function} callback - Função de callback para recarregar funcionários.
 * @property {number} funcionarioId - ID do funcionário.
 * @property {Function} onClose - Função para fechar o modal.
 */
export default function ListagemFuncionarios() {
    usePageBase({
        title: 'Listagem de Funcionários',
        description: 'Página que cosnta a listagem de todos os funcionários cadastrados no sistema.',
        hasTitle: true,
        hasSubMenu: true
    });

    //#region VARIAVEIS
    //Hooks
    const { coletaPermissoesPagina } = useAuth();
    const [loading, setLoading] = useState(true);
    const [color] = useState('#8ed4ae');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [Funcionarios, setFuncionarios] = useState([]);
    const [funcionariosOriginal, setFuncionariosOriginal] = useState([]);
    const [modalData, setModalData] = useState();
    const [filterName, setFilterName] = useState('');
    const [filterTipo, setFilterTipo] = useState('');
    const [tiposUser, setTiposUser] = useState([]);
    const [funcionarioId, setFuncionarioId] = useState(null);
    const [permissoes, setPermissoes] = useState([]);
    const usuario = useAuth();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    //#endregion

    //#region FUNCOES
    const coletaTiposUser = useCallback(() => {
        api.get('api/group?order_by=name:asc')
            .then(response => {
                if (response.data) {
                    const data = response.data;
                    const tiposUser = data.map((tipo) => {
                        return {
                            value: ChangeCase.toLowerCase(tipo.name),
                            label: ChangeCase.toUpperCase(tipo.name),
                        };
                    });
                    setTiposUser(tiposUser);
                }
            });
    }, []);

    const carregarFuncionarios = useCallback(async () => {
        setFuncionarioId(null);
        await FuncionarioService.getByTipoUser('select=id,tipo_user,name,email,status,telefone&sort=name&tipo=!terapeuta,responsavel')
            .then(({ data }) => {
                setFuncionarios(data);
                setFuncionariosOriginal(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }, []);

    const filtrar = useCallback(() => {
        if (filterName !== '' && filterTipo !== '') {
            let newFiltros = funcionariosOriginal;
            newFiltros = newFiltros.filter((e) => {
                return e.name.toLowerCase().includes(filterName.toLowerCase()) && e.tipo_user === filterTipo;
            });
            setFuncionarios(newFiltros);
        } else {
            let allVasil = true;
            if (filterName !== '') {
                setFuncionarios(funcionariosOriginal.filter((e) => {
                    return e.name.toLowerCase().includes(filterName.toLowerCase());
                }));
            } else {
                if (filterTipo !== '') {
                    setFuncionarios(funcionariosOriginal.filter((e) => {
                        return e.tipo_user.toLowerCase() === filterTipo.toLowerCase();
                    }));
                } else {
                    if (allVasil) {
                        setFuncionarios(funcionariosOriginal);
                    }
                }
            }
        }
    }, [filterName, filterTipo, funcionariosOriginal]);

    //#endregion

    //#region HANDLES
    const handleCloseFuncionario = useCallback(() => {
        setIsOpen(false);
        carregarFuncionarios();
    }, [carregarFuncionarios]);

    const handleNewFuncionário = useCallback(() => {
        setModalData(null);
        setIsOpen(true);
    }, []);
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (funcionariosOriginal) {
            filtrar();
        }
    }, [filterTipo, filterName, funcionariosOriginal]);
    useEffect(() => {
        const coletaPermissoes = () => {
            const localPermissoes = coletaPermissoesPagina();
            setPermissoes(localPermissoes);
        };
        carregarFuncionarios();
        coletaPermissoes()
        coletaTiposUser();
    }, []);
    //#endregion

    return (
        <Container>
            <div className="row">
                <div className="col-6 d-flex align-center">
                    <div className="w-100per">
                        <label className="label-filtro">Filtrar por Nome:</label>
                        <input className="input-filtro w-100per mt-20px" placeholder={'Pesquisar Funcionário por Nome:'} value={filterName}
                            onChange={(e) => {
                                setFilterName(e.target.value);
                            }}
                            onFocus={(e) => {
                                e.target.select();
                            }} />
                    </div>
                </div>
                <div className="col-6 d-flex align-center">
                    <div className="w-90per">
                        <label className="label-filtro">Filtrar por Tipo de Usuário:</label>
                        <select className="select-filtro h-38px w-90per mt-20px" value={filterTipo} onChange={(e) => setFilterTipo(e.target.value)}>
                            <option value="">Todos</option>
                            {tiposUser && tiposUser.map((tipo) => {
                                return <option value={tipo.value}>{tipo.label}</option>;
                            })};
                        </select>
                    </div>
                    <div className="w-10per">
                        <button className='btn-padrao h-38px w-100per mt-50px' title='Filtrar' onClick={(e) => { filtrar(); }}> <FiFilter /> </button>
                    </div>
                </div>
            </div>
            <br></br>
            <div style={{ marginBottom: 50 }}>
                {(Funcionarios.length !== 0 && loading === false)
                    ? <TableFuncionarios callback={carregarFuncionarios} source={Funcionarios} openModal={setIsOpen} setFuncionarioId={setFuncionarioId} permissoes={permissoes}
                        createButton={permissoes['criação'] &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <button onClick={() => handleNewFuncionário()} className="btn-padrao w-300px">
                                    <AiOutlinePlusCircle style={{ marginRight: 5 }} />
                                    Novo funcionário
                                </button>
                            </div>
                        } />
                    : <ClipLoader color={color} loading={loading} css={override} size={50} />
                }
            </div>
            {modalIsOpen
                && <FuncionariosModal setIsOpen={setIsOpen} callback={carregarFuncionarios} funcionarioId={funcionarioId} onClose={handleCloseFuncionario} />
            }
        </Container>
    );
}