//#region IMPORTAÇÕES
import { useAuth } from 'context/auth';
import useIcons from 'context/icons';
import React, { cloneElement, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { BsBoundingBoxCircles, BsCheckCircle, BsExclamationCircle, BsPlayCircle, BsQuestionCircle } from 'react-icons/bs';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { useApi } from 'services/api';
import { createPortal } from 'react-dom';
import { useErrorLogger } from 'context/errorLogger';
import ModalBase from 'components/ModalBase';
import { MdDragHandle } from 'react-icons/md';
import moment from 'moment';
//#endregion

//#region ESTILOS
const StatusBar = styled.div`
    /* Layout */
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    height: 30px;
    width: 100%;

    overflow: hidden;

    /* Espaçamento */
    padding-right: 30px;

    /* Transição */
    transition: height 0.3s;

    &.minimiza {
        /* Layout */
        height: 0px;
    }

    /* Estilo */
    background-color: #f8f8f8;
    border-top: 1px solid #e0e0e0;

    p {
        margin: 0;
        padding: 0;
    }

    .divider {
        /* Layout */
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        padding: 0 10px;

        /* Estilo */
        border-right: 1px solid #000;
        border-left: 1px solid #000;

        position: relative;

        &:hover .dropup-content {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

    }
`;

const Button = styled.button`
    /* Posicionamento */
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1002;

    /* Modelo de Caixa */
    padding: 0px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    /* Tipografia */
    font-weight: bold;
    color: white;

    /* Visuais */
    cursor: pointer;

    /* Alinhamento Interno */
    text-align: center;

    svg {
        /* Transição */
        transition: transform 0.3s;
    }

    &.miniza {
        svg {
            transform: rotate(180deg);
        }
    }
`;

const DropupContainer = styled.div`
    /* Layout */
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    z-index: 1003;
    transition: all 0.3s;

    /* Estilo */
    color: #fff !important;

    /* Borda */
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: none;

    /* Box Model */
    box-shadow: -8px -8px 16px 0px rgba(0,0,0,0.5);

    /* Visibility */
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    .dropup-titulo {
        /* Layout */
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        height: 40px;

        /* Estilo */
        background-color: #333;
        border: none;

        span {
            /* Layout */
            align-self: center;
            width: fit-content;
            height: fit-content;

            /* Estilo */
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #333;

            /* Interação */
            user-select: none;
            cursor: move;
            padding: 0;
            margin: 0;
        }

        h6 {
            /* Layout */
            margin: 0;
            padding: 8px 16px;
            text-align: center;

            /* Estilo */
            border: none;
            font-size: 1.2em;
        }

        .botao-fechar {
            /* Layout */
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;

            /* Estilo */
            background-color: transparent;
            color: #FF0000;
            border: none;
            border-radius: 50%;
            padding: 5px 8px 5px 5px;
            font-size: 22px;

            /* Interação */
            cursor: pointer;
        }
    }

    &.show {
        /* Visibility */
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    .dropup-content {
        /* Layout */
        padding: 0;
        text-align: center;
        height: 260px;
        overflow-y: auto;

        input {
            background-color: #2c2c54;
            color: #fff;
        }

        table {
            /* Layout */
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;

            th {
                /* Layout */
                padding: 8px 16px;
                text-align: left;
                position: sticky;
                top: 0;
                z-index: 1;

                /* Estilo */
                background-color: #2c2c54;
                font-weight: bold;
                border-bottom: 1px solid #ddd;
            }

            td {
                /* Layout */
                padding: 8px 16px;
                text-align: left;

                /* Estilo */
                border-bottom: 1px solid #ddd;
            }
        }

        .log-switches{
            /* Layout */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;

            .filter-group {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }

        .content-list {
            /* Layout */
            list-style-type: none;
            padding: 0;
            margin: 0;
            width: 100%;
            border-bottom: 1px solid #ddd;

            li {
                /* Layout */
                padding: 8px 16px;
                text-align: left;

                /* Estilo */
                border-bottom: 1px solid #ddd;

                &:hover {
                    /* Estilo */
                    background-color: #ddd;
                }
            }
        }
    }
`;
//#endregion

//#region DROPUP
const Dropup = React.forwardRef(({ titulo, children, width = 600 }, ref) => {
    const [show, setShow] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const windowSize = useMemo(() => ({ width: window.innerWidth, height: window.innerHeight }), []);
    const top = useMemo(() => windowSize.height - 330, [windowSize]);
    const left = useMemo(() => {
        const pos = position.left;
        const startOf = pos < width;
        const endOf = (windowSize.width - pos) < width;
        if (startOf) {
            return width / 2;
        } else if (endOf) {
            return windowSize.width - (width / 2);
        } else {
            return pos;
        }
    }, [windowSize, position, width]);

    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    const close = () => {
        setShow(false);
        setPosition({ top: 0, left: 0 }); // Reset position when closed
    };

    const open = (newPosition) => {
        setShow(true);
        setPosition(newPosition);
    };

    const handleMouseDown = (e) => {
        e.preventDefault(); // Impede seleção de texto
        setDragging(true);
        setDragStart({ x: e.clientX - position.left, y: e.clientY - position.top });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            setPosition({ top: e.clientY - dragStart.y, left: e.clientX - dragStart.x });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    useImperativeHandle(ref, () => ({
        open,
        close
    }));

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return createPortal(
        <DropupContainer className={`bg-roxo-dark ${show ? 'show' : ''}`} style={{ top: top, left: left, width: width }}>
            <div className='dropup-titulo'>
                <span> <MdDragHandle size={20} onMouseDown={handleMouseDown} /> </span>
                <h6>{titulo}</h6>
                <button type={'button'} className='botao-fechar' onClick={close}>X</button>
            </div>
            <div className='dropup-content bg-roxo'>
                {children}
            </div>
        </DropupContainer>,
        document.body
    );
});
//#endregion

/**
 * Componente BottomStatusBar
 * 
 * Este componente exibe uma barra de status na parte inferior da tela, mostrando informações sobre o status atual, logs de rede e logs do console.
 * 
 * @param {Object} props - Propriedades do componente.
 * @param {number} [props.status=5] - Status atual, representado por um número. Valores possíveis: 0 (Carregando), 1 (Operacional), 2 (Atenção), 3 (Alerta), 4 (Erro), 5 (Indefinido).
 * @param {boolean} [props.alerta=true] - Indica se o alerta está ativo.
 * @param {string} [props.textoAlerta='Alerta'] - Texto a ser exibido quando o alerta está ativo.
 * @param {string} [props.textoCarregando='Carregando'] - Texto a ser exibido durante o carregamento.
 * @param {boolean} [props.carregando=false] - Indica se o sistema está carregando.
 * @param {Object} rest - Outras propriedades que podem ser passadas para o componente.
 * 
 * @returns {JSX.Element} - Retorna o componente BottomStatusBar.
 */
const BottomStatusBar = ({
    status: propStatus = 5,
    alerta: propAlerta = true,
    textoAlerta = 'Alerta',
    textoCarregando = 'Carregando',
    carregando: propCarregando = false,
    ...rest
}) => {
    //#region VARIAVEIS
    // Hooks
    const { iconArray } = useIcons();
    const { user } = useAuth();
    const { networkLogs, activeRequests } = useApi();
    const { logs } = useErrorLogger();
    const tipo_user = useMemo(() => user?.tipo_user, [user]);
    // Referencias
    const dropupRef = useRef([]);
    const modalVisualizaDetalhesRef = useRef();
    // Datas
    const [data, setData] = useState(null);
    // States
    const apiLoading = useMemo(() => activeRequests > 0, [activeRequests]);
    const [status, setStatus] = useState('Indefinido');
    const [statusIcon, setStatusIcon] = useState(null);
    const [carregando, setCarregando] = useState(false);
    const [barraAberta, setBarraAberta] = useState(false);
    const statusList = [
        { text: 'Carregando', icon: <ClipLoader size={16} /> },
        { text: 'Operacional', icon: <BsCheckCircle size={22} color="green" /> },
        { text: 'Atenção', icon: <BsBoundingBoxCircles size={22} color="yellow" /> },
        { text: 'Alerta', icon: <BsPlayCircle size={22} color="orange" /> },
        { text: 'Erro', icon: <BsExclamationCircle size={22} color="red" /> }
    ];
    const [enabledLogTypes, setEnabledLogTypes] = useState({
        request: true,
        response: true,
        error: true,
        info: true,
        warn: true
    });
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedMethods, setSelectedMethods] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredNetworkLogs = useMemo(() =>
        networkLogs.filter(log =>
            log?.url?.includes(searchTerm) || log?.method?.includes(searchTerm) || log?.type?.includes(searchTerm)
        ), [networkLogs, searchTerm]);

    const filteredLogs = useMemo(() => logs.filter(log => log.message.join(" ").includes(searchTerm) || log.type.includes(searchTerm)), [logs, searchTerm]);
    //#endregion
    //#region HANDLES
    const handleTypeChange = (type) => {
        setSelectedTypes(prevState =>
            prevState.includes(type) ? prevState.filter(t => t !== type) : [...prevState, type]
        );
    };

    const handleMethodChange = (method) => {
        setSelectedMethods(prevState =>
            prevState.includes(method) ? prevState.filter(m => m !== method) : [...prevState, method]
        );
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleVisualizaData = (data) => {
        setData(data);
        modalVisualizaDetalhesRef.current.open();
    };

    const handleToggleBarraStatus = () => {
        setBarraAberta(prevState => !prevState);
    };

    const handleToggleDropup = (index) => {
        const element = document.getElementById(`dropup-button-${index}`);
        if (element) {
            const rect = element.getBoundingClientRect();
            const position = {
                top: rect.top - 10,
                left: rect.left + rect.width / 2
            };
            dropupRef.current[index]?.open(position);
        }
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        const status = statusList[propStatus] || {
            text: 'Indefinido',
            icon: <BsQuestionCircle color="gray" />
        };
        setStatus(status.text);
        setStatusIcon(status.icon);
    }, [propStatus]);

    useEffect(() => {
        if (tipo_user?.toLowerCase() === 'desenvolvedor') {
            setBarraAberta(true);
        }
    }, [tipo_user]);

    useEffect(() => {
        setCarregando(propCarregando);
    }, [propCarregando]);
    //#endregion
    //#region RENDER
    if (tipo_user === 'terapeuta' || tipo_user === 'responsavel') {
        return null;
    } else {
        const handleToggleLogType = (type) => {
            setEnabledLogTypes(prevState => ({
                ...prevState,
                [type]: !prevState[type]
            }));
        };

        const handleToggleNetworkLogType = (type) => {
            setEnabledLogTypes(prevState => ({
                ...prevState,
                [type]: !prevState[type]
            }));
        };

        return (
            <>
                <StatusBar id='bottom-status-bar' className={`bg-roxo font-branco ${barraAberta ? '' : 'minimiza'}`} {...rest}>
                    <span id='dropup-button-0' className='divider'>
                        <div className='divider-content' onClick={() => handleToggleDropup(0)}>
                            <ClipLoader className="loading-tooltip" color='#fff' size={22} loading={apiLoading} />
                        </div>
                        <Dropup ref={el => dropupRef.current[0] = el} position={{ top: 0, left: 0 }} titulo='Carregando'>
                            {textoCarregando}
                        </Dropup>
                    </span>
                    <span id='dropup-button-1' className='divider min-w-300px'>
                        <div className='divider-content' onClick={() => handleToggleDropup(1)}>
                            Status: {status} {statusIcon}
                        </div>
                        <Dropup ref={el => dropupRef.current[1] = el} position={{ top: 0, left: 0 }} titulo={`Status: ${status}`}>
                            {textoAlerta}
                        </Dropup>
                    </span>
                    <span id='dropup-button-2' className='divider min-w-300px'>
                        <div className='divider-content' onClick={() => handleToggleDropup(2)}>
                            Rede ({networkLogs.length})
                        </div>
                        <Dropup ref={el => dropupRef.current[2] = el} position={{ top: 0, left: 0 }} width={800} titulo='Listagem dos logs de rede'>
                            <input
                                className='p-2 w-100per'
                                type="text"
                                placeholder="Pesquisar..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <div className="log-switches">
                                <div className="filter-group w-100">
                                    <div className='w-50per d-flex justify-content-start'>
                                        {['request', 'response', 'error'].map(type => (
                                            <div key={type} className="form-check-reverse form-switch me-2 ms-2">
                                                <label className="form-check-label" htmlFor={`network-log-type-${type}`} style={{ textDecoration: enabledLogTypes[type] ? 'none' : 'line-through' }}>
                                                    <span className={`font-${type} p-0 m-0`}>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                                                </label>
                                                <input
                                                    className={`form-check-input switch-${type}`}
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`network-log-type-${type}`}
                                                    checked={enabledLogTypes[type]}
                                                    onChange={() => handleToggleNetworkLogType(type)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='w-50per d-flex justify-content-end'>
                                        {['GET', 'POST', 'PUT', 'DELETE'].map(method => (
                                            <div key={method} className="form-check-reverse form-switch me-2 ms-2">
                                                <label className="form-check-label" htmlFor={`method-${method}`} style={{ textDecoration: selectedMethods.includes(method) ? 'none' : 'line-through' }}>
                                                    <span className={`font-${method} p-0 m-0`}>{method}</span>
                                                </label>
                                                <input
                                                    className={`form-check-input switch-${method}`}
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`method-${method}`}
                                                    checked={selectedMethods.includes(method)}
                                                    onChange={() => handleMethodChange(method)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {filteredNetworkLogs.length > 0 ? (
                                <table className='font-branca'>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Método</th>
                                            <th>URL</th>
                                            <th>Parâmetros</th>
                                            <th>Duração</th>
                                            <th>Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredNetworkLogs
                                            .filter(net => selectedTypes.length === 0 || selectedTypes.includes(net?.type?.toUpperCase()))
                                            .filter(net => selectedMethods.length === 0 || selectedMethods.includes(net.method?.toUpperCase()))
                                            .map((net, index) => {
                                                if (!enabledLogTypes[net.type]) return null;
                                                let icon;
                                                let typeColor;
                                                switch (net?.type) {
                                                    case 'request':
                                                        icon = iconArray['seta-cima'];
                                                        typeColor = '#ADD8E6'; // Light Blue
                                                        break;
                                                    case 'response':
                                                        icon = iconArray['seta-baixo'];
                                                        typeColor = '#90EE90'; // Light Green
                                                        break;
                                                    case 'error':
                                                        icon = iconArray['fechar-circulo'];
                                                        typeColor = '#FFB6C1'; // Light Red
                                                        break;
                                                    default:
                                                        icon = iconArray['perguntar'];
                                                        typeColor = '#D3D3D3'; // Light Gray
                                                        break;
                                                }
                                                const splitedUrl = net?.url?.split('?');
                                                const url = splitedUrl[0];
                                                const params = splitedUrl[1]?.split('&').map(param => {
                                                    const [key, value] = param.split('=');
                                                    const values = value?.split(',')?.map((val, index) => (
                                                        <li key={index}>{val}</li>
                                                    ));
                                                    return (
                                                        <div key={key}>
                                                            <strong>{key}</strong>: <ul>{values}</ul>
                                                        </div>
                                                    );
                                                });
                                                return (
                                                    <tr key={index} onClick={() => handleVisualizaData(net.data)}>
                                                        <td>
                                                            <strong className='d-flex flex-row' title={net.type} style={{ color: typeColor }}>
                                                                {icon}
                                                                {net.status ? (<span style={{ marginLeft: 5, color: net.status === 'success' ? '#90EE90' : '#FFB6C1' }}>{net.status}</span>) : null}
                                                            </strong>
                                                        </td>
                                                        <td>{net.method}</td>
                                                        <td>{url}</td>
                                                        <td>{params}</td>
                                                        <td>{net.duration ? net.duration : 0} ms</td>
                                                        <td>{moment(net.timestamp).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            ) : (
                                'Sem log de rede.'
                            )}
                        </Dropup>
                    </span>
                    <span id='dropup-button-3' className="divider">
                        <div className='divider-content' onClick={() => handleToggleDropup(3)}>
                            Console ({logs.length})
                        </div>
                        <Dropup ref={el => dropupRef.current[3] = el} position={{ top: 0, left: 0 }} titulo='Listagem dos logs do console'>
                            <input
                                type="text"
                                placeholder="Pesquisar..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
                            />
                            <div className="log-switches">
                                <div className='filter-group'>
                                    {Object.keys(enabledLogTypes).map(type => (
                                        <div className="form-check-reverse form-switch me-2 ms-2" key={type}>
                                            <label className="form-check-label" htmlFor={`log-type-${type}`} style={{ textDecoration: enabledLogTypes[type] ? 'none' : 'line-through' }}>
                                                <span className={`font-${type} p-0 m-0`}>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                                            </label>
                                            <input
                                                className={`form-check-input switch-${type}`}
                                                type="checkbox"
                                                role="switch"
                                                id={`log-type-${type}`}
                                                checked={enabledLogTypes[type]}
                                                onChange={() => handleToggleLogType(type)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {filteredLogs.length > 0 ? (
                                <ul className='content-list'>
                                    {filteredLogs.map((log, index) => {
                                        if (!enabledLogTypes[log.type]) return null;
                                        let logColor;
                                        switch (log.type) {
                                            case 'error':
                                                logColor = '#FFB6C1'; // Light Red
                                                break;
                                            case 'warn':
                                                logColor = '#FFD700'; // Light Yellow
                                                break;
                                            case 'info':
                                                logColor = '#ADD8E6'; // Light Blue
                                                break;
                                            default:
                                                logColor = '#D3D3D3'; // Light Gray
                                                break;
                                        }
                                        return (
                                            <li key={index} style={{ color: logColor }}>
                                                <strong>[{log.type.toUpperCase()}]</strong>: {log.message.join(" ")}
                                            </li>
                                        )
                                    })}
                                </ul>
                            ) : (
                                'Sem erros.'
                            )}
                        </Dropup>
                    </span>

                    <ModalBase ref={modalVisualizaDetalhesRef} title='Visualizar Detalhamento' hasCloseButton={true} onOpen={() => { }} onClose={() => { }}>
                        <pre>
                            {JSON.stringify(data, null, 2)}
                        </pre>
                    </ModalBase>
                </StatusBar >
                <Button className={`bg-roxo ${barraAberta ? 'miniza' : ''}`} onClick={handleToggleBarraStatus}>
                    {cloneElement(iconArray['seta-cima'], { size: 17 })}
                </Button>
            </>
        );
    }
    //#endregion
};

export default BottomStatusBar;