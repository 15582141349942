var baseURL = '';
var wsBaseUrl = '';

switch (window.location.hostname) {
    case 'frontend.localhost':
        baseURL = 'https://api.localhost/';
        wsBaseUrl = 'wss://websocket.localhost';
        break;
    case '172.27.214.249':
        baseURL = 'https://172.27.214.249/';
        wsBaseUrl = 'wss://172.27.214.249/ws';
        break;
    case 'sistema.neurointensiva.com':
        baseURL = 'https://sistema.neurointensiva.com/';
        wsBaseUrl = 'wss://sistema.neurointensiva.com/ws';
        break;
    case 'localhost':
    case '127.0.0.1':
    default:
        baseURL = 'http://127.0.0.1:8000/';
        baseURL = 'https://api.localhost/';
        wsBaseUrl = 'ws://localhost:6001/ws';
        break;
}
export { baseURL, wsBaseUrl };