import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { toPng } from 'html-to-image';
import html2PDF from 'jspdf-html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { BsArrowDown, BsArrowUp, BsDownload } from 'react-icons/bs';
import Button from '../../Button';
import AssinaturaA4 from '../Modulos/Assinatura';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import useIcons from 'context/icons';

// Definindo os estilos para cada tipo de folha
const isIphone = /iPhone/.test(navigator.userAgent);
const innerWidth = isIphone ? window.screen.width : window.innerWidth;
const isMobile = innerWidth < 768 || isIphone;
const larguraPaginaMinimap = 210; // 794px
const alturaPaginaMinimap = 297; // 1122.52px

const minimapStyles = {
    minimap: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        position: 'relative',
        transition: 'opacity 0.3s ease',
        opacity: 0.5,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        marginTop: '0.4%',
    },
    minimapHover: {
        opacity: 1,
    },
    thumbnail: {
        cursor: 'pointer',
        border: '1px solid #ccc',
        borderRadius: '4px',
        transformOrigin: 'top left',

        maxWidth: 'calc(' + larguraPaginaMinimap + 'mm*0.2)',
        maxHeight: 'calc(' + alturaPaginaMinimap + 'mm*0.2)',
        minWidth: 'calc(' + larguraPaginaMinimap + 'mm*0.2)',
        minHeight: 'calc(' + alturaPaginaMinimap + 'mm*0.2)',
        padding: 'calc(22mm*0.2)',
        paddingTop: '0',
        paddingBottom: '0',
        margin: '0 auto',
        overflow: 'hidden',
        zIndex: 10,
        backgroundColor: 'white',

        textAlign: 'justify',

        marginBottom: 'calc(20px*0.2)',

        userSelect: 'none',
    },
    thumbnailSelected: {
        border: '2px solid blue',
    },
    thumbnailImage: {
        width: 'calc(30mm * 0.2)',
        height: 'auto',
    },
    thumbnailContent: {
        maxWidth: 'calc(793px * 0.2)',
        maxHeight: 'calc((' + alturaPaginaMinimap + 'mm-44mm) * 0.2)',
        overflow: 'hidden',
        fontSize: 'calc(4.8mm * 0.2)',
        transformOrigin: 'top left',
    },
    thumbnailHeader: {
        fontSize: 'calc(4.8mm * 0.2)',
        height: 'calc(22mm * 0.2)',
        wuidth: '100%',
        justifyContent: 'space-between',
    },
    thumbnailFooter: {
        fontSize: 'calc(4.8mm * 0.2)',
        height: 'calc(22mm * 0.2)',
        wuidth: '100%',
    },
    thumbnailTitle: {
        fontSize: 'calc(7.2mm * 0.2)',
    },
    thumbnailSubTitle: {
        fontSize: 'calc(6mm * 0.2)',
    },
    thumbnailContentText: {
        fontSize: 'calc(4.8mm * 0.2)',
    },
};

const Minimap = ({ pages, onThumbnailClick = () => { }, orientation = 'portrait', currentPage, title, minimapRef }) => {
    const [hover, setHover] = useState(false);
    const minimapSize = {
        width: `${orientation === 'landscape' ? 297 : 210}px`,
        height: `calc(100vh - 84px)`,
    };
    return (
        <div
            ref={minimapRef}
            style={{ ...minimapStyles.minimap, ...minimapSize, ...(hover ? minimapStyles.minimapHover : {}) }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {pages.map((pageContent, index) => (
                <div
                    key={index}
                    style={{ ...minimapStyles.thumbnail, ...(currentPage === index ? minimapStyles.thumbnailSelected : {}) }}
                    onClick={() => onThumbnailClick(index)}
                >
                    <div style={minimapStyles.thumbnailContent}>
                        <div style={minimapStyles.thumbnailHeader}>
                            <img src='/neuro_10_anos-1.png' alt='Logo' style={minimapStyles.thumbnailImage} />
                            <div style={minimapStyles.thumbnailTitle}>{title}</div>
                        </div>
                        <div style={minimapStyles.thumbnailContentText}>
                            {pageContent}
                        </div>
                        <div style={minimapStyles.thumbnailFooter}>
                            <p>Neuro Sistema © 2024. Todos os direitos reservados.</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

/**
 * A4Page component renders a document in A4 portrait format with various functionalities such as PDF export, printing, and navigation.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title of the document.
 * @param {function} [props.onCloseClick=null] - The function to call when the close button is clicked.
 * @param {string} [props.filename=''] - The filename for the exported document.
 * @param {boolean} [props.modalMode=false] - Whether the component is in modal mode.
 * @param {boolean} [props.assinatura=false] - Whether the document has a signature.
 * @param {boolean} [props.assinatura2=false] - Whether the document has a second signature.
 * @param {boolean} [props.hasScrollBar=false] - Whether the component has a scrollbar.
 * @param {string|Array|React.Element} props.content - The content of the document.
 * @param {string} props.orientation - The orientation of the document.
 *
 * @returns {JSX.Element} The rendered A4Page component.
 */
const A4Page = ({ title, onCloseClick = null, filename = '', modalMode = false, assinatura = false, assinatura2 = false, hasScrollBar = false, content, orientation = 'retrato' }) => {
    /**
     * Checks if the orientation is landscape.
     * @returns {boolean} True if the orientation is 'landscape' or 'paisagem', otherwise false.
     */
    const checkIfIsLandscape = () => {
        return orientation === 'landscape' || orientation === 'paisagem';
    };
    //#region VARIÁVEIS
    //Hooks
    const { iconArray } = useIcons();
    const [pages, setPages] = useState([]);
    const [minimapPages, setMinimapPages] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [isPrinting, setIsPrinting] = useState(false);
    const [isPdfing, setIsPdfing] = useState(false);
    const [exportDocumentType, setExportDocumentType] = useState(null);
    const [styleSave1, setStyleSave1] = useState();
    const [styleSave2, setStyleSave2] = useState();
    const documentId = 'pdfable';
    const realFilename = filename ? filename.replace('|', '') : typeof title === 'string' ? title?.replace(/ /g, '_').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace('|', '') : '';
    const minimapRef = useRef(null);
    const saveContainerRef = useRef(null);
    const scrollableRef = useRef(null);
    const toolboxRef = useRef(null);
    const containerRef = useRef(null);
    const pageHeightInPixel = checkIfIsLandscape ? 794 : 1122.52; // Definindo a altura correta da página
    /*
    210mm = 794px
    297mm = 1122.52px
    */
    const larguraPagina = checkIfIsLandscape() ? 297 : 210;
    const alturaPagina = checkIfIsLandscape() ? 210 : 297;
    const [stylesOld, setStylesOld] = useState();
    const stylesBase = {
        page: {
            width: `${Number(larguraPagina)}mm`,
            height: `${Number(alturaPagina)}mm`,
            transformOrigin: 'top left',
            padding: '0',
            margin: '0 auto',
            position: 'relative',
            overflow: 'hidden',
            zIndex: 10,
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(20, 0, 20, 0.4)',
            header: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px',
                width: '100%',
                height: '22mm',
                padding: '0 10px',
                title: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    textAlign: 'center',
                },
                logo: {
                    marginRight: '10px',
                    width: '150px',
                },
            },
            content: {
                fontSize: '16px',
                textAlign: 'justify',
                display: 'flex',
                alignItems: 'start',
                padding: '22mm',
                paddingTop: '0',
                paddingBottom: '0',
                height: alturaPagina - 46 + 'mm',
                flexWrap: 'wrap',
            },
            footer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                marginBottom: '20px',
                width: '100%',
                height: '24mm',
                padding: '0 10px 10px 10px',
                textAlign: 'center',
                flexWrap: 'wrap',
            },
        },
        // Resto dos estilos
    };
    const [styles, setStyles] = useState({
        container: {
            overflow: 'hidden',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'white'
        },
        overScroll: {
            height: 'calc(100vh - 76px)',
            backgroundColor: 'rgba(20, 0, 20, 0.5)',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        scroll: {
            height: 'calc(100vh - 76px)',
            zIndex: 2,
            flexDirection: 'column',
            flexWrap: 'wrap',
            scrollbarWidth: 'none', // Add this line to hide the scrollbar in Firefox
            overflowY: 'scroll',
            '&::WebkitScrollbar': {
                display: 'none', // Add this block to hide the scrollbar in Chrome and Safari
            },
        },
        toolbox: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '10px',
            backgroundColor: 'rgba(40, 0, 40, 0.8)',
            boxShadow: '0px 4px 8px rgba(20, 0, 20, 0.4)',
            zIndex: 200,
            height: `${isMobile ? 100 : 76}px`,
            width: '100%'
        },
        functions: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginLeft: '20px',
            width: '10%',
            margin: '0',
            pdfButton: {
                padding: '10px',
                margin: '0 10px',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                width: 'fit-content',
            },
        },
        informations: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80%',
            margin: '0',
            flexDirection: 'column',
            transform: `scale(${isMobile ? .8 : 1})`,
            actualPage: {
                color: 'white',
            },
            documentoAberto: {
                color: 'white',
                fontWeight: 'bold',
            },
            pageInput: {
                margin: '0 0px 0 10px',
                backgroundColor: 'rgba(100, 0, 100, 0.8)',
                color: 'white',
                textAlign: 'right',
                paddingRight: '5px',
                width: '40px',
            },
        },
        fechar: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '10%',
            botaoFechar: {
                padding: '10px',
                marginRight: '20px',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                fontSize: '30px',
                width: 'fit-content',
            }
        },
        navegation: {
            paddingRight: '20px',
            width: '200px',
            margin: '0',
            display: 'flex',
            alignItems: 'end',
            marginBottom: '20px',
            justifyContent: 'end',
            div: {
                display: 'flex',
                justifyContent: 'end',
                flexDirection: 'column',
            }
        },
        navegationButton: {
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            width: '55px',
            height: '55px',
            padding: '0',
            fontSize: '30px',
        },
        page: {
            width: `${Number(larguraPagina)}mm`,
            height: `${Number(alturaPagina)}mm`,
            transformOrigin: 'top left',
            transform: `scale(${isMobile ? .5 : 1})`,
            padding: '0',
            margin: '0 auto',
            position: 'relative',
            overflow: 'hidden',
            zIndex: 10,
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(20, 0, 20, 0.4)',
            header: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px',
                width: '100%',
                height: '22mm', // 83.86px
                padding: '0 10px 0px 10px',
                title: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    textAlign: 'center',
                },
                logo: {
                    marginRight: '10px',
                    width: '150px',
                },
            },
            content: {
                fontSize: '16px',
                textAlign: 'justify',
                display: 'flex',
                alignItems: 'start',
                padding: '22mm', // 83.86px
                paddingTop: '0',
                paddingBottom: '0',
                height: alturaPagina - 46 + 'mm', // 938.52px
                flexWrap: 'wrap',
            },
            footer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                marginBottom: '20px',
                width: '100%',
                height: '24mm', // 83.86px
                padding: '0px 10px 10px 10px',
                textAlign: 'center',
                flexWrap: 'wrap',
            },
        },
        sombra: {
            boxSizing: 'border-box',
            boxShadow: 'rgba(20, 0, 20, 0.4) 0px 30px 90px',
        },
        hiddenContent: {
            visibility: 'hidden',
            position: 'absolute',
        },
    });
    //#endregion

    //#region FUNCOES
    /**
     * Exports the document in the specified format.
     *
     * @param {string} [type='PDF'] - The type of document to export. Defaults to 'PDF'.
     */
    const exportDocument = (type = 'PDF') => {
        //#region execução
        setExportDocumentType(type);
        setIsPdfing(true);
        //#endregion
    };
    // Função para exportar a página para PDF
    /**
     * Generates a PDF from the specified HTML element.
     * 
     * This function temporarily adjusts the styles of the scrollable and container elements
     * to ensure the content fits correctly in the PDF. After generating the PDF, it restores
     * the original styles.
     * 
     * @function
     * @name pdf
     * 
     * @param {Object} scrollableRef - A reference to the scrollable element.
     * @param {Object} containerRef - A reference to the container element.
     * @param {string} documentId - The ID of the HTML element to convert to PDF.
     * @param {string} realFilename - The desired filename for the generated PDF.
     * @param {number} larguraPagina - The width of the page in millimeters.
     * @param {boolean} setIsPdfing - A function to set the PDF generation state.
     * @param {Object} stylesOld - The original styles to be restored after PDF generation.
     * @param {Function} setStyles - A function to set the styles of the elements.
     * 
     * @returns {void}
     */
    function pdf() {
        //#region variaveis
        // Ajustar a altura da div antes de exportar
        const previousHeight = scrollableRef.current.style.height;
        const previousScrollWidth = scrollableRef.current.style.width;
        const previousShadow = scrollableRef.current.style.boxShadow;
        const previousBorder = scrollableRef.current.style.border;
        const previousConHeight = containerRef.current.style.height;
        const previousWidth = containerRef.current.style.width;
        const previousMargin = containerRef.current.style.margin;
        //#endregion
        //#region execução
        scrollableRef.current.style.height = 'auto';
        scrollableRef.current.style.width = larguraPagina + 'mm';
        scrollableRef.current.style.boxShadow = 'none';
        scrollableRef.current.style.border = 'none';
        containerRef.current.style.height = 'auto';
        containerRef.current.style.width = 'auto';
        containerRef.current.style.margin = '0';

        const element = document.getElementById(documentId);
        const orientation = checkIfIsLandscape() === true ? 'landscape' : 'portrait';

        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: orientation },
            html2canvas: { scale: 4, useCORS: true, logging: true }, // Increased scale for better quality
            image: { type: 'jpeg', quality: 1 },
            output: `${realFilename}.pdf`
        };

        html2PDF(element, newOptions);
        scrollableRef.current.style.height = previousHeight;
        scrollableRef.current.style.width = previousScrollWidth;
        scrollableRef.current.style.boxShadow = previousShadow;
        scrollableRef.current.style.border = previousBorder;
        containerRef.current.style.height = previousConHeight;
        containerRef.current.style.width = previousWidth;
        containerRef.current.style.margin = previousMargin;
        setIsPdfing(false);
        setStyles(stylesOld);
        //#endregion
    }

    const exportToWord = () => {
        // Some header e footer
        for (let index = 1; index <= pages.length; index++) {
            if (index !== pages.length) {
                const footerElement = document.getElementById(`page-footer-${index}`);
                if (footerElement) {
                    footerElement.remove();
                }
            }
            if (index !== 1) {
                const headerElement = document.getElementById(`page-header-${index}`);
                if (headerElement) {
                    headerElement.remove();
                }
            }
        }
        // Converte imagens para base64
        const img = document.getElementById('logo_documento');

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL('image/png');
        img.src = dataURL;

        const toImageElements = Array.from(document.getElementsByClassName('to-img'));

        const promises = toImageElements.map(element =>
            toPng(element)
                .then(dataUrl => {
                    const img = document.createElement('img');
                    img.src = dataUrl;
                    element.replaceWith(img);
                })
                .catch(error => {
                    console.error('Error converting to image:', error);
                })
        );

        Promise.all(promises).then(() => {
            const element = document.getElementById(documentId).innerHTML;

            const htmlContent = `
                <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
                    <head>
                        <meta charset="UTF-8">
                        <style>
                            @page {
                                margin: 0;
                            }
                            .page-break {
                                page-break-after: always;
                            }
                            body {
                                margin: 0;
                                padding: 0;
                            }
                            div {
                                margin-top: 0 !important;
                            }
                        </style>
                    </head>
                    <body>
                        ${element}
                    </body>
                </html>
            `;
            const converted = htmlDocx.asBlob(htmlContent);
            saveAs(converted, `${realFilename}.docx`);
        });
    };
    function exportHTML() {
        // Converte imagens para base64
        const img = document.getElementById('logo_documento');

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL('image/png');
        img.src = dataURL;

        const toImageElements = Array.from(document.getElementsByClassName('to-img'));

        const promises = toImageElements.map(element =>
            toPng(element)
                .then(dataUrl => {
                    const img = document.createElement('img');
                    img.src = dataUrl;
                    element.replaceWith(img);
                })
                .catch(error => {
                    console.error('Error converting to image:', error);
                })
        );

        Promise.all(promises).then(() => {
            const content = document.getElementById(documentId).innerHTML;
            const finalContent = `
                <html xmlns:o='urn:schemas-microsoft-com:office:office'
                    xmlns:w='urn:schemas-microsoft-com:office:word'
                    xmlns='http://www.w3.org/TR/REC-html40'>
                    <head>
                        <meta charset='utf-8'>
                            <title></title>
                    </head>
                    <body>
                        ${content}
                    </body>
                </html>
            `;
            const source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(finalContent);
            let fileDownload = document.createElement("a");
            document.body.appendChild(fileDownload);
            fileDownload.href = source;
            fileDownload.download = `${realFilename}.doc`;
            fileDownload.click();
            document.body.removeChild(fileDownload);
        });
    }
    function exportToDocx() {
        // Converte imagens para base64
        const img = document.getElementById('logo_documento');

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL('image/png');
        img.src = dataURL;

        const toImageElements = Array.from(document.getElementsByClassName('to-img'));

        const promises = toImageElements.map(element =>
            toPng(element)
                .then(dataUrl => {
                    const img = document.createElement('img');
                    img.src = dataUrl;
                    element.replaceWith(img);
                })
                .catch(error => {
                    console.error('Error converting to image:', error);
                })
        );

        Promise.all(promises).then(() => {
            const pagesContent = pages.map(page => page.props.children.props.children); // Extrai o conteúdo de cada página

            // Converte o conteúdo HTML em parágrafos do documento Word
            const sections = pagesContent.map(pageContent => {
                const paragraphs = pageContent.split('<br>').map(line => new Paragraph({
                    children: [new TextRun(line.replace(/<[^>]+>/g, ''))]
                }));
                return {
                    properties: {},
                    children: paragraphs,
                };
            });

            const doc = new Document({
                sections: sections,
            });

            Packer.toBlob(doc).then(blob => {
                saveAs(blob, `${realFilename}.docx`);
            });
        });
    }
    // Função para imprimir a página
    /**
     * Prepares the document for printing by saving the original styles,
     * removing borders, margins, and shadows, and adjusting the width and overflow properties.
     * Also hides the toolbox and sets the printing state to true.
     */
    /* const print = () => {
        // Salvar as configurações originais
        const previousScrollableStyle = { ...scrollableRef.current.style };
        const previousContainerStyle = { ...containerRef.current.style };
        setStyleSave1(previousScrollableStyle);
        setStyleSave2(previousContainerStyle);
 
        // Remover bordas, margens e sombreamentos
        scrollableRef.current.style.border = 'none';
        scrollableRef.current.style.margin = '0';
        scrollableRef.current.style.boxShadow = 'none';
        containerRef.current.style.margin = '0';
        containerRef.current.style.boxShadow = 'none';
        toolboxRef.current.style.display = 'none';
 
        // Definir width como auto e overflowY como visible
        scrollableRef.current.style.width = 'auto';
        scrollableRef.current.style.overflowY = 'visible';
 
        // Restante do código...
        setIsPrinting(true);
    }; */
    /**
     * Calculates the pages and minimap pages based on the provided content.
     * 
     * This function processes the content, which can be an array, string, or React element,
     * and splits it into pages based on the container height. It updates the state with the
     * generated pages and minimap pages.
     * 
     * @function
     * @returns {void}
     */
    const calculaPaginas = () => {
        if (saveContainerRef.current) {
            saveContainerRef.current.style.height = 'auto'; // Garantir que a altura é recalculada
            saveContainerRef.current.style.display = 'block'; // Garantir que a altura é recalculada
        }
        const containerHeight = checkIfIsLandscape ? 500 : 670;
        let tempPages = [];
        let tempMinimapPages = [];

        if (Array.isArray(content)) {
            // Content is an array
            for (let [index, line] of content.entries()) {
                if (React.isValidElement(line)) {
                    // If the content is a React element, treat each element as a separate page
                    tempPages.push(line);
                    tempMinimapPages.push(line);
                } else {
                    let tempContent = '';
                    let lastTitleIndex;
                    let currentHeight = 0;

                    const words = line.content.split(' ');
                    for (let word of words) {
                        tempContent += `${word} `;
                        if (saveContainerRef.current) {
                            saveContainerRef.current.innerHTML = tempContent;
                            currentHeight = saveContainerRef.current.scrollHeight;
                        }

                        if (currentHeight > containerHeight) {
                            // Remove a última palavra para adicionar na próxima página
                            tempContent = tempContent.trim();
                            const lastSpaceIndex = tempContent.lastIndexOf(' ');
                            const pageContent = tempContent.substring(0, lastSpaceIndex);
                            const brContent = pageContent.trim().split('</br>');
                            const newBrContent = <div>
                                {brContent.map((content) => {
                                    return <>
                                        {content}<br></br><br></br>
                                    </>;
                                })}
                            </div>;
                            tempContent = tempContent.substring(lastSpaceIndex + 1);
                            if (lastTitleIndex !== index) {
                                tempPages.push(
                                    <div>
                                        <h4>{line.title}</h4>
                                        {newBrContent}
                                    </div>
                                );
                                tempMinimapPages.push(
                                    <div>
                                        <h4 style={minimapStyles.thumbnailSubTitle}>{line.title}</h4>
                                        {brContent.map((content) => {
                                            return <>
                                                {content}<br></br>
                                            </>;
                                        })}
                                    </div>
                                );
                                lastTitleIndex = index;
                            } else {
                                tempPages.push(newBrContent);
                                tempMinimapPages.push(pageContent.trim());
                            }

                            // Redefinir o container com a palavra restante
                            if (saveContainerRef.current) {
                                saveContainerRef.current.innerHTML = tempContent;
                            }
                        }
                    }
                    if (lastTitleIndex !== index) {
                        const brContent = tempContent.trim().split('</br>');
                        const newBrContent = <div>
                            {brContent.map((content) => {
                                return <>
                                    {content}<br></br><br></br>
                                </>;
                            })}
                        </div>;
                        tempPages.push(
                            <div>
                                <h4>{line.title}</h4>
                                {newBrContent}
                            </div>
                        );
                        tempMinimapPages.push(
                            <div>
                                <h4 style={minimapStyles.thumbnailSubTitle}>{line.title}</h4>
                                {tempContent.trim()}
                            </div>
                        );
                    } else {
                        tempPages.push(tempContent.trim());
                        tempMinimapPages.push(tempContent.trim());
                    }
                }
            }
        } else if (typeof content === 'string') {
            // Content is a string (como antes)
            let tempContent = '';
            let currentHeight = 0;

            const words = content.split(' ');
            for (let word of words) {
                tempContent += `${word} `;
                if (saveContainerRef.current) {
                    saveContainerRef.current.innerHTML = tempContent;
                    currentHeight = saveContainerRef.current.scrollHeight;
                }

                if (currentHeight > containerHeight) {
                    tempContent = tempContent.trim();
                    const lastSpaceIndex = tempContent.lastIndexOf(' ');
                    const pageContent = tempContent.substring(0, lastSpaceIndex);
                    const brContent = pageContent.trim().split('</br>');
                    const newBrContent = <div>
                        {brContent.map((content) => {
                            return <>
                                {content}<br></br><br></br>
                            </>;
                        })}
                    </div>;
                    tempContent = tempContent.substring(lastSpaceIndex + 1);

                    tempPages.push(newBrContent);
                    tempMinimapPages.push(newBrContent);

                    if (saveContainerRef.current) {
                        saveContainerRef.current.innerHTML = tempContent;
                    }
                }
            }
            tempPages.push(tempContent.trim());
            tempMinimapPages.push(tempContent.trim());
        } else if (React.isValidElement(content)) {
            // Content is a React Element (JSX)
            tempPages.push(content);
            tempMinimapPages.push(content); // Use JSX diretamente como conteúdo
        }

        saveContainerRef.current.style.display = 'none';
        setMinimapPages(tempMinimapPages);
        setPages(tempPages);
    };

    /**
     * Navigates to a specified page by scrolling the scrollable element.
     *
     * @param {number} pageIndex - The index of the page to navigate to.
     */
    const goToPage = (pageIndex) => {
        const newPage = pageIndex;
        if (newPage !== paginaAtual) {
            let newScroll = (newPage * pageHeightInPixel) + 20;
            scrollableRef.current.scrollTo({ top: newScroll, behavior: 'smooth' });
        }
    };
    /**
     * Synchronizes the scroll position of a minimap with a scrollable element.
     * 
     * This function calculates the proportion between the scrollable element's
     * client height and its scroll height, then adjusts the minimap's scrollTop
     * based on this proportion and the current scrollTop of the scrollable element.
     * 
     * @function
     * @name syncScroll
     * @returns {void}
     */
    const syncScroll = () => {
        const tamanhoGrande = scrollableRef?.current?.scrollHeight;
        const tamanhoPequeno = scrollableRef?.current?.clientHeight;
        const proporcao = tamanhoPequeno / tamanhoGrande;
        const scrollTop = scrollableRef?.current?.scrollTop;
        const scrollFactor = orientation === 'landscape' ? 0.9 : 0.75;  // Ajuste a proporção no modo paisagem
        minimapRef.current.scrollTop = scrollTop * (proporcao * scrollFactor);
    };
    //#endregion

    //#region HANDLES
    // Função para navegar entre as páginas
    /**
     * Handles navigation button events to change the current page.
     *
     * @param {Object} event - The event object or a string indicating the direction.
     * @param {string} [event.key] - The key pressed during the event.
     * @param {string} [event] - The direction string ('up' or 'down').
     */
    const handleNavigationButton = (event) => {
        let newPage = paginaAtual;
        if (event.key === 'ArrowUp' || event.key === 'PageUp') {
            event.preventDefault();
        }
        if (event.key === 'ArrowUp' || event.key === 'PageUp' || event === 'up') {
            newPage--;
        } else if (event.key === 'ArrowDown' || event.key === 'PageDown' || event === 'down') {
            newPage++;
        }
        goToPage(newPage);
    };
    // Função para atualizar a página atual baseado no scroll
    /**
     * Handles the scroll event for a scrollable element.
     * Calculates the current page based on the scroll position and updates the state.
     * 
     * @function handleScroll
     * @returns {void}
     */
    const handleScroll = () => {
        const { scrollTop } = scrollableRef.current;
        const currentPage = Math.floor(scrollTop / (pageHeightInPixel - 30));
        syncScroll();
        setPaginaAtual(currentPage);
    };
    // Função para mudar a página atual
    /**
     * Handles the change of the page based on the event target value.
     *
     * @param {Object} event - The event object triggered by the change.
     * @param {Object} event.target - The target element of the event.
     * @param {string} event.target.value - The value of the target element, expected to be a string representing the page number.
     */
    const handleChangePage = (event) => {
        const newPage = parseInt(event.target.value) - 1;
        goToPage(newPage);
    };
    /**
     * Handles the click event on a thumbnail and navigates to the specified page.
     *
     * @param {number} pageIndex - The index of the page to navigate to.
     */
    const handleThumbnailClick = (pageIndex) => {
        goToPage(pageIndex);
    };
    //#endregion

    //#region USE EFFECTS
    // Calcula as páginas
    useEffect(() => {
        if (content) {
            setPages([]);
            calculaPaginas();
        }
    }, [content]);
    useEffect(() => {
        if (isPdfing) {
            let stylesSave;
            switch (exportDocumentType) {
                case 'pdfable':
                    stylesSave = { ...styles };
                    setStylesOld(stylesSave);
                    setStyles(stylesBase);
                    break;
                default:
                    break;
            }
        }
    }, [isPdfing]);
    useEffect(() => {
        if (isPdfing) {
            pdf();
        }
    }, [styles]);
    // Imprime a página
    useEffect(() => {
        if (isPrinting) {
            const printOptions = {
                silent: true,
                printBackground: true,
                headers: false,
                margin: 'none'
            };
            window.print(printOptions);
            setIsPrinting(false);
            scrollableRef.current.style.border = styleSave1.border;
            scrollableRef.current.style.margin = styleSave1.margin;
            scrollableRef.current.style.boxShadow = styleSave1.boxShadow;
            containerRef.current.style.margin = styleSave2.margin;
            containerRef.current.style.boxShadow = styleSave2.boxShadow;
            containerRef.current.style.width = styleSave2.width;
            scrollableRef.current.style.width = styleSave1.width;
            scrollableRef.current.style.overflowY = 'auto';
            toolboxRef.current.style.display = 'flex';
            setStyleSave1();
            setStyleSave2();
        }
    }, [isPrinting]);
    // Adiciona o event listener para as teclas de navegação
    useEffect(() => {
        window.addEventListener('keydown', handleNavigationButton);
        return () => {
            window.removeEventListener('keydown', handleNavigationButton);
        };
    }, []);
    //#endregion
    return (
        <div ref={containerRef} id='gera-visualiza-documento' style={{
            ...styles.container, ...modalMode === true ? {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1200,
                width: `${innerWidth - (hasScrollBar ? 20 : 0)}px`,
            } : {}
        }}>
            <div ref={toolboxRef} style={styles.toolbox}>
                <div style={styles.functions}>
                    <button type='button' className='btn-icone hl-laranja' style={styles?.functions?.pdfButton} onClick={() => exportDocument('pdfable')} placeholder='Baixar o documento em PDF.'>
                        <BsDownload size={30} />
                    </button>
                    {/* <Button type='button' style={styles.pdfButton} onClick={() => print()}>Imprimir</Button> */}
                    <button type='button' className='btn-padrao' onClick={() => exportToWord()} placeholder='Baixar o documento em Word.'>{iconArray['baixar']} Word</button>
                    {/* 
                    <button type='button' className='btn-padrao' onClick={() => exportHTML()} placeholder='Baixar o documento em Word.'>Word 2</button>
                    <button type='button' className='btn-padrao' onClick={() => exportToDocx()} placeholder='Baixar o documento em Word.'>Word 3</button> */}
                </div>
                <div style={styles.informations}>
                    <div style={styles.informations?.documentoAberto}>
                        {realFilename + '.pdf'}
                    </div>
                    <div style={styles.informations?.actualPage}>
                        Página <input type='text' style={styles.informations?.pageInput} value={paginaAtual + 1} onChange={(e) => handleChangePage(e)} onFocus={(e) => e.target.select()} /> / {pages?.length}
                    </div>
                </div>
                <p style={styles.fechar}>
                    <button type='button' className='hl-vermelho' style={styles.fechar?.botaoFechar} onClick={() => {
                        if (onCloseClick !== null) {
                            onCloseClick();
                        } else {
                            window.close();
                        }
                    }}>
                        X
                    </button>
                </p>
            </div>
            <div style={styles.overScroll}>
                {!isMobile &&
                    <Minimap minimapRef={minimapRef} pages={minimapPages} orientation={orientation} onThumbnailClick={handleThumbnailClick} currentPage={paginaAtual} />
                }
                <div ref={scrollableRef} id='pdfable' style={{ ...styles.scroll, ...styles.sombra }} onScroll={handleScroll}>
                    {pages.map((pageContent, index) => {
                        const formattedTitle = typeof title === 'string' ? title.split('|').map((part, index) => (
                            <React.Fragment key={index}>
                                {part}
                                {index < title.split('|').length - 1 && <br />}
                            </React.Fragment>
                        )) : title;

                        return (
                            <div className={`page page-${index + 1} page-break`} key={index} style={{ ...styles.page, marginBottom: isPdfing ? '0' : '20px', pageBreakAfter: isPdfing ? 'always' : 'auto' }}>
                                <div id={`page-header-${index + 1}`} style={styles.page.header}>
                                    <img id="logo_documento" src='/neuro_10_anos-1.png' alt='Logo' style={styles.page.header.logo} />
                                    <div style={styles.page.header.title}>{formattedTitle}</div>
                                    <div style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '12px' }}>
                                        Página {index + 1} de {pages.length}
                                    </div>
                                    <p style={styles.page.header.logo}></p>
                                </div>
                                <div id={`page-content-${index + 1}`} style={styles.page.content}>
                                    {pageContent}
                                    {assinatura && <AssinaturaA4></AssinaturaA4>}
                                    {assinatura2 && <AssinaturaA4></AssinaturaA4>}
                                </div>
                                <div id={`page-footer-${index + 1}`} style={styles.page.footer}>
                                    <b>Neuro Intensiva - Centro de Terapias Neurológicas</b>
                                    <p>Rua Victório Malucelli, 360, Hauer - Curitiba - PR | (41) 3044-6930 | sistema.neurointensiva.com</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {!isMobile &&
                    <div style={styles?.navegation}>
                        <div style={styles?.navegation?.div}>
                            <Button type='button' style={styles.navegationButton} onClick={() => handleNavigationButton('up')}> <BsArrowUp></BsArrowUp> </Button>
                            <Button type='button' style={styles.navegationButton} onClick={() => handleNavigationButton('down')}> <BsArrowDown></BsArrowDown> </Button>
                        </div>
                    </div>
                }
            </div>
            <div ref={saveContainerRef} id='savecontainer' style={{ ...styles.page, ...styles.hiddenContent }}></div>
        </div >
    );
};

export default A4Page;