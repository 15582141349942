import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Container } from './styles';
import { Form } from '@unform/web';
import Input, { InputPlusButton, SimpleInput } from '../../Input';
import PlanoSaudeService from '../../../services/PlanoSaudeService';
import InputMask from '../../InputMask';
import { useSnackbar } from '../../../context/snackbar';
import Cep from '../../../services/cep';
import { leftContentDivStyle } from '../../../pages/AdminDashboard/Terapeutas/Novo/styles';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import TerapiasPlano from './TerapiaPlanoModal';
import Swal from 'sweetalert2';
import { CPFService } from '../../../services/CPFService';
import api from '../../../services/api';
import { ClipLoader } from 'react-spinners';
import { TableDefaultPagination } from '../../TableDefaultPagination';
import { IoAddCircle } from 'react-icons/io5';
import Button from 'components/Button';
import { BsTrash } from 'react-icons/bs';
import moment from 'moment';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
const PlanoSaudeModal = ({ setIsOpen, callback, data, terapias, especialidades }, ref) => {
    const [terapiasList, setTerapiasList] = useState([]);
    const [terapiasListOriginal, setTerapiasListOriginal] = useState([]);
    const [modalTerapiasPlano, setModalTerapiasPlano] = useState(false);
    const [modalTerapiasData, setModalTerapiasData] = useState(null);
    const formRef = useRef(null);
    const id = 'modal-plano-saude';
    const snackbar = useSnackbar();
    const [isTerapiasLoading, setIsTerapiasLoading] = useState(false);
    //===VARS VALIDADORES===
    const [listaDePresenca, setListaDePresenca] = useState(0);
    const [exigeGuia, setExigeGuia] = useState(0);
    const [cnpjValid, setCnpjValid] = useState(false);
    const [customStyleCNPJ, setCustomStyleCNPJ] = useState({});
    //===VARS PORCENTAGEM REAJUSTE===
    const [porcentagensDeReajuste, setPorcentagensDeReajuste] = useState([]);
    const [porcentagemReajuste, setPorcentagemReajuste] = useState(null);
    const [dataReajuste, setDataReajuste] = useState(null);
    //===VARS FILTROS===
    const [filtroTerapia, setFiltroTerapia] = useState('');
    const [filtroEspecialidade, setFiltroEspecialidade] = useState('');
    //===FUNCOES VALIDADORES===
    const validaCNPJ = (value = null) => {
        if (value !== null && value.target !== undefined) {
            value = value.target.value;
        }
        let response = CPFService.ValidaCNPJ(value);
        setCnpjValid(response.isValid);
        setCustomStyleCNPJ(response.style);
    };
    const columns = useMemo(
        () => [
            {
                Header: 'Terapia:',
                accessor: 'terapium.terapia',
            },
            {
                Header: 'Especialidade:',
                accessor: 'especialidade.especialidade',
            },
            {
                Header: 'Código 1:',
                accessor: 'codigo_plano',
                Cell: ({ cell }) => (
                    (cell.row.original.codigo_plano !== undefined && cell.row.original.codigo_plano !== null)
                        ? <>{cell.row.original.codigo_plano}</>
                        : '-'
                )
            },
            {
                Header: 'Código 2:',
                accessor: 'codigo_tuss',
                Cell: ({ cell }) => (
                    (cell.row.original.codigo_tuss !== undefined && cell.row.original.codigo_tuss !== null)
                        ? <>{cell.row.original.codigo_tuss}</>
                        : '-'
                )
            },
            {
                Header: 'Limite Diario:',
                accessor: 'limite_diario',
                Cell: ({ cell }) => (
                    (cell.row.original.limite_diario !== undefined && cell.row.original.limite_diario !== null)
                        ? <>{cell.row.original.limite_diario}</>
                        : '-'
                )
            },
            {
                Header: 'Valor:',
                accessor: 'valor',
                Cell: ({ cell }) => (
                    (cell.row.original.valor !== undefined && cell.row.original.valor !== null)
                        ? <>R$ {cell.row.original.valor}</>
                        : '-'
                )
            },
            {
                Header: 'Opções',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <AiOutlineEdit title={cell.row.original.id} style={{ cursor: 'pointer', }} size={20} onClick={e => handleEditTerapia(cell.row.original)} />
                            <AiOutlineDelete title={cell.row.original.id} style={{ cursor: 'pointer' }} size={20} onClick={e => handleDeleteTerapia(cell.row.original, terapiasListOriginal)} />
                        </div>
                    )
                )
            }
        ]
    );
    const handleOutsideClick = (e) => {
        if (e.target.id === id) setIsOpen(false);
    };
    const handleOpenTerapias = () => {
        setModalTerapiasPlano(true);
        setModalTerapiasData(null);
    };
    const handleDeleteTerapia = (terapia, terapiasLocal) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (data === null || data === undefined) {
                    const index = terapiasLocal.indexOf(terapia);
                    terapiasLocal.splice(index, 1);
                    setTerapiasList(terapiasLocal);
                    setTerapiasListOriginal(terapiasLocal);
                    snackbar.displayMessage('Terapia removida com sucesso!', 'success');
                } else {
                    api.delete('api/plano-saude-terapias/' + terapia.id).then(() => {
                        alteraListaTerapias(true, terapia.id);
                        snackbar.displayMessage('Terapia removida com sucesso!', 'success');
                    }).catch((e) => {
                        console.error(e);
                        snackbar.displayMessage('Houve um erro ao remover a terapia do plano Plano', 'error');
                    });
                }
            }
        });
    };
    const handleSubmit = (dados) => {
        dados.plano = dados.nome_reduzido;
        dados.exige_lista_presenca = listaDePresenca;
        dados.exige_guia = exigeGuia;
        delete (dados.terapia);
        delete (dados.especialidade);
        delete (dados.valor);
        if (cnpjValid) {
            try {
                if (data !== undefined) {
                    PlanoSaudeService.update(dados, data.id).then(() => {
                        snackbar.displayMessage('Plano de saúde alterado com sucesso!', 'success');
                    }).catch(e => {
                        console.error(e);
                        snackbar.displayMessage('Houve um erro ao alterar o Plano', 'error');
                    });
                } else {
                    PlanoSaudeService.store(dados).then(({ data }) => {
                        snackbar.displayMessage('Plano de saúde criado com sucesso!', 'success');
                        for (let index = 0; index < terapiasListOriginal.length; index++) {
                            const e = terapiasListOriginal[index];
                            let obj = { ...e, plano_saude_id: data.id };

                            delete (obj.especialidade);
                            delete (obj.terapium);

                            api.post('api/plano-saude-terapias', obj).catch((e) => {
                                console.error(e);
                                snackbar.displayMessage('Houve um erro ao criar/alterar o Plano', 'error');
                            });
                        }
                    }).catch(e => {
                        console.error(e);
                        snackbar.displayMessage('Houve um erro ao criar/alterar o Plano', 'error');
                    });
                }
            } catch (error) {
                snackbar.displayMessage('Houve um erro ao criar/alterar o Plano', 'error');
                console.error(error);
            } finally {
                formRef.current.setData(dados);
                setIsOpen(false);
                callback(true);
            }
        }
    };
    const handleEditTerapia = (terapia) => {
        setModalTerapiasData(terapia);
        setModalTerapiasPlano(true);
    };
    const handleAddPorcentagemReajuste = () => {
        if (!porcentagemReajuste && !dataReajuste) {
            Swal.fire('Por favor, preecha todos os campos da porcentagem de reajuste!', '', 'error');
            return;
        }
        const obj = {
            plano_saude_id: data.id,
            porcentagem: porcentagemReajuste,
            data: dataReajuste,
        };
        api.post('api/porcentagem-reajuste-plano', obj).then(() => {
            snackbar.displayMessage('Porcentagem de reajuste adicionada com sucesso!', 'success');
        }).catch(error => {
            console.error(error);
            snackbar.displayMessage('Porcentagem de reajuste não pode ser adicionada!', 'error');
        });
    };
    const handleDeletePorcentagemReajuste = (id) => {
        Swal.fire({
            title: 'Deseja realmente deletar essa Porcentagem de Reajuste?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Deletar!',
            cancelButtonAriaLabel: 'Volar'
        }).then((response) => {
            if (response.isConfirmed === true) {
                api.delete(`api/porcentagem-reajuste-plano/${id}?id=${id}`)
                    .then(() => {
                        snackbar.displayMessage('Porcentagem de Reajuste deletado com sucesso!', 'success');
                    }).catch((error) => {
                        console.error(error);
                        snackbar.displayMessage('Não foi possível deletar a Porcentagem de Reajuste!', 'error');
                    });
            }
        });
    };
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco').value = data.logradouro;
        document.getElementById('bairro').value = data.bairro;
        document.getElementById('estado').value = data.uf;
        document.getElementById('cidade').value = data.localidade;
    };
    const alteraListaTerapias = (subtrair = true, terapia_alterar) => {
        let listaAlterada = (subtrair)
            ? terapiasListOriginal.filter((e) => (terapia_alterar !== e.id))
            : { ...terapiasListOriginal, terapia_alterar };
        setTerapiasListOriginal(listaAlterada);
        setTerapiasList(listaAlterada);
    };
    const reloadTerapias = () => {
        api.get('api/plano-saude-terapias?filter[plano_saude_id]=' + data.id).then(({ data }) => {
            let terapiasListLocal = data;
            setTerapiasList(terapiasListLocal);
            setTerapiasListOriginal(terapiasListLocal);
        });
    };
    const filtrar = (e) => {
        let filtroTerapiaL;
        let filtroEspeciaL;
        switch (e.target.id) {
            case ('filtroTerapia'):
                setFiltroTerapia(e.target.value);
                filtroTerapiaL = e.target.value;
                filtroEspeciaL = filtroEspecialidade;
                break;
            case ('filtroEspecialidade'):
                setFiltroEspecialidade(e.target.value);
                filtroEspeciaL = e.target.value;
                filtroTerapiaL = filtroTerapia;
                break;
        }
        let terapiasFiltradas = terapiasListOriginal.filter(
            (t) => {
                let filter = false;
                if (filtroEspeciaL === null || filtroEspeciaL === '') {
                    if (filtroTerapiaL !== null || filtroTerapiaL !== '') {
                        filter = t.terapium.terapia.toLowerCase().includes(filtroTerapiaL.toLowerCase());
                    }
                } else {
                    if (filtroTerapiaL === null || filtroTerapiaL === '') {
                        filter = t.especialidade.especialidade.toLowerCase().includes(filtroEspeciaL.toLowerCase());
                    } else {
                        filter = t.especialidade.especialidade.toLowerCase().includes(filtroEspeciaL.toLowerCase()) && t.terapium.terapia.toLowerCase().includes(filtroTerapiaL.toLowerCase());
                    }
                }
                return filter;
            }
        );
        setTerapiasList(terapiasFiltradas);
    };
    //#endregion
    useEffect(() => {
        let cnpj;
        try {
            setIsTerapiasLoading(true);
            if (data !== undefined && data !== null) {
                cnpj = data.cnpj;
                if (data.plano_saude_terapia !== undefined && data.plano_saude_terapia !== null && data.plano_saude_terapia.length > 0) {
                    const terapiasListLocal = data.plano_saude_terapia ? data.plano_saude_terapia : [];
                    const porcentagensDeReajusteLocal = data.porcentagem_reajuste_planos ? data.porcentagem_reajuste_planos : [];
                    setTerapiasList(terapiasListLocal);
                    setTerapiasListOriginal(terapiasListLocal);
                    setPorcentagensDeReajuste(porcentagensDeReajusteLocal);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            validaCNPJ(cnpj);
            setIsTerapiasLoading(false);
        }
    }, []);
    return (<div>
        <Modal id={id} onMouseDown={handleOutsideClick}>
            <Container className='pt-0'>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'>
                        </div>
                        <ModalTitulo>{data === '' ? 'Adicionar ' : 'Alterar '}Plano de Saúde</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => setIsOpen(false)}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <Form ref={formRef} onSubmit={handleSubmit} className="row">
                    <fieldset className='ns-fieldset mt-4'>
                        <legend>Dados do Plano</legend>
                        <div className='row'>
                            <div className='col-12'>
                                <Input required={'required'} name={'razao_social'} title={'Razão social'} defaultValue={data === undefined ? '' : data.razao_social} />
                            </div>
                            <div className='col-6'>
                                <Input required={'required'} name={'convenio'} title={'Convênio médico'}
                                    defaultValue={data === undefined ? '' : data.convenio} />
                            </div>
                            <div className='col-6'>
                                <Input required={'required'} name={'nome_reduzido'} title={'Nome reduzido'}
                                    defaultValue={data === undefined ? '' : data.nome_reduzido} />
                            </div>
                            <div className='col-6'>
                                <Input containerStyle={customStyleCNPJ} required={'required'} name={'cnpj'} title={'CNPJ'}
                                    defaultValue={data === undefined ? '' : data.cnpj} onChange={(e) => { validaCNPJ(e); }} />
                            </div>
                            <div className='col-6'>
                                <Input type={'number'} name={'registro'} title={'Registro Nº'} defaultValue={data === undefined ? '' : data.registro} />
                            </div>
                        </div>
                    </fieldset>
                    {data !== null &&
                        <fieldset className='ns-fieldset mt-4'>
                            <legend>Porcentagem de Reajuste</legend>
                            <div className='row'>
                                <div className='col-6'>
                                    <Input id="porcentagem-reajuste" name="porcentagem-reajuste" title="Porcentagem de Reajuste" type="number" step={0.000001} onChange={(e) => setPorcentagemReajuste(e.target.value)} />
                                </div>
                                <div className='col-5'>
                                    <Input id="data-reajuste" name="data-reajuste" title="Data de Reajuste" type="date" onChange={(e) => setDataReajuste(e.target.value)} />
                                </div>
                                <div className='col-1 align-content-end'>
                                    <Button type="button" onClick={(e) => handleAddPorcentagemReajuste()} >
                                        <IoAddCircle></IoAddCircle>
                                    </Button>
                                </div>
                            </div>
                            {porcentagensDeReajuste.length > 0 &&
                                porcentagensDeReajuste.map((linha) => {
                                    const id = linha.id;
                                    const porcentagem = linha.porcentagem;
                                    const data = moment.utc(linha.data).format('YYYY-MM-DD');
                                    return (
                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <Input id="porcentagem-reajuste[]" name="porcentagem-reajuste[]" type="number" step="0.01" value={porcentagem} disabled={true} />
                                            </div>
                                            <div className='col-5'>
                                                <Input id="data-reajuste[]" name="data-reajuste[]" type="date" value={data} disabled={true} />
                                            </div>
                                            <div className='col-1 align-content-end'>
                                                <Button type="button" onClick={(e) => handleDeletePorcentagemReajuste(id)} >
                                                    <BsTrash></BsTrash>
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                        </fieldset>
                    }
                    <fieldset className='ns-fieldset mt-4'>
                        <legend>Terapias e Especialidades Cobertas</legend>
                        <div className="row">
                            <div className='col-12'>
                                <InputPlusButton type={'number'} title={'Limite Diário de Terapias'} id="limite_diario_terapias" name="limite_diario_terapias" defaultValue={data ? data.limite_diario_terapias : 0}
                                    button={
                                        <>
                                            <button
                                                type='button'
                                                className='btn-icone'
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                <svg style={{ alignItems: 'center' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                                    <text x="12" y="17" textAnchor="middle" fontSize="14" fill="rgb(149, 134, 172)" fontWeight='bold'>?</text>
                                                </svg>
                                            </button>
                                            <div className="dropdown-menu w-100 p-4 text-wrap" style={{ maxWidth: '400px' }}>
                                                Nesse campo, você define a quantidade limite de terapias que um paciente pode ter no mesmo dia por esse plano. Coloque zero, para não haver limite.
                                            </div>
                                        </>
                                    } />
                            </div>
                        </div>
                        {(isTerapiasLoading)
                            ? <div><ClipLoader></ClipLoader></div>
                            : terapiasList.length === 0 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 5 }}>
                                    <span>
                                        Nenhuma terapia encontrada
                                    </span>
                                </div>
                                :
                                <div className='col mt-20px'>
                                    <div className='justify-between col-12 d-flex flex-row'>
                                        <div className='w-50per m-3px'>
                                            <Input value={filtroTerapia} name='filtroTerapia' id='filtroTerapia' onChange={e => filtrar(e)} title='Filtrar por nome de terapia' placeholder='Filtrar por nome de terapia:' />
                                        </div>
                                        <div className='w-50per m-3px'>
                                            <Input value={filtroEspecialidade} name='filtroEspecialidade' id='filtroEspecialidade' onChange={e => filtrar(e)} title='Filtrar por nome de especialidade' placeholder='Filtrar por nome de especialidade:' />
                                        </div>
                                    </div>
                                    <TableDefaultPagination columns={columns} source={terapiasList} className='mt-20px' />
                                </div>
                        }
                        <div style={leftContentDivStyle} className='mt-20px'>
                            <button onClick={e => handleOpenTerapias()} type="button" className='btn-padrao'> Adicionar </button>
                        </div>
                    </fieldset>
                    <fieldset className='ns-fieldset mt-4'>
                        <legend>Endereço</legend>
                        <div className="row">
                            <div className='col-4'>
                                <InputMask maskFormat={'99999-999'} name={'cep'} title={'Cep'} defaultValue={data === undefined ? '' : data.cep} required={'required'} onBlur={e => getCep(e.target.value)} />
                            </div>
                            <div className='col-6'>
                                <Input name={'logradouro'} title={'Rua'} defaultValue={data === undefined ? '' : data.logradouro} required={'required'} id="endereco" />
                            </div>
                            <div className='col-2'>
                                <Input name={'numero'} title={'Nº'} defaultValue={data === undefined ? '' : data.numero} required={'required'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input name={'cidade'} title={'Cidade'} defaultValue={data === undefined ? '' : data.cidade} id="cidade" />
                            </div>
                            <div className='col-1'>
                                <Input name={'estado'} title={'Estado'} defaultValue={data === undefined ? '' : data.estado} id="estado" />
                            </div>
                            <div className="col-5">
                                <Input name={'bairro'} title={'Bairro'} defaultValue={data === undefined ? '' : data.bairro} required={'required'} id="bairro" />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <Input name={'complemento'} title={'Complemento'} defaultValue={data === undefined ? '' : data.complemento} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="ns-fieldset mt-4">
                        <legend>Parâmetros do Plano</legend>
                        <div className="row">
                            <div className="col-3 d-flex align-items-center">
                                <div className="form-check form-switch">
                                    <SimpleInput className={'form-check-input'}
                                        name={'exige_lista_presenca'}
                                        id={'exige_lista_presenca'}
                                        defaultChecked={data?.exige_lista_presenca}
                                        onChange={(e) => setListaDePresenca(e.target.checked)}
                                        type={'checkbox'}
                                        role="switch" />
                                    <label className="form-check-label unselectable" htmlFor="exige_lista_presenca">Exige Lista de Presença</label>
                                </div>
                            </div>
                            <div className="col-3 d-flex align-items-center">
                                <div className="form-check form-switch">
                                    <SimpleInput className={'form-check-input'}
                                        name={'exige_guia'}
                                        id={'exige_guia'}
                                        defaultChecked={data?.exige_guia}
                                        onChange={(e) => setExigeGuia(e.target.checked)}
                                        type={'checkbox'}
                                        role="switch" />
                                    <label className="form-check-label unselectable" htmlFor="exige_guia">Exige Guia</label>
                                </div>
                            </div>
                            <div className="col-3">
                                <Input name={'nome_codigo_1'} id={'nome_codigo_1'} title={'Nome Código 1'} defaultValue={data === undefined ? '' : data.nome_codigo_1} />
                            </div>
                            <div className="col-3">
                                <Input name={'nome_codigo_2'} id={'nome_codigo_2'} title={'Nome Código 2'} defaultValue={data === undefined ? '' : data.nome_codigo_2} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="ns-fieldset mt-4">
                        <legend>Contato</legend>
                        <div className="row">
                            <div className="col-6">
                                <Input name={'contato_nome'} title={'Nome'} defaultValue={data === undefined ? '' :
                                    data.contato_nome} required={'required'} />
                            </div>
                            <div className="col-6">
                                <Input name={'contato_cargo'} title={'Cargo'} defaultValue={data === undefined ? '' :
                                    data.contato_cargo} required={'required'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input name={'contato_email'} title={'E-mail'} defaultValue={data === undefined ? '' :
                                    data.contato_email} required={'required'} />
                            </div>
                            <div className="col-6">
                                <InputMask maskFormat={'(99) 99999-9999'} name={'contato_telefone'} title={'Telefone'} defaultValue={data === undefined ? '' : data.contato_telefone} required={'required'} />
                            </div>
                        </div>
                    </fieldset>
                    <button className='btn-padrao mt-4' type="submit" >
                        {data !== undefined ? 'Alterar Plano de Saúde' : 'Adicionar Plano de Saúde'}
                    </button>
                </Form>
            </Container>
            {(modalTerapiasPlano === true)
                ? <TerapiasPlano
                    onClose={() => setModalTerapiasPlano(false)}
                    terapias={terapias}
                    especialidades={especialidades}
                    planoSaudeId={data === undefined ? null : data.id}
                    modalData={modalTerapiasData}
                    terapiasList={terapiasListOriginal}
                    setTerapiasList={setTerapiasList}
                    setTerapiasListOriginal={setTerapiasListOriginal}
                    reloadTerapias={reloadTerapias}
                />
                : <></>
            }
        </Modal>
    </div>);
};
export default forwardRef(PlanoSaudeModal);