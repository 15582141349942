import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { wsBaseUrl } from 'services/baseUrl';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    //#region VARIAVEIS
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const [socketLink, setSocketLink] = useState(null);
    const [conectaComSocket, setConectaComSocket] = useState(false);

    const urlWS = useMemo(() => `${wsBaseUrl}/${socketLink}`, [socketLink]);
    //#endregion

    //#region FUNCOES
    const sendMessage = useCallback(() => {
        if (socket && newMessage !== '' && newMessage !== null) {
            switch (typeof newMessage) {
                case 'object':
                    socket.send(JSON.stringify(newMessage));
                    break;
                default:
                    socket.send(newMessage);
                    break;
            }
            setNewMessage(null);
        }
    }, [socket, newMessage, setNewMessage]);
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        const handleMessage = (event) => {
            const data = JSON.parse(event.data);
            if (data !== null) {
                setMessages((prevMessages) => [...prevMessages, data]);
            }
        };
        const handleOpen = () => {
            setSocketState(true);
        };
        const handleClose = () => {
            setSocketState(false);
            reconnect();
        }
        const handleConectaComSocket = () => {
            if (!socket) {
                if (socketLink) {
                    const socketLocal = new WebSocket(urlWS);

                    socketLocal.addEventListener('open', handleOpen);

                    socketLocal.addEventListener('close', handleClose);

                    socketLocal.addEventListener('message', handleMessage);

                    setSocket(socketLocal);
                }
            }
        }
        const reconnect = () => {
            setTimeout(handleConectaComSocket, 5000);
        };

        handleConectaComSocket();
    }, [socket, socketLink, conectaComSocket]);

    useEffect(() => {
        if (newMessage === null) return;
        sendMessage();
    }, [newMessage]);
    //#endregion

    return (
        <WebSocketContext.Provider value={{ socketState, messages, setSocketLink, setNewMessage, setConectaComSocket }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};