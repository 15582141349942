import { Form } from '@unform/web';
import Button from 'components/Button';
import A4Page from 'components/Folha/A4-Retrato';
import AssinaturaA4 from 'components/Folha/Modulos/Assinatura';
import Input from 'components/Input';
import Select from 'components/Select';
import { useAuth } from 'context/auth';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import { cloneElement, useEffect, useMemo, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import Swal from 'sweetalert2';
import { Tr0, Th1, ThHora, TdOcupado, TdVazio } from './styles';
import useIcons from 'context/icons';

const LongLabelBase = (props) => {
    return (
        <span>
            <b className={props.className[0] !== undefined ? props.className[0] : 'font-12px'}>{props.dayName[0]}</b>
            <span className={props.className[1] !== undefined ? props.className[1] : 'font-8px'}>{props.dayName.slice(1, props.dayName.length)}</span>
        </span>
    );
};

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

/**
 * Componente Declaracoes.
 * 
 * Este componente permite a geração de declarações de comparecimento e atendimentos feitos.
 * Utiliza diversos hooks e estados para gerenciar os dados e a interface do usuário.
 * 
 * @component
 * @example
 * return (
 *   <Declaracoes />
 * )
 * 
 * @returns {JSX.Element} O componente Declaracoes.
 */
const Declaracoes = () => {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Declarações',
        description: 'Gere declarações de comparecimento e atendimentos feitos.',
        hasTitle: true,
        hasSubnavbar: false,
        routes: [
            '/dashboard/{tipo_user}/declaracoes',
        ],
    })
    const { coletaPermissoesPagina } = useAuth();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const { iconArray } = useIcons();

    // Parametros
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

    const [pacienteId, setPacienteId] = useState(null);
    const [paciente, setPaciente] = useState(null);

    // Lista de atendimentos
    const [agendamentos, setAgendamentos] = useState(null);
    const [atendimentos, setAtendimentos] = useState(null);

    // Estado de carregamento
    const [loading, setLoading] = useState(false);

    // Estado de geração de documento
    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);

    // Dados do documento
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');
    const [orientation, setOrientation] = useState('portrait');

    // Data
    const [horarios, setHorarios] = useState([]);
    const [temHorarios, setTemHorarios] = useState();
    const [temDias, setTemDias] = useState();
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'S',
            longLabel: <LongLabelBase dayName='Segunda-Feira' className={['', '']} />,
        },
        {
            value: 2,
            label: 'T',
            longLabel: <LongLabelBase dayName='Terça-Feira' className={['', '']} />,
        },
        {
            value: 3,
            label: 'Q',
            longLabel: <LongLabelBase dayName='Quarta-Feira' className={['', '']} />,
        },
        {
            value: 4,
            label: 'Q',
            longLabel: <LongLabelBase dayName='Quinta-Feira' className={['', '']} />,
        },
        {
            value: 5,
            label: 'S',
            longLabel: <LongLabelBase dayName='Sexta-Feira' className={['', '']} />,
        },
        {
            value: 6,
            label: 'S',
            longLabel: <LongLabelBase dayName='Sábado' className={['', '']} />,
        },
    ];

    // Dados do usuário
    const permissoes = useMemo(() => coletaPermissoesPagina(), [coletaPermissoesPagina]);

    // Habilita comparecimento
    const comparecimento = useMemo(() => {
        const hojeSelecionado = dataInicio === moment().format('YYYY-MM-DD');
        if (hojeSelecionado) {
            return false;
        } else {
            return !permissoes['comparecimento_retroativo'];
        }
    }, [dataInicio, dataFim, permissoes]);

    //#endregion
    //#region FUNCOES
    const carregaPaciente = () => {
        api.get(`/api/paciente/${pacienteId?.value}?with=agendamentos,agendamentos.estacao,agendamentos.terapium&filter[reserva]=0`)
            .then((response) => {
                let paciente = response.data;
                if (response.status === 200) {
                    let agendamentos = paciente.agendamentos.map((atendimento) => {
                        atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                        let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                        atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                        return atendimento;
                    });
                    let dias = [];
                    let horarios = [];
                    agendamentos.forEach(agenda => {
                        if (dias.filter((inc) => inc === moment.utc(agenda.data_atendimento_inicial).weekday()).length === -0) {
                            dias.push(moment.utc(agenda.data_atendimento_inicial).weekday());
                        }

                        if (horarios.filter(horario => horario === agenda.horarios_atendimento_id).length === 0) {
                            horarios.push(agenda.horarios_atendimento_id);
                        }
                    });
                    paciente.agendamentos = agendamentos;
                    setTemDias(dias);
                    setTemHorarios(horarios);
                }
                setPaciente(paciente);
            });
    };
    const carregaAgendaHorarios = () => {
        api.get(`/api/horario-atendimento?filter[dia_semana]=${moment().weekday()}`).then(({ data }) => {
            setHorarios(data);
        });
    };
    const carregaAgendaDia = () => {
        const dataSegundaFeira = moment().isoWeekday(1).format('YYYY-MM-DD');
        const dataSextaFeira = moment().isoWeekday(5).format('YYYY-MM-DD');
        api.get(`/api/agendamento?filter[paciente_id]=${pacienteId?.value}&filter[between]=${dataSegundaFeira},${dataSextaFeira}&with=estacao`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAgendamentos(data);
        });
    };
    //#endregion
    //#region HANDLES
    /**
     * Manipula a mudança de valor dos campos de entrada.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.id - O ID do alvo do evento.
     * @param {string} e.target.name - O nome do alvo do evento.
     * @param {string} e.target.value - O valor do alvo do evento.
     */
    const handleChange = (e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        setAtendimentos(null);

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                break;
            case 'data_fim':
                setDataFim(value);
                break;
            case 'paciente_id':
                setPacienteId(pacienteOptions.find(option => option.value === value));
                break;
            default:
                break;
        }
    };
    /**
     * Função assíncrona que lida com o envio de dados para gerar um relatório filtrado.
     * Define o estado de carregamento como verdadeiro, cria um objeto de filtros com as datas de início e fim e o ID do paciente,
     * e faz uma requisição POST para a API com esses filtros. Se a requisição for bem-sucedida, define os atendimentos com os dados
     * da resposta e define o estado de carregamento como falso. Em caso de erro, exibe o erro no console e define o estado de carregamento como falso.
     * 
     * @async
     * @function handleSubmit
     * @returns {Promise<void>} Uma Promise que resolve quando a operação é concluída.
     */
    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (!pacienteId) {
                Swal.fire('Erro', 'Selecione um paciente!', 'error');
                setLoading(false);
                return;
            }
            const filters = {
                data_inicio: dataInicio,
                data_fim: dataFim,
                paciente_id: pacienteId?.value,
                sugestao_recepcao: 1,
                duplica_alteracoes: false
            };
            const response = await api.post('api/atendimento/relatorio/filtrado/puro', filters);
            const sortedAtendimentos = response.data.sort((a, b) => {
                const [dayA, monthA, yearA] = a.data_do_atendimento.split(' ')[0].split('/');
                const [dayB, monthB, yearB] = b.data_do_atendimento.split(' ')[0].split('/');
                return new Date(`${yearA}-${monthA}-${dayA}T${a.hora_atendimento}`) - new Date(`${yearB}-${monthB}-${dayB}T${b.hora_atendimento}`);
            });
            setAtendimentos(sortedAtendimentos.filter((filter) => filter.tipo_atendimento_id !== 8 && filter.sugestao_recepcao === 1));
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    /**
     * Gera o relatório de agendamento do paciente.
     * 
     * Define o título do relatório com o nome do paciente e a data atual.
     * Ordena os agendamentos do paciente por dia da semana e hora inicial.
     * Divide os agendamentos em grupos de até 18 por página.
     * Gera o conteúdo do relatório em formato de tabela, com informações sobre o dia da semana, sala, estação e terapia.
     * Adiciona uma observação sobre a obrigatoriedade do acompanhamento de um responsável legal.
     * Adiciona a assinatura na última página do relatório.
     * Define o conteúdo do relatório, o nome do arquivo e abre o modal de geração de documento.
     * 
     * @function handleGeraRelatorioAgendamento
     */
    const handleGeraRelatorioAgendamento = () => {
        setTitle(`Relatório de Agendamento ${paciente.nome} ${moment().format('DD/MM/YYYY')}`);
        const sortedAgendamentos = agendamentos.sort((a, b) => {
            return a.dia_semana_index - b.dia_semana_index || a.hora_inicial.localeCompare(b.hora_inicial);
        });
        const chunkAgendamentos = sortedAgendamentos.filter(filter => {
            return filter.tipo_atendimento_id === 1 && filter.reserva === 0;
        }).reduce((acc, agendamento, index) => {
            const pageIndex = Math.floor(index / 17);
            if (!acc[pageIndex]) {
                acc[pageIndex] = [];
            }
            acc[pageIndex].push(agendamento);
            return acc;
        }, []);

        const content = chunkAgendamentos.map((agendamentosGroup, groupIndex) => (
            <div key={groupIndex} className='w-100'>
                <p className='font-size-24px'>
                    Declaramos para os devidos fins que o(a) paciente: {paciente.nome} permanece em tratamento terapêutico na clínica Neuro Intensiva, sem previsão de alta, e possui a seguinte agenda semanal de atendimentos (cada atendimento tem duração de 40 minutos):
                </p>
                <b>Obs.: É obrigatório o acompanhamento de um responsável legal pelo paciente durante todo o período do atendimento terapêutico.</b>
                <br /><br />
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Dia da Semana</th>
                            <th>Terapia</th>
                            <th>Terapeuta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {agendamentosGroup.map((agendamento, index) => (
                            <tr key={index}>
                                <td>{diasDaSemanaArray.find(dia => dia.value === agendamento.dia_semana_index).longLabel}</td>
                                <td>{agendamento.terapium.terapia}</td>
                                <td>{agendamento.terapeutum.nome}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {groupIndex === chunkAgendamentos.length - 1 && <AssinaturaA4 />}
            </div>
        ));

        setContent(content);
        setFilename(`Relatório de Agendamento ${paciente.nome} ${moment().format('DD-MM-YYYY')}`);
        setIsGeraDocumentoOpen(true);
    };
    /**
     * Função para exportar declarações em formato Word.
     * Dependendo do modo selecionado, gera diferentes tipos de declarações.
     *
     * @param {string} mode - O modo de exportação. Pode ser 'atendimentos', 'assinados' ou 'comparecimento'.
     *
     * Modos:
     * - 'atendimentos': Gera uma declaração de atendimentos feitos.
     * - 'assinados': Gera uma declaração de atendimentos assinados.
     * - 'comparecimento': Gera uma declaração de comparecimento, solicitando o nome do responsável.
     */
    const handleExport = (mode) => {
        const exportToWord = (mode, nome_responsavel = null) => {
            const title = mode === 'comparecimento' ? 'Declaração de Comparecimento' : 'Declaração de Atendimentos Feitos';
            const thClass = 'font-12px';
            const sortedAtendimentos = [...atendimentos].sort((a, b) => new Date(`1970-01-01T${a.hora_atendimento}:00Z`) - new Date(`1970-01-01T${b.hora_atendimento}:00Z`));
            const terapeutas = sortedAtendimentos.reduce((acc, atendimento) => {
                if (!acc.includes(atendimento.terapeuta_nome)) {
                    acc.push(atendimento.terapeuta_nome);
                }
                return acc;
            }, []);
            const chunkAtendimentos = sortedAtendimentos.reduce((acc, atendimento, index) => {
                const tableIndex = Math.floor(index / 22);
                if (!acc[tableIndex]) {
                    acc[tableIndex] = [];
                }
                acc[tableIndex].push(atendimento);
                return acc;
            }, []);

            const first20 = chunkAtendimentos[0];

            const content = [
                <div className='w-100'>
                    <p className='font-size-24px'>
                        Declaramos para os devidos fins que o(a) paciente: {pacienteId?.label}
                        {
                            mode === 'comparecimento' ? `compareceu na data de ${moment.utc(dataInicio).format('DD/MM/YYYY')} e realizou os atendimentos terapêuticos nos horários abaixo (cada atendimento tem duração de 40 minutos):`
                                : mode === 'atendimentos'
                                    ? 'realizou os atendimentos terapêuticos nas datas e horários listados abaixo (cada atendimento tem duração de 40 minutos):'
                                    : ''
                        }
                    </p>
                    <b>Obs.: É obrigatório o acompanhamento de um responsável legal pelo paciente durante todo o período do atendimento terapêutico.</b>
                    {mode === 'comparecimento' && <p className='ms-2'>Responsável: <b>{nome_responsavel}</b></p>}
                    <div className='row'>
                        <div className={'col-12'} key={`div-table-document-${0}`}>
                            <table key={`table-document-${0}`} className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th className={thClass}>Data</th>
                                        <th className={thClass}>Horário</th>
                                        <th className={thClass}>Terapia</th>
                                        <th className={thClass}>Terapeuta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {first20.map((atendimento) => {
                                        const tdClass = 'font-12px';
                                        const arrHorario = atendimento?.hora_atendimento.split(':');
                                        const horario = `${arrHorario[0]}:${arrHorario[1]}`;
                                        const linha = (
                                            <tr key={atendimento.id}>
                                                <td className={tdClass}>{atendimento?.data_do_atendimento}</td>
                                                <td className={tdClass}>{horario}</td>
                                                <td className={tdClass}>{atendimento?.terapia}</td>
                                                <td className={tdClass}>{atendimento?.terapeuta_nome}</td>
                                            </tr>
                                        )
                                        return linha;
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {chunkAtendimentos.length === 1 &&
                            <div className='col-12'>
                                <AssinaturaA4 />
                            </div>
                        }
                    </div>
                </div>
            ];
            chunkAtendimentos.forEach((atendimentosGroup, groupIndex) => {
                if (groupIndex !== 0) {
                    content.push(
                        <>
                            <div className='col-12' key={`div-table-document-${groupIndex}`}>
                                <table key={`table-document-${groupIndex}`} className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th className={thClass}>Data</th>
                                            <th className={thClass}>Horário</th>
                                            <th className={thClass}>Terapia</th>
                                            <th className={thClass}>Terapeuta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {atendimentosGroup.map((atendimento, index) => {
                                            const tdClass = 'font-12px';
                                            const arrHorario = atendimento?.hora_atendimento.split(':');
                                            const horario = `${arrHorario[0]}:${arrHorario[1]}`;
                                            const linha = (
                                                <tr key={atendimento.id}>
                                                    <td className={tdClass}>{moment.utc(atendimento?.created_at).format('DD/MM/YYYY')}</td>
                                                    <td className={tdClass}>{horario}</td>
                                                    <td className={tdClass}>{atendimento?.terapia}</td>
                                                    <td className={tdClass}>{atendimento?.terapeuta_nome}</td>
                                                </tr>
                                            )
                                            return linha;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {groupIndex === chunkAtendimentos.length - 1 &&
                                <div className='col-12'>
                                    <AssinaturaA4 />
                                </div>
                            }
                        </>
                    );
                }
            });

            if (false === true && mode === 'atendimentos') {
                const assinaturasPage = (
                    <div className='col-12 d-flex justify-content-center flex-wrap flex-noshrink gap-1'>
                        {terapeutas.map(terapeuta => (
                            <AssinaturaA4 className="w-300px" nome={terapeuta} tipo={'terapeuta'} key={terapeuta} />
                        ))}
                    </div>
                );
                content.push(assinaturasPage);
            }

            const filename = `${title} - ${pacienteId?.label}`;
            setTitle(title);
            setContent(content);
            setFilename(filename);
            setOrientation('portrait');
            setIsGeraDocumentoOpen(true);
        };

        switch (mode) {
            case 'atendimentos':
            case 'assinados':
                exportToWord(mode);
                break;
            case 'comparecimento':
                let responsaveis = [];
                api.get('api/responsavel/get/by/paciente/' + pacienteId?.value).then(response => {
                    responsaveis = response?.data;
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    const responsavelOptions = responsaveis.map(responsavel => responsavel.nome);
                    Swal.fire({
                        title: 'Nome do Acompanhante',
                        html: `
                            <input list="responsaveis-list" id="responsavel-input" class="swal2-input" placeholder="Digite o nome do responsável">
                            <datalist id="responsaveis-list">
                                ${responsavelOptions.map(nome => `<option value="${nome}"></option>`).join('')}
                            </datalist>
                        `,
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Cancelar',
                        preConfirm: () => {
                            const responsavelInput = Swal.getPopup().querySelector('#responsavel-input').value;
                            if (!responsavelInput) {
                                Swal.showValidationMessage('Você precisa digitar um nome!');
                            }
                            return responsavelInput;
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            exportToWord(mode, result.value);
                        }
                    });
                });
                break;
            default:
                break;
        }
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {// ON LOAD
        carregaAgendaHorarios();
        fetchPacienteOptionsData();
    }, []);
    useEffect(() => {// ON LOAD
        if (pacienteId) {
            carregaPaciente();
            carregaAgendaDia();
        }
    }, [pacienteId]);
    //#endregion
    //#region HTML
    return (
        <div>
            {isGeraDocumentoOpen === true
                ? <A4Page
                    onCloseClick={() => setIsGeraDocumentoOpen(false)}
                    modalMode={true}
                    title={title}
                    content={content}
                    filename={filename}
                    hasScrollBar={false}
                    orientation={orientation}
                />
                : <div>
                    <div className={'w-100per'}>
                        <Form onSubmit={handleSubmit} className='row d-flex flex-wrap gy-4 p-4 justify-content-center'>
                            <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                                <legend>Período</legend>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-12' id='filter_data_inicio'>
                                        <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChange} />
                                    </div>
                                    <div className='col-lg-6 col-sm-12' id='filter_data_fim'>
                                        <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChange} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                                <legend>Paciente</legend>
                                <div className='row'>
                                    <div className='col-12' id='filter_paciente_id'>
                                        <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} isClearable={true} value={pacienteId} onChange={(e) => handleChange({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} required />
                                    </div>
                                </div>
                            </fieldset>
                            <div className='col-12 d-flex justify-content-end'>
                                <Button type="submit" className={'w-fit-content-i searching'}>
                                    {iconArray['pesquisar']}
                                    Pesquisar
                                </Button>
                            </div>
                        </Form>
                    </div>
                    {loading ?
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                </div>
                            </div>
                        </div>
                        : paciente && <div className='w-100'>
                            <fieldset className="ns-fieldset col-4 bg-white mx-auto">
                                <legend>Relatórios</legend>
                                <div className='row p-0 m-0 justify-content-center'>
                                    {atendimentos && atendimentos.length > 0 && (
                                        <>
                                            <Button
                                                className='w-fit-content-i flex-column align-items-center mx-4'
                                                onClick={() => handleExport('comparecimento')}
                                                disabled={comparecimento}
                                                title={comparecimento ? 'Relatório de comparecimento apenas pode ser gerado no dia!' : ''}>
                                                <FaFilePdf size={30} />Comparecimento
                                            </Button>
                                            <Button
                                                className='w-fit-content-i flex-column align-items-center mx-4'
                                                onClick={() => handleExport('atendimentos')}>
                                                <FaFilePdf size={30} />Atendimentos Feitos
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        className='w-fit-content-i flex-column align-items-center mx-4'
                                        onClick={() => handleGeraRelatorioAgendamento()}>
                                        {cloneElement(iconArray['agenda'], { size: 30 })}Agendamentos
                                    </Button>
                                </div>
                            </fieldset>
                        </div>
                    }
                </div>
            }
        </div>
    );
    //#endregion
};

export default Declaracoes;