import Dropdown from 'components/Dropdown';
import useIcons from 'context/icons';
import { get as getObjectValue } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { ClipLoader } from 'react-spinners';
import { usePagination, useSortBy, useTable } from 'react-table';
import * as XLSX from 'xlsx';
import TablePagination from '../TablePagination';
import { CelulaCabecalho, LinhaTabela, LinhaTabelaVazia, MultiSelectFiltro } from './styles';
import VirtualizedTableDefault from './virtualized-table-default';
import { RiFileExcel2Fill } from 'react-icons/ri';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Table = styled.table`
    margin: 0;
    width: 100%;
`;

const TableToolbox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    padding: 0;
`;

/**
 * Componente de paginação padrão para tabelas.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {string} [props.id=''] - O ID do componente.
 * @param {Array} props.source - A fonte de dados para a tabela.
 * @param {Array} props.columns - As colunas da tabela.
 * @param {string} props.emptyPhrase - Frase exibida quando não há dados.
 * @param {Object} [props.initialState={ pageIndex: 0, pageSize: 8 }] - Estado inicial da tabela.
 * @param {boolean} [props.enableFooter=true] - Se o rodapé da tabela deve ser exibido.
 * @param {string|null} [props.selectedRowId=null] - ID da linha selecionada.
 * @param {string} [props.tableTitle='Listagem'] - Título da tabela.
 * @param {string} [props.prefix_id='default'] - Prefixo para IDs dos elementos da tabela.
 * @param {boolean} [props.hoverLineMark=true] - Se deve marcar a linha ao passar o mouse.
 * @param {React.Element} props.searchBar - Barra de pesquisa.
 * @param {React.Element|null} [props.createButton=null] - Botão de criação.
 * @param {string} props.className - Classe CSS do componente.
 * @param {boolean} props.loading - Se a tabela está carregando dados.
 * @param {boolean} [props.infiniteScroll=false] - Se a tabela deve usar scroll infinito.
 *
 * @returns {React.Element} O componente de paginação padrão para tabelas.
 * 
 * @example
 * // Exemplo de uso:
 * <TableDefaultPagination
 *      id='table-id'
 *      source={source}
 *      columns={columns}
 *      exportColumns={exportColumns}
 *      emptyPhrase='Nada encontrado.'
 *      initialState={{ pageIndex: 0, pageSize: 8 }}
 *      enableFooter={true}
 *      selectedRowId={selectedRowId}
 *      tableTitle='Listagem'
 *      prefix_id='default'
 *      hoverLineMark={true}
 *      searchBar={searchBar}
 *      createButton={createButton}
 *      className='w-100per-i'
 *      loading={loading}
 *      infiniteScroll={false}
 *      topExportButton={false}
 *      enableExport={true}
 *      enablePageCount={true}
 *      enablePageSize={true}
 *      enableLinesCount={true}
 *      enableGoToPage={true}
 *      fixedHeader={false}
 *      fixedFooter={false}
 * />
 */
export function TableDefaultPagination({
    id = '',
    source,
    columns,
    exportColumns,
    emptyPhrase,
    initialState = { pageIndex: 0, pageSize: 8 },
    enableFooter = true,
    selectedRowId = null,
    tableTitle = 'Listagem',
    prefix_id = 'default',
    hoverLineMark = true,
    searchBar,
    createButton = null,
    className = 'w-100per-i',
    loading,
    infiniteScroll = false,
    topExportButton = false,
    enableExport = true,
    enablePageCount = true,
    enablePageSize = true,
    enableLinesCount = true,
    enableGoToPage = true,
    fixedHeader = false,
    fixedFooter = false,
    style = {
        tableTopContainer: {},
        toolbox: {},
        tableContainer: {},
        table: {},
        thead: {},
        tbody: {},
        tfoot: {}
    },
}) {
    //#region VARIAVEIS
    const { iconArray } = useIcons();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filterSearchTerm, setFilterSearchTerm] = React.useState('');
    const [columnFilters, setColumnFilters] = React.useState({});
    const [openedFilter, setOpenedFilter] = React.useState(false);

    const data = React.useMemo(() => {
        return source.filter(row => {
            const matchesSearch = Object.values(row).some(value =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            );
            const matchesFilters = Object.keys(columnFilters).every(colId => {
                if (!columnFilters[colId] || columnFilters[colId].length === 0) return true;

                const rowValue = row[colId];
                const column = columns.find(col => col.accessor === colId);

                if (column?.filterType === 'data') {
                    const [startDate, endDate] = columnFilters[colId];
                    const rowDate = moment(rowValue);
                    if (startDate && !endDate) {
                        return rowDate.isSame(moment(startDate));
                    }
                    if (!startDate && endDate) {
                        return rowDate.isSame(moment(endDate));
                    }
                    return (!startDate || rowDate.isSameOrAfter(moment(startDate))) &&
                        (!endDate || rowDate.isSameOrBefore(moment(endDate)));
                }

                if (Array.isArray(rowValue)) {
                    // Verifica se algum item do array corresponde ao filtro
                    return rowValue.some(item => {
                        if (column?.filterValue) {
                            return columnFilters[colId].find(find => find === getObjectValue(item, column.filterValue));
                        } else {
                            return columnFilters[colId].find(find => find === item.id);
                        }
                    });
                }

                return columnFilters[colId].includes(rowValue);
            });

            return matchesSearch && matchesFilters;
        });
    }, [source, searchTerm, columnFilters]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: initialState
    },
        useSortBy,
        ...(infiniteScroll ? [] : [usePagination])
    );
    //#region
    //#region FUNCOES
    /**
     * Exporta os dados da tabela para um arquivo Excel.
     *
     * @function
     * @name exportToExcel
     * @description Esta função exporta os dados da tabela para um arquivo Excel (.xlsx). 
     * Ela filtra as colunas exportáveis, mapeia os dados da tabela e cria uma planilha Excel.
     * 
     * @param {Array} exportColumns - Colunas específicas a serem exportadas. Se não fornecido, todas as colunas exceto 'Ações' serão exportadas.
     * @param {Array} columns - Todas as colunas disponíveis na tabela.
     * @param {Array} source - Dados da tabela a serem exportados.
     * @param {string} tableTitle - Título da tabela que será usado no nome do arquivo Excel.
     * 
     * @requires XLSX - Biblioteca para manipulação de arquivos Excel.
     * @requires moment - Biblioteca para manipulação de datas.
     */
    const exportToExcel = () => {
        const exportableColumns = exportColumns?.length ? exportColumns : columns.filter(col => col.Header !== 'Ações' && col.Header !== 'Ações:');
        const tableData = source.map(row => {
            let rowData = {};
            exportableColumns.forEach(col => {
                const cellValue = row[col.accessor];
                rowData[col.Header] = cellValue;
            });
            return rowData;
        });

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        /*
        // Set column widths to auto size
        const columnWidths = exportableColumns.map(col => ({ wch: Math.max(...tableData.map(row => String(row[col.Header]).length), col.Header.length) }));
        worksheet['!cols'] = columnWidths;
        */
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TableData');
        XLSX.writeFile(workbook, `${tableTitle} ${moment().format('DD-MM-YYYY HH:mm')}.xlsx`);
    };
    /**
     * Obtém valores únicos de uma coluna específica.
     *
     * @param {string} columnId - O identificador da coluna para a qual os valores únicos serão obtidos.
     * @returns {Array<{ rawValue: any, renderedValue: any }>} - Um array de objetos contendo os valores brutos e renderizados únicos da coluna.
     */
    const getUniqueValues = (columnId) => {
        const column = columns.find(col => col.accessor === columnId);
        const values = source.flatMap(row => {
            const rawValue = getObjectValue(row, column?.filterAccessor ?? columnId);
            // Se o valor for um array, mapeie cada item do array
            if (Array.isArray(rawValue)) {
                return rawValue.map(item => {
                    const rawValue = getObjectValue(item, column?.filterValue);
                    const renderedValue = getObjectValue(item, column?.filterLabel);

                    return { rawValue, renderedValue };
                });
            }
            try {
                // Processa o valor pelo Cell, se definido, exceto se for boolean
                const renderedValue = (column && column.Cell && column.filterType !== 'boolean')
                    ? column.Cell({ cell: { value: rawValue } })
                    : (column.filterType === 'boolean' ? (rawValue ? 'Sim' : 'Não') : rawValue);
                return { rawValue, renderedValue };
            } catch (error) {
                // Processa o valor pelo Cell, se definido
                const renderedValue = rawValue;
                return { rawValue, renderedValue };
            }
        });

        const returnValues = Array.from(new Map(values.map(item => [item.rawValue, item])).values());
        return returnValues;
    };
    /**
     * Filtra valores únicos com base em um termo de busca.
     *
     * @param {Object} param - O objeto contendo os valores a serem filtrados.
     * @param {any} param.rawValue - O valor bruto a ser filtrado.
     * @param {any} param.renderedValue - O valor renderizado a ser filtrado.
     * @returns {boolean} - Retorna true se o termo de busca estiver presente no valor bruto ou renderizado, caso contrário, false.
     */
    const uniqueValuesFilter = ({ rawValue, renderedValue }) => {
        const searchTerm = filterSearchTerm?.toLowerCase();
        return (!searchTerm || String(rawValue).toLowerCase().includes(searchTerm) || String(renderedValue).toLowerCase().includes(searchTerm));
    }
    //#region
    //#region HANDLES
    /**
     * Manipula a mudança de filtro de coluna.
     *
     * @param {string} columnId - O ID da coluna que está sendo filtrada.
     * @param {Array} selectedValues - Os valores selecionados para o filtro da coluna.
     */
    const handleColumnFilterChange = (columnId, selectedValues) => {
        setColumnFilters(prev => ({ ...prev, [columnId]: selectedValues }));
    };
    /**
     * Manipula a seleção de todos os valores únicos de uma coluna específica.
     * Se houver um termo de busca, filtra os valores únicos com base nesse termo.
     * 
     * @param {string} columnId - O ID da coluna cujos valores únicos serão selecionados.
     */
    const handleSelectAll = (columnId) => {
        let allValues = getUniqueValues(columnId);
        if (filterSearchTerm) {
            allValues = allValues.filter(({ rawValue, renderedValue }) => {
                const searchTerm = filterSearchTerm?.toLowerCase();
                return (
                    !searchTerm ||
                    String(rawValue).toLowerCase().includes(searchTerm) ||
                    String(renderedValue).toLowerCase().includes(searchTerm)
                );
            });
        }
        setColumnFilters(prev => ({ ...prev, [columnId]: allValues.map(({ rawValue }) => rawValue) }));
    };
    /**
     * Limpa o filtro aplicado a uma coluna específica.
     *
     * @param {string} columnId - O ID da coluna cujo filtro deve ser limpo.
     */
    const handleClearFilter = (columnId) => {
        setColumnFilters(prev => {
            const newFilters = { ...prev };
            delete newFilters[columnId];
            return newFilters;
        });
    };
    //#region
    //#region USE EFFECTS
    React.useEffect(() => {
        if (!openedFilter) {
            setFilterSearchTerm('');
        }
    }, [openedFilter]);
    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'x') {
                event.preventDefault();
                exportToExcel();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    //#region
    //#region HTML
    return (
        <div key={'table-top-container'} id={id} className={className} style={style?.tableTopContainer}>
            <TableToolbox key={'table-toolbox'} style={style?.toolbox}>
                <div className={topExportButton && enableExport ? 'd-flex flex-row' : ''} style={{ width: '50%' }}>
                    {(topExportButton && enableExport) &&
                        <button className='btn-outline' onClick={exportToExcel}>
                            <RiFileExcel2Fill className='font-roxo' size={26} />
                        </button>
                    }
                    {searchBar &&
                        <div className={`input-group`}>
                            <input
                                type="text"
                                className='fake-input'
                                placeholder="Pesquisar na tabela..."
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                aria-label="Search Term" aria-describedby="search"
                            />
                            <span className="input-group-text btn-padrao" id="search">
                                {React.cloneElement(iconArray['pesquisar'], { color: 'white' })}
                            </span>
                        </div>
                    }
                </div>
                <div className='d-flex justify-content-end' style={{ width: '50%' }}>
                    {createButton}
                </div>
            </TableToolbox>
            {infiniteScroll
                ? <VirtualizedTableDefault
                    rowHeight={50}
                    rows={data}
                    containerHeight={400}
                    getTableProps={getTableProps}
                    prefix_id={prefix_id}
                    headerGroups={headerGroups}
                    getUniqueValues={getUniqueValues}
                    uniqueValuesFilter={uniqueValuesFilter}
                    setOpenedFilter={setOpenedFilter}
                    columnFilters={columnFilters}
                    iconArray={iconArray}
                    handleClearFilter={handleClearFilter}
                    handleSelectAll={handleSelectAll}
                    filterSearchTerm={filterSearchTerm}
                    setFilterSearchTerm={setFilterSearchTerm}
                    handleColumnFilterChange={handleColumnFilterChange}
                    prepareRow={prepareRow}
                    hoverLineMark={hoverLineMark}
                    selectedRowId={selectedRowId}
                    getTableBodyProps={getTableBodyProps}
                />
                : <TableContainer key={'table-container'} style={style?.tableContainer}>
                    <Table {...getTableProps()} style={style?.table} id={`${prefix_id}-table`}>
                        <thead id={`${prefix_id}-thead`} className='pointer' style={{ position: fixedHeader ? 'sticky' : 'relative', top: 0 }}>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => {
                                        const uniqueValues = getUniqueValues(column.id).filter(uniqueValuesFilter)
                                        return (
                                            <CelulaCabecalho key={index} id={`${prefix_id}-col-${index}`}>
                                                <div className='position-relative d-flex justify-content-between align-items-center'>
                                                    <span style={{ width: 36 }}>
                                                        {column.filterable
                                                            ? <Dropdown
                                                                clickMode={true}
                                                                setMostrando={setOpenedFilter}
                                                                reserva={index === 0}
                                                                substituicao={index === headerGroup.headers.length - 1}
                                                                button={
                                                                    columnFilters[column?.filterAccessor ?? column.id]?.length
                                                                        ? React.cloneElement(iconArray['filtrado'], { color: 'yellow', size: 18 })
                                                                        : React.cloneElement(iconArray['filtrar'], { color: 'white', size: 18 })
                                                                }
                                                                content={
                                                                    <MultiSelectFiltro className='position-fixed'>
                                                                        <h1 className='title'>
                                                                            Filtros
                                                                        </h1>
                                                                        <div className='multiselect-container'>
                                                                            <ul className='toolbox'>
                                                                                <li className='font-branca'>
                                                                                    <button className='btn-outline py-1 d-flex align-items-start font-branca' onClick={() => handleClearFilter(column?.filterAccessor ?? column.id)}>
                                                                                        {React.cloneElement(iconArray['limpar-geral'], { size: 18, color: 'white', style: { marginRight: 10 } })} Limpar filtro
                                                                                    </button>
                                                                                </li>
                                                                                <li className='font-branca'>
                                                                                    <button className='btn-outline py-1 d-flex align-items-start font-branca' onClick={() => handleSelectAll(column?.filterAccessor ?? column.id)}>
                                                                                        {React.cloneElement(iconArray['seleciona-quadrado'], { size: 18, color: 'white', style: { marginRight: 10 } })} Selecionar todos
                                                                                    </button>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='search-input'>
                                                                                <input className='form-control w-100 py-1 m-0' type="text" placeholder="Filtrar..." value={filterSearchTerm} onChange={(e) => setFilterSearchTerm(e.target.value)} />
                                                                            </div>
                                                                            {column.filterType === 'data' ? (
                                                                                <div className='date-filter'>
                                                                                    <input
                                                                                        type="date"
                                                                                        value={columnFilters[column.id]?.[0] || ''}
                                                                                        onBlur={e => handleColumnFilterChange(column.id, [e.target.value, columnFilters[column.id]?.[1]])}
                                                                                        className='form-control'
                                                                                    />
                                                                                    <input
                                                                                        type="date"
                                                                                        value={columnFilters[column.id]?.[1] || ''}
                                                                                        onBlur={e => handleColumnFilterChange(column.id, [columnFilters[column.id]?.[0], e.target.value])}
                                                                                        className='form-control'
                                                                                    />
                                                                                    <button type='button' className='btn-outline'>
                                                                                        Filtrar
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <ul className='filter-list'>
                                                                                    {uniqueValues.map(({ rawValue, renderedValue }) => (
                                                                                        <li key={rawValue} className='p-0 m-0 list-style-none text-left'>
                                                                                            <label className='w-100 font-normal unselectable d-flex flex-row'>
                                                                                                <input
                                                                                                    className='me-2'
                                                                                                    type="checkbox"
                                                                                                    checked={columnFilters[column?.filterAccessor ?? column.id]?.includes(rawValue) || false}
                                                                                                    onChange={(e) => {
                                                                                                        const selectedValues = columnFilters[column?.filterAccessor ?? column.id] || [];
                                                                                                        if (e.target.checked) {
                                                                                                            handleColumnFilterChange(column?.filterAccessor ?? column.id, [...selectedValues, rawValue]);
                                                                                                        } else {
                                                                                                            handleColumnFilterChange(column?.filterAccessor ?? column.id, selectedValues.filter(v => v !== rawValue));
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                                <div className='display-block'>
                                                                                                    {renderedValue}
                                                                                                </div>
                                                                                            </label>
                                                                                        </li>
                                                                                    ))
                                                                                    }
                                                                                </ul>
                                                                            )}
                                                                        </div>
                                                                    </MultiSelectFiltro>
                                                                }
                                                            />
                                                            : <></>
                                                        }</span>
                                                    <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                    </span>
                                                    <span style={{ width: 36 }}>
                                                        {columnFilters[column?.filterAccessor ?? column.id]?.length
                                                            && <button className='btn-outline p-0 d-flex align-items-start font-branca' onClick={() => handleClearFilter(column?.filterAccessor ?? column.id)}>
                                                                {React.cloneElement(iconArray['limpar-geral'], { size: 18, color: 'white' })}
                                                            </button>
                                                        }
                                                        {column.isSorted
                                                            ? (column.isSortedDesc
                                                                ? <AiOutlineCaretDown style={{ marginLeft: '10%' }} color='white' />
                                                                : <AiOutlineCaretUp style={{ marginLeft: '10%' }} color='white' />
                                                            )
                                                            : ''
                                                        }
                                                    </span>
                                                </div>
                                            </CelulaCabecalho>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody id={`${prefix_id}-tbody`} {...getTableBodyProps()} className="TableListaEsperaBody">
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className={hoverLineMark ? 'line-hover' : ''} {...row.getRowProps()}>
                                        {row.cells.map((cell, index) =>
                                            <LinhaTabela key={index} id={`${prefix_id}-col-${index}-row-${i}`}
                                                style={{
                                                    height: '14px',
                                                    textAlign: 'center',
                                                    backgroundColor: row.original.id === selectedRowId ? '#e2e2e2' : '',
                                                }}>
                                                {cell.render('Cell')}
                                            </LinhaTabela>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </TableContainer>
            }
            {loading ?
                <LinhaTabelaVazia><ClipLoader color={'purple'} /></LinhaTabelaVazia>
                : (source?.length === 0)
                    ? <LinhaTabelaVazia> {emptyPhrase ?? 'Nada encontrado.'} </LinhaTabelaVazia>
                    : ''
            }
            <div id={`${prefix_id}-tfoot`} className='mt-2 w-100per' style={{ position: fixedFooter ? 'sticky' : 'relative', bottom: 0 }}>
                {(enableFooter === true && !infiniteScroll) ?
                    <TablePagination
                        prefix_id={prefix_id}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        linhas={data?.length}
                        exportToExcel={exportToExcel}
                        enableExport={(!topExportButton && enableExport) ? true : false}
                        enablePageCount={enablePageCount}
                        enablePageSize={enablePageSize}
                        enableLinesCount={enableLinesCount}
                        enableGoToPage={enableGoToPage}
                    />
                    : ''
                }
            </div>
        </div>
    );
    //#region
}