//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Button from 'components/Button';
import Input from 'components/Input';
import ModalTitulo from 'components/ModalTitulo';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { useAuth } from 'context/auth';
import useHorarioAtendimentoData from 'context/horario-atendimento';
import useModalidadeData from 'context/modalidade';
import usePacienteData from 'context/paciente';
import useSalaData from 'context/sala';
import useStatusAtendimentosData from 'context/status-atendimento';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { ClipLoader, ScaleLoader } from 'react-spinners';
import api from 'services/api';
import DiaHoraService from 'services/DiaHoraService';
import PacienteService from 'services/PacienteService';
import Swal from 'sweetalert2';
import { Container, Modal } from './styles';
//#endregion
/**
 * Componente AtendimentoModal.
 * 
 * Este componente é responsável por exibir e gerenciar o modal de atendimento, permitindo visualizar e editar informações de um atendimento específico.
 * 
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.atualizaSocket - Função para atualizar o socket.
 * @param {boolean} props.isAtendimentoEditavel - Indica se o atendimento é editável.
 * @param {Array} props.tipoAtendimentoOptions - Opções de tipo de atendimento.
 * @param {Array} props.statusAtendimento - Status do atendimento.
 * @param {Array} props.modalidadesOptions - Opções de modalidades.
 * @param {Array} props.horarioAtendimentoOptions - Opções de horário de atendimento.
 * @param {Array} props.atendimentos - Lista de atendimentos.
 * @param {Array} props.pacientes - Lista de pacientes.
 * @param {Array} props.estacaoOptions - Opções de estação.
 * @param {Array} props.pacienteOptionsOriginal - Opções originais de pacientes.
 * @param {number} props.atendimentoId - Identificador do atendimento.
 * @param {number} props.controleDiarioId - Identificador do controle diário.
 * @param {Function} props.onClose - Função para fechar o modal.
 * @param {Function} props.setHouveAlteracaoStatusEdicao - Função para definir se houve alteração no status de edição.
 * @param {Object} props.snackbar - Objeto para exibir mensagens de snackbar.
 * 
 * @returns {JSX.Element} O componente AtendimentoModal.
 */
const AtendimentoModal = ({
    // LISTAS
    tipoAtendimentoOptions: tipoAtendimentoOptionsProp,
    statusAtendimento: statusAtendimentoOptionsProp,
    modalidadesOptions: modalidadesOptionsListProp,
    horarioAtendimentoOptions: horarioAtendimentoOptionsProp,
    estacaoOptions: estacaoOptionsProp,
    pacientes: pacientesProp,
    pacienteOptionsOriginal: pacienteOptionsProp,
    atendimentos: atendimentosProp,
    // FUNÇÕES
    atualizaSocket = () => { },
    onClose = () => { },
    setHouveAlteracaoStatusEdicao = () => { },
    // Lista de opções habilitadas
    modal_alteracao_opcoes = {
        aprova: true,
        reprova: true,
        cria: true,
    },
    // IDs
    atendimentoId,
    solicitacaoId,
    controleDiarioId,
    // HOOKS
    snackbar,
    // FLAGS
    isAtendimentoEditavel,
    tipoSolicitacao = null,
}) => {
    //#region VARIÁVEIS
    // Hooks
    const { pacientes, pacienteOptions, fetchPacienteData } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const { salaEstacaoOptions, fetchSalaEstacaoData } = useSalaData();
    const { horarioAtendimentoComFimOptions, fetchHorarioAtendimentoComFimData } = useHorarioAtendimentoData();
    const { modalidadesOptions, fetchModalidadeData } = useModalidadeData();
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const usuario = useAuth();
    const [pacienteService, setPacienteService] = useState(null);

    // Parâmetros
    const [hour, setHour] = useState(null);

    // Listas
    const tipoAtendimentoOptionsList = useMemo(() => tipoAtendimentoOptionsProp || tipoAtendimentosOptions, [tipoAtendimentoOptionsProp, tipoAtendimentosOptions]);
    const statusAtendimentoOptionsList = useMemo(() => statusAtendimentoOptionsProp || statusAtendimentosOptions, [statusAtendimentosOptions, statusAtendimentoOptionsProp]);
    const modalidadesOptionsList = useMemo(() => modalidadesOptionsListProp || modalidadesOptions, [modalidadesOptionsListProp, modalidadesOptions]);
    const horarioAtendimentoOptionsList = useMemo(() => horarioAtendimentoOptionsProp || horarioAtendimentoComFimOptions, [horarioAtendimentoOptionsProp, horarioAtendimentoComFimOptions]);
    const estacaoOptionsList = useMemo(() => estacaoOptionsProp || salaEstacaoOptions, [estacaoOptionsProp, salaEstacaoOptions]);
    const pacienteOptionsList = useMemo(() => {
        const newPacienteOptionsList = pacienteOptionsProp || pacienteOptions;
        if (newPacienteOptionsList) {
            if (hour) {
                const newPacienteOptions = PacienteService.adicionaStatusIndisponivel(pacienteOptionsProp, hour, atendimentoId, atendimentosProp);
                return newPacienteOptions;
            } else {
                return newPacienteOptionsList;
            }
        }
    }, [pacienteOptionsProp, pacienteOptions]);
    const pacientesList = useMemo(() => pacientesProp || pacientes, [pacientesProp, pacientes]);
    const [listasCarregadas, setListasCarregadas] = useState(false);

    // Refs
    const formRef = useRef(null);

    // Estados de Carregamento
    const [loading, setloading] = useState(false);
    const [salvando, setsalvando] = useState(false);

    // Estados de Modal
    const modalId = 'modalDetalhesDoAtendimento';

    // Data
    const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];

    // Solicitacao
    const fetchSolicitaca = async (solicitacaoId) => {
        return await api.get(`api/solicitacao-alteracao/${solicitacaoId}?id=${solicitacaoId}`)
            .then(({ data }) => data)
            .catch(error => {
                console.error('Error fetching solicitacao:', error);
                return null;
            });
    };
    const [solicitacao, setSolicitacao] = useState(null);

    // Memórias
    const [comentario, setComentario] = useState('');
    const [atendimento, setAtendimento] = useState(null);

    // Memórias de Alteração
    const [alteracao, setAlteracao] = useState(null);
    const [alteracaoId, setAlteracaoId] = useState(null);
    const [alterado, setAlterado] = useState(false);
    const [statusAlteracao, setStatusAlteracao] = useState(null);

    // Campos do Formulário
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [pacienteOptSelecionado, setPacienteOptSelecionado] = useState([]);

    const [terapiasPacienteOptions, setTerapiasPacienteOptions] = useState([]);
    const [terapiasPacienteOptSelecionado, setTerapiasPacienteOptSelecionado] = useState([]);
    const [terapiasPacienteSelecionado, setTerapiasPacienteSelecionado] = useState([]);

    const [terapeutasOptions, setTerapeutasOptions] = useState([]);
    const [terapeutaOptSelecionado, setTerapeutaOptSelecionado] = useState([]);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);

    const [modalidadeOptSelecionado, setModalidadeOptSelecionado] = useState([]);
    const [modalidadeSelecionado, setModalidadesSelecionada] = useState(null);

    const [tipoAtendimentoOptSelecionado, setTipoAtendimentoOptSelecionado] = useState([]);
    const [tipoAgendamentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);

    const [reservaSelecionado, setReservaSelecionada] = useState(null);

    const [estacaoOptSelecionado, setEstacaoOptSelecionado] = useState();
    const [estacaoSelecionado, setEstacaoSelecionado] = useState();

    const [statusAtendimentoAtualOptSelecionado, setStatusAtendimentoAtualOptSelecionado] = useState([]);
    const [statusAtendimentoOriginalOptSelecionado, setStatusAtendimentoOriginalOptSelecionado] = useState([]);

    const [horarioSelecionadoOpt, setHorarioSelecionadoOpt] = useState(null);
    //#endregion

    //#region FUNÇÕES
    /*==CHECKERS==*/
    /**
     * Função que verifica se o terapeuta está disponível para atendimento.
     * @param {Number} terapeuta_id Identificador do terapeuta
     * @param {String} horario Hora usada para chegar os agendamentos do terapeuta
     * @returns {boolean}
     */
    const checkAtendimentosTerapeuta = (terapeuta_id, horario, agendamentoId = null) => {
        let isTerapeuta = false;
        atendimentosProp.forEach((agendamento) => {
            if (agendamento.terapeuta_id === terapeuta_id && agendamento.data_atendimento_inicial === horario._i && agendamento.id !== agendamentoId) isTerapeuta = true;
        });
        return isTerapeuta;
    };
    /*==GETTERS==*/
    /**
     * Função que carreta as terapias e especialidades para a listagem
     * @param {Array} paciente Todas as informações do paciente.
     * @returns {void}
     */
    const getTerapiasEspecialidades = (paciente) => {
        if (paciente !== null) {
            let terapiasList = [];
            let especialidadesList = [];
            if (paciente.terapia_paciente !== undefined && paciente.terapia_paciente.length > 0) {
                paciente.terapia_paciente.forEach((terapia) => {
                    terapiasList.push({ value: terapia.terapia_id, label: terapia.terapia, plano_saude_id: terapia.plano_saude_id });
                    if (especialidadesList.find((value) => value.value === terapia.especialidade_id) === undefined) {
                        especialidadesList.push({ value: terapia.especialidade_id, label: terapia.especialidade });
                    }
                });
            }
        } else {
            snackbar.displayMessage('Selecione o paciente.', 'warning');
        }
    };
    /**
     * Função assíncrona que busca os dados de um atendimento específico a partir de um ID de controle diário e um ID de atendimento.
     * 
     * @async
     * @function getAtendimento
     * @returns {Promise<Object>} Retorna um objeto contendo os dados do atendimento.
     * 
     * @throws {Error} Lança um erro se a requisição à API falhar.
     * 
     * @description
     * Esta função faz uma requisição à API para obter os dados de um atendimento específico. Ela constrói a URL da API com base nos IDs fornecidos e nos parâmetros adicionais necessários. Após obter a resposta da API, a função processa os dados do atendimento, formatando datas e horários, e atualiza o estado do componente com as informações obtidas.
     * 
     * @example
     * const atendimento = await getAtendimento();
     */
    const getAtendimento = async () => {
        const apiUrl = 'api/atendimento/getByControleDiario/';
        const listasUrl = '?listas=solicitacao_de_alteracaos,guia,paciente.terapias_paciente';
        const extraFlagUrl = '&alterado=false';
        const extraSelectUrl = `&${[
            'extraSelect=terapia_paciente.plano_saude.id',
            'terapia_paciente.paciente.nome',
            'terapia_paciente.terapia.terapia',
            'terapia_paciente.especialidade.especialidade',
            'terapia_paciente.plano_saude.nome_reduzido',
        ].join(',')}`;
        const finalUrl = `${apiUrl}${controleDiarioId}${listasUrl}${extraSelectUrl}&atendimento_id=${atendimentoId}${extraFlagUrl}`;
        const response = await api.get(finalUrl);
        let atendimentoResponse = response.data[0];
        let localOriginal = response.data[0];
        const localAlteracao = localOriginal?.atendimento_alteracaos;
        const newAlterado = localAlteracao?.status === '1' || localAlteracao?.status === '2' || localAlteracao?.status === '4' ? true : false;

        if (newAlterado) {
            atendimentoResponse = atendimentoResponse.old;
        }

        atendimentoResponse.data = atendimentoResponse.data_atendimento_inicial.split('T')[0];
        atendimentoResponse.horario = moment(atendimentoResponse.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm');
        atendimentoResponse.data_atendimento_numero = DiaHoraService.getDiaMesAnoFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
        atendimentoResponse.data_atendimento_extenso = DiaHoraService.getDiaSemanaFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
        if (localAlteracao) {
            setStatusAtendimentoOriginalOptSelecionado(statusAtendimentoOptionsList.filter(filter => filter.value === localAlteracao.alteracao_sugestao_recepcao));
            setStatusAlteracao(localAlteracao.status);
        }
        setAlteracaoId(localAlteracao?.id);
        setAlterado(newAlterado);
        if (newAlterado) {
            setComentario(localAlteracao.comentario_a);
            setAlteracao(localAlteracao);
        }
        setAtendimento(atendimentoResponse);

        return atendimentoResponse;
    };
    /**
     * Obtém a lista de terapeutas disponíveis com base nos parâmetros fornecidos.
     *
     * @param {number|null} especialidade_id - ID da especialidade do terapeuta.
     * @param {number|null} terapia_id - ID da terapia.
     * @param {string} date - Data do atendimento no formato 'YYYY-MM-DD'.
     * @param {string} hour - Hora do atendimento no formato 'HH:MM'.
     * @param {number} horarios_atendimento_id - ID do horário de atendimento.
     * @returns {Promise<Array<{value: number, label: string, pos: number}>>} - Uma promessa que resolve para uma lista de opções de terapeutas.
     */
    const getTerapeutas = (especialidade_id, terapia_id, date, hour, horarios_atendimento_id) => {
        if (especialidade_id !== null && terapia_id !== null) {
            const link = `api/terapeuta/disponivel/${date}/${hour}?atendimento=true&terapia_id=${terapia_id}&especialidade_id=${especialidade_id}&horarios_atendimento_id=${horarios_atendimento_id}&agendamento_id=${atendimentoId}`;
            return api.get(link).then(({ data }) => {
                let terapeutaOptions = [];
                for (let index = 0; index < data.length; index++) {
                    const terapeuta = data[index];
                    terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome, pos: !(terapeuta.nome.includes('(') && terapeuta.nome.includes(')')) ? 0 : 1 });
                }
                if (!terapeutaOptions) {
                    snackbar.displayMessage('Não há terapeutas disponíveis para essa Especialidade e Terapia.', 'warning');
                }
                let t1 = terapeutaOptions.filter((filter) => filter.pos === 0);
                let t2 = terapeutaOptions.filter((filter) => filter.pos === 1);
                t1 = t1.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                t2 = t2.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                let mergedObj = [];
                t1.forEach((e) => mergedObj.push(e));
                t2.forEach((e) => mergedObj.push(e));
                setTerapeutasOptions(mergedObj);
                return mergedObj;
            });
        }
    };
    /**
     * Função assíncrona que carrega e define diversos estados relacionados ao atendimento de um paciente.
     * Utiliza várias funções assíncronas para buscar dados e atualiza os estados correspondentes.
     * 
     * @function carregaTudo
     * @async
     * @returns {Promise<void>}
     * 
     * @throws {Error} Se ocorrer um erro durante a execução das funções assíncronas.
     * 
     * @example
     * carregaTudo();
     */
    const carregaTudo = useCallback(async () => {
        try {
            const modalidadeOptionsLocal = modalidadesOptionsList;
            const estacaoOptionsLocal = estacaoOptionsList;
            getAtendimento().then(async (response) => {
                const newResponse = response;
                const pacienteData = {
                    terapia_pacientes: newResponse.terapias_paciente
                };
                const especialidade_id = newResponse.especialidade_id;
                const terapia_id = newResponse.terapia_id;
                const horarios_atendimento_id = newResponse.horarios_atendimento_id;
                const terapia_paciente_id = newResponse.terapia_paciente_id;
                const paciente_id = newResponse.paciente_id;
                const terapeuta_id = newResponse.terapeuta_id;
                const reserva = newResponse.reserva;
                const tipo_atendimento_id = newResponse.tipo_atendimento_id;
                const modalidade_id = newResponse.modalidade_id;
                const estacao_id = newResponse.estacao_id;
                const status_atendimento = newResponse.sugestao_recepcao;
                setStatusAtendimentoAtualOptSelecionado(statusAtendimentoOptionsList.filter(filter => filter.value === status_atendimento));

                await getTerapiasEspecialidades(pacienteData);
                const terapeutaOptions = await getTerapeutas(
                    especialidade_id,
                    terapia_id,
                    moment(newResponse.created_at).format('YYYY-MM-DD'),
                    `${newResponse.hora_atendimento.split(':')[0]}:${newResponse.hora_atendimento.split(':')[1]}`,
                    horarios_atendimento_id
                );
                const terapiaMapFunction = (map) => ({
                    value: map.id,
                    label: `${map.terapia} - ${map.especialidade} - ${map.nome_reduzido}`,
                    terapia_id: map.terapia_id,
                    especialidade_id: map.especialidade_id,
                    plano_saude_id: map.plano_saude_id,
                });
                const selectTerapia = pacienteData.terapia_pacientes.map(terapiaMapFunction);
                setTerapiasPacienteSelecionado(terapia_paciente_id);
                setTerapiasPacienteOptSelecionado(selectTerapia.filter((e) => Number(e.value) === Number(terapia_paciente_id)));

                setPacienteSelecionado(paciente_id);
                const pacienteOptionSelecionado = pacienteOptionsList.find((e) => Number(e.value) === Number(paciente_id));
                setPacienteOptSelecionado(pacienteOptionSelecionado);
                const terapiaPacientesList = pacienteOptionSelecionado.terapia_paciente;
                setTerapiasPacienteOptions(terapiaPacientesList.map(terapiaMapFunction));

                setTerapeutaSelecionado(terapeuta_id);
                setTerapeutaOptSelecionado(terapeutaOptions.filter(e => Number(e.value) === Number(terapeuta_id)));

                setReservaSelecionada(reserva);

                setTipoAtendimentoSelecionado(tipo_atendimento_id);
                if (alterado) {
                    setTipoAtendimentoOptSelecionado(tipoAtendimentoOptionsList.find((value) => value.value === tipo_atendimento_id));
                }

                setModalidadesSelecionada(modalidade_id);
                setModalidadeOptSelecionado(modalidadeOptionsLocal.find((value) => value.value === modalidade_id));

                setEstacaoSelecionado(estacao_id);
                setEstacaoOptSelecionado(estacaoOptionsLocal.filter((e) => e.value === estacao_id));
                const horario_atendimento = horarioAtendimentoOptionsList.filter((e) => Number(e.id) === Number(horarios_atendimento_id));
                setHorarioSelecionadoOpt(horario_atendimento);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    }, [modalidadesOptionsList, estacaoOptionsList, pacienteOptionsList, statusAtendimentoOptionsList, tipoAtendimentoOptionsList, horarioAtendimentoOptionsList]);
    //#endregion

    //#region HANDLES
    /**
     * Função que exibe um alerta de confirmação ao usuário antes de fechar o modal.
     * Se o usuário confirmar, a função `onClose` é chamada para fechar o modal.
     * 
     * @function handleCloseCheck
     * @returns {void}
     */
    const handleCloseCheck = () => {
        Swal.fire({
            title: 'Deseja mesmo fechar?',
            text: 'Se você fechar dessa maneira, não irá salvar nenhuma infomação. Deseja mesmo fechar?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Sair sem salvar.',
            cancelButtonAriaLabel: 'Não Sair!'
        }).then((response) => {
            if (response.isConfirmed) {
                onClose();
            }
        });
    };
    /**
     * Função para lidar com cliques fora do modal.
     * Fecha o modal se o elemento clicado tiver o mesmo ID do modal.
     *
     * @param {Object} e - O evento de clique.
     * @param {Object} e.target - O alvo do evento de clique.
     * @param {string} e.target.id - O ID do alvo do evento de clique.
     */
    const handleOutsideClick = (e) => {
        if (e.target.id === modalId) handleCloseCheck();
    };
    /**
     * Manipula a mudança do paciente selecionado.
     *
     * @param {Object} e - O evento de mudança ou o objeto de seleção.
     * @param {Object} e.target - O alvo do evento de mudança.
     * @param {string|number} e.target.value - O valor do paciente selecionado.
     * @param {string|number} e.value - O valor do paciente selecionado (se disponível).
     */
    const handleChangePaciente = (e) => {
        const pacienteData = pacientesList.find((value) => Number(value.id) === Number((e.value === undefined) ? e.target.value : e.value));

        getTerapiasEspecialidades(pacienteData);
        let terapiaPacientesList = [];
        if (pacienteData.terapia_paciente !== undefined) {
            for (let index = 0; index < pacienteData.terapia_paciente.length; index++) {
                const el = pacienteData.terapia_paciente[index];
                terapiaPacientesList.push({
                    value: el.id,
                    label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                    terapia_id: el.terapia_id,
                    especialidade_id: el.especialidade_id,
                });
            }
        }
        setTerapiasPacienteOptions(terapiaPacientesList);
        setTerapiasPacienteSelecionado(null);
        setTerapiasPacienteOptSelecionado(null);

        setTerapeutaSelecionado(null);
        setTerapeutaOptSelecionado(null);

        setPacienteSelecionado((e.value === undefined) ? e.target.value : e.value);
        setPacienteOptSelecionado(pacienteData);
    };
    /**
     * Manipula a mudança de horário selecionado.
     *
     * @param {Object} e - O evento ou objeto contendo as informações do horário.
     * @param {Object} e.target - O alvo do evento, se disponível.
     * @param {string} e.target.hora_final - A hora final do horário selecionado.
     * @param {string} e.hora_final - A hora final do horário selecionado, se o alvo do evento não estiver disponível.
     */
    const handleChangeHorario = (e) => {
        setHorarioSelecionadoOpt((e.target !== undefined) ? e.target : e);
        document.getElementById('hora_final').value = (e.target !== undefined)
            ? e.target.hora_final
            : e.hora_final;
    };
    /**
     * Manipula a mudança de terapia dos pacientes.
     *
     * @async
     * @function handleChangeTerapiaPacientes
     * @param {Object} e - O evento de mudança contendo os dados da terapia e especialidade.
     * @param {number} e.especialidade_id - O ID da especialidade.
     * @param {number} e.terapia_id - O ID da terapia.
     * @param {string} e.value - O valor selecionado da terapia do paciente.
     *
     * @returns {void}
     */
    const handleChangeTerapiaPacientes = async (e) => {
        getTerapeutas(e.especialidade_id, e.terapia_id, moment(atendimento.created_at).format('YYYY-MM-DD'), moment(atendimento.data_atendimento_inicial).format('HH:mm'), atendimento.horarios_atendimento_id);
        setTerapiasPacienteSelecionado(e.value);
        setTerapiasPacienteOptSelecionado(e);

        setTerapeutaSelecionado(null);
        setTerapeutaOptSelecionado([]);
    };
    /**
     * Manipula a mudança do terapeuta selecionado.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.value - O valor do alvo do evento.
     * @param {string} [e.value] - O valor opcional do evento.
     */
    const handleChangeTerapeuta = (e) => {
        setTerapeutaSelecionado((e.value === undefined) ? e.target.value : e.value);
        setTerapeutaOptSelecionado(e);
    };
    /**
     * Manipula a mudança de modalidades selecionadas no modal.
     *
     * @param {Object} e - O evento de mudança ou o objeto de seleção.
     * @param {string} e.target.value - O valor da modalidade selecionada a partir do evento.
     * @param {string} e.value - O valor da modalidade selecionada a partir do objeto de seleção.
     */
    const handleChangeModalidades = (e) => {
        setModalidadesSelecionada((e.value === undefined) ? e.target.value : e.value);
        setModalidadeOptSelecionado(e);
    };
    /**
     * Manipula a mudança do tipo de atendimento.
     *
     * @param {Object} e - O evento de mudança.
     * @param {string} e.value - O valor do tipo de atendimento selecionado.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.value - O valor do tipo de atendimento do alvo do evento.
     */
    const handleChangeTipoAtendimento = (e) => {
        setTipoAtendimentoSelecionado((e.value === undefined) ? e.target.value : e.value);
        setTipoAtendimentoOptSelecionado(e);
    };
    /**
     * Manipula a mudança de estação selecionada.
     *
     * @param {Object} e - O evento de mudança ou o objeto de seleção.
     * @param {string} e.target.value - O valor da estação selecionada a partir do evento.
     * @param {string} e.value - O valor da estação selecionada a partir do objeto de seleção.
     */
    const handleChangeEstacao = (e) => {
        setEstacaoSelecionado((e.value === undefined) ? e.target.value : e.value);
        setEstacaoOptSelecionado(e);
    };
    /**
     * Manipula a mudança de status da recepção.
     *
     * @param {Object} e - O evento ou valor selecionado.
     * @param {string} tipo - O tipo de status a ser alterado ('atual' ou 'original').
     */
    const handleChangeStatusRecepcao = (e, tipo) => {
        if (tipo === 'atual') {
            setStatusAtendimentoAtualOptSelecionado(e);
        } else if (tipo === 'original') {
            setStatusAtendimentoOriginalOptSelecionado(e);
        }
    };
    /**
     * Manipula a mudança no campo de comentário.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.value - O novo valor do campo de comentário.
     */
    const handleChangeComentario = (e) => {
        setComentario(e.target.value);
    };
    /**
     * Função para lidar com o envio do formulário de alteração de atendimento.
     * 
     * @param {Object} param0 - Objeto contendo a hora final do atendimento.
     * @param {string} param0.hora_final - Hora final do atendimento.
     * 
     * @returns {void}
     * 
     * @throws {Error} - Lança um erro se houver um problema ao alterar o atendimento.
     */
    const handleSubmit = ({ hora_final }) => {
        const fecha = () => {
            atualizaSocket();
            onClose();
        };
        setsalvando(true);
        try {
            if (statusAtendimentoOriginalOptSelecionado === null || statusAtendimentoOriginalOptSelecionado?.value === undefined || statusAtendimentoOriginalOptSelecionado?.value === null) {
                Swal.fire('Faltando status!', 'Falta adicionar o status do atendimento original. Retorne e adicione o status do atendimento original.', 'error');
                setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                return;
            }
            Swal.fire({
                title: 'Deseja mesmo alterar esse atendimento?',
                text: 'Esta ação resultara em impactos em todo o sistema, tenha certeza do que esta fazendo!',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonAriaLabel: 'Confirmar alteração!',
                cancelButtonAriaLabel: 'Não anterar atendimento'
            }).then((response) => {
                if (response.isConfirmed) {
                    const horarioLocal = horarioSelecionadoOpt[0] ? horarioSelecionadoOpt[0] : horarioSelecionadoOpt;
                    let newData = {
                        atendimento_id: atendimento.id,
                        paciente_id: pacienteSelecionado,
                        terapia_paciente_id: terapiasPacienteSelecionado,
                        terapia_id: terapiasPacienteOptSelecionado && terapiasPacienteOptSelecionado[0] ? terapiasPacienteOptSelecionado[0].terapia_id : terapiasPacienteOptSelecionado.terapia_id,
                        especialidade_id: terapiasPacienteOptSelecionado && terapiasPacienteOptSelecionado[0] ? terapiasPacienteOptSelecionado[0].especialidade_id : terapiasPacienteOptSelecionado.especialidade_id,
                        terapeuta_id: terapeutaSelecionado,
                        modalidade_id: modalidadeSelecionado,
                        tipo_atendimento_id: tipoAgendamentoSelecionado,
                        reserva: reservaSelecionado === 'sim' ? true : false,
                        horarios_atendimento_id: horarioLocal.id,
                        data_atendimento_inicial: moment(atendimento.created_at).format('YYYY-MM-DD') + ' ' + horarioLocal.label,
                        data_atendimento_final: moment(atendimento.created_at).format('YYYY-MM-DD') + ' ' + hora_final,
                        estacao_id: estacaoSelecionado,
                        /*
                            1 - PENDENTE
                            2 - APROVADO
                            3 - REPROVADO
                        */
                        status: solicitacaoId ? '2' : '1',
                        sugestao_recepcao: statusAtendimentoOriginalOptSelecionado.value,
                        comentario: comentario,
                        user_id_solicitante: usuario.user.id,
                    };
                    if (isAtendimentoEditavel) {
                        api.post('api/atendimento-alteracao', newData).then(() => {
                            if (solicitacaoId) {
                                api.put(`api/solicitacao-alteracao/${solicitacaoId}?id=${solicitacaoId}`, { status: 'Aprovado', user_id_analista: usuario.user.id }).then(() => {
                                    fecha();
                                    snackbar.displayMessage('Alteração do atendimento cadastrada com sucesso!', 'success');
                                }).catch((e) => {
                                    console.error(e);
                                    setsalvando(false);
                                    snackbar.displayMessage('Houve um problema ao adicionar a alteração do atendimento!', 'error');
                                });
                            } else {
                                fecha();
                                snackbar.displayMessage('Alteração do atendimento cadastrada com sucesso!', 'success');
                            }
                        }).catch((e) => {
                            console.error(e);
                            setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                            snackbar.displayMessage('Houve um problema ao adicionar a alteração do atendimento!', 'error');
                        });
                    } else {
                        setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                        snackbar.displayMessage('Não é permitido editar esse atendimento!', 'error');
                    }
                } else {
                    setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido se o usuário cancelar
                }
            });
        } catch (e) {
            console.error(e);
            setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
            snackbar.displayMessage('Houve um problema ao alterar o atendimento!', 'error');
        }
    };
    /**
     * Função para reprovar a alteração de um atendimento.
     *
     * @param {number} id - O ID da alteração do atendimento a ser reprovada.
     * @returns {void}
     */
    const handleReprovaAlteracao = (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: 'Deseja realmente reprovar a alteração do atendimento? Apenas o comentário será salvo nessa alteração!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            const localId = tipoSolicitacao === 'Novo Atendimento' ? atendimentoId : id;
            const putObj =
                tipoSolicitacao === 'Novo Atendimento'
                    ? { status_aprovacao: 2, comentario: comentario, user_id_analista: usuario.user.id }
                    : { status: 3, comentario: comentario, user_id_analista: usuario.user.id, sugestao_recepcao: null };
            if (result.isConfirmed) {
                const url = `api/atendimento${tipoSolicitacao !== 'Novo Atendimento' ? '-alteracao' : ''}/${localId}`;
                api.put(url, putObj).then(() => {
                    snackbar.displayMessage('Alteração do atendimento reprovada com sucesso!', 'success');
                    onClose();
                }).catch((e) => {
                    console.error(e);
                    snackbar.displayMessage('Houve um problema ao reprovar a alteração do atendimento!', 'error');
                });
            }
        });
    };
    /**
     * Função para aprovar a alteração de um atendimento.
     *
     * Exibe um modal de confirmação para o usuário. Se confirmado, envia uma requisição PUT para atualizar o status do atendimento.
     *
     * @param {number} id - O ID do atendimento a ser alterado.
     * @param {Object} dadosAmostra - Os dados da amostra a serem alterados.
     */
    const handleAprovaAlteracao = (id, dadosAmostra) => {
        const html = tipoSolicitacao === 'Novo Atendimento' ? 'Deseja realmente confirar a criação de um Novo Atendimento?' : `<div>
                Deseja realmente aprovar a alteração do atendimento?
                <h4>CASO CONFIRMADO ESSA AÇÃO PODERÁ RESULTAR NA REMOÇÃO DOS SEGUINTES CAMPOS:</h4>
                <ul style="text-align: left;">
                    <li>Status de Execução: ${dadosAmostra.executado};</li>
                    <li>Status de Liberação: ${dadosAmostra.liberado};</li>
                    <li>Status do Atendimento: ${dadosAmostra.sugestao_recepcao};</li>
                    <li>Código 1: ${dadosAmostra.codigo_1};</li>
                    <li>Código 2: ${dadosAmostra.codigo_2};</li>
                </ul>
                <div style="text-align: left;">
                    <input type="checkbox" id="confirmRemoval" name="confirmRemoval">
                    <label for="confirmRemoval">Desejo remover os dados das colunas a cima.</label>
                </div>
            </div>`;
        Swal.fire({
            title: 'Confirmação',
            html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                const localId = tipoSolicitacao === 'Novo Atendimento' ? atendimentoId : id;
                const removeDados = document?.getElementById('confirmRemoval')?.checked;
                const putObj =
                    tipoSolicitacao === 'Novo Atendimento'
                        ? { id: localId, status_aprovacao: 1, comentario: comentario, user_id_analista: usuario.user.id }
                        : { id: localId, status: 4, comentario: comentario, user_id_analista: usuario.user.id, remove_dados: removeDados }
                    ;
                const url = `api/atendimento${tipoSolicitacao !== 'Novo Atendimento' ? '-alteracao' : ''}/${localId}`;
                api.put(url, putObj).then(() => {
                    snackbar.displayMessage('Alteração do atendimento aprovada com sucesso!', 'success');
                    onClose();
                }).catch((e) => {
                    console.error(e);
                    snackbar.displayMessage('Houve um problema ao aprovar a alteração do atendimento!', 'error');
                });
            }
        });
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {/* ON LOAD */
        if (!tipoAtendimentoOptionsProp || !tipoAtendimentoOptionsProp?.length > 0) fetchTipoAtendimentosData();
        if (!modalidadesOptionsListProp || !modalidadesOptionsListProp?.length > 0) fetchModalidadeData();
        if (!horarioAtendimentoOptionsProp || !horarioAtendimentoOptionsProp?.length > 0) fetchHorarioAtendimentoComFimData();
        if (!estacaoOptionsProp || !estacaoOptionsProp?.length > 0) fetchSalaEstacaoData();
        if (!statusAtendimentoOptionsProp || !statusAtendimentoOptionsProp?.length > 0) fetchStatusAtendimentosData();
        if (!pacienteOptionsProp || !pacienteOptionsProp?.length > 0) fetchPacienteData();
        setloading(true);
    }, []);
    useEffect(() => {// Carrega tudo
        setListasCarregadas(modalidadesOptionsList?.length > 0 && estacaoOptionsList?.length > 0 && pacientesList?.length > 0 && statusAtendimentoOptionsList?.length > 0 && tipoAtendimentoOptionsList?.length > 0 && horarioAtendimentoOptionsList?.length > 0);
    }, [modalidadesOptionsList, estacaoOptionsList, pacientesList, statusAtendimentoOptionsList, tipoAtendimentoOptionsList, horarioAtendimentoOptionsList]);
    useEffect(() => {
        if (listasCarregadas) {
            carregaTudo();
        }
    }, [listasCarregadas]);
    useEffect(() => {
        if (pacienteOptionsList) {
            setPacienteService(new PacienteService(pacienteOptionsList));
        }
    }, [pacienteOptionsList]);
    useEffect(() => {
        if (!atendimento) return;
        setHour(atendimento.hora_atendimento);
    }, [atendimento]);
    useEffect(() => {
        if (terapeutaSelecionado && terapeutasOptions.length > 0) {
            setTerapeutaOptSelecionado(terapeutasOptions.find((value) => value.value === terapeutaSelecionado));
        }
    }, [terapeutaSelecionado]);
    useEffect(() => {
        if (pacienteSelecionado) {
            setPacienteOptSelecionado(pacienteOptionsList.find((value) => value.value === pacienteSelecionado));
        }
    }, [pacienteSelecionado]);
    useEffect(() => {
        if (solicitacaoId) {
            fetchSolicitaca(solicitacaoId).then(data => {
                setSolicitacao(data);
            });
        }
    }, [solicitacaoId]);
    //#endregion

    //#region HTML
    return (
        <>
            <Modal id={modalId} onClick={handleOutsideClick}>
                <Container>
                    <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                        <div className="container-fluid d-flex align-items-center justify-content-between">
                            <div className='w-200px'></div>
                            <ModalTitulo>{isAtendimentoEditavel ? 'Alterar Atendimento' : 'Visualizar Alterações'}</ModalTitulo>
                            <label className='btn-outline w-200px text-right' onClick={() => handleCloseCheck()}>
                                <CgClose size={22} color='red' />
                            </label>
                        </div>
                    </nav>
                    <br></br>
                    {(!loading && atendimento)
                        ? <Form className='row' onSubmit={handleSubmit} ref={formRef}>
                            {solicitacaoId && solicitacao &&
                                <>
                                    <div className='col-6'>
                                        <TextArea disabled title='Solicitação de Alteração' name='descricao' value={solicitacao.descricao} />
                                    </div>
                                    <div className='col-6'>
                                        <TextArea disabled title='Justificativa' name='motivo' value={solicitacao.motivo} />
                                    </div>
                                </>
                            }
                            {/* VISUALIZAÇÃO DADOS ORIGINAIS */}
                            <div className={`col-${tipoSolicitacao === 'Novo Atendimento' ? '12' : '6'}`}>
                                <div className='div-titulo w-100'>
                                    <label className='font-16px w-100'>Dados Originais do Atendimento</label>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <Input disabled title='Identificador:' name='_identificador' value={atendimento && `${atendimento.paciente_identificador}`} />
                                    </div>
                                    <div className='col-10'>
                                        <Input disabled title='Nome:' name='_paciente' value={atendimento && `${atendimento.paciente_nome}`} />
                                    </div>
                                    <div className='col-6'>
                                        <Input disabled title='Data:' name='_data' defaultValue={atendimento && dayName[atendimento.dia_semana]} />
                                    </div>
                                    <div className='col-6'>
                                        <Input disabled title='Horario:' name='_horario' defaultValue={atendimento && moment.utc(atendimento.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(atendimento.data_atendimento_final).format('HH:mm')} />
                                    </div>
                                    <div className='col-6'>
                                        <Input disabled title='Terapia do Paciente:' name='_terapia_paciente' defaultValue={atendimento && atendimento.terapia + ' - ' + atendimento.especialidade + ' - ' + atendimento.nome_reduzido} />
                                    </div>
                                    <div className='col-6'>
                                        <Input disabled title='Terapeuta:' name='_terapeuta' defaultValue={atendimento && atendimento.terapeuta_nome} />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Código 1:' name='_codigo1' defaultValue={atendimento && atendimento.codigo_1} />
                                    </div>
                                    <div className='col-3'>
                                        <Input disabled title='Código 2:' name='_codigo2' defaultValue={atendimento && atendimento.codigo_2} />
                                    </div>
                                    <div className='col-3'>
                                        <Input disabled title='Status Execucao:' name='_executado' defaultValue={atendimento && atendimento.executado} />
                                    </div>
                                    <div className='col-2'>
                                        <Input disabled title='Liberado:' name='_executado' defaultValue={atendimento ? atendimento.liberado === 0 ? '❌' : atendimento.liberado === 1 ? '✅' : 'vazio' : null} />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Modalidade:' name='_Modalidade' defaultValue={atendimento && atendimento.modalidade} />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Tipo de Atendimento:' name='_tipoAtendimento' defaultValue={atendimento && atendimento.tipo_atendimento} />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Sala / Estação:' name='_estacao' defaultValue={atendimento && (atendimento.sala + ' / ' + atendimento.estacao)} />
                                    </div>
                                    {tipoSolicitacao !== 'Novo Atendimento' &&
                                        <div className='col-12'>
                                            <Select
                                                id={'status-original'}
                                                name={'_status-original'}
                                                label='Status do Atendimento Original:'
                                                options={statusAtendimentoOptionsList}
                                                readOnly={isAtendimentoEditavel ? '' : 'readOnly'}
                                                value={statusAtendimentoOriginalOptSelecionado}
                                                onChange={(e) => { handleChangeStatusRecepcao(e, 'original'); }}
                                            />
                                        </div>
                                    }

                                    <div className='col-12'>
                                        <TextArea disabled title='Comentário:' name='_comentario' defaultValue={atendimento && atendimento.comentario} />
                                    </div>
                                    <div className='row col-12'>
                                        {tipoSolicitacao === 'Novo Atendimento' && usuario?.user?.tipo_user?.toUpperCase() !== 'AGENDAMENTO' &&
                                            <>
                                                <div className='col-4 d-flex justify-content-end mt-2'>
                                                    <Button type={'button'} className='btn-padrao btn-vermelho' onClick={() => handleReprovaAlteracao()}> Reprovar Alteração </Button>
                                                </div>
                                                <div className='col-4 d-flex justify-content-end mt-2'>
                                                    <Button type={'button'} className='btn-padrao btn-verde' onClick={() => handleAprovaAlteracao()}> Aprovar Alteração </Button>
                                                </div>
                                            </>

                                        }
                                        <div className={`d-flex justify-content-start col-${tipoSolicitacao === 'Novo Atendimento' && usuario?.user?.tipo_user?.toUpperCase() !== 'AGENDAMENTO'
                                            ? '4'
                                            : '12'
                                            } mt-2`}>
                                            <Button type='button' onClick={() => onClose()} disabled={salvando}> <BsArrowLeft size={25} className='me-2' /> Voltar </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* SOLICITAR NOVA ALTERAÇÃO */}
                            {(!alteracao && isAtendimentoEditavel)
                                ? (atendimento && !atendimento.reposicao && tipoSolicitacao !== 'Novo Atendimento') && <div className='col-6'>
                                    <div className='div-titulo w-100'>
                                        <label className='font-16px'>Solicitar Alteração</label>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Select label='Paciente:' id='paciente_select' name='paciente' options={pacienteOptionsList} value={pacienteOptSelecionado} filterOption={pacienteService.customPacienteFilter} defaultValue={pacienteOptSelecionado} onChange={(e) => handleChangePaciente(e)} />
                                        </div>
                                        <div className='col-4'>
                                            <Input disabled title='Data:' name='data' readOnly={'readOnly'} defaultValue={atendimento && dayName[atendimento.dia_semana]} />
                                        </div>
                                        <div className='col-4'>
                                            <Select name='horario' label='Horário:' options={horarioAtendimentoOptionsList} onChange={(e) => handleChangeHorario(e)} defaultValue={horarioSelecionadoOpt} value={horarioSelecionadoOpt} />
                                        </div>
                                        <div className='col-4'>
                                            <Input id='hora_final' name='hora_final' title={'Horário final:'} defaultValue={atendimento?.data_atendimento_final && moment.utc(atendimento.data_atendimento_final).format('HH:mm')} />
                                        </div>
                                        <div className='col-12'>
                                            <Select label='Terapia do Paciente:' name='terapia_pacientes' options={terapiasPacienteOptions} value={terapiasPacienteOptSelecionado} defaultValue={terapiasPacienteOptSelecionado} onChange={(e) => handleChangeTerapiaPacientes(e)} />
                                        </div>
                                        <div className='col-12'>
                                            <div className='col-lg-12 col-sm-12'>
                                                <Select label='Terapeuta:' name='Terapeuta' options={terapeutasOptions} value={terapeutaOptSelecionado} defaultValue={terapeutaOptSelecionado} onChange={(e) => handleChangeTerapeuta(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <Select name='modalidade_id' label='Modalidade:' options={modalidadesOptionsList} value={modalidadeOptSelecionado} defaultValue={modalidadeOptSelecionado} onChange={(e) => handleChangeModalidades(e)} />
                                        </div>
                                        <div className='col-4'>
                                            <Select name='tipo_atendimento_id' label='Tipo de Atendimento:' options={tipoAtendimentoOptionsList} defaultValue={tipoAtendimentoOptSelecionado} value={tipoAtendimentoOptSelecionado} onChange={(e) => handleChangeTipoAtendimento(e)} />
                                        </div>
                                        <div className='col-4'>
                                            <Select name='estacao_id' label='Sala / Estação:' options={estacaoOptionsList} value={estacaoOptSelecionado} defaultValue={estacaoOptSelecionado} onChange={(e) => handleChangeEstacao(e)} />
                                        </div>
                                        <div className='col-12'>
                                            <TextArea title='Comentário:' name='comentario' value={comentario} onChange={(e) => handleChangeComentario(e)} />
                                        </div>
                                        {modal_alteracao_opcoes['cria'] &&
                                            <div className='col-12 d-flex justify-content-end mt-2'>
                                                <Button type={'submit'} disabled={salvando}>
                                                    {salvando
                                                        ? <>
                                                            Salvando <ScaleLoader css={'display: block; margin-top: 0px; margin-left: 4px; border-color: orange;'} height={20} color={'#fd931a'} />
                                                        </>
                                                        : 'Alterar Atendimento'
                                                    }
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : <div className={`col-6`}>
                                    <div className='div-titulo w-100'>
                                        <label className='font-16px w-100'>Dados Da Alteração</label>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Input disabled title='Nome:' name='_paciente' value={alteracao?.paciente_nome} />
                                        </div>
                                        <div className='col-6'>
                                            <Input disabled title='Data:' name='_data' defaultValue={alteracao && dayName[alteracao?.dia_semana]} />
                                        </div>
                                        <div className='col-6'>
                                            <Input disabled title='Horario:' name='_horario' defaultValue={alteracao && moment.utc(alteracao?.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(alteracao?.data_atendimento_final).format('HH:mm')} />
                                        </div>

                                        <div className='col-12'>
                                            <Input disabled title='Terapia do Paciente:' name='_terapia_paciente' defaultValue={alteracao && alteracao?.terapia + ' - ' + alteracao?.especialidade + ' - ' + alteracao?.nome_reduzido} />
                                        </div>
                                        <div className='col-12'>
                                            <Input disabled title='Terapeuta:' name='_terapeuta' defaultValue={alteracao && alteracao?.terapeuta_nome} />
                                        </div>
                                        <div className='col-4'>
                                            <Input disabled title='Modalidade:' name='_Modalidade' defaultValue={alteracao && alteracao?.modalidade} />
                                        </div>
                                        <div className='col-4'>
                                            <Input disabled title='Tipo de Atendimento:' name='_tipoAtendimento' defaultValue={alteracao && alteracao?.tipo_atendimento} />
                                        </div>
                                        <div className='col-4'>
                                            <Input disabled title='Sala / Estação:' name='_estacao' defaultValue={alteracao && (alteracao?.sala + ' / ' + alteracao?.estacao)} />
                                        </div>
                                        <div className='col-12'>
                                            <TextArea disabled title='Comentário:' name='_comentario' defaultValue={alteracao && alteracao?.comentario_a} />
                                        </div>
                                        {alterado &&
                                            <>
                                                {modal_alteracao_opcoes['aprova'] && statusAlteracao === '1' &&
                                                    <div className={`col-${modal_alteracao_opcoes['aprova'] ? '6' : '12'} d-flex justify-content-end mt-2`}>
                                                        <Button type={'button'} className='btn-padrao btn-verde' onClick={() => handleAprovaAlteracao(alteracao?.id, {
                                                            executado: atendimento.executado,
                                                            liberado: atendimento.liberado === 1 ? '✅' : atendimento.liberado === 0 ? '❌' : 'vazio',
                                                            sugestao_recepcao: atendimento.sugestao_recepcao_name,
                                                            codigo_1: atendimento.codigo_1,
                                                            codigo_2: atendimento.codigo_2
                                                        })}> Aprovar Alteração </Button>
                                                    </div>
                                                }
                                                {modal_alteracao_opcoes['reprova'] &&
                                                    <div className={`col-${modal_alteracao_opcoes['aprova'] && statusAlteracao === '1' ? '6' : '12'} d-flex justify-content-end mt-2`}>
                                                        <Button type={'button'} className='btn-padrao btn-vermelho' onClick={() => handleReprovaAlteracao(alteracao?.id)}> Reprovar Alteração </Button>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </Form>
                        : <div className='m-auto'>
                            <ClipLoader size={50} color='#4906AB' />
                        </div>
                    }
                </Container>
            </Modal>
        </>
    );
    //#endregion
};
export default AtendimentoModal;
