import { useState } from 'react';
import api from 'services/api';

const useResponsaveisData = () => {
    const [responsaveisOptions, setResponsaveisOptions] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);

    const mapResponsaveisData = (data) => {
        return data.map((terapeuta) => ({
            value: terapeuta.id,
            label: terapeuta.nome
        }));
    };

    const fetchResponsaveisData = async () => {
        try {
            const { data } = await api.get('api/resposavel?select=id,nome');
            const options = mapResponsaveisData(data);
            setResponsaveisOptions(options);
        } catch (error) {
            console.error('Erro ao carregar responsaveis:', error);
        }
    };

    const fetchResponsaveisFiltradosData = async (filtro) => {
        try {
            const { data } = await api.post(`api/responsavel/relatorio/filtrado`, filtro);
            setResponsaveis(data);
            return data;
        } catch (error) {
            console.error('Erro ao carregar responsaveis:', error);
        }
    }

    return { responsaveisOptions, responsaveis, fetchResponsaveisData, mapResponsaveisData, fetchResponsaveisFiltradosData };
};

export default useResponsaveisData;