//#region IMPROTAÇÕES
import { Form } from '@unform/web';
import LiveBadge from 'components/LiveBadge';
import ComentariosModal from 'components/Modais/Comentarios';
import ModalTitulo from 'components/ModalTitulo';
import useIcons from 'context/icons';
import { useSnackbar } from 'context/snackbar';
import useStatusAtendimentosData from 'context/status-atendimento';
import moment from 'moment';
import React, { cloneElement, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';
import { SimpleInput } from '../../Input';
import Select from '../../Select';
import './styles.css';
import useTerapiasData from 'context/terapia';
import useEspecialidadesData from 'context/especialidade';
import { wsBaseUrl } from 'services/baseUrl';
//#endregion

/**
 * Componente de Modal para Controle de Execução.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.controleDiarioSocket - Socket de controle diário.
 * @param {Object} props.user - Objeto do usuário.
 * @param {Function} props.onClose - Função chamada ao fechar o modal.
 * @param {Function} props.onOpen - Função chamada ao abrir o modal.
 * @param {boolean} props.modoIncorporado - Indica se o modal está em modo incorporado.
 * @param {boolean} props.isCheckIn - Indica se o modal é de check-in.
 * @param {Object} ref - Referência do componente.
 *
 * @returns {JSX.Element} - Elemento JSX do modal de controle de execução.
 *
 * @example
 * <ControleDeExecucaoModal
 *   controleDiarioSocket={socket}
 *   user={user}
 *   onClose={handleClose}
 *   onOpen={handleOpen}
 *   modoIncorporado={false}
 *   isCheckIn={false}
 *   ref={modalRef}
 * />
 */
const ControleDeExecucaoModal = forwardRef(({
    controleDiarioSocket = null,
    user,
    onClose = () => { },
    onOpen = () => { },
    modoIncorporado = false,
    isCheckIn = false,
}, ref) => {
    //#region VARIÁVEIS
    // Dados da página
    const title = 'Controle de Execução';
    // Hooks
    const snackbar = useSnackbar();
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const { iconArray } = useIcons();
    const { navigator } = React.useContext(UNSAFE_NavigationContext);
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    // Estados
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setloading] = useState(false);
    const [presenca, setPresenca] = useState([]);
    const [carregandoGuias, setCarregandoGuias] = useState(false);
    const [salvando, setSalvando] = useState(false);
    // Estado de motificação
    const [pendenteDeSalvamento, setPendenteDeSalvamento] = useState(false);
    // Listas
    const [guiasPaciente, setGuiasPaciente] = useState([]);
    const [guiasId, setGuiasId] = useState([]);
    const [atendimentosFiltrados, setAtendimentosFiltrados] = useState(null);
    const [filtredStatusAtendimentoOptions, setFiltredStatusAtendimentoOptions] = useState(null);
    const executadoOptions = {
        plano: [
            { value: 'vazio', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
            { value: 'pendente', label: 'Pendente' },
            { value: 'executado', label: 'Executado' },
            { value: 'executado-sce', label: 'SCE' },
            { value: 'nao', label: 'Não Executado' },
        ],
        particular: [
            { value: 'vazio', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
            { value: 'pendente', label: 'Pendente' },
            { value: 'pago', label: 'Pago' }
        ]
    };
    const opcoesLiberado = [
        { value: 'vazio', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
        { value: '0', label: '❌' },
        { value: '1', label: '✅' }
    ];
    // Dados de formulário
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState([]);
    const [executadoSelecionadoOpt, setExecutadoSelecionadoOpt] = useState(null);
    const [pacienteId, setPacienteId] = useState();
    const [controleDiarioId, setControleDiarioId] = useState();
    const [carterinhas, setCarterinhas] = useState([]);
    const [nome, setNome] = useState('');
    // Limites
    const [limitesPorTerapia, setLimitesPorTerapia] = useState([]);
    const [limiteAtingido, setLimiteAtingido] = useState(false);
    // Socket
    const socketRef = useRef(null);
    const [socketState, setSocketState] = useState(null);
    // Estilos
    const customStyles = {
        overlay: modoIncorporado ? {
            position: 'relative',
            backgroundColor: 'transparent',
            margin: '0',
            padding: '0',
            ...isCheckIn ? {
                display: 'flex',
                justifyContent: 'end',
            } : {}
        } : {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '999',
        },
        content: modoIncorporado ? {
            position: 'relative',
            height: 'fit-content',
            backgroundColor: '#F5F7FA',
            inset: '0',
            ...isCheckIn ? {
                width: window.innerWidth - 50,
            } : {
                width: '100%',
            }
        } : {
            position: 'absolute',
            zIndex: '1000',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '95vw',
            marginRight: '-50%',
            padding: '0',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#F5F7FA'
        },
    };
    const [newCustomStyles, setNewCustomStyles] = useState({
        overlay: { ...customStyles.overlay },
        content: {
            ...customStyles.content,
            ...presenca?.filter(f => {
                const tem = (Number(f.user_id) !== Number(user.id) && Number(f.paciente_id) === Number(pacienteId));
                return tem;
            }).length > 0
                ? {
                    boxShadow: '0px 0px 20px #fff000',
                    border: '2px #fff000 solid'
                }
                : {}
        }
    });
    const codigosInputStyle = {
        width: '80%',
        margin: 'auto',
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    };
    // Refs
    const modalComentariosRef = useRef();
    const [selectedGuiaIds, setSelectedGuiaIds] = useState([]);
    //#endregion
    //#region FUNCOES
    //#endregion
    //#region FUNCOES de MODAL
    /**
     * Abre um modal para exibir informações de atendimento e carteirinhas faltantes de um paciente.
     *
     * @function
     * @async
     * @param {number} controle_diario_id - ID do controle diário.
     * @param {number} paciente_id - ID do paciente.
     * @returns {Promise<void>}
     *
     * @example
     * openModal(123, 456);
     *
     * @description
     * Esta função faz duas requisições assíncronas para obter dados de carteirinhas faltantes e atendimentos
     * de um paciente específico. Os dados são processados e armazenados em estados locais para serem exibidos
     * em um modal. A função também lida com erros de requisição e ordena os atendimentos por hora.
     */
    const openModal = useCallback(async (controle_diario_id, paciente_id) => {
        /* CARREGA LISTAS INICIAIS */
        fetchTerapias();
        fetchEspecialidades();
        fetchStatusAtendimentosData();

        /* Atualiza os status de abertura de de carregamento */
        setIsOpen(true);
        setloading(true);

        // Coleta os limites de terapias diárias
        let atendimentos = [];
        let listaSelecionadosExec = [];
        setControleDiarioId(controle_diario_id);
        setPacienteId(paciente_id);
        await api.get(`api/paciente/getCarterinhasFaltantes/${paciente_id}`)
            .then(({ data }) => {
                const { carterinhas, pendentes } = data;
                let carterinhasList = [];
                pendentes?.forEach((item) => {
                    carterinhasList.push({
                        id: item.id,
                        created_at: false,
                        plano_saude: { nome_reduzido: item.plano },
                        ultima_conferencia: null
                    });
                });

                carterinhas?.forEach((item) => {
                    if (typeof item === 'object') {
                        item.forEach((subItem) => {
                            carterinhasList.push(subItem);
                        });
                    } else {
                        carterinhasList.push(item);
                    }
                });

                setCarterinhas(carterinhasList);
            })
            .catch((error) => console.error(error));
        const filters = {
            paciente_id: paciente_id,
            controle_diario_id: controle_diario_id,
            duplica_alteracoes: false,
            select: [
                'id',
                'paciente_id',
                'paciente_nome',
                'paciente_identificador',
                'terapeuta_nome',
                'terapeuta_id',
                'terapia',
                'especialidade',
                'sala',
                'estacao',
                'hora_atendimento',
                'liberado',
                'assinado',
                'sugestao_recepcao',
                'executado',
                'terapia_paciente_id',
                'terapia_id',
                'especialidade_id',
                'tipo_atendimento_id',
                'tipo_atendimento',
                'codigo_1',
                'codigo_2',
                'nome_reduzido',
                'exige_guia',
                'exige_lista_presenca',
                'nome_codigo_1',
                'nome_codigo_2',
                'modo_criacao',
                'modo_criacao_nome',
                'codigo_plano',
                'guia_id',
            ]
        }
        const url = `api/atendimento/relatorio/filtrado/puro`;
        await api.post(url, filters)
            .then(({ data }) => {
                try {
                    data.sort((a, b) => {
                        return a.hora_atendimento.localeCompare(b.hora_atendimento);
                    });

                    const guias_id = [...new Set(data.map((a) => a.guia_id).filter((id) => id !== null))];
                    setGuiasId(guias_id);
                    // Reseta o estado de guias do paciente
                    setGuiasPaciente([]);
                    // Coleta as guias do paciente
                    handleAtualizaGuias(controle_diario_id, paciente_id, guias_id);

                    data.forEach(async (a) => {
                        setNome(`(${a.paciente_identificador}) ${a.paciente_nome}`);
                        let atendimento = a;
                        atendimentos.push(atendimento);
                        switch (atendimento.executado) {
                            case 'pago':
                                listaSelecionadosExec[atendimentos.length - 1] = { value: 'pago', label: 'Pago' };
                                break;
                            case 'executado':
                                listaSelecionadosExec[atendimentos.length - 1] = { value: 'executado', label: 'Executado' };
                                break;
                            case 'executado-sce':
                                listaSelecionadosExec[atendimentos.length - 1] = { value: 'executado-sce', label: 'Executado Sce' };
                                break;
                            case 'pendente':
                                listaSelecionadosExec[atendimentos.length - 1] = { value: 'pendente', label: 'Pendente' };
                                break;
                            default:
                                listaSelecionadosExec[atendimentos.length - 1] = null;
                                break;
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        setExecutadoSelecionadoOpt(listaSelecionadosExec);
        setAtendimentosFiltrados(atendimentos);
        setloading(false);
        onOpen();
    }, []);
    /**
     * Fecha o modal de check-in.
     *
     * @param {boolean} isSalvando - Indica se as alterações estão sendo salvas.
     * @param {Object} socketLocal - Instância do socket local.
     * @param {number} controleDiarioId - ID do controle diário.
     * @returns {Promise<void>} - Uma promessa que resolve quando o modal é fechado.
     */
    const closeModal = useCallback(async (isSalvando, socketLocal, controleDiarioId) => {
        setPendenteDeSalvamento(false);
        setSalvando(false);
        let passa = false;
        if (!isSalvando) {
            await Swal.fire({
                title: 'Deseja mesmo saír sem salvar nada?',
                html: 'Se você sair agora, qualquer alteração feita não será salva, caso queira salvar, aperte em Salvar!',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonAriaLabel: 'Sair sem salvar!',
                cancelButtonAriaLabel: 'Voltar'
            }).then((response) => {
                if (response.isConfirmed) {
                    passa = true;
                }
            });
        }

        if (isSalvando || passa) {
            setIsOpen(false);
            socketRef.current.close();
            onClose(controleDiarioSocket, controleDiarioId);
        }
    }, [onClose, controleDiarioSocket, socketRef]);
    const afterOpenModal = () => { };
    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
        isOpen
    }), [isOpen]);
    //#endregion
    //#region HANDLES
    /**
     * Manipula a mudança do estado "liberado" de um atendimento filtrado.
     *
     * @param {Object} e - O evento que contém o novo valor.
     * @param {number} index - O índice do atendimento a ser atualizado.
     */
    const handleChangeLiberado = useCallback((e, index) => {
        setPendenteDeSalvamento(true);
        const newAtendimentos = [...atendimentosFiltrados];
        newAtendimentos[index].liberado = String(e.value);
        setAtendimentosFiltrados(newAtendimentos);
    }, [atendimentosFiltrados]);
    const handleChangeStatusAtendimento = useCallback((e, index) => {
        setPendenteDeSalvamento(true);
        const newAtendimentos = [...atendimentosFiltrados];
        newAtendimentos[index].sugestao_recepcao = String(e.value);
        setAtendimentosFiltrados(newAtendimentos);
    }, [atendimentosFiltrados]);
    /**
     * Manipula a mudança do estado "assinado" de um atendimento específico.
     *
     * @param {Object} e - O evento de mudança do checkbox.
     * @param {number} index - O índice do atendimento no array de atendimentos filtrados.
     */
    const handleChangeAssinado = useCallback((e, index) => {
        setPendenteDeSalvamento(true);
        const newAtendimentos = [...atendimentosFiltrados];
        newAtendimentos[index].assinado = e.target.checked;
        setAtendimentosFiltrados(newAtendimentos);
    }, [atendimentosFiltrados]);
    /**
     * Manipula a mudança de seleção de um item executado.
     *
     * @param {Object} e - O evento de mudança.
     * @param {number} index - O índice do item na lista de opções selecionadas.
     */
    const handleChangeExecutado = useCallback((e, index) => {
        setPendenteDeSalvamento(true);
        const newSelect = [...executadoSelecionadoOpt];
        newSelect[index] = e;
        setExecutadoSelecionadoOpt(newSelect);
    }, [executadoSelecionadoOpt]);
    /**
     * Manipula a visibilidade dos elementos select 'liberado' e 'executado' com base no valor do input 'codigo1'.
     * 
     * @param {number} index - O índice do elemento a ser manipulado.
     */
    const handleChangeCodigo = useCallback((index, codigo = null) => {
        setPendenteDeSalvamento(true);
        let codigoSelect = null;
        if (codigo) {
            const { value, guia_id } = codigo;
            codigoSelect = value;
            const newSelectedGuiaIds = [...selectedGuiaIds];
            newSelectedGuiaIds[index] = guia_id;
            setSelectedGuiaIds(newSelectedGuiaIds);
        }
        const cod1 = codigoSelect ? codigoSelect : document.getElementById('codigo1' + index)?.value;
        const selectLiberado = document.getElementById('liberado' + index);
        const selectExecutado = document.getElementById('executado' + index);
        const bloqueioLiberado = document.getElementById('bloqueio-liberado' + index);
        if (cod1) {
            if (selectLiberado)
                selectLiberado.classList.remove('some');
            if (selectExecutado)
                selectExecutado.classList.remove('some');
            if (bloqueioLiberado)
                bloqueioLiberado.classList.add('some');
        } else {
            if (selectLiberado)
                selectLiberado.classList.add('some');
            if (selectExecutado)
                selectExecutado.classList.add('some');
            if (bloqueioLiberado)
                bloqueioLiberado.classList.remove('some');

            let newSelectExecutado = [...executadoSelecionadoOpt];
            newSelectExecutado[index] = { value: 'vazio', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> };
            setExecutadoSelecionadoOpt(newSelectExecutado);
        }
    }, [executadoSelecionadoOpt, selectedGuiaIds]);
    /**
     * Função que verifica se a quantidade de terapias diárias permitidas por plano de saúde foi atingida.
     * 
     * Percorre os limites definidos por terapia e verifica se a quantidade de atendimentos liberados
     * para cada plano de saúde excede o limite diário permitido. Caso o limite seja atingido, exibe
     * um alerta utilizando a biblioteca Swal e atualiza o estado `limiteAtingido`.
     * 
     * @callback handleTestaQuantidade
     * @returns {void}
     */
    const handleTestaQuantidade = useCallback(() => {
        let atingido = false;
        limitesPorTerapia.forEach((limite) => {
            let quantidade = 0;
            let nome;
            atendimentosFiltrados.forEach((atendimento, index) => {
                const cod1 = document.getElementById('codigo1' + index);
                if (atendimento.liberado && cod1.value) {
                    if (Number(limite.value) === Number(planoSaudeSelecionadoOpt[index][0].plano_saude_id)) {
                        quantidade = quantidade + 1;
                        nome = planoSaudeSelecionadoOpt[index][0].label;
                    }
                }
            });
            if (limite.limite_diario_terapias !== null && limite.limite_diario_terapias !== 0 && quantidade > limite.limite_diario_terapias) {
                Swal.fire('Limite Atingido!', `O limite de terapias diárias do plano de saúde: ${nome} foi atingido. Limite: ${limite.limite_diario_terapias} Quantidade: ${quantidade}`, 'error');
                atingido = true;
            }
        });
        setLimiteAtingido(atingido);
    }, [limitesPorTerapia, atendimentosFiltrados, planoSaudeSelecionadoOpt]);
    /**
     * Função para lidar com o envio de dados do formulário.
     * 
     * @param {Object} data - Dados do formulário a serem enviados.
     * 
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando o processo de envio é concluído.
     * 
     * @throws {Error} - Lança um erro se ocorrer algum problema durante o envio dos dados.
     * 
     * @async
     * 
     * @callback handleSubmit
     * 
     * @param {boolean} limiteAtingido - Indica se o limite de atendimentos diário foi atingido.
     * @param {Array} carterinhas - Lista de carteirinhas do paciente.
     * @param {Array} atendimentosFiltrados - Lista de atendimentos filtrados.
     * @param {Array} planoSaudeSelecionadoOpt - Opções de plano de saúde selecionadas.
     * @param {Array} executadoSelecionadoOpt - Opções de execução selecionadas.
     * @param {number} paciente_id - ID do paciente.
     * @param {Object} snackbar - Objeto para exibir mensagens de feedback.
     * @param {Function} closeModal - Função para fechar o modal.
     * @param {number} controleDiarioId - ID de controle diário.
     */
    const handleSubmit = useCallback(async (data) => {
        const localGuias = await handleAtualizaGuias();
        setSalvando(true);
        if (limiteAtingido) {
            return Swal.fire('Limite Atingido!', 'O limite de atendimentos diário de algum dos planos utilizados foi atingido!', 'error');
        }
        Swal.fire({
            title: 'Deseja mesmo salvar os dados inseridos?',
            html: 'Você confirma a procedência dos dados inseridos, e deseja salva-los?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Sair sem salvar!',
            cancelButtonAriaLabel: 'Voltar'
        }).then(async (response) => {
            if (response.isConfirmed) {
                try {
                    //#region ATUALIZAR CARTERINHAS
                    carterinhas.forEach((item) => {
                        const alteredItem = data['numero_carterinha' + item.id];
                        if (data[`numero_carterinha${item.id}new`]) {
                            if (data[`numero_carterinha${item.id}new`]) {
                                let newData = {
                                    plano_saude_id: item.id,
                                    paciente_id: pacienteId,
                                    ultima_conferencia: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    numero_carterinha: data[`numero_carterinha${item.id}new`]
                                };
                                newData = {
                                    ...newData,
                                    numero_carterinha: alteredItem
                                };
                                api.post('api/carterinha-plano-paciente', newData)
                                    .catch(error => console.error(error));
                            }
                        } else {
                            let newData = {
                                ...item,
                                ultima_conferencia: moment().format('YYYY-MM-DD HH:mm:ss')
                            };
                            if (item.numero_carterinha !== alteredItem) {
                                newData = {
                                    ...newData,
                                    numero_carterinha: alteredItem
                                };
                            }
                            api.patch(`api/carterinha-plano-paciente/${item.id}`, newData)
                                .catch(error => console.error(error));
                        }
                    });
                    //#endregion
                    let temErroGuia = false;
                    const atendimentos = atendimentosFiltrados.map((el, index) => {
                        //#region CAMPOS A ATUALIZAR
                        const guia_id = selectedGuiaIds[index] ? selectedGuiaIds[index] : null;
                        const guia = localGuias?.find(guia => Number(guia.id) === Number(guia_id));
                        const codigo1 = Boolean(el.exige_guia)
                            ? guia?.identificador_geral
                            : data[`codigo1${index}`] ? data[`codigo1${index}`] : 'vazio';
                        const codigo2 = data[`codigo2${index}`] ? data[`codigo2${index}`] : 'vazio';
                        const sugestao = el?.sugestao_recepcao ? el.sugestao_recepcao : null;
                        const liberado =
                            (codigo1 && codigo1 !== undefined && codigo1 !== 'vazio')
                                ? el.liberado
                                : Boolean(el.assinado)
                                    ? el.liberado
                                    : sugestao
                                        ? 0
                                        : el.liberado
                            ;
                        //#endregion

                        //#region TESTE DE GUIAS
                        if (Boolean(el.exige_guia) && el.liberado == 1) {
                            const guia = localGuias.find(guia => guia.terapia_paciente_id === el.terapia_paciente_id);
                            if (!guia) {
                                temErroGuia = true;
                                Swal.fire({
                                    title: 'Erro!',
                                    html: `<div>
                                        <h5>Guia não encontrada para o atendimento!</h5>
                                        <table class='table table-bordered table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>${el.id}</td>
                                                    <td>${el.nome_reduzido} - ${el.terapia} - ${el.especialidade}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>`,
                                    icon: 'error'
                                })
                                return;
                            } else {
                                const disponivel = guia.quantidade_liberada - guia.quantidade_feita;
                                const usandoAgora = atendimentosFiltrados.filter(a => a.terapia_paciente_id === el.terapia_paciente_id && a.liberado == 1).length;

                                if (disponivel < usandoAgora) {
                                    temErroGuia = true;
                                    Swal.fire({
                                        title: 'Erro!',
                                        html: `<div>
                                            <h5>Quantidade de atendimentos excede o limite da guia!</h5>
                                            <table class='table table-bordered table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th></th>
                                                        <th>D</th>
                                                        <th>N</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>${el.id}</td>
                                                        <td>${el.nome_reduzido} - ${el.terapia} - ${el.especialidade}</td>
                                                        <td>${disponivel}</td>
                                                        <td>${usandoAgora}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>`,
                                        icon: 'error'
                                    })
                                    return;
                                }
                            }
                        }
                        //#endregion
                        const atendimento = {
                            id: el.id,
                            codigo_1: codigo1,
                            codigo_2: codigo2,
                            guia_id: guia_id,
                            ...isCheckIn
                                ? {
                                    assinado: el.assinado,
                                    sugestao_recepcao: el.sugestao_recepcao,
                                    liberado: liberado,
                                }
                                : {
                                    executado: executadoSelecionadoOpt[index] ? executadoSelecionadoOpt[index].value : '',
                                }
                        };

                        return atendimento;
                    });
                    if (!temErroGuia) {
                        await api.put('api/atendimento/controle/execucao', { atendimentos }).then(() => {
                            snackbar.displayMessage('Controle de execução salvo com sucesso.', 'success');
                        }).catch((error) => {
                            snackbar.displayMessage('Erro ao salvar controle de execução.', 'error');
                            console.error(error);
                        });
                        closeModal(true, socketRef.current, controleDiarioId);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        });
        setSalvando(false);
    }, [limiteAtingido, carterinhas, atendimentosFiltrados, planoSaudeSelecionadoOpt, executadoSelecionadoOpt, pacienteId, snackbar, closeModal, controleDiarioId, selectedGuiaIds, salvando]);
    /**
     * Manipula a abertura do modal de comentários com os dados do atendimento fornecido.
     *
     * @param {Object} atendimento - Objeto contendo os dados do atendimento.
     * @param {string} atendimento.terapeuta_nome - Nome do terapeuta.
     * @param {string} atendimento.paciente_id - ID do paciente.
     * @param {string} atendimento.paciente_identificador - Identificador do paciente.
     * @param {string} atendimento.paciente_nome - Nome do paciente.
     * @param {string} atendimento.terapia - Tipo de terapia.
     * @param {string} atendimento.especialidade - Especialidade do atendimento.
     * @param {string} atendimento.nome_reduzido - Nome reduzido do plano de saúde.
     * @param {string} atendimento.sala - Sala do atendimento.
     * @param {string} atendimento.estacao - Estação do atendimento.
     * @param {boolean} atendimento.reposicao - Indica se é uma reposição.
     * @param {Object} atendimento.tipo_atendimento - Tipo de atendimento.
     * @param {string} atendimento.modalidade - Modalidade do atendimento.
     * @param {string} atendimento.usuario_acesso_execucao_id - ID do usuário que executou o acesso.
     * @param {string} atendimento.ultimo_acesso_execucao - Último acesso de execução.
     * @param {string} atendimento.id - ID do atendimento.
     * @param {string} atendimento.sugestao_terapeuta - Sugestão do terapeuta.
     * @param {string} atendimento.sugestao_recepcao - Sugestão da recepção.
     * @param {string} atendimento.comentario_sugestao_terapeuta - Comentário da sugestão do terapeuta.
     * @param {boolean} atendimento.liberado - Indica se o atendimento está liberado.
     * @param {boolean} atendimento.checkin - Indica se o check-in foi realizado.
     * @param {string} atendimento.data_atendimento_inicial - Data e hora inicial do atendimento.
     * @param {string} atendimento.data_atendimento_final - Data e hora final do atendimento.
     * @param {string} atendimento.comentario - Comentário do atendimento.
     * @param {string} atendimento.guia - Guia do atendimento.
     * @param {boolean} atendimento.executado - Indica se o atendimento foi executado.
     * @param {string} atendimento.codigo_1 - Código 1 do atendimento.
     * @param {string} atendimento.codigo_2 - Código 2 do atendimento.
     * @param {string} atendimento.modo_criacao_nome - Nome do modo de criação.
     */
    const handleModalComentarios = (atendimento) => {
        let dados = [];
        dados['terapeuta'] = `${atendimento.terapeuta_nome ? `${atendimento.terapeuta_nome.substring(0, 15)}` : '---'}`;
        dados['paciente'] = `${user.tipo_user === 'DESENVOLVEDOR' ? `(${atendimento.paciente_id})` : ''} ${atendimento.paciente_identificador}`;
        dados['paciente_nome'] = `${atendimento.paciente_nome}`;
        dados['paciente_id'] = atendimento.paciente_id;
        dados['terapia'] = atendimento.terapia;
        dados['especialidade'] = atendimento.especialidade;
        dados['plano_saude'] = atendimento.nome_reduzido;
        dados['sala'] = atendimento.sala;
        dados['estacao'] = atendimento.estacao;
        dados['terapia'] = atendimento.terapia;
        dados['especialidade'] = atendimento.especialidade;
        dados['sala'] = atendimento.sala;
        dados['estacao'] = atendimento.estacao;
        dados['reposicao'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['modalidade'] = atendimento.modalidade;
        dados['tipo_atendimento'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento;
        dados['usuario_acesso_execucao_id'] = atendimento.usuario_acesso_execucao_id;
        dados['ultimo_acesso_execucao'] = atendimento.ultimo_acesso_execucao;
        dados['id'] = atendimento.id;
        dados['terapeuta_status'] = atendimento.sugestao_terapeuta;
        dados['recepcao_status'] = atendimento.sugestao_recepcao;
        dados['comentario_sugestao_terapeuta'] = atendimento.comentario_sugestao_terapeuta;
        dados['terapeuta_status_nome'] = AtendimentoService.getStatusSelecionadoByValue(statusAtendimentosOptions, atendimento.sugestao_terapeuta) === undefined ? '-' : AtendimentoService.getStatusSelecionadoByValue(statusAtendimentosOptions, atendimento.sugestao_terapeuta).label;
        dados['recepcao_status_nome'] = AtendimentoService.getStatusSelecionadoByValue(statusAtendimentosOptions, atendimento.sugestao_recepcao) === undefined ? '-' : AtendimentoService.getStatusSelecionadoByValue(statusAtendimentosOptions, atendimento.sugestao_recepcao).label;
        dados['liberado'] = atendimento.liberado;
        dados['checkin'] = atendimento.checkin;
        dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
        dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
        dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
        dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
        dados['comentario'] = atendimento.comentario;
        dados['guia'] = atendimento.guia;
        dados['executado'] = atendimento.executado;
        dados['codigo_1'] = atendimento.codigo_1;
        dados['codigo_2'] = atendimento.codigo_2;
        dados['modo_criacao'] = atendimento.modo_criacao_nome;
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    };

    /**
     * Atualiza as guias do paciente e o estado de carregamento.
     *
     * @async
     * @function handleAtualizaGuias
     * @param {number} [controle_diario_id] - O ID do controle diário (opcional).
     * @param {number} [paciente_id] - O ID do paciente (opcional).
     * @returns {Promise<Array>} Uma promessa que resolve para um array de guias do paciente.
     */
    const handleAtualizaGuias = async (controle_diario_id, paciente_id, guias_id) => {
        // Atualiza o estado de carregamento
        setCarregandoGuias(true);
        // Verifica se há controle_diario_id e paciente_id, se não, usa as variáveis de estado
        const controleId = controle_diario_id || controleDiarioId;
        const pacienteIdLocal = paciente_id || pacienteId;
        const guiasLocal = guias_id || guiasId;
        // Objeto de filtros
        const filterData = {
            guias_id: guiasLocal,
            paciente_id: pacienteIdLocal,
            controle_diario_id: controleId
        };
        const url = `api/guia/valida/by/controle-diario`;
        //const url = `api/guia?filter[paciente_id]=${pacienteIdLocal}&filter[status]=ativo`;
        const localGuias = await api.post(url, filterData)
            //const localGuias = await api.get(url)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                console.error(error)
            });
        setGuiasPaciente(localGuias);
        setCarregandoGuias(false);
        return localGuias;
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (isOpen) {
            if (pacienteId && !socketRef.current) {
                const params = `?user_id=${user.id}&username=${user.name}&paciente_id=${pacienteId}&controle_diario_id=${controleDiarioId}`;
                const urlWS = `${wsBaseUrl}/controle_execucao${params}`;

                socketRef.current = new WebSocket(urlWS);

                const handleNovaPresenca = (pessoa) => {
                    setPresenca(prevPresenca => {
                        const updatedPresenca = [...prevPresenca];
                        const index = updatedPresenca?.findIndex(p => p.id === pessoa.id);
                        if (index !== -1) {
                            updatedPresenca[index] = pessoa;
                        } else {
                            updatedPresenca.push(pessoa);
                        }
                        return updatedPresenca;
                    });
                };

                const handleUpdatePresencaList = data => {
                    setPresenca(data.connections ? data.connections : []);
                };

                const handleRemovePresenca = (id) => {
                    setPresenca(prevPresenca =>
                        prevPresenca.filter(pessoa => pessoa.id !== id)
                    );
                };

                const handleSocketOpen = () => {
                    setSocketState(true);
                };

                const handleSocketClose = () => {
                    setSocketState(false);
                    socketRef.current = null;
                };

                const handleSocketMessage = (event) => {
                    try {
                        if (event.data) {
                            const data = JSON.parse(event.data);
                            if (data) {
                                if (data.action === 'new_connection') {
                                    handleNovaPresenca(data);
                                } else if (data.action === 'update_connections_list') {
                                    handleUpdatePresencaList(data);
                                } else if (data.action === 'disconnect') {
                                    handleRemovePresenca(data.id);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Erro ao parsear a mensagem: ', error);
                    }
                };

                const handleSocketError = (event) => {
                    console.error('Erro: ', event);
                };

                socketRef.current.addEventListener('open', handleSocketOpen);
                socketRef.current.addEventListener('close', handleSocketClose);
                socketRef.current.addEventListener('message', handleSocketMessage);
                socketRef.current.addEventListener('error', handleSocketError);
            }
        }
    }, [isOpen, pacienteId, user.id, user.name]);

    useEffect(() => {
        setNewCustomStyles({
            overlay: { ...customStyles.overlay },
            content: {
                ...customStyles.content,
                ...presenca?.filter(f => {
                    const tem = (Number(f.user_id) !== Number(user.id) && Number(f.paciente_id) === Number(pacienteId));
                    return tem;
                }).length > 0 ?
                    {
                        boxShadow: '0px 0px 20px #fff000',
                        border: '2px #fff000 solid'
                    }
                    : {}
            }
        });
    }, [presenca]);
    useEffect(() => {
        if (statusAtendimentosOptions) {
            setFiltredStatusAtendimentoOptions(statusAtendimentosOptions.filter(filter => filter.value !== 1));
        }
    }, [statusAtendimentosOptions]);
    useEffect(() => {
        if (!pendenteDeSalvamento) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [pendenteDeSalvamento, navigator]);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (pendenteDeSalvamento) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pendenteDeSalvamento]);
    //#endregion
    const GuiaDropDown = useMemo(() => {
        return (
            <div className="dropdown ms-4">
                <button className="btn btn-secondary dropdown-toggle transition-border-radius" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Guais
                </button>
                <div className='dropdown-menu min-w-600px-i bg-cinza-bs font-branca-i px-2' style={{
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15,
                    borderBottomLeftRadius: 15,
                    borderTopLeftRadius: 0,
                    borderTop: 0,
                    marginTop: -5,
                    marginLeft: '-1px'
                }}>
                    <div className='d-flex justify-content-between align-items-center px-3 pt-1 pb-2'>
                        <span></span>
                        <div className='d-flex justify-content-between align-items-center font-branca'>
                            <h5>Guias do Paciente</h5>
                        </div>
                        <span className={`btn-padrao`} onClick={() => handleAtualizaGuias()}>
                            <span className={`${carregandoGuias ? 'rodando' : ''}`}>
                                {iconArray['atualizar']}
                            </span>
                        </span>
                    </div>
                    <table className="ns-table line-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nº Guia</th>
                                <th>Terapia & Especialidade</th>
                                <th>Quantidade</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guiasPaciente && guiasPaciente.length > 0 ?
                                terapiasOptions && terapiasOptions.length > 0 &&
                                especialidadesOptions && especialidadesOptions.length > 0 &&
                                guiasPaciente.map((guia, index) => {
                                    return (
                                        <tr key={index}>
                                            <th> {guia.id} </th>
                                            <td> {guia.identificador_geral} </td>
                                            <td> {terapiasOptions?.find(find => Number(find.value) === Number(guia.terapia_id))?.label} - {especialidadesOptions?.find((find) => Number(find.value) === Number(guia.especialidade_id))?.label} </td>
                                            <td> {(Number(guia.quantidade_liberada) - Number(guia.quantidade_feita))} </td>
                                            <td> {guia.status} </td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <td colSpan={4} className='text-center'>
                                        {carregandoGuias
                                            ? <ClipLoader size={22} color={'#000'} loading={true} />
                                            : 'Nenhuma guia encontrada.'
                                        }
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }, [guiasPaciente, especialidadesOptions, terapiasOptions, carregandoGuias]);
    return (
        <ReactModal style={newCustomStyles} onRequestClose={() => closeModal(false, socketRef.current, controleDiarioId)} afterOpenModal={afterOpenModal} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} isOpen={isOpen} transparent>
            {loading
                ? <ClipLoader size={60} color={'#000'} loading={true} />
                : <></>
            }
            {modoIncorporado
                ? <div className={`d-flex justify-content-between align-items-center${loading ? ' some' : ''}`}>
                    <div className='d-flex justify-content-start align-items-center'>
                        <ClipLoader size={22} color={'#000'} loading={false} />
                        <div className='ps-4 pt-4'>
                            <LiveBadge sincronizado={socketState} texto={false} posicao={'relativa'} />
                        </div>
                    </div>
                    <ModalTitulo> {nome} {GuiaDropDown}</ModalTitulo>
                    <label className='btn-outline text-right pointer' onClick={() => closeModal(false, socketRef.current, controleDiarioId)}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
                : <nav className={`navbar sticky-top bg-cinza-claro shadow m-0${loading ? ' some' : ''}`}>
                    <div className='container-fluid d-flex align-items-center justify-content-between'>
                        <div className='col-4 d-flex flex-row'>
                            <ClipLoader size={22} color={'#000'} loading={false} />
                            <div className='ps-4 pt-4'>
                                <LiveBadge sincronizado={socketState} texto={false} posicao={'relativa'} />
                            </div>
                        </div>
                        <ModalTitulo className='col-4 text-center'>{title}<br /> {nome} {GuiaDropDown}</ModalTitulo>
                        <label className={`btn-outline col-4 text-right pointer`} onClick={() => closeModal(false, socketRef.current, controleDiarioId)}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
            }
            <div className={`m-0 p-0${loading ? ' some' : ''}`}>
                <Form onSubmit={handleSubmit} className='d-flex flex-col justify-content-between max-h-100per-80px h-100per-80px'>
                    <table className='table-round'>
                        <thead key={'thead-ce'}>
                            <tr key={'cabecalho-ce'}>
                                {isCheckIn
                                    ? <>
                                        <th key={'col-1-ce'} className='plano_saude'>Plano de Saúde</th>
                                        <th key={'col-2-ce'} className='horario'>Horário</th>
                                        <th key={'col-3-ce'} className='terapeuta'>Terapeuta</th>
                                        <th key={'col-4-ce'} className='terapia'>Terapia</th>
                                        <th key={'col-5-ce'} className='especialidade'>Especialidade</th>
                                        <th key={'col-8-ce'} className='codigo'>Código Terapia</th>
                                        <th key={'col-6-ce'} className='codigo'>Código 1</th>
                                        <th key={'col-7-ce'} className='codigo'>Código 2</th>
                                        <th key={'col-9-ce'} className='liberado'>Liberado</th>
                                        <th key={'col-11-ce'} className='status_atendimento'>Status Atendimento</th>
                                        <th key={'col-12-ce'} className='assinado'>Assinado</th>
                                        <th key={'col-fim'} className='fim'></th>
                                    </>
                                    : <>
                                        <th key={'col-1-ce'} className='plano_saude'>Plano de Saúde</th>
                                        <th key={'col-2-ce'} className='horario'>Horário</th>
                                        <th key={'col-3-ce'} className='terapeuta'>Terapeuta</th>
                                        <th key={'col-4-ce'} className='terapia'>Terapia</th>
                                        <th key={'col-5-ce'} className='especialidade'>Especialidade</th>
                                        <th key={'col-8-ce'} className='codigo'>Código Terapia</th>
                                        <th key={'col-6-ce'} className='codigo'>Código 1</th>
                                        <th key={'col-7-ce'} className='codigo'>Código 2</th>
                                        <th key={'col-10-ce'} className='executado'>Executado</th>
                                        <th key={'col-12-ce'} className='assinado'>Assinado</th>
                                        <th key={'col-11-ce'} className='status_atendimento'>Status Atendimento</th>
                                        <th key={'col-9-ce'} className='liberado'>Liberado</th>
                                        <th key={'col-fim'} className='fim'></th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody key={'tbody-ce'}>
                            {atendimentosFiltrados && atendimentosFiltrados.map((el, index) => {
                                const guiasOptions = guiasPaciente?.filter(filter => filter.terapia_paciente_id === el.terapia_paciente_id);
                                const guiasSelect = guiasOptions?.map(guia => ({
                                    guia_id: guia.id,
                                    value: guia.identificador_geral,
                                    label: guia.identificador_geral
                                }));
                                guiasSelect.push({
                                    label: '(vazio)',
                                    value: null,
                                    guia_id: 'vazio'
                                });
                                guiasSelect.reverse();
                                const findGuiaId = selectedGuiaIds[index] ? selectedGuiaIds[index] : el.guia_id;
                                const guiaSelected = guiasSelect && guiasSelect?.length ? guiasSelect?.find(guia => Number(guia.guia_id) === Number(findGuiaId)) : [];
                                const guia = guiasOptions && guiasOptions?.length ? guiasOptions?.find(guia => guia.id === findGuiaId) : [];

                                const codigo_1_bloqueado = el.exige_guia;
                                const codigo_1 = codigo_1_bloqueado
                                    ? guia && guia?.identificador_geral !== undefined
                                        ? guia?.identificador_geral
                                        : el.codigo_1
                                    : el.codigo_1;
                                const codigo_2 = el.codigo_2;
                                const liberado = String(el.liberado);

                                let executadoOptionsLocal;
                                try {
                                    executadoOptionsLocal = el.nome_reduzido.toLowerCase() === 'particular' ? executadoOptions?.particular : executadoOptions?.plano;
                                } catch (error) {
                                    console.error(error);
                                }
                                if (isCheckIn) {
                                    /* CHECKIN */
                                    return (
                                        <tr key={'row-' + index + '-ce'} className='table-check-in-linha'>
                                            <td key={'row-' + index + '-ce-col-1'} className='plano_saude'>
                                                {el.terapeuta_nome ?
                                                    el.nome_reduzido
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-2'} className='horario'>
                                                <div className='hora'>
                                                    {el.hora_atendimento.slice(0, -3)}
                                                </div>
                                            </td>
                                            <td key={'row-' + index + '-ce-col-3'} className='terapeuta'>{el.terapeuta_nome}</td>
                                            <td key={'row-' + index + '-ce-col-4'} className='terapia'>{el.terapia}</td>
                                            <td key={'row-' + index + '-ce-col-5'} className='especialidade'>{el.especialidade}</td>
                                            <td key={'row-' + index + '-ce-col-8'} className='codigo p-2 text-center' id={'codigo' + index}> {el.codigo_plano} </td>
                                            <td key={'row-' + index + '-ce-col-6'} className='codigo p-0 text-center'>
                                                {codigo_1_bloqueado ? (
                                                    <Select
                                                        id={'codigo1' + index}
                                                        name={'codigo1' + index}
                                                        options={guiasSelect}
                                                        value={guiaSelected}
                                                        onChange={(e) => handleChangeCodigo(index, e)}
                                                        className='m-0'
                                                        style={codigosInputStyle}
                                                    />
                                                ) : (
                                                    <SimpleInput
                                                        id={'codigo1' + index}
                                                        name={'codigo1' + index}
                                                        className='m-0'
                                                        onChange={() => handleChangeCodigo(index)}
                                                        onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                        defaultValue={codigo_1}
                                                        placeholder={el.nome_codigo_1 || 'Código 1'}
                                                        style={codigosInputStyle}
                                                    />
                                                )}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-7'} className='codigo p-2 text-center'>
                                                <SimpleInput
                                                    id={'codigo2' + index}
                                                    name={'codigo2' + index}
                                                    className='m-0'
                                                    onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                    defaultValue={codigo_2}
                                                    placeholder={el.nome_codigo_2 || 'Código 2'}
                                                    style={codigosInputStyle}
                                                />
                                            </td>
                                            <td key={'row-' + index + '-ce-col-9'} className='liberado'>
                                                {el.terapeuta_nome
                                                    ? <span>
                                                        <Select
                                                            id={'liberado' + index}
                                                            name={'liberado' + index}
                                                            options={opcoesLiberado}
                                                            defaultValue={opcoesLiberado.find(option => option.value === liberado)}
                                                            onChange={(e) => { handleChangeLiberado(e, index); /* handleTestaQuantidade(); */ }}
                                                            className={codigo_1 !== null && codigo_1 !== '' && codigo_1 !== undefined ? '' : 'some'}
                                                        />
                                                        <span id={'bloqueio-liberado' + index}
                                                            name={'bloqueio-liberado' + index}
                                                            className={codigo_1 === null || codigo_1 === '' || codigo_1 === undefined ? '' : 'some'}>
                                                            ❌
                                                        </span>
                                                    </span>
                                                    : <span>❌</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-11'} className='status_atendimento'>
                                                {el.sugestao_recepcao === 1
                                                    ? <span className='ms-2'>Realizado</span>
                                                    : <Select
                                                        id={'status_atendimento' + index}
                                                        name={'status_atendimento' + index}
                                                        options={[{ value: '', label: <span>(Vazio)</span> }, ...filtredStatusAtendimentoOptions]}
                                                        defaultValue={filtredStatusAtendimentoOptions.find(option => Number(option.value) === Number(el.sugestao_recepcao))}
                                                        onChange={(e) => { handleChangeStatusAtendimento(e, index); }}
                                                    />
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-12'} className='assinado m-0'>
                                                {el?.exige_lista_presenca ?
                                                    <label className='check-label' >
                                                        <SimpleInput type='checkbox' name={'assinado' + index} id={'assinado' + index} onChange={(e) => handleChangeAssinado(e, index)} checked={el.assinado} />
                                                        <span className='checkmark'></span>
                                                    </label>
                                                    : ''
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-fim'} className='fim'>
                                                {cloneElement(iconArray['visualizar'], { size: 22, className: 'pointer', onClick: () => handleModalComentarios(el) })}
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    /* EXECUÇÃO */
                                    return (
                                        <tr key={'row-' + index + '-ce'} className='table-check-in-linha'>
                                            <td key={'row-' + index + '-ce-col-1'} className='plano_saude'>
                                                {el.nome_reduzido}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-2'} className='horario'>
                                                <div className='hora'>
                                                    {el.hora_atendimento.slice(0, -3)}
                                                </div>
                                            </td>
                                            <td key={`row-${index}-ce-col-3`} className='terapeuta'>{el.terapeuta_nome}</td>
                                            <td key={`row-${index}-ce-col-4`} className='terapia'>{el.terapia}</td>
                                            <td key={`row-${index}-ce-col-5`} className='especialidade'>{el.especialidade}</td>
                                            <td key={`row-${index}-ce-col-8`} className='codigo p-2 text-center' id={'codigo' + index}> {el.codigo_plano} </td>
                                            <td key={`row-${index}-ce-col-6`} className='codigo p-0 text-center'>
                                                {codigo_1_bloqueado ? (
                                                    <Select
                                                        id={'codigo1' + index}
                                                        name={'codigo1' + index}
                                                        options={guiasSelect}
                                                        value={guiaSelected}
                                                        onChange={(e) => handleChangeCodigo(index, e)}
                                                        className='m-0'
                                                        style={codigosInputStyle}
                                                    />
                                                ) : (
                                                    <SimpleInput
                                                        id={'codigo1' + index}
                                                        name={'codigo1' + index}
                                                        className='m-0'
                                                        onChange={() => handleChangeCodigo(index)}
                                                        onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                        defaultValue={codigo_1}
                                                        placeholder={el.nome_codigo_1 || 'Código 1'}
                                                        style={codigosInputStyle}
                                                    />
                                                )}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-7'} className='codigo p-2 text-center'>
                                                <SimpleInput
                                                    id={'codigo2' + index}
                                                    name={'codigo2' + index}
                                                    className='m-0'
                                                    onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')}
                                                    defaultValue={codigo_2} placeholder={el.nome_codigo_2 || 'Código 2'}
                                                    style={codigosInputStyle} />
                                            </td>
                                            <td key={'row-' + index + '-ce-col-10'} className='executado m-0'>
                                                {el.terapeuta_nome
                                                    ? <Select
                                                        id={'executado' + index}
                                                        name={'executado' + index}
                                                        options={executadoOptionsLocal}
                                                        defaultValue={executadoSelecionadoOpt[index]}
                                                        value={executadoSelecionadoOpt[index]}
                                                        className={
                                                            (executadoSelecionadoOpt[index] !== null)
                                                                ? (executadoSelecionadoOpt[index]?.value === 'pendente')
                                                                    ? 'select-exec select-pendente'
                                                                    : executadoSelecionadoOpt[index]?.value === '' || executadoSelecionadoOpt[index]?.value === 'vazio'
                                                                        ? 'select-exec'
                                                                        : 'select-exec select-executado'
                                                                : (codigo_1 && el.liberado == 1)
                                                                    ? 'select-exec'
                                                                    : 'some'
                                                        }
                                                        onChange={(e) => handleChangeExecutado(e, index)}
                                                    />
                                                    : <span>Sem terapeuta...</span>
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-12'} className='assinado m-0'>
                                                {el?.exige_lista_presenca
                                                    ? <label className='check-label'>
                                                        <SimpleInput type='checkbox' name={'assinado' + index} id={'assinado' + index} onChange={(e) => handleChangeAssinado(e, index)} checked={el.assinado} />
                                                        <span className='checkmark'></span>
                                                    </label>
                                                    : ''
                                                }
                                            </td>
                                            <td key={'row-' + index + '-ce-col-11'} className='status_atendimento'>
                                                {statusAtendimentosOptions.find(option => Number(option.value) === Number(el.sugestao_recepcao))?.label}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-9'} className='liberado'>
                                                {opcoesLiberado.find(option => option.value === liberado)?.label}
                                            </td>
                                            <td key={'row-' + index + '-ce-col-fim'} className='fim'>
                                                {cloneElement(iconArray['visualizar'], { size: 22, className: 'pointer', onClick: () => handleModalComentarios(el) })}
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                    <div className='row m-0 p-0'>
                        <div className='col-lg-8 col-md-12'>
                            <ModalTitulo className='col-4 text-center'>Conferir Carterinhas dos Planos</ModalTitulo>
                            <table className='table-folha-horarios'>
                                <thead>
                                    <tr>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px', width: '200px' }}>Nº</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Plano</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Criado</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Ultima Edição</th>
                                        <th style={{ maxHeight: '15px', padding: '5px 10px' }}>Verificado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {carterinhas && carterinhas.map((item, index) => {
                                        return (
                                            <tr key={`carterinha-${index}`} className='table-check-in-linha'>
                                                <td style={{ width: '200px' }}>
                                                    <SimpleInput
                                                        id={`numero_carterinha${item.id}${item.created_at === false ? 'new' : ''}`}
                                                        containerStyle={{ width: '200px' }}
                                                        style={{ width: '200px' }}
                                                        name={`numero_carterinha${item.id}${item.created_at === false ? 'new' : ''}`}
                                                        className='m-0'
                                                        defaultValue={item.numero_carterinha}
                                                        placeholder='Digite o número da carterinha.'
                                                        required={atendimentosFiltrados?.findIndex(atendimento => atendimento.liberado == 1) !== -1}
                                                    />
                                                </td>
                                                <td>{item?.plano_saude?.nome_reduzido}</td>
                                                <td>{item.created_at ? moment.utc(item.created_at).format('DD/MM/YYYY HH:mm') : 'Sem Carterinha'}</td>
                                                <td>{item.created_at ? moment.utc(item.updated_at).format('DD/MM/YYYY HH:mm') : 'Sem Carterinha'}</td>
                                                <td>
                                                    <div className='form-check d-inline-flex ms-4'>
                                                        <SimpleInput
                                                            type='checkbox'
                                                            className={'form-check-input'}
                                                            name={`conferido_carterinha_${item.id}${item.created_at === false && '_new'}`}
                                                            id={`conferido_carterinha_${item.id}${item.created_at === false && '_new'}`}
                                                            defaultChecked={item.ultima_conferencia ? moment.utc(item.ultima_conferencia).isSame(moment(), 'day') : false}
                                                            required={atendimentosFiltrados?.findIndex(atendimento => atendimento.liberado == 1) !== -1}
                                                        />
                                                        <label className='form-check-label ms-3' htmlFor={`conferido_carterinha_${item.id}${item.created_at === false && '_new'}`}>
                                                            Conferido
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-4 col-md-12 d-flex justify-content-end align-items-end pe-4'>
                            <button className='btn-padrao btn-executar' type='submit' isdisabled={salvando}>
                                Salvar
                                {salvando && <ClipLoader size={22} color={'#fff'} loading={true} />}
                                <svg className='bi bi-floppy-fill ms-2' xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='currentColor' viewBox='0 0 16 16'>
                                    <path d='M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z' />
                                    <path d='M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z' />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
            <ComentariosModal ref={modalComentariosRef} atualizaSocket={() => { }} getAgendaDia={() => { }} />
        </ReactModal>
    );
});

ControleDeExecucaoModal.displayName = 'ControleDeExecucaoModal';

export default ControleDeExecucaoModal;