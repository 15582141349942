import { Form } from '@unform/web';
import axios from 'axios';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import useEspecialidadesData from 'context/especialidade';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import usePlanoSaudeData from 'context/plano-saude';
import useTerapeutasData from 'context/terapeuta';
import useTerapiasData from 'context/terapia';
import { useEffect, useState, useRef } from 'react';
import { BsSearch, BsPrinter } from 'react-icons/bs';
import { FaBroom, FaFilePdf } from 'react-icons/fa';
import { CgExport } from 'react-icons/cg';
import { RiFileExcel2Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { toPng } from 'html-to-image';
import html2PDF from 'jspdf-html2canvas';
import * as XLSX from 'xlsx';
import api from 'services/api';
import { format } from 'date-fns';
import moment from 'moment';

const RelatoriosTerapeutas = () => {
    usePageBase({
        title: 'Relatório de Terapeutas',
        description: 'Tela onde é possível visualizar os terapeutas cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/terapeutas'
        ]
    })
    const [terapeutas, setTerapeutas] = useState([]);
    const [loading, setLoading] = useState(true);
    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const statusTerapeuta = [
        { value: 'Ativo', label: 'Ativo' },
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Bloqueado', label: 'Bloqueado' },
    ]
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const defaultFilters = {
        nome: '',
        email: '',
        cpf: '',
        status: '',
        especialidade_id: '',
        terapia_id: '',
        plano_saude_id: '',
        paciente_id: ''
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [selectedColumns, setSelectedColumns] = useState({
        nome: true,
        email: true,
        cpf: true,
        status: true,
        n_cracha: false,
        nascimento: false,
        rg: false,
        telefone1: false,
        telefone2: false,
        telefone3: false,
        endereco: false,
        bairro: false,
        cidade: false,
        estado: false,
        complemento: false,
        cep: false,
        caminho_curriculo: false,
        created_at: false,
        /* updated_at: false, */
        user_id: false,
        nome_curto: false,
        numero: false,
        data_entrada: false,
        data_saida: false,
        banco: false,
        agencia: false,
        conta: false
    });

    const toExportRef = useRef(null);

    useEffect(() => {
        const fetchTerapeutas = async () => {
            try {
                const response = await api.post('/api/terapeuta/filtrado', filters);
                setTerapeutas(response?.data?.map((terapeuta) => {
                    const data_entrada = terapeuta.data_entrada ? moment.utc(terapeuta.data_entrada).format('DD/MM/YYYY') : '';
                    const data_saida = terapeuta.data_saida ? moment.utc(terapeuta.data_saida).format('DD/MM/YYYY') : '';
                    const created_at = terapeuta.created_at ? moment.utc(terapeuta.created_at).format('DD/MM/YYYY') : '';

                    return {
                        ...terapeuta,
                        data_entrada,
                        data_saida,
                        created_at
                    };
                }));
            } catch (error) {
                console.error('Erro ao buscar terapeutas:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTerapeutas();
        fetchEspecialidades();
        fetchTerapeutasData();
        fetchTerapias();
        fetchPlanoSaude();
        fetchPacienteOptionsData();
    }, []);

    const handleChange = (e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleColumnChange = (e) => {
        const { name, checked } = e.target;
        setSelectedColumns((prev) => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleChangeHabilitaSucesso = (e) => {
        const { id, checked } = e.target;
        setSelectedColumns((prev) => ({
            ...prev,
            [id]: checked
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        const noFiltersApplied = Object.values(filters).every(value => value === '' || value === null);

        if (noFiltersApplied) {
            const confirmProceed = await Swal.fire({
                title: 'Nenhum filtro foi aplicado',
                text: 'Deseja continuar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            });
            if (!confirmProceed.isConfirmed) {
                setLoading(false);
                return;
            }
        }

        try {
            const response = await api.post('/api/terapeuta/filtrado', filters);
            setTerapeutas(response.data);
        } catch (error) {
            console.error('Erro ao buscar terapeutas:', error);
        } finally {
            setLoading(false);
        }
    };

    const limpaFiltros = () => {
        setFilters(defaultFilters);
    };

    const exportToPrint = () => {
        const printContents = toExportRef.current.outerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    const exportToImage = () => {
        toPng(toExportRef.current).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'relatorio.png';
            link.href = dataUrl;
            link.click();
        });
    };

    const exportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const wsData = [
            Object.keys(selectedColumns).filter(col => selectedColumns[col]).map(col => col.charAt(0).toUpperCase() + col.slice(1))
        ];

        terapeutas.forEach(terapeuta => {
            const row = [];
            Object.keys(selectedColumns).forEach(col => {
                if (selectedColumns[col]) {
                    row.push(terapeuta[col]);
                }
            });
            wsData.push(row);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);

        XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
        XLSX.writeFile(wb, 'relatorio.xlsx');
    };

    const exportToPdf = () => {
        const filename = `Relatório de Terapeutas`;
        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
            html2canvas: { scale: 2, useCORS: true, logging: true },
            image: { type: 'jpeg', quality: 0.98 },
            output: `${filename}.pdf`
        };
        html2PDF(toExportRef.current, newOptions);
    };

    const exportToWord = () => {
        const htmlString = toExportRef.current.innerHTML;
        const converted = htmlDocx.asBlob(htmlString);
        saveAs(converted, 'output.docx');
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'dd/MM/yyyy');
    };

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} className='row d-flex flex-wrap gy-4 p-4'>
                <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                    <legend>Informações do Terapeuta</legend>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12' id='filter_nome'>
                            <Input type="text" title="Nome:" label="Nome:" id="nome" name="nome" value={filters.nome} onChange={handleChange} />
                        </div>
                        <div className='col-lg-6 col-sm-12' id='filter_email'>
                            <Input type="text" title="Email:" label="Email:" id="email" name="email" value={filters.email} onChange={handleChange} />
                        </div>
                        <div className='col-lg-6 col-sm-12' id='filter_cpf'>
                            <Input type="text" title="CPF:" label="CPF:" id="cpf" name="cpf" value={filters.cpf} onChange={handleChange} />
                        </div>
                        <div className='col-lg-6 col-sm-12' id='filter_status'>
                            <Select id="status" name="status" label="Status:" title="Status:" options={statusTerapeuta} value={statusTerapeuta.find(find => find.value === filters.status)} onChange={(e) => handleChange({ target: { id: 'status', name: 'status', value: e.value } })} isClearable={true} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                    <legend>Informações de Formação</legend>
                    <div className='row'>
                        <div className='col-12' id='filter_terapia_id'>
                            <Select id="terapia_id" name="terapia_id" label="Terapia:" title="Terapia:" options={terapiasOptions} value={terapiasOptions.find((find) => find.value === filters.terapia_id)} onChange={(e) => handleChange({ target: { id: 'terapia_id', name: 'terapia_id', value: e.value } })} isClearable={true} />
                        </div>
                        <div className='col-12' id='filter_especialidade_id'>
                            <Select id="especialidade_id" name="especialidade_id" label="Especialidade:" title="Especialidade:" options={especialidadesOptions} value={especialidadesOptions.find((find) => find.value === filters.especialidade_id)} onChange={(e) => handleChange({ target: { id: 'especialidade_id', name: 'especialidade_id', value: e.value } })} isClearable={true} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                    <legend>Informações do Paciente</legend>
                    <div className='row'>
                        <div className='col-12' id='filter_paciente_id'>
                            <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} value={pacienteOptions.find((find) => find.value === filters.paciente_id)} onChange={(e) => handleChange({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} isClearable={true} />
                        </div>
                    </div>
                </fieldset>
                <div className='col-12 mt-2 d-flex justify-content-end'>
                    <Button type="submit" className={'w-fit-content-i searching'}>
                        <BsSearch className='icone' size={30} /> Pesquisar
                    </Button>
                    <Button type="button" onClick={limpaFiltros} className={`ms-2 broom-sweep w-fit-content-i ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} >
                        <FaBroom size={30} type="button" className="icone" /> Limpar Filtros
                    </Button>
                </div>
            </Form>
            <div className='d-flex'>
                <div style={{ width: '10%' }}>
                    <fieldset className="ns-fieldset w-100">
                        <legend>Selecionar Colunas</legend>
                        <div className='d-flex flex-wrap mt-4 justify-content-center w-100'>
                            <fieldset className="ns-fieldset col-12"><legend>Dados Pessoais</legend>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="nome" style={{ textDecoration: selectedColumns.nome ? 'none' : 'line-through' }}>
                                            Nome
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="nome" checked={selectedColumns.nome} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="email" style={{ textDecoration: selectedColumns.email ? 'none' : 'line-through' }}>
                                            Email
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="email" checked={selectedColumns.email} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="cpf" style={{ textDecoration: selectedColumns.cpf ? 'none' : 'line-through' }}>
                                            CPF
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="cpf" checked={selectedColumns.cpf} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="status" style={{ textDecoration: selectedColumns.status ? 'none' : 'line-through' }}>
                                            Status
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="status" checked={selectedColumns.status} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="n_cracha" style={{ textDecoration: selectedColumns.n_cracha ? 'none' : 'line-through' }}>
                                            Número do Crachá
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="n_cracha" checked={selectedColumns.n_cracha} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="nascimento" style={{ textDecoration: selectedColumns.nascimento ? 'none' : 'line-through' }}>
                                            Data de Nascimento
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="nascimento" checked={selectedColumns.nascimento} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="rg" style={{ textDecoration: selectedColumns.rg ? 'none' : 'line-through' }}>
                                            RG
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="rg" checked={selectedColumns.rg} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="ns-fieldset col-12"><legend>Contato</legend>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="telefone1" style={{ textDecoration: selectedColumns.telefone1 ? 'none' : 'line-through' }}>
                                            Telefone 1
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="telefone1" checked={selectedColumns.telefone1} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="telefone2" style={{ textDecoration: selectedColumns.telefone2 ? 'none' : 'line-through' }}>
                                            Telefone 2
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="telefone2" checked={selectedColumns.telefone2} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="telefone3" style={{ textDecoration: selectedColumns.telefone3 ? 'none' : 'line-through' }}>
                                            Telefone 3
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="telefone3" checked={selectedColumns.telefone3} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="endereco" style={{ textDecoration: selectedColumns.endereco ? 'none' : 'line-through' }}>
                                            Endereço
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="endereco" checked={selectedColumns.endereco} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="bairro" style={{ textDecoration: selectedColumns.bairro ? 'none' : 'line-through' }}>
                                            Bairro
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="bairro" checked={selectedColumns.bairro} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="cidade" style={{ textDecoration: selectedColumns.cidade ? 'none' : 'line-through' }}>
                                            Cidade
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="cidade" checked={selectedColumns.cidade} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="estado" style={{ textDecoration: selectedColumns.estado ? 'none' : 'line-through' }}>
                                            Estado
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="estado" checked={selectedColumns.estado} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="complemento" style={{ textDecoration: selectedColumns.complemento ? 'none' : 'line-through' }}>
                                            Complemento
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="complemento" checked={selectedColumns.complemento} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="cep" style={{ textDecoration: selectedColumns.cep ? 'none' : 'line-through' }}>
                                            CEP
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="cep" checked={selectedColumns.cep} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="ns-fieldset col-12"><legend>Datas</legend>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="created_at" style={{ textDecoration: selectedColumns.created_at ? 'none' : 'line-through' }}>
                                            Data de Criação
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="created_at" checked={selectedColumns.created_at} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                {/* <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="updated_at" style={{ textDecoration: selectedColumns.updated_at ? 'none' : 'line-through' }}>
                                            Data de Atualização
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="updated_at" checked={selectedColumns.updated_at} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div> */}
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="data_entrada" style={{ textDecoration: selectedColumns.data_entrada ? 'none' : 'line-through' }}>
                                            Data de Entrada
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="data_entrada" checked={selectedColumns.data_entrada} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="data_saida" style={{ textDecoration: selectedColumns.data_saida ? 'none' : 'line-through' }}>
                                            Data de Saída
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="data_saida" checked={selectedColumns.data_saida} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="ns-fieldset col-12"><legend>Outros</legend>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="caminho_curriculo" style={{ textDecoration: selectedColumns.caminho_curriculo ? 'none' : 'line-through' }}>
                                            Caminho do Currículo
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="caminho_curriculo" checked={selectedColumns.caminho_curriculo} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="user_id" style={{ textDecoration: selectedColumns.user_id ? 'none' : 'line-through' }}>
                                            ID do Usuário
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="user_id" checked={selectedColumns.user_id} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="nome_curto" style={{ textDecoration: selectedColumns.nome_curto ? 'none' : 'line-through' }}>
                                            Nome Curto
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="nome_curto" checked={selectedColumns.nome_curto} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="numero" style={{ textDecoration: selectedColumns.numero ? 'none' : 'line-through' }}>
                                            Número
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="numero" checked={selectedColumns.numero} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="banco" style={{ textDecoration: selectedColumns.banco ? 'none' : 'line-through' }}>
                                            Banco
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="banco" checked={selectedColumns.banco} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="agencia" style={{ textDecoration: selectedColumns.agencia ? 'none' : 'line-through' }}>
                                            Agência
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="agencia" checked={selectedColumns.agencia} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                                <div className='w-100per'>
                                    <div className="form-check-reverse form-switch me-2">
                                        <label className="form-check-label" htmlFor="conta" style={{ textDecoration: selectedColumns.conta ? 'none' : 'line-through' }}>
                                            Conta
                                        </label>
                                        <input className="form-check-input switch-roxo" type="checkbox" role="switch" id="conta" checked={selectedColumns.conta} onChange={handleChangeHabilitaSucesso} />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </fieldset>
                </div>
                <div ref={toExportRef} style={{ width: '90%' }}>
                    <div className='w-100per'>
                        <fieldset className="ns-fieldset">
                            <legend>Opções de Exportação</legend>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToPrint}>
                                    <BsPrinter size={30} />Imprimir
                                </Button>
                                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToImage}>
                                    <CgExport size={30} />Imagem
                                </Button>
                                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToExcel}>
                                    <RiFileExcel2Fill size={30} />Excel
                                </Button>
                                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToPdf}>
                                    <FaFilePdf size={30} />PDF
                                </Button>
                                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToWord}>
                                    <FaFilePdf size={30} />Word
                                </Button>
                            </div>
                        </fieldset>
                    </div>
                    <table className='table table-striped table-hover'>
                        <thead>
                            <tr>
                                {selectedColumns.nome && <th>Nome</th>}
                                {selectedColumns.email && <th>Email</th>}
                                {selectedColumns.cpf && <th>CPF</th>}
                                {selectedColumns.status && <th>Status</th>}
                                {selectedColumns.n_cracha && <th>Número do Crachá</th>}
                                {selectedColumns.nascimento && <th>Data de Nascimento</th>}
                                {selectedColumns.rg && <th>RG</th>}
                                {selectedColumns.telefone1 && <th>Telefone 1</th>}
                                {selectedColumns.telefone2 && <th>Telefone 2</th>}
                                {selectedColumns.telefone3 && <th>Telefone 3</th>}
                                {selectedColumns.endereco && <th>Endereço</th>}
                                {selectedColumns.bairro && <th>Bairro</th>}
                                {selectedColumns.cidade && <th>Cidade</th>}
                                {selectedColumns.estado && <th>Estado</th>}
                                {selectedColumns.complemento && <th>Complemento</th>}
                                {selectedColumns.cep && <th>CEP</th>}
                                {selectedColumns.caminho_curriculo && <th>Caminho do Currículo</th>}
                                {selectedColumns.created_at && <th>Data de Criação</th>}
                                {/* {selectedColumns.updated_at && <th>Data de Atualização</th>} */}
                                {selectedColumns.user_id && <th>ID do Usuário</th>}
                                {selectedColumns.nome_curto && <th>Nome Curto</th>}
                                {selectedColumns.numero && <th>Número</th>}
                                {selectedColumns.data_entrada && <th>Data de Entrada</th>}
                                {selectedColumns.data_saida && <th>Data de Saída</th>}
                                {selectedColumns.banco && <th>Banco</th>}
                                {selectedColumns.agencia && <th>Agência</th>}
                                {selectedColumns.conta && <th>Conta</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {terapeutas.map((terapeuta) => (
                                <tr key={terapeuta.id}>
                                    {selectedColumns.nome && <td>{terapeuta.nome}</td>}
                                    {selectedColumns.email && <td>{terapeuta.email}</td>}
                                    {selectedColumns.cpf && <td>{terapeuta.cpf}</td>}
                                    {selectedColumns.status && <td>{terapeuta.status}</td>}
                                    {selectedColumns.n_cracha && <td>{terapeuta.n_cracha}</td>}
                                    {selectedColumns.nascimento && <td>{formatDate(terapeuta.nascimento)}</td>}
                                    {selectedColumns.rg && <td>{terapeuta.rg}</td>}
                                    {selectedColumns.telefone1 && <td>{terapeuta.telefone1}</td>}
                                    {selectedColumns.telefone2 && <td>{terapeuta.telefone2}</td>}
                                    {selectedColumns.telefone3 && <td>{terapeuta.telefone3}</td>}
                                    {selectedColumns.endereco && <td>{terapeuta.endereco}</td>}
                                    {selectedColumns.bairro && <td>{terapeuta.bairro}</td>}
                                    {selectedColumns.cidade && <td>{terapeuta.cidade}</td>}
                                    {selectedColumns.estado && <td>{terapeuta.estado}</td>}
                                    {selectedColumns.complemento && <td>{terapeuta.complemento}</td>}
                                    {selectedColumns.cep && <td>{terapeuta.cep}</td>}
                                    {selectedColumns.caminho_curriculo && <td>{terapeuta.caminho_curriculo}</td>}
                                    {selectedColumns.created_at && <td>{(terapeuta.created_at)}</td>}
                                    {/* {selectedColumns.updated_at && <td>{(terapeuta.updated_at)}</td>} */}
                                    {selectedColumns.user_id && <td>{terapeuta.user_id}</td>}
                                    {selectedColumns.nome_curto && <td>{terapeuta.nome_curto}</td>}
                                    {selectedColumns.numero && <td>{terapeuta.numero}</td>}
                                    {selectedColumns.data_entrada && <td>{(terapeuta.data_entrada)}</td>}
                                    {selectedColumns.data_saida && <td>{(terapeuta.data_saida)}</td>}
                                    {selectedColumns.banco && <td>{terapeuta.banco}</td>}
                                    {selectedColumns.agencia && <td>{terapeuta.agencia}</td>}
                                    {selectedColumns.conta && <td>{terapeuta.conta}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default RelatoriosTerapeutas;
