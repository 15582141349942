import SolicitacaoAtividadeExternaService from '../services/SolicitacaoAtividadeExternaService';
import { useState } from 'react';

const SolicitacaoAtividadeExterna = () => {
    const [PacienteId, setPacienteId] = useState(null);
    const [PacienteIdPlanoSaude, setPacienteIdPlanoSaude] = useState(null);
    const [TerapiaId, setTerapiaId] = useState(null);
    const [EspecialidadeId, setEspecialidadeId] = useState(null);
    const [StatusSolicitacaoId, setStatusSolicitacaoId] = useState(1);
    const [UserId, setUserId] = useState(null);
    const [UserIdStatus, setUserIdStatus] = useState(1);
    const [Objetivo, setObjetivo] = useState(null);
    const [Local, setLocal] = useState(null);
    const [Horario, setHorario] = useState(null);
    const [Descricao, setDescricao] = useState(null);
    const [SolicitacaoAtividadeExternas, setSolicitacaoAtividadeExternas] = useState(null);

    const loadSolicitacaoAtividadeExternas = () => {
        SolicitacaoAtividadeExternaService.index().then(({ data }) => setSolicitacaoAtividadeExternas(data));
    };

    const storeSolicitacaoAtividadeExterna = (form) => {
        let postData = {
            paciente_id: PacienteId,
            paciente_id_plano_saude: PacienteIdPlanoSaude,
            terapia_id: TerapiaId,
            especialidade_id: EspecialidadeId,
            status_solicitacao_id: StatusSolicitacaoId,
            user_id: UserId,
            user_id_status: UserIdStatus,
            objetivo: form.Objetivo,
            local: form.Local,
            horario: form.Horario,
            descricao: form.Descricao,
        };
        return SolicitacaoAtividadeExternaService.store(postData).then(({ data }) => {
            return true;
        }).catch(() => false);
    };
    const loadSolicitacaoAtividade = (id) => {
        SolicitacaoAtividadeExternaService.show(id).then(({ data }) => {
            setPacienteId(data.paciente_id);
            setPacienteIdPlanoSaude(data.paciente_id_plano_saude);
            setTerapiaId(data.terapia_id);
            setEspecialidadeId(data.especialidade_id);
            setStatusSolicitacaoId(data.status_solicitacao_id);
            setUserId(data.user_id);
            setUserIdStatus(data.user_id_status);
            setObjetivo(data.objetivo);
            setLocal(data.local);
            setHorario(data.horario);
            setDescricao(data.descricao);
        });
    };
    const updateSolicitacaoAtividadeExterna = (id) => {
        let postData = {
            paciente_id: PacienteId,
            paciente_id_plano_saude: PacienteIdPlanoSaude,
            terapia_id: TerapiaId,
            especialidade_id: EspecialidadeId,
            status_solicitacao_id: StatusSolicitacaoId,
            user_id: UserId,
            user_id_status: UserIdStatus,
            objetivo: Objetivo,
            local: Local,
            horario: Horario,
            descricao: Descricao,
        };
        SolicitacaoAtividadeExternaService.update(postData, id).then(({ data }) => {
            
        });
    };
    const deleteSolicitacaoAtividadeExterna = (id) => {
        SolicitacaoAtividadeExternaService.destroy(id).then(({ data }) => {
            
        });
    };

    return {
        loadSolicitacaoAtividadeExternas,
        storeSolicitacaoAtividadeExterna,
        PacienteId,
        setPacienteId,
        TerapiaId,
        setTerapiaId,
        EspecialidadeId,
        setEspecialidadeId,
        Descricao,
        setDescricao,
        PacienteIdPlanoSaude,
        setPacienteIdPlanoSaude,
        UserId,
        setUserId,
    };
};

export default SolicitacaoAtividadeExterna;