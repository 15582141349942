import styles from 'styled-components';

export const Container = styles.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
    max-height: calc(100vh - 90px);
    overflow-y: auto;

    .coluna-1 {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        margin: 0;
        padding: 0;
    }
    .coluna-2 {
        flex-direction: column;
    }
    .coluna-3 {
        
    }
`;

export const PageContainer = styles.div`
    padding: 20px 60px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    max-width: 942px;
    margin: 20px auto;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;

    h1 {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: center;
    }

    h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    ul {
        margin-left: 20px;
    }

    li {
        font-size: 16px;
        margin-bottom: 10px;
    }

    b {
        font-weight: bold;
    }
`;

export const Footer = styles.footer`
    text-align: center;
    padding: 10px;
    background-color: #f1f1f1;
    border-top: 1px solid #e1e1e1;
    margin-top: 20px;
    font-size: 14px;
    color: #555;
`;