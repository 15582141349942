import React from 'react';
import { Container, PageContainer, Footer } from './styles';
import version from 'version';

const PoliticaDeCookies = () => {
    return (
        <div className=''>
            <nav className='sys-navbar'>
                <div className='m-0 p-0 flex-row d-flex align-items-end'>
                    <a key={0} href={'/'} className='nav-logo'>
                        <img style={{ maxWidth: '150px', height: 'auto' }} src={'/logo.png'} alt='Neuro' />
                    </a>
                    <span className='font-branco-i pb-4'>
                        {version()}
                    </span>
                </div>
                <div></div>
                <div>
                    <a className='btn-padrao laranja' href='/'>
                        Retornar ao login
                    </a>
                </div>
            </nav>
            <div className='gamb'></div>
            <Container>
                <div className='coluna-1'></div>
                <div className='coluna-2'>
                    <PageContainer>
                        <h1>Política de Cookies</h1>
                        <p>
                            Esta Política de Cookies explica o que são cookies e como os usamos. Você deve ler esta política para entender o que são cookies, como os usamos, os tipos de cookies que usamos, ou seja, as informações que coletamos usando cookies, como essas informações são usadas e como controlar as preferências de cookies. Para mais informações sobre como usamos, armazenamos e mantemos seus dados pessoais seguros, consulte nossa Política de Privacidade.
                        </p>
                        <h2>O que são cookies?</h2>
                        <p>
                            Cookies são pequenos arquivos de texto usados para armazenar pequenas informações. Eles são armazenados em seu dispositivo quando o site é carregado em seu navegador. Esses cookies nos ajudam a fazer o site funcionar corretamente, torná-lo mais seguro, proporcionar uma melhor experiência ao usuário, entender como o site funciona e analisar o que funciona e onde precisa de melhorias.
                        </p>
                        <h2>Como usamos cookies?</h2>
                        <p>
                            Nosso site usa apenas cookies primários para várias finalidades. Os cookies primários são principalmente necessários para que o site funcione corretamente e não coletam nenhum dos seus dados de identificação pessoal.
                        </p>
                        <h2>Tipos de cookies que usamos</h2>
                        <ul>
                            <li><b>Essenciais:</b> Alguns cookies são essenciais para que você possa experimentar todas as funcionalidades do nosso site. Eles nos permitem manter sessões de usuário e prevenir quaisquer ameaças à segurança. Eles não coletam ou armazenam nenhuma informação pessoal.</li>
                            <li><b>Preferências:</b> Esses cookies nos ajudam a armazenar suas configurações e preferências de navegação, como preferências de idioma, para que você tenha uma experiência melhor e eficiente em futuras visitas ao site.</li>
                        </ul>
                        <h2>Como posso controlar as preferências de cookies?</h2>
                        <p>
                            Caso decida mudar suas preferências mais tarde durante sua sessão de navegação, você pode clicar na guia "Política de Privacidade e Cookies" na sua tela. Isso exibirá novamente o aviso de consentimento permitindo que você mude suas preferências ou retire seu consentimento completamente.
                        </p>
                        <p>
                            Além disso, diferentes navegadores fornecem diferentes métodos para bloquear e excluir cookies usados pelos sites. Você pode alterar as configurações do seu navegador para bloquear/excluir os cookies. Para saber mais sobre como gerenciar e excluir cookies, visite wikipedia.org, www.allaboutcookies.org.
                        </p>
                    </PageContainer>
                    <Footer>
                        &copy; {new Date().getFullYear()} Neuro Intensiva. Todos os direitos reservados.
                    </Footer>
                </div>
                <div className='coluna-3'></div>
            </Container>
        </div>
    );
};

export default PoliticaDeCookies;