import React, { useMemo } from 'react';

import { AiOutlineDownload } from 'react-icons/ai';
import { TableDefault } from '../../../../../components/TableDefault';
import moment from 'moment/moment';
import { baseURL } from '../../../../../services/baseUrl';
import { useAuth } from '../../../../../context/auth';


export function TableDocumentosAssinadosResponsavel({ source }) {
  const { user } = useAuth();
  const data = React.useMemo(() => source, [source]);

  const columns = useMemo(
    () => [
      {
        Header: 'Nome Documento',
        accessor: 'documentos_administrativo.nome_documento',
      },
      {
        Header: 'Validado Por',
        accessor: 'responsavel.nome', // accessor is the "key" in the data
      },
      {
        Header: 'Data Assinatura',
        accessor: 'assinado_dia', // accessor is the "key" in the data
        Cell: ({ cell }) => (
          (<div>
            {moment(cell.row.original.created_at).format('DD/MM/YYYY HH:ss')}
          </div>
          )
        ),
      },
      {
        Header: 'Download',
        accessor: 'name',
        Cell: ({ cell }) => (
          (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <a href={baseURL + `api/documento-administrativo/${cell.row.original.id}/${cell.row.original.responsavel_id}/download`}
              target='_blank' style={{ color: '#7340BD', cursor: 'pointer' }}
              title={'Download do documento'} rel="noreferrer"><AiOutlineDownload color='#7340BD' size={22} /></a>
          </div>
          )
        ),
      },
    ],
    [source]
  );

  return (
    <TableDefault columns={columns} source={data} />
  );
}
