import { Form } from '@unform/web';
import Dropdown from 'components/Dropdown';
import { useAuth } from 'context/auth';
import { usePageBase } from 'context/page-base';
import { toPng } from 'html-to-image';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { AiFillCaretDown, AiFillEye, AiOutlineClear, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BsDownload } from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';
import { ChangeCase } from 'services/ChangeCase';
import AgendaModalTerapeuta from '../../../../components/Modais/Terapeuta/AgendaModal';
import Select from '../../../../components/Select';
import { useSnackbar } from '../../../../context/snackbar';
import { getLegendaAgendamento } from '../../../../helpers/agenda.helper';
import AgendamentoService from '../../../../services/AgendamentoService';
import api from '../../../../services/api';
import LegendaDefault from '../ControleDiario/Modal/Legenda';
import '../Terapeuta/agenda.css';
import DetalhesDoAgendamento from '../Terapeuta/Modal/DetalhesDoAgendamento';
import { Container, DivContentDisplay, filtered, listOfStyles, styles_escuro, styles_filtred } from '../Terapeuta/styles';
import Responsavel from './Modal/Responsavel';
import './styles.css';
import AgendaTerapeutaTbody from './tbody';
/**
 * Componente AgendaTerapeutas
 * 
 * Este componente exibe a agenda semanal dos terapeutas, permitindo a visualização e edição de agendamentos.
 * 
 * @component
 * 
 * @returns {JSX.Element} Retorna o componente de agenda dos terapeutas.
 * 
 * @example
 * <AgendaTerapeutas />
 * 
 * @description
 * O componente utiliza várias variáveis de estado para gerenciar os dados exibidos, incluindo informações sobre terapeutas, pacientes, horários, agendamentos e filtros aplicados. 
 * Ele também possui diversas funções para buscar dados da API, filtrar resultados e atualizar a interface conforme necessário.
 * 
 * @function handleClickOutside
 * @description Função que fecha o filtro quando um clique é detectado fora do elemento de filtro.
 * 
 * @function getPacientes
 * @description Função que coleta todos os pacientes cadastrados em uma lista de seleção.
 * 
 * @function getTerapias
 * @description Função que coleta todas as terapias em uma lista de seleção.
 * 
 * @function getEspecialidades
 * @description Função que coleta todas as especialidades em uma lista de seleção.
 * 
 * @function getTerapeutas
 * @description Função que coleta todos os terapeutas em uma lista de seleção.
 * 
 * @function filtrar
 * @description Função que aplica o filtro de nome aos agendamentos.
 * 
 * @function updateFilter
 * @description Função que atualiza os filtros aplicados.
 * 
 * @function atualizaComponenteData
 * @description Função que atualiza os dados do componente com base na data selecionada.
 * 
 * @function modalLegendas
 * @description Função que abre o modal de legendas.
 * 
 * @function modalDetalhesAgendamento
 * @description Função que abre o modal de detalhes do agendamento.
 * 
 * @function getAgendaDia
 * @description Função que busca a agenda do dia.
 * 
 * @function getAgendaHorarios
 * @description Função que busca os horários da agenda.
 * 
 * @function getSalas
 * @description Função que busca as salas disponíveis.
 * 
 * @function getAgendamentos
 * @description Função que busca os agendamentos para a data selecionada.
 * 
 * @function handleClickNextDay
 * @description Função que avança para o próximo dia na agenda.
 * 
 * @function handleClickPrevDay
 * @description Função que retorna para o dia anterior na agenda.
 * 
 * @function handleExportToImage
 * @description Função que exporta a agenda como uma imagem.
 * 
 * @function syncScroll
 * @description Função que sincroniza o scroll entre diferentes elementos.
 * 
 * @function getTooltipClass
 * @description Função que retorna a classe CSS apropriada para o tooltip com base no índice do horário e do terapeuta.
 * 
 * @useEffect
 * @description Efeito que atualiza a agenda quando há exclusão de agendamento.
 * 
 * @useEffect
 * @description Efeito que aplica os filtros aos terapeutas e agendamentos.
 * 
 * @useEffect
 * @description Efeito que inicializa os dados do componente ao montar.
 */
export default function AgendaTerapeutas() {
    usePageBase({
        title: 'Agenda Terapeutas',
        description: 'Página que exibe a agenda semanal dos terapeutas.',
        hasTitle: true,
        hasSubMenu: true
    });
    //#region VARIÁVEIS
    /*==VARS ESTILOS==*/
    const stylesSize = [90, 70, 90, 70];
    const differenceBase = [10, 295];
    const styles = useMemo(() => listOfStyles({
        baseWidth: stylesSize[0],
        baseHeight: stylesSize[1],
        baseWidthHora: stylesSize[2],
        baseHeightHora: stylesSize[3],
        isFullScreen: false,
        esperaMode: false,
        differenceBase: differenceBase
    }), [listOfStyles]);

    /*==VARS UTILITÁRIOS==*/
    const snackbar = useSnackbar();

    /*==VARS LOADING==*/
    const [loading, setloading] = useState(true);

    /*==VARS MODAIS==*/
    const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
    const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [esperaMode, setEsperaMode] = useState(false);

    /*==VARS SELEÇÃO==*/
    const agendaModalRef = useRef(null);
    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);

    /*==VARS DIAS==*/
    const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];

    /*==VARS Listas de Dados==*/
    const [estacaos, setEstacaos] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [newAgendamentos, setNewAgendamentos] = useState([]);
    const usuario = useAuth();

    const [modalAgendaOpen, setModalAgendaOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState(moment().format('YYYY-MM-DD'));
    const [agendamentoId, setAgendamentoId] = useState();

    /*==VARS DATAS==*/
    const dateNow = new Date();
    const [dataAtual, setDataAtual] = useState(moment(dateNow));
    const [diaAtual, setDiaAtual] = useState(dateNow.getDay());
    const [dataSave, setDataSave] = useState(moment());

    /*==VARS FILTRO==*/
    const [filtroNome, setFiltroNome] = useState('');
    const filtroDivRef = useRef(null);
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState(null);
    const [terapiaSelecionadaOpt, setTerapiaSelecionadaOpt] = useState(null);
    const [especialidadeSelecionadaOpt, setEspecialidadeSelecionadaOpt] = useState(null);

    const [terapeutas, setTerapeutas] = useState([]);
    const [terapeutasOriginal, setTerapeutasOriginal] = useState([]);

    const pacientesList = useMemo(() => {
        const pacientesList = [];
        if (Array.isArray(agendamentos)) {
            agendamentos.forEach((a) => {
                if (pacientesList.some((p) => p.value === a.paciente_id) === false) {
                    pacientesList.push({ value: a.paciente_id, label: a.paciente_nome });
                }
            });
        }
        return pacientesList;
    }, [agendamentos]);

    const terapeutasList = useMemo(() => {
        const terapeutasList = [];
        if (Array.isArray(terapeutasOriginal)) {
            terapeutasOriginal.forEach((t) => {
                terapeutasList.push({ value: t.id, label: t.nome_curto });
            });
        }
        return terapeutasList;
    }, [terapeutasOriginal]);

    const terapiasList = useMemo(() => {
        const terapiasList = [];
        if (Array.isArray(agendamentos)) {
            agendamentos.forEach((a) => {
                if (terapiasList.some((p) => p.value === a.terapia_id) === false) {
                    terapiasList.push({ value: a.terapia_id, label: a.terapia_terapia });
                }
            });
        }
        return terapiasList;
    });

    const especialidadesList = useMemo(() => {
        const especialidadesList = [];
        if (Array.isArray(agendamentos)) {
            agendamentos.forEach((a) => {
                if (especialidadesList.some((p) => p.value === a.especialidade_id) === false) {
                    especialidadesList.push({ value: a.especialidade_id, label: a.especialidade_especialidade });
                }
            });
        }
        return especialidadesList;
    });

    const tableRef = useRef(null);
    const horaTableBodyRef = useRef(null);
    const agendamentoTableBodyRef = useRef(null);
    const terapeutaTableHeadRef = useRef(null);
    const elementRef = useRef(null);
    //#endregion
    //#region FUNCOES
    function getTooltipClass(horarioIndex, terapeutaIndex) {
        const horarioInicio = horarioIndex === 0;
        const horarioFim = horarioIndex === horarios.length - 1;
        const pertoTopo = horarioIndex === 0 || horarioIndex === 1;
        const horarioMeioCima = (horarios.length - 1) / 2 > horarioIndex;
        const horarioMeioBaixo = (horarios.length - 1) / 2 < horarioIndex;
        const estacaoInicio = terapeutaIndex === 0;
        const estacaoFim = terapeutaIndex === terapeutas.length - 1;
        if (horarioInicio && estacaoInicio) {
            return 'tooltip-right-bottom';
        }
        if (horarioInicio && estacaoFim) {
            return 'tooltip-left-bottom';
        }
        if (horarioFim && estacaoInicio) {
            return 'tooltip-right-top';
        }
        if (horarioFim && estacaoFim) {
            return 'tooltip-left-top';
        }
        if (estacaoInicio) {
            return 'tooltip-right';
        }
        if (estacaoFim) {
            if (pertoTopo) {
                return 'tooltip-left-bottom';
            } else {
                return 'tooltip-left';
            }
        }
        if (horarioInicio) {
            return 'tooltip-bottom';
        }
        if (horarioFim) {
            return 'tooltip-top';
        }
        if (!horarioInicio && !horarioFim && !estacaoInicio && !estacaoFim) {
            if (horarioMeioCima) {
                return 'tooltip-bottom';
            }
            if (horarioMeioBaixo) {
                return 'tooltip-top';
            }
        }
        return 'tooltip-top';
    }
    /**
     * Filtra e realça os agendamentos com base no nome do paciente.
     * 
     * Esta função utiliza o filtroNome para determinar quais agendamentos devem ser realçados.
     * Se o filtro estiver vazio ou nulo, todos os agendamentos terão o campo 'filtrado' indefinido.
     * Caso contrário, os agendamentos cujo nome do paciente contenha o filtro serão marcados como 'filtrado: true',
     * e os demais como 'filtrado: false'.
     * 
     * @function
     * @name filtrarRealcarAgendamentos
     * @returns {void}
     */
    const filtrarRealcarAgendamentos = useCallback(() => {
        const filtro = filtroNome;
        const paciente_id = pacienteSelecionadoOpt ? pacienteSelecionadoOpt.value : null;
        setAgendamentos(agendamentos.map((a) => {
            let novo = {}; // Alterar de const para let
            if (paciente_id) {
                if (a.paciente_id === paciente_id) {
                    novo = { ...a, filtrado: true };
                } else {
                    novo = { ...a, filtrado: false };
                }
            } else if (filtro === '' || filtro === null) {
                novo = { ...a, filtrado: undefined };
            } else {
                if (String(a.paciente.nome).toLowerCase().includes(String(filtro).toLowerCase())) {
                    novo = { ...a, filtrado: true };
                } else {
                    novo = { ...a, filtrado: false };
                }
            }
            return (novo);
        }));
        updateFilter();
    }, [filtroNome, pacienteSelecionadoOpt, agendamentos]);
    /**
     * Filtra a lista de terapeutas com base nas opções selecionadas de terapia, especialidade e terapeuta.
     *
     * @function
     * @name filtrarTerapeutas
     * @param {Array} terapeutasOriginal - Lista original de terapeutas.
     * @param {Array} terapiaSelecionadaOpt - Opções selecionadas de terapia.
     * @param {Array} especialidadeSelecionadaOpt - Opções selecionadas de especialidade.
     * @param {Array} terapeutaSelecionadoOpt - Opções selecionadas de terapeuta.
     * @returns {void} - Atualiza a lista de terapeutas filtrados.
     */
    const filtrarTerapeutas = useCallback(() => {
        const newTerapeutas = terapeutasOriginal.filter((t) => {
            let matchTerapia = true;
            let matchEspecialidade = true;
            let matchTerapeuta = true;

            if (terapiaSelecionadaOpt && terapiaSelecionadaOpt.length > 0) {
                matchTerapia = t.terapeuta_terapia.some((tt) => {
                    return terapiaSelecionadaOpt
                        ? terapiaSelecionadaOpt.some((el) => Number(tt.terapia_id) === Number(el.value))
                        : true;
                });
            }

            if (especialidadeSelecionadaOpt && especialidadeSelecionadaOpt.length > 0) {
                matchEspecialidade = t.terapeuta_especialidades.some((te) => {
                    return especialidadeSelecionadaOpt
                        ? especialidadeSelecionadaOpt.some((el) => Number(te.especialidade_id) === Number(el.value))
                        : true;
                });
            }

            if (terapeutaSelecionadoOpt && terapeutaSelecionadoOpt.length > 0) {
                matchTerapeuta = terapeutaSelecionadoOpt.some((fil) => t.id === fil.value);
            }
            return matchTerapeuta && matchTerapia && matchEspecialidade;
        });
        setTerapeutas(newTerapeutas);
    }, [terapeutaSelecionadoOpt, terapeutasOriginal, terapiaSelecionadaOpt, especialidadeSelecionadaOpt]);
    /**
     * Função que atualiza os filtros aplicados.
     * 
     * @returns {Array} Lista de filtros aplicados.
     */
    const updateFilter = () => {
        let fields = document.getElementsByName('salas[]');
        let checks = [];
        fields.forEach((x) => {
            if (x.checked) {
                checks.push(x.value);
            }
        });

        return checks;
    };
    /**
     * Função que atualiza os dados do componente com base na data selecionada.
     * 
     * @param {number|null} diaAtualLocal - O dia atual local.
     * @param {Object|null} dataSaveLocal - A data salva local.
     */
    const atualizaComponenteData = useCallback(async (diaAtualLocal = null, dataSaveLocal = null) => {
        if (diaAtualLocal === null && dataSaveLocal === null) {
            dataSaveLocal = moment(dataSave);
            diaAtualLocal = dataSaveLocal.format('e');
        }
        setDiaAtual(Number(diaAtualLocal));
        setDataAtual(dataSaveLocal);
        setDataSave(dataSaveLocal);
        setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
        getAgendamentos(dataSaveLocal.format('YYYY-MM-DD'));
        getAgendaHorarios(diaAtualLocal);
    }, [dataSave]);
    /**
     * Função que abre o modal de legendas.
     */
    const modalLegendas = () => {
        setIsModalLegendaVisible(true);
    };
    /**
     * Função que abre o modal de detalhes do agendamento.
     * 
     * @param {Object} detalhes - Os detalhes do agendamento.
     */
    const modalDetalhesAgendamento = (detalhes) => {
        setAgendamentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    /**
     * Função para buscar a lista de terapeutas da API e atualizar o estado com os dados recebidos.
     * A função faz uma requisição GET para a rota 'api/terapeuta' com os parâmetros de consulta necessários.
     * 
     * A resposta da API é processada para filtrar terapeutas e terapias não deletadas e atualizar o estado com os terapeutas.
     * 
     * @function getTerapeutas
     * @returns {void}
     */
    const getTerapeutas = () => {
        api.get('api/terapeuta?with=terapeuta_terapia,terapeuta_terapia.terapium,terapeuta_disponibilidades,terapeuta_especialidades,terapeuta_especialidades.especialidade')
            .then(({ data }) => {
                const terapeutas = [];
                const terapeutasList = [];
                data.filter((filter) => filter?.status?.toLowerCase() !== 'inativo').forEach((p) => {
                    terapeutas.push({
                        nome_curto: p.nome_curto,
                        id: p.id,
                        terapeuta_terapia: p.terapeuta_terapia.filter((filter) => !filter.deleted_at),
                        terapeuta_especialidades: p.terapeuta_especialidades.filter((filter) => !filter.deleted_at),
                        terapeuta_disponibilidades: p.terapeuta_disponibilidades
                    });
                    terapeutasList.push({ value: p.id, label: `(${p.id}) ${p.nome}`, key: p.id });
                });
                setTerapeutas(null);
                setTerapeutasOriginal(terapeutas);
            });
    };
    /**
     * Função que busca os horários da agenda.
     * 
     * @param {number} diaAtualLocal - O dia atual local.
     */
    const getAgendaHorarios = useCallback((diaAtualLocal) => {
        api.get('/api/horario-atendimento?filter[dia_semana]=' + diaAtualLocal).then(({ data }) => {
            setHorarios(data);
        });
    }, []);

    /**
     * Função que busca os agendamentos para a data selecionada.
     * 
     * @param {string} dataSaveLocal - A data salva local.
     */
    const getAgendamentos = useCallback(async (dataSaveLocal) => {
        setloading(true);
        const day = moment(dataSaveLocal).day();
        AgendamentoService.getByWeekDay(
            day,
            'terapia_paciente,terapia_paciente.plano_saude,terapia,especialidade,estacao,horarios_atendimento',
            'terapia_paciente.plano_saude_id,plano_saude.nome_reduzido,terapia.terapia,especialidade.especialidade,terapeuta.status',
            'terapeuta.status,!=,inativo'
        ).then(({ data }) => {
            const newData = data['lista'];
            setAgendamentos(newData);
            const newAgendamentos = [];
            for (let index = 0; index < newData.length; index++) {
                const a = newData[index];
                const where = (a.tipo_atendimento_id === 1 || a.tipo_atendimento_id === 2) && a.reserva === 0;
                if (where) {
                    if (!newAgendamentos[a.terapeuta_id]) {
                        newAgendamentos[a.terapeuta_id] = {};
                    }
                    if (!newAgendamentos[a.terapeuta_id][a.horarios_atendimento_id]) {
                        newAgendamentos[a.terapeuta_id][a.horarios_atendimento_id] = [];
                    }
                    newAgendamentos[a.terapeuta_id][a.horarios_atendimento_id].push(
                        (a.prop && a.prop.constructor === Array)
                            ? a.reverse()
                            : a
                    );
                }
            }
            setNewAgendamentos(newAgendamentos);
            setloading(false);
        });
    }, []);
    /**
     * Sincroniza o scroll de um elemento fonte com elementos alvo.
     *
     * @param {Object} source - Referência ao elemento fonte.
     * @param {Object} targetUp - Referência ao elemento alvo para sincronizar o scroll vertical (opcional).
     * @param {Object} targetLeft - Referência ao elemento alvo para sincronizar o scroll horizontal (opcional).
     */
    const syncScroll = (source, targetUp, targetLeft) => {
        source = source.current;
        targetUp = targetUp != null ? targetUp.current : null;
        targetLeft = targetLeft != null ? targetLeft.current : null;
        if (targetUp != null)
            targetUp.scrollTop = source.scrollTop;
        if (targetLeft != null)
            targetLeft.scrollLeft = source.scrollLeft;
    };
    //#endregion
    //#region HANDLES
    /**
     * Função que fecha o filtro quando um clique é detectado fora do elemento de filtro.
     * 
     * @param {Event} event - O evento de clique.
     */
    const handleClickOutside = (event) => {
        if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
            setExpand(false);
        }
    };
    const handleClickNextDay = () => {
        let dataSaveLocal = moment(dataSave).add(1, 'days');
        if (dataSaveLocal.day() === 0) {
            dataSaveLocal = moment(dataSave).add(2, 'days');
        }
        let diaAtualLocal = dataSaveLocal.format('e');
        atualizaComponenteData(diaAtualLocal, dataSaveLocal);
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = moment(dataSave).subtract(1, 'days');
        if (dataSaveLocal.day() === 0) {
            dataSaveLocal = moment(dataSave).subtract(2, 'days');
        }
        let diaAtualLocal = dataSaveLocal.format('e');
        atualizaComponenteData(diaAtualLocal, dataSaveLocal);
    };
    const handleExportToImage = () => {
        if (elementRef.current === null) {
            return;
        }
        elementRef.current.classList.add('overflow-y-no-scroll');
        elementRef.current.classList.add('bg-branco');
        elementRef.current.classList.add('w-content-i');
        elementRef.current.classList.add('h-content-i');
        agendamentoTableBodyRef.current.classList.add('w-content-i');
        agendamentoTableBodyRef.current.classList.add('h-content-i');
        terapeutaTableHeadRef.current.classList.add('w-content-i');
        terapeutaTableHeadRef.current.classList.add('h-content-i');
        horaTableBodyRef.current.classList.add('h-content-i');
        terapeutaTableHeadRef.current.classList.add('w-content-i');
        terapeutaTableHeadRef.current.classList.add('h-content-i');
        toPng(elementRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = `agenda-${esperaMode ? 'espera-' : ''}${dataSave.format('DD_MM_YYYY-HH_MM')}.jpeg`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.error('Error generating image:', err);
            })
            .finally(() => {
                elementRef.current.classList.remove('overflow-y-no-scroll');
                elementRef.current.classList.remove('bg-branco');
                elementRef.current.classList.remove('w-content-i');
                elementRef.current.classList.remove('h-content-i');
                agendamentoTableBodyRef.current.classList.remove('w-content-i');
                agendamentoTableBodyRef.current.classList.remove('h-content-i');
                terapeutaTableHeadRef.current.classList.remove('w-content-i');
                terapeutaTableHeadRef.current.classList.remove('h-content-i');
                horaTableBodyRef.current.classList.remove('h-content-i');
                terapeutaTableHeadRef.current.classList.remove('w-content-i');
                terapeutaTableHeadRef.current.classList.remove('h-content-i');
            });
    };
    const handleSelectDay = (e) => {
        const dia = e.target.value;
        const dataSaveLocal = moment().day(dia);
        atualizaComponenteData(dia, dataSaveLocal);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (!houveExclusaoAgendamento) return;
        setHouveExclusaoAgendamento(false);
    }, [houveExclusaoAgendamento]);
    useEffect(() => {
        try {
            filtrarRealcarAgendamentos();
            filtrarTerapeutas();
        } catch (e) {
            console.error(e);
        }
    }, [terapeutasOriginal, terapeutaSelecionadoOpt, pacienteSelecionadoOpt, terapiaSelecionadaOpt, especialidadeSelecionadaOpt]);
    useEffect(() => {/* ON LOAD */
        const handleAtalhos = (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleAtalhos);

        getAgendaHorarios(diaAtual);
        getAgendamentos(dataSelecionada);
        getTerapeutas();
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleAtalhos);
        };
    }, []);
    //#endregion
    //#region HTML
    return (
        <React.Fragment>
            <Container>
                <div style={styles.containerFlexColumn}>
                    <div ref={tableRef} className='w-content m-auto'>
                        <div style={styles.tr0}>
                            <div ref={filtroDivRef} className='div-filter-style justify-content-start align-center' style={styles.filtro}>
                                <div className="w-100per pointer d-flex justify-between align-center" style={{ height: '40px' }} onClick={() => setExpand(!expand)}>
                                    <div id="blank" style={{ height: 'fit-content' }}>{!expand ? 'Expandir' : 'Fechar'} Filtro</div>
                                    <AiFillCaretDown className='pointer' color="white" size={22} />
                                </div>
                                <Form className='div-expand-over' style={{ backgroundColor: 'rgb(149, 134, 172)', marginLeft: '-16px', width: '60vw', ...expand ? {} : { display: 'none' } }}>
                                    <div style={DivContentDisplay} className='d-flex flex-col'>
                                        <div className='row w-100per'>
                                            <div className="col-12">
                                                <label className="label-filtro">Terapeuta:</label>
                                                <div className="d-flex justify-around align-center w-100per">
                                                    <Select
                                                        id={'terapeutas'}
                                                        name={'terapeutas'}
                                                        options={terapeutasList}
                                                        value={terapeutaSelecionadoOpt}
                                                        onChange={(e) => { setTerapeutaSelecionadoOpt(e); }}
                                                        isMulti={true} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => { setTerapeutaSelecionadoOpt(null); }} size={25} />
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="label-filtro">Paciente:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select
                                                        name={'pacientes'}
                                                        options={pacientesList}
                                                        value={pacienteSelecionadoOpt}
                                                        onChange={(e) => {
                                                            setFiltroNome('');
                                                            setPacienteSelecionadoOpt({ value: e.value, label: e.label });
                                                        }} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setPacienteSelecionadoOpt(null);
                                                    }} size={25} />
                                                </div>
                                            </div>
                                            <div className="col-6 mt-3">
                                                <label className="label-filtro">Terapias:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select name={'terapias'} options={terapiasList} value={terapiaSelecionadaOpt} onChange={(e) => { setTerapiaSelecionadaOpt(e); }} isMulti={true} />
                                                    <AiOutlineClear className="pointer m-2" size={25} onClick={(e) => { setTerapiaSelecionadaOpt(null); }} />
                                                </div>
                                            </div>
                                            <div className="col-6 mt-3">
                                                <label className="label-filtro">Especialidades:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select name={'terapias'} options={especialidadesList} value={especialidadeSelecionadaOpt} onChange={(e) => { setEspecialidadeSelecionadaOpt(e); }} isMulti={true} />
                                                    <AiOutlineClear className="pointer m-2" size={25} onClick={(e) => { setEspecialidadeSelecionadaOpt(null); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className='p-1 justify-content-around d-flex align-center' style={{ width: '300px' }}>
                                <AiOutlineLeft className='pointer' color="black" size={30} onClick={() => handleClickPrevDay()} />
                                <div>
                                    <select className='texto-navegacao' style={{ border: 'none', backgroundColor: 'transparent', padding: '0', margin: '0' }} value={diaAtual} onChange={handleSelectDay}>
                                        {dayName.map((dia, index) => {
                                            return <option key={index} value={index}>{dia}</option>;
                                        })}
                                    </select>
                                    <br></br>
                                </div>
                                <AiOutlineRight className='pointer' color="black" size={30} onClick={() => handleClickNextDay()} />
                            </div>
                            <div className='d-inline-flex justify-content-end align-center' style={{ width: '300px' }}>
                                {/* BOTÃO EXPORT IMAGEM */}
                                <BsDownload className='pointer' size={30} onClick={() => handleExportToImage()} style={{ marginRight: '10px' }} />
                                {/* BOTÃO DE ATUALIZAR AGENDA */}
                                <FiRefreshCcw className='pointer' onClick={() => atualizaComponenteData()} size={30} style={{ marginRight: '20px' }} />
                                <button onClick={() => modalLegendas()} className='btn-outline' title='Legenda dos tipos de atendimento por cor.'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" fill="none" stroke="rgb(149, 134, 172)" strokeWidth="2" />
                                        <text x="12" y="17" textAnchor="middle" fontSize="14" fill="rgb(149, 134, 172)" fontWeight='bold'>?</text>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div ref={elementRef} id='div-agenda' className='d-inline-flex'>
                            <table className='table-agenda-terapeuta' style={styles.table}>
                                <thead style={{ ...styles.thead, height: '55px' }}>
                                    <tr style={{ ...styles.thead.trS, height: '55px' }} key={'terapeutas'}>
                                        <th className='d-flex' style={{ ...styles.tr1, height: '55px', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center', margin: '0', padding: '0' }}><span style={{ fontSize: '10pt' }}>Terapeutas</span> <br></br> <span>{terapeutas?.length}</span></th>
                                    </tr>
                                </thead>
                                <tbody ref={horaTableBodyRef} style={{ ...styles.tbody0 }} onScrollCapture={(e) => { syncScroll(horaTableBodyRef, agendamentoTableBodyRef, null); }}>
                                    {horarios.map((horario, index) => {
                                        let tarde = (Number(horario.horario.split('T')[1].split(':')[0]) >= 17);
                                        return (
                                            <tr style={styles.tbody.tr} key={'horas-' + index}>
                                                <td className='word-wrap' key={horarios.id} style={tarde ? { ...styles.trHora, ...styles.tarde } : { ...styles.trHora }} align={'center'}>{moment.utc(horario.horario).format('HH:mm')}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <table className='tbody-agenda-terapeuta' style={styles.table}>
                                <thead ref={terapeutaTableHeadRef} style={{ ...styles.thead, height: '55px' }} onScrollCapture={(e) => syncScroll(terapeutaTableHeadRef, null, agendamentoTableBodyRef)}>
                                    <tr style={{ ...styles.thead.tr, height: '55px' }} key={'terapeutas-tr'}>
                                        {terapeutas && terapeutas.map((terapeuta, index) => {
                                            if (terapeuta) {
                                                if (terapeuta?.terapeuta_terapia?.length > 0) {
                                                    return (
                                                        <td key={index} align={'center'}
                                                            style={{
                                                                ...styles.tr2T,
                                                                fontSize: '8pt',
                                                                height: '55px'
                                                            }}
                                                            className='font-14px pointer word-break'
                                                            onClick={() => {
                                                                agendaModalRef.current.openModal(terapeuta.id);
                                                                setModalAgendaOpen(true);
                                                            }}>
                                                            {terapeuta?.nome_curto}
                                                        </td>
                                                    );
                                                }
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <AgendaTerapeutaTbody
                                    horarios={horarios}
                                    terapeutas={terapeutas}
                                    newAgendamentos={newAgendamentos}
                                    loading={loading}
                                    styles={styles}
                                    styles_filtred={styles_filtred}
                                    styles_escuro={styles_escuro}
                                    syncScroll={syncScroll}
                                    agendamentoTableBodyRef={agendamentoTableBodyRef}
                                    horaTableBodyRef={horaTableBodyRef}
                                    terapeutaTableHeadRef={terapeutaTableHeadRef}
                                    getTooltipClass={getTooltipClass}
                                    modalDetalhesAgendamento={modalDetalhesAgendamento}
                                    esperaMode={esperaMode}
                                    usuario={usuario}
                                    agendamentos={agendamentos}
                                    getLegendaAgendamento={getLegendaAgendamento}
                                    ChangeCase={ChangeCase}
                                    diaAtual={diaAtual}
                                    setPacienteSelecionadoOpt={setPacienteSelecionadoOpt}
                                    filtered={filtered}
                                />
                            </table>
                        </div>
                    </div>
                </div>
            </Container>

            {isModalLegendaVisible
                ? <LegendaDefault onClose={() => setIsModalLegendaVisible(false)} />
                : (<></>)
            }
            {isModalResponsavelVisible
                ? <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />
                : (<></>)
            }
            {isModalDetalhesDoAtendimentoVisible
                ? <DetalhesDoAgendamento agendamentoId={agendamentoId} agendaButtons={true} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                : ''
            }
            <AgendaModalTerapeuta ref={agendaModalRef} onClose={() => setModalAgendaOpen(false)} mode={1} />
        </React.Fragment >
    );
    //#endregion
}