import React, { useEffect } from 'react';
import { Modal } from './styles';
import { BsCheck, BsEye, BsTrashFill } from 'react-icons/bs';
import { IoAddCircleOutline } from 'react-icons/io5';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { styles_filtred, filtered, listOfStyles } from 'pages/RecepcaoDashboard/Agenda/Terapeuta/styles';

// Definindo os valores permitidos para legendaMode
const allowedLegendaModes = ['agenda', 'controle-diario'];

/**
 * Componente de Modal para exibir a legenda: Agenda e Controle Diário.
 * 
 * @param {string} id - ID do modal.
 * @param {function} onClose - Função chamada ao fechar o modal.
 * @param {string} legendaMode - Modo da legenda. Valores permitidos: 'agenda', 'controle-diario'.
 * @returns {JSX.Element} O componente de modal com as legendas.
 */
export default function LegendaDefault({ id = 'modalLegenda', onClose = () => { }, legendaMode = 'agenda' }) {
    //#region ESTILOS
    const stylesSize = [90, 70, 90, 70];
    /*==VARS ESTILOS==*/
    const styles = listOfStyles({
        baseWidth: stylesSize[0],
        baseHeight: stylesSize[1],
        baseWidthHora: stylesSize[2],
        baseHeightHora: stylesSize[3]
    });
    const div2styleAgenda = {
        cursor: 'default',
        ...styles.trOcupado,
    };
    //#endregion
    //#region VALIDAÇÕES DOS PROPS
    // Verificação do valor de legendaMode
    if (!allowedLegendaModes.includes(legendaMode)) {
        console.warn(`Valor inválido para legendaMode: ${legendaMode}. Usando valor padrão 'agenda'.`);
        legendaMode = 'agenda';
    }
    //#endregion
    //#region VARIÁVEIS
    const data = legendaMode === 'agenda'
        ? [
            {
                color: null,
                texto: 'Agendamento',
                descricao: <ul className="list-group" key={'detalhamento-do-agendamento'}>
                    <li className="list-group-item" key={'detalhamento-do-agendamento-1'}>
                        <AiFillEye className='h-azul' title='Detalhes do agendamento.' /> - Botão utilizado para visualizar o detalhamento das informações do agendamento;
                    </li>
                    <li className="list-group-item" key={'detalhamento-do-agendamento-2'}>
                        <AiFillEdit className='h-azul' title='Editar agendamento.' /> - Botão utilizado para editar o agendamento por completo;
                    </li>
                    <li className="list-group-item" key={'detalhamento-do-agendamento-3'}>
                        <BsTrashFill className='h-azul' title='Excluir agendamento.' /> - Botão utilizado para excluir permanentemente o agendamento. Esse botão possui uma dupla checagem que vai perguntar antes de excluir de fato.
                    </li>
                    <li className="list-group-item" key={'detalhamento-do-agendamento-4'}>
                        <BsCheck className='h-azul' size={22} title='Aprovar reserva como atendimento.' /> - Se o agendamento for uma reserva, você visualizará esse botão que, quando pressionado, fará com que a reserva se torne um agendamento normal.
                    </li>

                </ul>,
                icon: <div className='d-grid me-4' style={{ ...div2styleAgenda, ...styles.tamanhoPadrao, backgroundColor: '#35D058', fontSize: '7pt', width: '60px', height: '60px', marginLeft: '-6px' }}>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="pt-2" style={styles.divTitulo}>PACIENTE</div>
                    </div>
                    <div className='col-12 d-flex justify-content-between align-items-center h-auto-i m-0 p-0 pe-1 ps-1'>
                        <AiFillEye className='h-azul' title='Detalhes do agendamento.' />
                        <AiFillEdit className='h-azul' title='Editar agendamento.' />
                        <BsTrashFill className='h-azul' title='Excluir agendamento.' />
                    </div>
                    <div className='col-12 h-auto-i m-0 p-0'>
                        <div className="pt-1" style={styles.divTitulo}>TERAPEUTA</div>
                    </div>
                </div>
            },
            {
                color: '#35D058',
                texto: 'Padrão',
                descricao: 'Agendamento de um atendimento normal, fica na agenda até ser removido. Agendamento será carregado ao Controle Diário normalmente.',
                icon: null
            },
            {
                color: '#E98232',
                texto: 'Conflito de Horários Terapeuta',
                descricao: 'Significa que que existe algum Agendamento no mesmo horário para o mesmo terapeuta em salas diferêntes. Agendamento será carregado ao Controle Diário normalmente.',
                icon: null
            },
            {
                color: '#F94181',
                texto: 'Conflito de Horários Paciente',
                descricao: 'Significa que existe algum Agendamento no mesmo horário para o mesmo paciente em salas diferêntes. Agendamento será carregado ao Controle Diário normalmente.',
                icon: null
            },
            {
                color: '#f00',
                texto: 'Conflito de Entre Agendas',
                descricao: 'Essa cor só vai aparecer na Agenda de Espera. Significa que existe um Agendamento, na mesma sala, no mesmo horário mas com terapeutas diferêntes entre agendas. Agendamento será carregado ao Controle Diário normalmente.',
                icon: null
            },
            {
                color: '#00FA9A',
                texto: 'Agendamento Duplicado',
                descricao: 'Essa cor só vai aparecer em ambas as agendas. Significa que existe um Agendamento, na mesma sala, no mesmo horário com o mesmo terapeuta e paciente. Agendamento será carregado ao Controle Diário normalmente.',
                icon: null
            },
            {
                color: '#EED10F',
                texto: 'Avaliação',
                descricao: 'Agendamento de um atendimento de avaliação, esse Agendamento se comporta como um Agendamento Padrão, mas quando carregado ao Controle Diário, ele fica como Realizado. Depois de realizado, a avaliação pode ser editada.',
                icon: null
            },
            {
                color: '#C901E8',
                texto: 'Reposição',
                descricao: 'Agendamento de um atendimento de reposição, esse Agendamento se comporta como um Agendamento Padrão, mas quando carregado para o Controle Diário, fica como Realizado. Depois de realizado, não pode ser editado.',
                icon: null
            },
            {
                color: '#2781DA',
                texto: 'Aguardando Terapeuta',
                descricao: 'Agendamento de um atendimento sem terapeuta. Esse tipo de Agendamento, fica em uma agenda paralela chamada Agenda de Espera, que pode ser visualizada usando o botão Switch na agenda. Esse Agendamemento se comporta como um atendimento padrão, carrega para o Controle Diário, mas pode não ter um terapeuta definido.',
                icon: null
            },
            {
                color: ' #a9b1b9 ',
                texto: 'Realizados',
                descricao: 'Os Agendamentos Realizados são Agendamentos de Atendimentos que já foram feitos, como Reposição, Avaliação e Substituição. Esse Agendamento NÃO carrega para o Controle Diário.',
                icon: null
            },
            {
                color: ' #e16ff2 ',
                texto: 'Substiuição',
                descricao: 'Agendamento de um Atendimento de Substituição. Esse Tipo de Atendimento é usado para substituir um Agendamento no local e horário. Esse Agendamento será carregado normalmente para o Controle Diário, mas o Agendamento original será carregado com uma marcação de substituído.',
                icon: null
            },
            {
                color: null,
                texto: 'Paciente/Terapeuta em Férias',
                descricao: 'Ao se deparar com os nomes, do terapeuta ou do paciente, destacados em vermelho significa que estão de férias.',
                icon: <div className='d-grid me-4' style={{ ...div2styleAgenda, ...styles.tamanhoPadrao, backgroundColor: '#35D058', fontSize: '7pt', width: '60px', height: '60px', marginLeft: '-6px' }}>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="pt-2" style={{ ...styles.divTitulo, color: '#FF0000', fontWeight: '700' }}>PACIENTE</div>
                    </div>
                    <div className='col-12 d-flex justify-content-between align-items-center h-auto-i m-0 p-0 pe-1 ps-1'>
                        <AiFillEye className='h-azul' title='Detalhes do agendamento.' />
                        <AiFillEdit className='h-azul' title='Editar agendamento.' />
                        <BsTrashFill className='h-azul' title='Excluir agendamento.' />
                    </div>
                    <div className='col-12 h-auto-i m-0 p-0' style={{ color: '#FF0000', fontWeight: '700' }}>
                        <div className="pt-1" style={styles.divTitulo}>TERAPEUTA</div>
                    </div>
                </div>
            },
            {
                color: null,
                texto: 'Filtrado',
                descricao: 'Quando você notar que os agendamentos estão dessa maneira, significa que um filtro foi utilizado, e o agendamento em questão atendte ao filtro.',
                icon: <div className='d-grid me-4' style={{ ...div2styleAgenda, ...styles.tamanhoPadrao, backgroundColor: '#35D058', fontSize: '7pt', width: '60px', height: '60px', marginLeft: '-6px', ...styles_filtred['padrao'], ...filtered }}>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="pt-2" style={styles.divTitulo}>PACIENTE</div>
                    </div>
                    <div className='col-12 d-flex justify-content-between align-items-center h-auto-i m-0 p-0 pe-1 ps-1'>
                        <AiFillEye className='h-azul' title='Detalhes do agendamento.' />
                        <AiFillEdit className='h-azul' title='Editar agendamento.' />
                        <BsTrashFill className='h-azul' title='Excluir agendamento.' />
                    </div>
                    <div className='col-12 h-auto-i m-0 p-0'>
                        <div className="pt-1" style={styles.divTitulo}>TERAPEUTA</div>
                    </div>
                </div>
            },
            {
                color: null,
                texto: 'Paciente com aviso de agendamento',
                descricao: 'Quando o nome do paciente estiver dessa maneira, significa que o paciente tem um aviso de agendamento.',
                icon: <div className='d-grid me-4' style={{
                    ...div2styleAgenda,
                    ...styles.tamanhoPadrao,
                    backgroundColor: '#35D058',
                    fontSize: '7pt',
                    width: '60px',
                    height: '60px',
                    marginLeft: '-6px',
                    border: '1px solid red',
                    boxShadow: '0 0 10px red'
                }}>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="pt-2" style={styles.divTitulo}>PACIENTE</div>
                    </div>
                    <div className='col-12 d-flex justify-content-between align-items-center h-auto-i m-0 p-0 pe-1 ps-1'>
                        <AiFillEye className='h-azul' title='Detalhes do agendamento.' />
                        <AiFillEdit className='h-azul' title='Editar agendamento.' />
                        <BsTrashFill className='h-azul' title='Excluir agendamento.' />
                    </div>
                    <div className='col-12 h-auto-i m-0 p-0'>
                        <div className="pt-1" style={styles.divTitulo}>TERAPEUTA</div>
                    </div>
                </div>
            }
        ]
        : [
            {
                color: null,
                texto: 'Modo de Criação SISTEMA',
                descricao: 'Derfine que o atendimento foi criado pelo próprio sistema, na rotina de carregamento.',
                icon: <span className={`d-flex align-items-center justify-content-center bg-cinza-claro me-4`} style={{ borderRadius: '50%', border: '1px solid purple', padding: '2px', width: 25, height: 25 }}>
                    S
                </span>
            },
            {
                color: null,
                texto: 'Modo de Criação MANUAL',
                descricao: 'Define que o atendimento foi criado por um usuário de forma manual.',
                icon: <span className={`d-flex align-items-center justify-content-center bg-roxo-dark font-branca me-4`} style={{ borderRadius: '50%', border: '1px solid purple', padding: '2px', width: 25, height: 25, marginRight: -12 }}>
                    M
                </span>
            },
            {
                color: '#35d058',
                texto: 'Sem Erros ou Conflitos',
                descricao: 'Não há nada que impeça o Controle Diário de ser salvo, ou algum Conflito a ser avaliado.',
                icon: null
            },
            {
                color: 'rgb(249, 65, 129)',
                texto: 'Erros',
                descricao: 'São erros nos atendimentos que impedem o Controle Diário de salvar. Esses erros são relácionados a falta de informações obrigatórias de preenchimento, ou caso não haja uma Guia válido para aquele atendimento. No Controle Diário o Status sempre é obrigatório, mas caso o status seja Realizado as informações: Liberado, Execução, Código 1 e Código 2 são obrigatórias também.',
                icon: null
            },
            {
                color: '#E2630D',
                texto: 'Conflitos',
                descricao: 'São Conflitos nos atendimentos que não vão impedir o Controle Diário de salvar. Os Conflitos necessitaram de uma dupla checagem na hora de Fechar o Controle Diário. Esses Conflitos podem ser: Pacientes iguais no mesmo horário em salas diferentes; Terapeutas iguais no mesmo horário em salas diferentes; Dois atendimento no mesmo horário e mesma sala.',
                icon: null
            },
            {
                color: null,
                texto: 'Sem Guia',
                descricao: 'Quando a coluna do nome do Terapeuta estiver com um contorno em vermelho, significa que o atendimento não tem uma guia válida.',
                icon: <div className="me-4 rounded-2 pe-4 ps-4 pt-3 pb-3" style={{ /* borderBottom: '1px #f00 solid', */ borderLeft: '1px #f00 solid', backgroundColor: '#f0f0f0' }}></div>
            },
            {
                color: null,
                texto: 'Substituído',
                descricao: 'Quando a coluna do nome do Terapeuta estiver com um contorno em roxo, significa que o atendimento esta substituído por outro.',
                icon: <div className="me-4 rounded-2 pe-4 ps-4 pt-3 pb-3" style={{ /* borderBottom: '1px #e16ff2 solid', */ borderRight: '1px #e16ff2 solid', backgroundColor: '#f0f0f0' }}></div>
            },
            {
                color: null,
                texto: 'Alterado',
                descricao: 'Quando a coluna de alteração estiver dessa maneira, significa que o atendimento foi alterado.',
                icon: <div className="me-4 rounded-2 pe-3 ps-3 pt-2 pb-2 d-flex align-center justify-center" style={{ backgroundColor: '#40C5EC' }}>
                    <BsEye className="p-0 m-0" size={15}></BsEye>
                </div>
            },
            {
                color: null,
                texto: 'Alteração Pendente de Aprovação',
                descricao: 'Quando a coluna de alteração estiver dessa maneira, significa que o atendimento possue uma alteração pendente de aprovação.',
                icon: <div className="me-4 rounded-2 pe-3 ps-3 pt-2 pb-2 d-flex align-center justify-center" style={{ backgroundColor: '#F5D70A' }}>
                    <BsEye className="p-0 m-0" size={15}></BsEye>
                </div>
            },
            {
                color: null,
                texto: 'Anulado Alteração',
                descricao: 'Quando a coluna de alteração estiver dessa maneira, significa que havia uma alteração, e que foi anulada.',
                icon: <div className="me-4 rounded-2 pe-3 ps-3 pt-2 pb-2 d-flex align-center justify-center" style={{ backgroundColor: '#EC4040' }}>
                    <IoAddCircleOutline className="p-0 m-0" size={15}></IoAddCircleOutline>
                </div>
            },
            {
                color: null,
                texto: 'Sem Alteração',
                descricao: 'Quando a coluna de alteração estiver dessa maneira, significa que não existe alteração alguma, nem ativa, nem anulada.',
                icon: <div className="me-4 rounded-2 pe-3 ps-3 pt-2 pb-2 d-flex align-center justify-center" style={{ backgroundColor: '#BCBCBC' }}>
                    <IoAddCircleOutline className="p-0 m-0" size={15}></IoAddCircleOutline>
                </div>
            },
        ];
    //#endregion
    //#region HANDLES
    /**
     * Função para lidar com cliques fora do modal, fechando-o.
     * 
     * @param {Object} e - Evento de clique.
     */
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        /**
         * Função para lidar com a tecla 'Escape', fechando o modal.
         * 
         * @param {Object} e - Evento de tecla.
         */
        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);
    //#endregion

    //#region HTML
    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <div className="div-modal p-0" style={{ width: '35vw' }}>
                <nav className='navbar sticky-top bg-cinza-claro shadow'>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div></div>
                        <ModalTitulo>Legenda</ModalTitulo>
                        <label className='btn-outline text-right' onClick={() => onClose()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div key={'leganda-list'} className="col-12 accordion" id="listagemDeLegendas">
                    {data.map((legendas, index) =>
                        <div key={'legenda' + index} className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button hl-roxo-claro collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#legenda' + index} aria-expanded="false" aria-controls={'legenda' + index}>
                                    {legendas.color
                                        ? <div className="me-4 rounded-2 pe-4 ps-4 pt-3 pb-3"
                                            style={{ backgroundColor: legendas.color }} />
                                        : legendas.icon
                                    }
                                    <label className="pointer">{legendas.texto}</label>
                                </button>
                            </h2>
                            <div id={'legenda' + index} className="accordion-collapse collapse" data-bs-parent="#listagemDeLegendas">
                                <div className="accordion-body">
                                    {legendas.descricao}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
    //#endregion
}