import { Form } from '@unform/web';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import Select from '../../../../../components/Select';
import { useSnackbar } from '../../../../../context/snackbar';
import api from '../../../../../services/api';
import TerapiaPacienteService from '../../../../../services/TerapiaPacienteService';
import useIcons from 'context/icons';



const ModalTerapia = (props, ref) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: 'auto',
            maxHeight: '70vh',
            width: '50vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF'
        },
    };
    let tipoTerapiasOptions = [
        { label: 'Tratamento', value: 'tratamento' },
        { label: 'Complementar', value: 'complementar' },
        { label: 'Integrativa', value: 'integrativa' }
    ];

    let subtitle;
    let index = 0;

    // Hooks
    const { iconArray } = useIcons();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [eEditavel, setEEditavel] = useState(false);
    const [dados, setDados] = useState(null);
    const [valor, setValor] = useState(0);
    const [idGuia, setIdGuia] = useState(null);

    const [terapia, setTerapia] = useState([]);
    const [tipoTerapia, setTipoTerapia] = useState([]);
    const [planoDeSaude, setPlanoDeSaude] = useState([]);
    const [especialidade, setEspecialidade] = useState([]);

    const [terapiaSelectBox, setTerapiaSelectBox] = useState([]);
    const [planoDeSaudeSelectBox, setPlanoDeSaudeSelectBox] = useState([]);
    const [especialidadeSelectBox, setEspecialidadeSelectBox] = useState([]);
    const frequenciaPagamentoOptions = [
        { label: 'Atendimento', value: 0 },
        { label: 'Semanal', value: 1 },
        { label: 'Quinzenal', value: 2 },
        { label: 'Mensal', value: 3 },
    ];
    const [terapiaEscolhida, setTerapiaEscolhida] = useState(null);
    const [planoDeSaudeEscolhido, setPlanoDeSaudeEscolhido] = useState(null);
    const [frequeciaPagamentoEscolhida, setFrequenciaPagamentoEscolhida] = useState(null);
    const [especialidadeEscolhido, setEspecialidadeEscolhido] = useState(null);
    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [salvando, setSalvando] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const generateId = (len) => {
        let result = [];
        let i = 0;
        while (i <= len) {
            let num = Math.floor(Math.random() * 9);
            result.push(num);
            i++;
            if (i === len) {
                let data = result.join('');
                setIdGuia(data);
            }
        };
    };

    const getDefaultTerapia = (terapias) => {
        if (!props.modalData) return;
        const terapia = terapias.filter(data => {
            return data.value === props.modalData.terapia_id;
        })[0];
        setTerapiaEscolhida(terapia);
    };

    const getDefaultEspecialidade = (especialidades) => {
        if (!props.modalData) return;
        const especialidade = especialidades.filter(data => {
            return data.value === props.modalData.especialidade_id;
        })[0];
        setEspecialidadeEscolhido(especialidade);
    };

    const getDefaultTipoTerapia = (tipos) => {
        if (!props.modalData) return;
        const tipoTerapia = tipos.filter(data => {
            return data.value === props.modalData.tipo_terapia;
        })[0];
        setTipoTerapia(tipoTerapia);
    };

    const getDefaultPlano = (planos) => {
        if (!props.modalData) return;
        const plano = planos.filter(data => {
            return data.value === props.modalData.plano_saude_id;
        })[0];
        setPlanoDeSaudeEscolhido(plano);
    };

    const getDefaultFrequenciaPagamento = (frequencias) => {
        if (!props.modalData) return;

        const frequencia = frequencias.filter(data => {
            return data?.value === props.modalData.frequencia_pagamento;
        })[0];
        setFrequenciaPagamentoEscolhida(frequencia);
    };

    const editNewPacienteTerapia = (terapia, dados) => {
        let terapiaLocal = [...props.terapias];
        const index = terapiaLocal.indexOf(terapia);
        terapiaLocal.splice(index, 1, dados);
        props.setTerapias(terapiaLocal);
    };

    const handleSubmit = async (data) => {
        setSalvando(true);
        try {
            let terapiaLocal = [...props.terapias];
            if (terapiaEscolhida === null || planoDeSaudeEscolhido === null || especialidadeEscolhido === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                const dados = {
                    terapia_id: terapiaEscolhida.value,
                    tipo_terapia: 'Tratamento',
                    plano_saude_id: planoDeSaudeEscolhido.value,
                    especialidade_id: especialidadeEscolhido.value,
                    quantidade: data.quantidade,
                    identificador_guia: data.identificador_guia,
                    data_inicio: data.data_inicio,
                    data_fim: data.data_fim,
                    valor: valor,
                    frequencia_pagamento: frequeciaPagamentoEscolhida?.value || null,
                };
                /* Confere se é ou não Edição */
                if (props.modalData === null) {/* Caso não seja */
                    /* Confere se é edição de paciente, ou paciente novo */
                    if (props.paciente_id !== undefined && props.paciente_id !== null) {/* Edição de Paciente */
                        dados.paciente_id = props.paciente_id;
                        let testIfInsert = await api.get(`api/terapia-paciente?filter[paciente_id]=${dados.paciente_id}&filter[terapia_id]=${dados.terapia_id}&filter[plano_saude_id]=${dados.plano_saude_id}&filter[especialidade_id]=${dados.especialidade_id}`);
                        testIfInsert = testIfInsert.data;
                        if (testIfInsert.length > 0) {
                            Swal.fire('Terapia incapaz de ser cadastrada!', 'Já existe uma terapia de mesmo plano e terapia cadastrada. Altere os dados e tente novamente.', 'error');
                        } else {
                            await api.post('api/terapia-paciente', dados).then(async ({ data }) => {
                                dados.terapium = {
                                    terapia: terapiaEscolhida.label
                                };
                                dados.plano_saude = {
                                    plano: planoDeSaudeEscolhido.label
                                };
                                dados.especialidade = {
                                    especialidade: especialidadeEscolhido.label
                                };
                                terapiaLocal.push({ ...dados, id: data.id });
                                snackbar.displayMessage('Adicionada nova terapia com sucesso!', 'success');
                                props.setTerapias(terapiaLocal);
                                props.setNewTerapiaAdd(true);
                                setTerapiaEscolhida(null);
                                setEspecialidadeEscolhido(null);
                                setPlanoDeSaudeEscolhido(null);
                                setValor(0);
                                setIsOpen(false);
                            }).catch((e) => {
                                console.error(e);
                                Swal.fire('Houve um problema ao adicionar a terapia!', e.response.data.error || 'Erro desconhecido', 'error');
                            });
                        }
                    } else {/* Paciente novo */
                        if (dados.index_terapia === undefined) {
                            dados.index_terapia = terapiaLocal.length;
                        }
                        dados.terapium = {
                            terapia: terapiaEscolhida.label
                        };
                        dados.plano_saude = {
                            plano: planoDeSaudeEscolhido.label
                        };
                        dados.especialidade = {
                            especialidade: especialidadeEscolhido.label
                        };
                        terapiaLocal.push(dados);
                        snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                        props.setTerapias(terapiaLocal);
                        props.setNewTerapiaAdd(true);
                        setTerapiaEscolhida(null);
                        setEspecialidadeEscolhido(null);
                        setPlanoDeSaudeEscolhido(null);
                        setValor(0);
                        setIsOpen(false);
                    }
                } else {/* Caso seja */
                    dados.index_terapia = terapiaLocal.length;
                    if (props.paciente_id === undefined || props.paciente_id === null) {
                        if (dados.index_terapia === undefined) {
                            dados.index_terapia = index;
                        } else {
                            dados.index_terapia = props.modalData.index_terapia;
                        }
                        dados.terapium = {
                            terapia: terapiaEscolhida.label
                        };
                        dados.plano_saude = {
                            plano: planoDeSaudeEscolhido.label
                        };
                        dados.especialidade = {
                            especialidade: especialidadeEscolhido.label
                        };
                        terapiaLocal.push(dados);
                        editNewPacienteTerapia(props.modalData, dados);
                        setPlanoDeSaudeEscolhido(null);
                        setEspecialidadeEscolhido(null);
                        setTerapiaEscolhida(null);
                        setValor(0);
                        index++;
                        setIsOpen(false);
                        snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                        props.setNewTerapiaAdd(true);
                    } else {
                        if (planoDeSaudeEscolhido.label.toLowerCase() !== 'particular') {
                            dados.valor = 0;
                        }
                        TerapiaPacienteService.update(dados, props.modalData.id).then(({ data }) => {
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                            for (let i = 0; i < terapiaLocal.length; i++) {
                                if (terapiaLocal[i].id === props.modalData.id) {
                                    terapiaLocal[i] = data;
                                    break;
                                }
                            }
                            props.setTerapias(terapiaLocal);
                            setIsOpen(false);
                            props.setNewTerapiaAdd(true);
                            props.callBack();
                        }).catch((e) => {
                            Swal.fire('Houve um problema ao tentar cadastrar!', e.response.data.error || 'Erro desconhecido', 'error');
                        });
                    }
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao tentar cadastrar!', error.response.data.error || 'Erro desconhecido', 'error');
        } finally {
            setSalvando(false);
        }
    };

    const handleChangeTerapia = async (e) => {
        setTerapiaEscolhida(e);
        setEspecialidadeEscolhido(null);
        setPlanoDeSaudeEscolhido(null);
        let lista_ids = [];
        let planos = await planoDeSaude.filter(data => {
            let filtro = false;
            if (data.plano_saude_terapia.length && data.plano_saude_terapia.length > 0) {
                for (let index = 0; index < data.plano_saude_terapia.length; index++) {
                    const el = data.plano_saude_terapia[index];
                    if (el.terapia_id === e.value) {
                        lista_ids.push(el.especialidade_id);
                        filtro = true;
                    }
                }
            } else {
                filtro = false;
            }
            return filtro;
        });
        let especialidades = await especialidade.filter(data => {
            let filtro = false;
            for (let index = 0; index < lista_ids.length; index++) {
                const el = lista_ids[index];
                if (el === data.id) {
                    filtro = true;
                    break;
                }
            }
            return filtro;
        });
        setEspecialidadeSelectBox(especialidades.map((el) => ({ label: el.especialidade, value: el.id })));
        setPlanoDeSaudeSelectBox(planos.map((el) => ({ label: el.plano, value: el.id })));
    };

    const handleChangeEspecialidade = async (e) => {
        setEspecialidadeEscolhido(e);
        setPlanoDeSaudeEscolhido(null);
        let planos = await planoDeSaude.filter(data => {
            for (let index = 0; index < data.plano_saude_terapia.length; index++) {
                const el = data.plano_saude_terapia[index];
                if (el.especialidade_id === e.value && el.terapia_id === terapiaEscolhida.value) {
                    return true;
                }
            }
        });
        setPlanoDeSaudeSelectBox(planos.map((el) => ({ label: el.plano, value: el.id })));
    };

    useEffect(() => {//On Load
        setLoadingDadosIniciais(true);

        async function loadDadosIniciais() {
            const dadosTerapia = await api.get('api/terapia');
            setTerapia(dadosTerapia.data);

            const selectTerapia = dadosTerapia.data.map(d => ({
                'value': d.id,
                'label': d.terapia
            }));
            setTerapiaSelectBox(selectTerapia);

            const dadosPlanoDeSaude = await api.get('api/plano-saude?with=plano_saude_terapia');
            setPlanoDeSaude(dadosPlanoDeSaude.data);

            const selectPlanoDeSaude = dadosPlanoDeSaude.data.map(d => ({
                'value': d.id,
                'label': d.plano
            }));
            setPlanoDeSaudeSelectBox(selectPlanoDeSaude);

            const dadosEspecialidade = await api.get('api/especialidade');
            setEspecialidade(dadosEspecialidade.data);

            const selectEspecialidade = dadosEspecialidade.data.map(d => ({
                'value': d.id,
                'label': d.especialidade
            }));
            setEspecialidadeSelectBox(selectEspecialidade);

            setLoadingDadosIniciais(false);
        };
        loadDadosIniciais();
        //generateId(8);
    }, []);

    useEffect(() => {// Na alteração da modalData
        if (props.modalData === null) {// Nova terapia
            setPlanoDeSaudeEscolhido(null);
            setEspecialidadeEscolhido(null);
            setTerapiaEscolhida(null);
            setFrequenciaPagamentoEscolhida(null);
            setValor(0);
            setEEditavel(true);
        } else {// Editar terapia
            getDefaultEspecialidade(especialidadeSelectBox);
            getDefaultPlano(planoDeSaudeSelectBox);
            getDefaultFrequenciaPagamento(frequenciaPagamentoOptions);
            getDefaultTerapia(terapiaSelectBox);
            getDefaultTipoTerapia(tipoTerapiasOptions);
            setValor(props.modalData.valor);
            setEEditavel(!props.modalData.ja_usado);
        }
    }, [props.modalData]);

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    };

    return (
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent appElement={document.getElementById('root')}>
            <div style={{ position: 'relative' }}>
                {!eEditavel
                    ? <span style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        left: '10px', fontSize: '15px', top: '-10px',
                        color: '#442953', fontWeight: 'bold'
                    }} onClick={() => Swal.fire('Informação', 'A terapia já foi utilizada e, portanto, não pode ser editada para garantir a integridade dos dados. Caso precise mesmo mudar, será necessário excuir essa terpia, adicionar outra, e editar onde for necessário!', 'info')}>
                        {iconArray['dica']} Porque não posso editar?
                    </span>
                    : null
                }
                <label style={{
                    opacity: 0.5, cursor: 'pointer', position: 'absolute',
                    right: '10px', fontSize: '25px', top: '-10px'
                }} onClick={() => setIsOpen(false)}>
                    x
                </label>
            </div>
            <div className='d-flex flex-column w-100' style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ color: '#442953', fontWeight: 'bold', textAlign: 'center' }} ref={(_subtitle) => (subtitle = _subtitle)}>{props.modalData === null ? 'Adicionar Nova Terapia' : props.isGuias === true ? 'Detalhes da Guia' : 'Alterar Terapia'}</h2>
                <Form ref={formRef} onSubmit={handleSubmit} className='d-flex flex-column justify-center align-center w-100'>
                    <div className='w-100'>
                        <Select
                            name="terapia_id"
                            label="Terapia"
                            options={terapiaSelectBox}
                            isDisabled={!eEditavel}
                            onChange={handleChangeTerapia} defaultValue={props.modalData === null ? '' : terapiaEscolhida} />
                    </div>
                    <div className='w-100'>
                        <Select
                            name="especialidade_id"
                            label="Especialidade:"
                            isDisabled={!eEditavel}
                            options={especialidadeSelectBox}
                            onChange={handleChangeEspecialidade}
                            defaultValue={props.modalData === null ? '' : especialidadeEscolhido} />
                    </div>
                    <div className='w-100'>
                        <Select
                            name="plano_saude_id"
                            label="Plano de Saúde:"
                            isDisabled={!eEditavel}
                            options={planoDeSaudeSelectBox}
                            onChange={setPlanoDeSaudeEscolhido}
                            defaultValue={props.modalData === null ? '' : planoDeSaudeEscolhido} />
                    </div>
                    <div className='w-100'>
                        <Select
                            name="frequencia_pagamento"
                            label="Frequência de Pagamento:"
                            isDisabled={!eEditavel || planoDeSaudeEscolhido?.value !== 2}
                            options={frequenciaPagamentoOptions}
                            onChange={setFrequenciaPagamentoEscolhida} defaultValue={props.modalData === null ? '' : frequeciaPagamentoEscolhida} />
                    </div>
                    {(eEditavel)
                        ? <button className='btn-padrao mt-30px w-300px' type="button" onClick={functionThatSubmitsForm} disabled={salvando}>
                            {salvando
                                ? <ClipLoader color='#ffffff' size={30}></ClipLoader>
                                : (props.modalData === null)
                                    ? <>
                                        {iconArray['adicionar']} Adicionar Terapia do Paciente
                                    </>
                                    : <>
                                        {iconArray['editar']} Editar Terapia do Paciente
                                    </>
                            }
                        </button>
                        : ''
                    }
                </Form>
            </div>
        </Modal>
    );
};

export default forwardRef(ModalTerapia);
