import bgImage from 'assets/background-image.jpeg';
import userGeneric from 'assets/img_avatar.png';
import Button from 'components/Button';
import Carregamento from 'components/Carregamento';
import ModalTitulo from 'components/ModalTitulo';
import { useAuth } from 'context/auth';
import { useSnackbar } from 'context/snackbar';
import { getLegendaAgendamento } from 'helpers/agenda.helper';
import { toPng } from 'html-to-image';
import moment from 'moment';
import { listOfStyles } from 'pages/RecepcaoDashboard/Agenda/Terapeuta/styles';
import DetalhesDoAgendamento from 'pages/TerapeutaDashboard/Agenda/Modal/DetalhesDoAgendamento';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import 'styles/reparticoes-css/agenda/agenda-modal.css';
/**
 * Componente de modal para exibir a agenda de um paciente.
 * 
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {string} [props.id='agendaModal'] - ID do modal.
 * @param {string} props.paciente_id - ID do paciente.
 * @param {Function} [props.outSideSet=() => {}] - Função de callback para definir o estado externo.
 * @param {boolean} [props.temDetalhes=true] - Indica se o modal deve exibir detalhes do atendimento.
 * @param {React.Ref} ref - Referência para o componente.
 * 
 * @returns {JSX.Element} O componente de modal da agenda do paciente.
 */
const AgendaPacienteModal = forwardRef(({ id = 'agendaModal', paciente_id, outSideSet = () => { }, temDetalhes = true }, ref) => {
    //#region VARIÁVEIS
    // Hooks
    const navigate = useNavigate();
    // Estados
    const [isOpen, setIsOpen] = useState(false);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [loading, setloading] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [horarios, setHorarios] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [atendimentoId, setAtendimentoId] = useState();
    const [selectedPacienteId, setSelectedPacienteId] = useState();
    const [viewSize, setViewSize] = useState(window.innerWidth);
    const [baseWidth, baseHeight] = [180, 120];

    const title = 'Agenda do Paciente';
    // Hooks
    const snackbar = useSnackbar();
    const { user } = useAuth();

    // Constantes de dias da semana
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        { value: 1, label: 'Segunda', short_label: 'S' },
        { value: 2, label: 'Terça', short_label: 'T' },
        { value: 3, label: 'Quarta', short_label: 'Q' },
        { value: 4, label: 'Quinta', short_label: 'Q' },
        { value: 5, label: 'Sexta', short_label: 'S' },
        { value: 6, label: 'Sábado', short_label: 'S' },
    ];

    // Estilos
    const styles = listOfStyles(baseWidth, baseHeight);
    const smallScreen = useMemo(() => viewSize < 800, [viewSize]);
    // Estilos do modal
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 15,
        },
        content:
            smallScreen
                ? {
                    inset: '0px',
                    position: 'relative',
                    height: `80vh`,
                    width: `100vw`,
                    margin: 'calc(100vh / 2 - 80vh / 2) 0px 0px 0px',
                    backgroundColor: '#FFFFFF',
                    zIndex: 100,
                    paddingTop: '0px',
                }
                : {
                    position: 'absolute',
                    top: '90px',
                    left: '1vw',
                    height: `calc(100vh - 90px)`,
                    width: `98vw`,
                    backgroundColor: '#FFFFFF',
                    zIndex: 100,
                    paddingTop: '0px',
                },
    };
    // Referências
    const elementRef = useRef();
    //#endregion
    //#region FUNÇÕES DA MODAL
    /**
     * Abre o modal de agendamento para um paciente específico.
     *
     * @param {number} paciente_id - O ID do paciente para o qual o modal será aberto.
     */
    const openModal = useCallback((paciente_id) => {
        setSelectedPacienteId(paciente_id);
        setPaciente(null);
        setAgendamentos([]);
        setIsOpen(true);
        outSideSet(true);
    }, []);
    /**
     * Fecha o modal de agenda de pacientes.
     * 
     * Esta função redefine o ID do paciente selecionado para null,
     * define o estado do modal como fechado e atualiza o estado externo.
     */
    const closeModal = useCallback(() => {
        setSelectedPacienteId(null);
        setIsOpen(false);
        outSideSet(false);
    }, []);
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    //#region HANDLES
    /**
     * Exporta um elemento HTML como imagem PNG.
     *
     * @param {string} baixar - Ação a ser realizada: 'baixar' para baixar a imagem ou 'copiar' para copiar a imagem para a área de transferência.
     * @returns {void}
     *
     * @example
     * handleExportToImage('baixar');
     * handleExportToImage('copiar');
     *
     * @throws {Error} Se ocorrer um erro ao gerar a imagem ou ao copiar a imagem para a área de transferência.
     */
    const handleExportToImage = useCallback((baixar) => {
        toPng(elementRef.current)
            .then((dataUrl) => {
                switch (baixar) {
                    case 'baixar':
                        snackbar.displayMessage('Baixando...');
                        const link = document.createElement('a');
                        link.download = `agenda-${paciente?.nome}-${moment().format('DD_MM_YYYY-HH_MM')}.jpeg`;
                        link.href = dataUrl;
                        link.click();
                        break;
                    case 'copiar':
                        fetch(dataUrl)
                            .then(res => res.blob())
                            .then(blob => {
                                navigator.clipboard.write([
                                    // eslint-disable-next-line no-undef
                                    new ClipboardItem({ 'image/png': blob })
                                ]).then(() => {
                                    snackbar.displayMessage('Imagem copiada para a área de transferência.');
                                }).catch((err) => {
                                    console.error('Erro ao copiar imagem: ', err);
                                });
                            })
                            .catch((err) => {
                                console.error('Erro ao gerar o blob da imagem: ', err);
                            });
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {
                console.error('Erro ao gerar imagem:', err);
            });
    }, [snackbar]);
    //#endregion
    //#region FUNCOEs
    /**
     * Retrieves a patient's information from the API.
     * 
     * @param {number} paciente_id - The ID of the patient.
     */
    const getPaciente = useCallback((paciente_id) => {
        if (paciente_id !== undefined && paciente_id !== null) {
            api.get(`api/paciente/${paciente_id}?with=diagnostico1,diagnostico2,terapia_pacientes,terapia_pacientes.plano_saude,terapia_pacientes.terapium,terapia_pacientes.especialidade`)
                .then(({ data }) => {
                    setPaciente(data);
                });
        }
    }, []);
    /**
     * Retrieves the agenda horarios from the API.
     */
    const getAgendaHorarios = useCallback(() => {
        api.get(`/api/horario-atendimento?filter[dia_semana]=${moment().weekday()}`).then(({ data }) => {
            setHorarios(data);
        });
    }, []);
    /**
     * Retrieves the agenda for a specific day.
     * 
     * @returns {void}
     */
    const getAgendaDia = useCallback(() => {
        let paciente_id = selectedPacienteId;
        api.get(`/api/agendamento?filter[paciente_id]=${paciente_id}&with=estacao,estacao.sala,terapium,especialidade`).then(({ data }) => {
            const temDias = [];
            const temHorarios = [];
            const newData = data.map(agenda => {
                agenda.dia_semana_index = moment(agenda.data_atendimento_inicial).weekday();
                let horaInicial = agenda.data_atendimento_inicial.split('T')[1];
                agenda.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');

                if (temDias.filter((inc) => inc === moment.utc(agenda.data_atendimento_inicial).weekday()).length === -0) {
                    temDias.push(moment.utc(agenda.data_atendimento_inicial).weekday());
                }

                if (temHorarios.filter(horario => horario === agenda.horarios_atendimento_id).length === 0) {
                    temHorarios.push(agenda.horarios_atendimento_id);
                }
                return agenda;
            });
            setAgendamentos(newData);
        });
    }, [selectedPacienteId]);
    /**
     * Opens the modal for displaying details of an appointment.
     *
     * @param {Object} detalhes - The details of the appointment.
     */
    const modalDetalhesDoAtendimento = useCallback((detalhes) => {
        unstable_batchedUpdates(() => {
            setAtendimentoId(detalhes.id);
            setIsModalDetalhesDoAtendimentoVisible(true);
        });
    }, []);
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        getAgendaHorarios();

        window.addEventListener('resize', () => {
            setViewSize(window.innerWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setViewSize(window.innerWidth);
            });
        };
    }, []);
    useEffect(() => {
        try {
            if (selectedPacienteId !== undefined && selectedPacienteId !== null && horarios) {
                const carregaTudo = () => {
                    getPaciente(selectedPacienteId);
                    getAgendaDia();
                };
                setloading(true);
                setPaciente(null);
                setAgendamentos([]);
                carregaTudo();
                setloading(false);
            }
        } catch (e) {
            console.error('Erro ao carregar dados do paciente - ', e);
        }
    }, [selectedPacienteId, horarios]);
    //#endregion
    //#region HTML
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -15px 0px -15px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-50px'></div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-50px text-right' onClick={() => closeModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className='d-flex justify-content-between flex-wrap'>
                    <div style={{ width: smallScreen ? 'fit-content' : '70%' }}>
                        <div className='w-100per d-flex justify-content-between'>
                            <button className='btn-padrao' type='button' onClick={() => handleExportToImage('baixar')}>
                                Baixar Agenda
                            </button>
                            <button className='btn-padrao' type='button' onClick={() => handleExportToImage('copiar')}>
                                Copiar Agenda
                            </button>
                        </div>
                        <div className='w-100per px-4 pb-4' style={{ backgroundImage: `url(${bgImage})`, }} ref={elementRef}>
                            <h4 className='text-center w-100per font-bold font-roxo pt-4 pb-2'>{paciente?.nome}</h4>
                            <table className='table-agenda'>
                                <thead className='thead'>
                                    <tr>
                                        <td key={'dias-da-semana'} align={'center'} className='tr1'>Horário</td>
                                        {diasDaSemanaArray.map(dia => (
                                            <td
                                                key={'dia-semana' + dia.value}
                                                style={{ width: baseWidth }}
                                                className={`text-center tr1${diaSemanaIndex === dia.value ? ' selecionado' : ''}`}>
                                                {dia.label}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {horarios.map((horario, horarioIdx) => {
                                        const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                        const temAgendamento = diasDaSemanaArray.some(dia => {
                                            return agendamentos.some(atendimento => atendimento.dia_semana_index === dia.value && atendimento.reserva === 0 && atendimento.hora_inicial === horasExtenso && atendimento.tipo_atendimento_id !== 4 && atendimento.tipo_atendimento_id !== 6 && atendimento.tipo_atendimento_id !== 7);
                                        });

                                        if (!temAgendamento) {
                                            return null; // Não renderiza a linha se não houver agendamentos
                                        }
                                        return (
                                            <tr key={`linha-horario-${horarioIdx}`}>
                                                <td align={'center'} className='tr-hora' style={{ height: baseHeight }}>{horasExtenso}</td>
                                                {diasDaSemanaArray.map(dia => {
                                                    const atendimentoEncontrado = agendamentos.filter(atendimento => atendimento.dia_semana_index === dia.value && atendimento.reserva === 0 && atendimento.hora_inicial === horasExtenso && atendimento.tipo_atendimento_id !== 4 && atendimento.tipo_atendimento_id !== 6 && atendimento.tipo_atendimento_id !== 7);
                                                    let content;
                                                    let div2style = [];
                                                    let legenda = [];
                                                    if (atendimentoEncontrado.length > 0) {
                                                        const atendimentoZero = atendimentoEncontrado[0];
                                                        legenda = getLegendaAgendamento(atendimentoZero, agendamentos);
                                                        div2style = {
                                                            ...styles[legenda]
                                                        };
                                                        content = <div className='table-detaild-agenda'>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoZero.estacao.sala.sala}/{atendimentoZero.estacao.estacao}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoZero?.terapium?.terapia}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoZero?.especialidade?.especialidade}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoZero?.terapeutum?.nome}
                                                            </span>
                                                        </div>;
                                                    }
                                                    return < td
                                                        onClick={() => { if (temDetalhes && atendimentoEncontrado.length > 0) modalDetalhesDoAtendimento(atendimentoEncontrado.length > 0); }}
                                                        key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                        className={`tr-vazio text-left d-grid font-size-12px p-0 ${temDetalhes ? ' cursor-zoom' : ''}`}
                                                        style={{ ...div2style, width: baseWidth, height: baseHeight }}>
                                                        {content}
                                                    </td>;
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='flex-column align-content-start max-w-300px min-w-300px' style={{ display: smallScreen ? 'none' : 'flex' }}>
                        <div className='d-flex flex-column align-items-center bg-white w-100' style={{ boxShadow: '0 0 10px 0px silver', borderRadius: 10, height: 'auto' }}>
                            <div className='d-flex flex-column w-100'>
                                <div className='d-flex justify-content-center'>
                                    <img src={(paciente === null) ? userGeneric : (paciente.foto_perfil === null || paciente.foto_perfil === '') ? userGeneric : paciente.foto_perfil}
                                        height={75} width={75} className='perfil-image rounded-circle mb-2 mt-2' alt={''} />
                                </div>
                                <div className='d-flex w-100 justify-content-start flex-column m-2'>
                                    <span>
                                        <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                            Nome:
                                        </label> {paciente?.nome}
                                    </span>
                                    <span>
                                        <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                            Idade:
                                        </label> {moment(paciente?.nascimento).diff(moment(), 'years') * -1}
                                    </span>
                                    <span>
                                        <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                            Diagnóstico:
                                        </label>
                                        <ul>
                                            <li>{paciente?.diagnostico1?.diagnostico}</li>
                                            {paciente?.diagnostico2 && <li>{paciente?.diagnostico2?.diagnostico}</li>}
                                        </ul>
                                    </span>
                                    <span>
                                        <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                            Planos:
                                        </label>
                                        <ul>
                                            {paciente?.terapia_pacientes && paciente?.terapia_pacientes.length > 0
                                                ? [...new Set(paciente.terapia_pacientes.map(terapia => terapia?.plano_saude?.nome_reduzido))]
                                                    .map((plano, index) => (
                                                        <li key={`plano-${index}`}>
                                                            <span>{plano}</span>
                                                        </li>
                                                    ))
                                                : <li>Sem terapias</li>
                                            }
                                        </ul>
                                    </span>
                                    <span>
                                        <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                            Terapias:
                                        </label>
                                        <ul>
                                            {paciente?.terapia_pacientes && paciente?.terapia_pacientes.length > 0
                                                ? paciente.terapia_pacientes
                                                    .filter((terapia, index, self) =>
                                                        index === self.findIndex((t) => (
                                                            t.terapium.terapia === terapia.terapium.terapia && t.especialidade.especialidade === terapia.especialidade.especialidade
                                                        ))
                                                    )
                                                    .map((terapia, index) => {
                                                        return (
                                                            <li key={`terapia-${index}`}>
                                                                <span>{terapia?.terapium?.terapia}</span>
                                                                <span className='mx-2'>-</span>
                                                                <span>{terapia?.especialidade?.especialidade}</span>
                                                            </li>
                                                        );
                                                    })
                                                : <li>Sem terapias</li>
                                            }
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            <div className='d-flex justify-arround col-12'>
                                <Button className='btn-padrao w-content-i mb-2' onClick={() => navigate(`/dashboard/${user.tipo_user}/pacientes/perfil/${paciente_id}`, { tagert: 'blank' })}>Cadastro</Button>
                                <Button className='btn-padrao w-content-i mb-2' onClick={() => navigate(`/dashboard/${user.tipo_user}/pacientes/prontuarios/${paciente_id}`, { tagert: 'blank' })} disabled={true}>Prontuário</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
            {
                isModalDetalhesDoAtendimentoVisible
                    ? <DetalhesDoAgendamento agendamentoId={atendimentoId} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                    : (<></>)
            }
        </>
    );
    //#endregion
});
export default AgendaPacienteModal;