import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiFillFileAdd, AiOutlineDownload } from 'react-icons/ai';
import { MdOutlineContentPaste, MdOutlineContentPasteOff } from 'react-icons/md';
import DocumentoAdministrativoModal from 'components/Modais/DocumentoAdministrativoModal';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { useAuth } from 'context/auth';
import { useSnackbar } from 'context/snackbar';
import api from 'services/api';
import { baseURL } from 'services/baseUrl';
import DocumentoAdministrativoService from 'services/DocumentoAdministrativoService';
import { Container } from './styles';

export default function Documentos() {

  const [documentoAdministrativoModalOpen, setDocumentoAdministrativoModalOpen] = useState(false);
  const { user } = useAuth();
  const [documentos, setDocumentos] = useState([]);
  const [terapeuta, setTerapeuta] = useState({});
  const snackbar = useSnackbar();

  const loadDocumentos = () => {
    DocumentoAdministrativoService.index()
      .then(({ data }) => {
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setDocumentos(data);
      });
  };

  const handleObsoleto = (e, documento) => {
    let mensagem = `Documento ${documento.nome_documento} ativado com sucesso!`;
    if (!documento.obsoleto) {
      mensagem = `Documento ${documento.nome_documento} desativado com sucesso!`;
    }
    if (documento.obsoleto === true) {
      const data = {
        obsoleto: false,
      };
      api.put(`api/documento-administrativo/${documento.id}`, data)
        .then(response => {
          snackbar.displayMessage(mensagem, 'success');
          loadDocumentos();
        })
        .catch(error => {
          snackbar.displayMessage(`Houve um erro ao ativar/desativar o documento: ${documento.nome_documento}`, 'error');
        });
    } else {
      const data = {
        obsoleto: true,
      };
      api.put(`api/documento-administrativo/${documento.id}`, data)
        .then(response => {
          snackbar.displayMessage(mensagem, 'success');
          loadDocumentos();
        })
        .catch(error => {
          snackbar.displayMessage(`Houve um erro ao ativar/desativar o documento: ${documento.nome_documento}`, 'error');
        });
    }


  };

  useEffect(() => {
    let t = JSON.parse(sessionStorage.getItem('@Neuro:user'));
    setTerapeuta(t);
    loadDocumentos();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome do documento',
        accessor: 'nome_documento',
      },
      {
        Header: 'Validade (em dias)',
        accessor: 'validade',
      },
      {
        Header: 'Prazo (em dias)',
        accessor: 'prazo',
      },
      {
        Header: 'Data Cadastro',
        accessor: 'created_at',
        Cell: ({ cell }) => (<div>{moment(cell.row.original.created_at).format('DD/MM/YYYY')}</div>)
      },
      {
        Header: 'Tipo',
        accessor: 'administrativo_ou_terapeutico',
        Cell: ({ cell }) => (
          (<div>{cell.row.original.id % 2 === 0 ? 'Administrativo' : 'Terapêutico'}</div>) //? //QUESTION?: implementar solução real
        ),
      },
      {
        Header: 'Obrigatoriedade',
        accessor: 'tipo_documento',
        Cell: ({ cell }) => (
          (<div>{cell.row.original.tipo_documento.charAt(0).toUpperCase() + cell.row.original.tipo_documento.slice(1)}</div>)
        ),
      },
      {
        Header: 'Atribuido para',
        accessor: 'atribuido_para',
        Cell: ({ cell }) => (
          (<div>{cell.row.original.atribuido_para.charAt(0).toUpperCase() + cell.row.original.atribuido_para.slice(1)}</div>)
        ),
      },
      {
        Header: 'Obsoleto',
        accessor: 'obsoleto',
        Cell: ({ cell }) => (
          (<div>{cell.row.original.obsoleto ? 'Sim' : 'Não'}</div>)
        ),
      },
      {
        Header: 'Ações',
        accessor: 'acoes',
        Cell: ({ cell }) => (
          (<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {cell.row.original.obsoleto ? <MdOutlineContentPaste style={{ cursor: 'pointer' }}
              onClick={(e) => handleObsoleto(e, cell.row.original)}
              color='#7340BD' title={'Reativar documento'}
              size={22} /> :
              <MdOutlineContentPasteOff style={{ cursor: 'pointer' }} title={'Desativar documento'}
                onClick={(e) => handleObsoleto(e, cell.row.original)} color='#7340BD'
                size={22} />}
            <a href={baseURL + `api/documento-administrativo/${cell.row.original.id}/${user.id}/download`} target='_blank'
              title={'Download do documento'} rel="noreferrer"><AiOutlineDownload color='#7340BD' size={22} /></a>
          </div>
          )
        ),
      },
    ],
    [],
  );

  return (
    <Container>
      <div>
        <button
          onClick={() => setDocumentoAdministrativoModalOpen(true)}
          style={{
            backgroundColor: '#7340BD',
            color: '#FFFFFF',

            border: 'none',
            padding: '10px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <AiFillFileAdd style={{ marginRight: 5 }} />
          Novo Documento
        </button>
      </div>
      <div>
        <TableDefaultPagination columns={columns} source={documentos} emptyPhrase={'Nenhum documento cadastrado.'} />
      </div>
      {documentoAdministrativoModalOpen &&
        <DocumentoAdministrativoModal
          loadDocumentos={loadDocumentos}
          setDocumentoAdministrativoModalOpen={setDocumentoAdministrativoModalOpen}
          onClose={() => setDocumentoAdministrativoModalOpen(false)} />}
    </Container>
  );
}
