import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #7442BF;
  border-radius: 10px;
  border: 0;
  color: #312e38;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.2, '#ff9000')};
  }
`;

export const MultiButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const MultiButtonOptions = styled.div`
  position: absolute;
  top: -100%; // Ajuste conforme necessário
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;