import React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TutorialBadge } from 'components/AjudanteInicial';
import { Dashboard } from 'helpers/dashboard.helper';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import AcessoRapido from '../../components/AcessoRapido';
import { MostradoresColoridos } from '../../components/MostradoresColoridos';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import RecadosCard from '../DashboardPadrao/RecadosCard';
import { Container, ContainerSub, GridHeader, GridRecados, Header } from './styles';
import { usePageBase } from 'context/page-base';
import BalaoDica from 'components/Baloes/BalaoDica';
import ModalBase from 'components/ModalBase';
import { Form } from '@unform/web';
import useListas from 'context/listas-de-selecao';
import InstallApp from 'components/InstallApp';
import LiveBadge from 'components/LiveBadge';
import styled from 'styled-components';
import { useWebSocket } from 'context/websocket.jsx';
import useIcons from 'context/icons';

const ChatContainer = styled.div`
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 500px;
    height: 300px;
    background-color: #fff;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    transform: ${({ minimized }) => (minimized ? 'scale(0.1)' : 'scale(1)')};
    opacity: ${({ minimized }) => (minimized ? '0' : '1')};
    pointer-events: ${({ minimized }) => (minimized ? 'none' : 'auto')};
    z-index: 5;
    padding: 0;

    .barra {
        background-color: #007bff;
        color: #fff;
        padding: 5px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .messages {
        flex: 1;
        overflow-y: auto;
        padding: 10px;
        background-color: #f9f9f9;
        .message-group {
            padding: 0px;
            margin: 5px 0;
            background-color: #e1f5fe;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            border: 1px solid #ddd;
            border-radius: 4px;

            .username{
                width: 100%;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                margin: 0px;
                border-bottom: 1px solid #ddd;
                padding: 5px;
                border-radius: 4px 4px 0 0;
            }

            .message{
                display: flex;
                width: 100%;
                padding: 10px;
            }
    
            &.greetings {
                background-color: #d1c4e9;
                text-align: center;
            }
    
            &.message-left {
                .username {
                    text-align: left;
                    background-color:rgb(156, 224, 255);
                }
                .message {
                    background-color: #e1f5fe;
                    justify-content: flex-start;
                }
            }
    
            &.message-right {
                .username {
                    text-align: right;
                    background-color:rgb(136, 226, 139);
                }
                .message {
                    background-color: #c8e6c9;
                    justify-content: flex-end;
                }
            }
        }
    }


    .message-input {
        display: flex;
        padding: 10px;
        background-color: #f1f1f1;
        border-top: 1px solid #ddd;

        input {
            flex: 1;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-right: 10px;
            outline: none;
            transition: border-color 0.3s;

            &:focus {
                border-color: #007bff;
            }
        }

        button {
            padding: 10px 15px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
`;

const MinimizeButton = styled.button`
    border: none;
    cursor: pointer;
    font-size: 20px;

    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    background: none;
    
    &:hover {
        color: #007bff;
    }
`;

const ChatBubble = styled.div`
    position: fixed;
    bottom: 40px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    transform: ${({ minimized }) => (minimized ? 'scale(1)' : 'scale(0)')};
    opacity: ${({ minimized }) => (minimized ? '1' : '0')};
    pointer-events: ${({ minimized }) => (minimized ? 'auto' : 'none')};
`;

export default function DashboardPadrao() {
    usePageBase({
        title: 'Dashboard Padrão',
        description: 'Dashboard padrão do sistema',
        hasTitle: false,
        hasSubmenu: false
    });
    //#region VARIAVEIS
    const { user } = useAuth();
    const { iconArray } = useIcons();
    const { fetchListas } = useListas([
        'diagnostico',
        'especialidade',
        'terapeuta',
        'status_atendimento',
        'sala',
        'plano_saude',
        'paciente:ativo|select=id.nome.identificador',
        'modalidade',
        'horarios_atendimento',
        'tipo_atendimento',
        'tipo_demanda',
        'terapia',
        'terapia_paciente',
    ]);
    const tipo_user = useMemo(() => user?.tipo_user?.toLowerCase(), [user]);
    const modalBaseTestRef = useRef();

    const [dashboardStructure, setDashboardStructure] = useState(null);
    const [recado, setRecado] = useState([]);
    const [mostradoresColoridos, setMostradoresColoridos] = useState({
        titulo: 'Estatísticas',
        mostradores: [],
    });
    const [acessoRapido, setAcessoRapido] = useState([]);
    const [loadingMostradores, setLoadingMostradores] = useState(false);
    const [loadingAcessos, setLoadingAcessos] = useState(false);
    const mostradorStyle = {
        borderTopLeftRadius: '5%',
        borderTopRightRadius: '5%',
        gridArea: 'mostradores',
        margin: '0',
        padding: '0'
    };

    const { messages, socketState, setSocketLink, setNewMessage, setConectaComSocket } = useWebSocket();
    const [mensagem, setMensagem] = useState('');
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [mensagens, setMensagens] = useState([]);
    const [minimized, setMinimized] = useState(true);
    //#endregion

    //#region HANDLES
    const handleEnviaMensagem = (e) => {
        if (e) e.preventDefault();
        setNewMessage({
            action: 'send_message',
            message: mensagem,
            username: nomeUsuario
        });
        setMensagem('');
    };
    const handleConectaSocket = (e) => {
        if (e) e.preventDefault();
        setSocketLink(`teste?username=${nomeUsuario}`);
        setConectaComSocket(true);
    };
    //#endregion
    //#region FUNCOES
    const onLoad = async () => {
        const tipoUserUpper = tipo_user.toUpperCase();
        const dashboard = new Dashboard(tipoUserUpper, user.id);
        await dashboard.setMostradoresInformacao();

        try {
            if (dashboard.getEnableRecados()) {
                const recadoResponse = await api.get('api/recado/get/by/group/' + tipo_user);
                setRecado(recadoResponse.data);
            }
            if (dashboard.getEnableMostradores()) {
                const mostradores = dashboard.getMostradores();
                setMostradoresColoridos({
                    titulo: 'Estatísticas',
                    mostradores: mostradores
                });
            }
            if (dashboard.getEnableAcessoRapido) {
                const acessoRapido = dashboard.getAcessoRapido();
                setAcessoRapido(acessoRapido);
            }
            setDashboardStructure(dashboard);
            setLoadingMostradores(false);
            setLoadingAcessos(false);
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        console.log('messages', messages);
        setMensagens(prevMessages => [...prevMessages, messages[messages.length - 1]]);
    }, [messages]);
    useEffect(() => {
        if (tipo_user) {
            setLoadingMostradores(true);
            setLoadingAcessos(true);
            onLoad();
            if (tipo_user === 'desenvolvedor') {
                //fetchListas();
            }
        }
    }, [tipo_user]);
    //#endregion

    //#region HTML
    return (
        <>
            <Container className='row'>
                {!dashboardStructure &&
                    <div className='col-12 d-flex'>
                        <label className="text-center all-center w-content h-content mx-auto">
                            Carregando dashboard...<br></br><br></br>
                            <ScaleLoader color='#000' loading={true} />
                        </label>
                    </div>
                }
                <ContainerSub id='containerSub'>
                    {dashboardStructure && dashboardStructure.getEnableHeader() &&
                        <GridHeader id="header">
                            <Header>
                                <label>Bem Vindo {user?.name && user?.name}!</label>
                                <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                            </Header>
                        </GridHeader>
                    }
                    {(dashboardStructure && dashboardStructure && dashboardStructure?.getEnableMostradores()) &&
                        <MostradoresColoridos mostradores={mostradoresColoridos.mostradores} style={mostradorStyle} titulo={mostradoresColoridos.titulo} id="mostradores" className='mostradores' name="mostradores" loading={loadingMostradores || mostradoresColoridos.mostradores.length === 0} />
                    }
                    {dashboardStructure?.getEnableRecados() &&
                        <>
                            <GridRecados id="recados">
                                <RecadosCard title={'Recado'} recado={recado} />
                            </GridRecados>
                            <BalaoDica
                                id={'dica_recados'}
                                elementoAncora='recados'
                                apenasUmaVez={true}
                                abertoPadrao={true}
                                titulo={'Recados Importantes'}
                            >
                                Aqui você poderia visualizar os recados importantes do sistema e da clínica, sempre fique de olho 👀.
                            </BalaoDica>
                        </>
                    }
                    {acessoRapido && dashboardStructure && dashboardStructure?.getEnableAcessoRapido() &&
                        <AcessoRapido id="acesso" name="acesso" acessos={acessoRapido} loading={loadingAcessos} />
                    }
                </ContainerSub>
                {tipo_user === 'desenvolvedor' && <>
                    <ChatContainer minimized={minimized}>
                        <div className="barra">
                            <span></span>
                            <span>Chat</span>
                            <MinimizeButton onClick={() => setMinimized(true)}>
                                {iconArray['seta-angulo-baixo']}
                            </MinimizeButton>
                        </div>
                        {socketState ? <>
                            <div className="messages">
                                {mensagens?.map((mensagem, index) => {
                                    const action = mensagem?.action;
                                    const username = mensagem?.username;
                                    const message = mensagem?.message;

                                    switch (action) {
                                        case 'send_message':
                                            return (
                                                <div key={index} className={`message-group ${username === nomeUsuario ? 'message-right' : 'message-left'}`}>
                                                    <span className='username'>{username}</span>
                                                    <span className='message'>{message}</span>
                                                </div>
                                            );
                                        case 'greeting':
                                            return (
                                                <div key={index} className="message-group greetings">
                                                    {message}
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                            </div>
                            <form className="message-input" onSubmit={handleEnviaMensagem}>
                                <input
                                    type="text"
                                    value={mensagem}
                                    onChange={(e) => setMensagem(e.target.value)}
                                    placeholder="Digite sua mensagem..."
                                />
                                <button type={'submit'} onClick={handleEnviaMensagem}>Enviar</button>
                            </form>
                        </>
                            : <div className="message">
                                <span>Conectar ao Chat</span>
                                <form className="message-input" onSubmit={handleConectaSocket}>
                                    <input
                                        type="text"
                                        value={nomeUsuario}
                                        onChange={(e) => setNomeUsuario(e.target.value)}
                                        placeholder="Digite seu nome de usuário..."
                                    />
                                    <button type={'submit'} onClick={handleConectaSocket}>Conectar</button>
                                </form>
                            </div>}
                    </ChatContainer>
                    <ChatBubble minimized={minimized} onClick={() => setMinimized(false)}>
                        💬
                    </ChatBubble>

                    <button className='btn-padrao w-content-i' onClick={() => modalBaseTestRef.current.open()}>Abrir modal teste</button>
                    <ModalBase ref={modalBaseTestRef} title='Teste de modal' hasCloseButton={true} onOpen={() => alert('Abriu')} onClose={() => alert('Fechou')}>
                        <Form>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="name">Nome:</label>
                                    <input type="text" id="name" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Mensagem:</label>
                                    <textarea id="message" className="form-control"></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">Enviar</button>
                            </div>
                        </Form>
                    </ModalBase>
                    {/* <InstallApp /> */}
                </>}
            </Container>
            <LiveBadge sincronizado={socketState} />
            {dashboardStructure &&
                <TutorialBadge
                    steps={
                        [
                            {
                                title: 'Bem Vindo',
                                text: <div className="d-flex flex-col">
                                    <span className="font-12px font-cinza">(Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial)</span>
                                    <span>Bem vindo ao Neuro Sistema! Passaremos por algumas etapas, para você aprender como utilizar o sistema!</span>
                                </div>
                            },
                            { title: 'Informações Básicas', selector: '#header', text: 'Aqui você tem algumas informações básicas!' },
                            { title: 'Indicadores Importantes', selector: '#mostradores', text: 'Nesse local, você pode ver alguns indicadores importantes, passe o mouse por cima, para ver seus detalhes!' },
                            { title: 'Acesso Rápido', selector: '#acesso', text: 'Temos aqui alguns links de Acesso Rápido, clique para acessa-los!' },
                            { title: 'Recados Importantes', selector: '#recados', text: 'Nesse local, você pode ver alguns recados importantes, sempre se atente a isso!' },
                        ]
                    }
                    hasTopicos={true}
                    standartFinal={true}
                />
            }
        </>
    );
}