import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HeaderContent, MenuIcon } from './styles';
import '../../styles/global.css';
import { useAuth } from '../../context/auth';
import FuncoesUsuario from '../FuncoesUsuario';
import HeaderTerapeuta from '../HeaderTerapeuta';
import { MdMenu } from 'react-icons/md';
import NavLink from './NavLink';
import SubMenu from '../SubMenu';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import version from 'version';
import UpdateNotes from 'components/UpdateNotes';
import UpdateNotesWraper from 'components/UpdateNotes';

/**
 * Header component that displays the navigation bar and handles user interactions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} [props.onSubMenuToggle=() => {}] - Callback function to handle submenu toggle state.
 *
 * @returns {JSX.Element} The rendered Header component.
 *
 * @example
 * <Header onSubMenuToggle={handleSubMenuToggle} />
 *
 * @description
 * The Header component is responsible for rendering the navigation bar, handling user interactions,
 * and managing the state of the submenu. It includes various useEffect hooks to handle side effects
 * such as checking mobile view, setting document title, and loading user permissions.
 *
 * @function
 * @name Header
 */
const Header = ({ onSubMenuToggle = () => { } }) => {
    //#region VARIAVEIS
    const usuario = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [openMenu, setOpenMenu] = useState(false);
    const [subMenuLinks, setSubMenuLInks] = useState(null);
    const [subLinks, setSubLinks] = useState(null);
    const [headerItens, setHeaderItens] = useState(null);
    const [hasSubMenu, setHasSubMenu] = useState(false);
    const [tituloAtual, setTituloAtual] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const [quantidadeDeItens, setQuantidadeDeItens] = useState(0);
    const [permissoes_por_grupo, setListaDePermissoes] = useState();
    const [isSubMenuMinimized, setIsSubMenuMinimized] = useState(false);

    const pathArray = useMemo(() => location?.pathname?.split('/'), [location?.pathname]);
    const atualLocal = useMemo(() => (pathArray[3] !== undefined ? pathArray[3] : ''), [pathArray]);
    const vetorPathname = useMemo(() => pathArray, [pathArray]);
    //#endregion

    //#region FUNCOES
    // Debounce function
    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    // Responsividade melhorada com window.matchMedia
    const checkMobileView = useCallback(() => {
        const isMobile = window.matchMedia('(max-width: 768px)').matches;
        setIsMobileView(isMobile);
        setOpenMenu(!isMobile);
    }, []);

    /**
     * Generates navigation items based on user permissions and updates the header state.
     *
     * This function creates a list of navigation links (`links`) and sub-links (`subLinksLocal`) 
     * based on the user's permissions (`permissoes_por_grupo`). It also sets various states 
     * such as `hasSubMenu`, `tituloAtual`, `quantidadeDeItens`, `subLinks`, and `headerItens`.
     *
     * @function
     * @returns {void}
     */
    const getGenericItens = useCallback(() => {
        let links = [];
        let saveLinks = [];
        let tipo_user = usuario?.user?.route_tipo_user;
        let subLinksLocal = [];
        let quantidadeItens = 0;

        links.push(
            <NavLink key={'home/dashboard/' + tipo_user + '/'}
                link={'/dashboard/' + tipo_user + '/'}
                name={'Menu Inicial'}
                icon={'home'}
                atualLocal={atualLocal}
                notificacao={0} />
        );
        if (permissoes_por_grupo) {
            permissoes_por_grupo.forEach((el) => {
                if (!saveLinks.includes(el.link) && el.linkable) {
                    const link = '/dashboard/' + tipo_user + el.link;
                    const isPage = '/' + atualLocal;
                    const page = '/' + el.link.split('/')[1];

                    if (isPage === page) {
                        setHasSubMenu(el.has_submenu);
                        subLinksLocal.push({ label: el.title, url: link, position: el.submenu_position, icon: el.icon });
                    }

                    if (link === location.pathname) setTituloAtual(el.title);

                    if (!el.sublinkable) {
                        saveLinks.push(el.link);
                        const listagemDeSublinks =
                            el.permissions?.length > 0
                                ? el.permissions.filter(item => (item.linkable && permissoes_por_grupo.some((some) => some.link === item.link)))
                                : permissoes_por_grupo.filter(item => (item.link && `/${link.split('/')[3]}` === `/${item.link.split('/')[1]}` && item.linkable && item.sublinkable));
                        const subLinks = [];
                        subLinks.push({ label: el.title, link: link, position: el.submenu_position, icon: el.icon, position: -1 });
                        listagemDeSublinks.forEach(item => subLinks.push({ label: item.title, link: '/dashboard/' + tipo_user + item.link, position: item.submenu_position, icon: item.icon }));

                        const subLinksFinal = subLinks.length > 1 ? subLinks : null;
                        quantidadeItens++;
                        links.push(
                            <NavLink
                                key={el.title + link}
                                link={link}
                                name={el.title}
                                icon={el.icon}
                                atualLocal={atualLocal}
                                notificacao={0}
                                dropDownList={subLinksFinal}
                                clickable={subLinksFinal === null}
                            />
                        );
                    }
                }
            });
        }

        setQuantidadeDeItens(usuario?.user?.route_tipo_user === 'terapeuta' ? quantidadeItens + 5 : quantidadeItens);
        setSubLinks(subLinksLocal);
        setHeaderItens(links);
    }, [usuario, permissoes_por_grupo, atualLocal, location.pathname]);

    // Executa a inicialização da página
    /**
     * Asynchronous function to handle user access validation and load generic items.
     * 
     * This function checks if the logged-in user is an auditor and verifies if their access has expired.
     * If the access is expired, it displays an alert and navigates to the home page.
     * After the validation, it loads generic items.
     * 
     * @async
     * @function onLoad
     * @returns {Promise<void>} A promise that resolves when the function completes.
     */
    const onLoad = useCallback(async () => {
        if (usuario?.user?.route_tipo_user === 'auditor') {
            const dataAtual = new Date();
            const dataTeste = new Date(usuario?.user.limite_acesso);

            if (dataAtual > dataTeste) {
                Swal.fire('Acesso expirado!', 'Parece que seu acesso expirou, converse com a coordenação para receber a liberação, e a nova senha.', 'error')
                    .then(() => navigate('/'));
            }
        }

        await getGenericItens();
    }, [usuario, navigate, getGenericItens]);
    //#endregion

    //#region HANDLES
    // Função para lidar com toggle do submenu
    const handleSubMenuToggle = useCallback((isMinimized) => {
        setIsSubMenuMinimized(isMinimized);
    }, []);
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        onSubMenuToggle(isSubMenuMinimized);
    }, [isSubMenuMinimized]);

    useEffect(() => {
        if (!usuario?.user) {
            localStorage.setItem('old_route', window.location.pathname);
            navigate('/');
        }
        if (permissoes_por_grupo) {
            onLoad();
            checkMobileView();
        }

        const handleLoad = () => {
            sessionStorage.removeItem('@Neuro');
        };

        window.addEventListener('load', handleLoad);
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, [location, usuario, permissoes_por_grupo]);

    // UseEffect para setar lista de permissões
    useEffect(() => {
        if (usuario?.user?.permissoes_por_grupo?.length > 0) {
            setListaDePermissoes(
                usuario.user.permissions
                    ? usuario.user.permissions
                    : usuario.user.permissoes_por_grupo[0].permissions
            );
        }
    }, [usuario]);

    useEffect(() => {
        checkMobileView();
    }, [quantidadeDeItens]);

    useEffect(() => {
        document.title = tituloAtual === null ? 'Neuro Sistema' : 'Neuro Sistema - ' + tituloAtual;
    }, [tituloAtual]);

    useEffect(() => {
        if (subLinks) {
            const subLinksLocal = subLinks.sort((a, b) => a.position - b.position);
            setSubMenuLInks(subLinksLocal);
        }
    }, [subLinks]);

    useEffect(() => {
        const debouncedCheckMobileView = debounce(checkMobileView, 200);
        window.addEventListener('resize', debouncedCheckMobileView);
        return () => {
            window.removeEventListener('resize', debouncedCheckMobileView);
        };
    }, [quantidadeDeItens]);

    useEffect(() => {
        const handleResize = debounce(() => {
            checkMobileView();
        }, 200);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //#endregion

    //#region HTML
    return (
        <>
            <nav className={'sys-navbar d-flex justify-between align-items-center'} style={{ transition: 'all .5s' }} id='navAll'>
                <div className='m-0 p-0 flex-row d-flex align-items-end'>
                    <a key={0} href={'/dashboard/' + usuario?.user?.route_tipo_user + '/'} className='nav-logo'>
                        <img style={{ maxWidth: '150px', height: 'auto' }} src={'/logo.png'} alt='Neuro' />
                    </a>
                    <span className='font-branco-i pb-4'>
                        {version()}
                        <UpdateNotesWraper />
                    </span>
                </div>
                <div className='m-0 p-0 d-flex flex-wrap justify-end w-auto'>
                    {isMobileView && <MenuIcon className={openMenu ? 'open' : ''}>
                        <MdMenu size={30} onClick={() => setOpenMenu(!openMenu)} />
                    </MenuIcon>}
                    {openMenu && (
                        <HeaderContent
                            id='navContent'
                            className={`${isMobileView ? 'w-300px position-absolute ns-two-per-line is-mobile  ' : ''}nav-collapse bg-roxo flex-wrap`}
                            style={isMobileView ? { marginTop: '60px', left: '50%', transform: 'translateX(-50%)' } : {}}
                        >
                            {usuario?.user?.route_tipo_user === 'terapeuta'
                                ? <HeaderTerapeuta />
                                : headerItens
                            }
                        </HeaderContent>
                    )}
                </div>
                <FuncoesUsuario key={'funcoes-usuario-1'} className='m-0 p-0 nav-usuario' menu={<></>} isMobileView={isMobileView} />
            </nav>
            <div className='gamb'></div>
            {hasSubMenu && subLinks && subLinks?.length > 0 && (
                <SubMenu
                    selectedItem={vetorPathname?.length > 0 ? vetorPathname[vetorPathname.length - 1] : ''}
                    links={subMenuLinks}
                    sideMode={vetorPathname?.length > 0 && (vetorPathname[3] === 'controle_diario' || vetorPathname[3] === 'configuracoes')}
                    onToggleMinimized={handleSubMenuToggle}
                />
            )}

        </>
    );
    //#endregion
};

export default Header;
