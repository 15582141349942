import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { FaArrowLeft, FaArrowRight, FaCalendarDay, FaCalendarWeek } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import DetalhesDoAgendamento from '../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { Container, tr0, trHora } from './styles';

const alturaMenor = 60;
const largura = '15%';
const larguraSemanal = '14%';

const EspacoAgendamento = styled.div`
    font-size: 9px;
    color: #fff;
    padding: 0px;
    border-radius: 6px;
    border-color: #F5F7FA;
    border-width: 0.1px;
    border-style: solid;
    cursor: zoom-in;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: 0.3s;

    &.vazio{
        background-color: #fff;
        margin-top: 0px;
        border: 1px solid #F5F7FA;

        .faixa-tipo{
            background-color: #fff;
        }
    }

    &.modo-dia {
        width: 100%;
        height: ${alturaMenor}px;
        min-height: ${alturaMenor}px;
        max-height: ${alturaMenor}px;
    }
    &.modo-semanal {
        min-width: ${larguraSemanal};
        max-width: ${larguraSemanal};
        width: ${larguraSemanal};
        height: ${alturaMenor}px;
        min-height: ${alturaMenor}px;
        max-height: ${alturaMenor}px;
    }
    
    .faixa-tipo{
        background-color: #35D058;
        height: 100%;
        width: 15px;
    }
    .coluna-dados{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 5px;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    &.aguardando-terapeuta {
        .faixa-tipo{
            background-color: #2781DA;
        }
    }

    &:hover {
        background-color: #f0f0f0;
    }
`;

const Navegador = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
    background-color: #F5F7FA;
    border-radius: 6px;
    margin-top: 0px;
    margin-bottom: 10px;

    .nome-dia{
        font-size: 20px;
        font-weight: bold;
    }
    
    button {
        background-color: #fff;
        border: none;
        cursor: pointer;
        padding: 5px;
        margin: 0px 5px;
        border-radius: 6px;
        transition: 0.3s;
    }
    button:hover {
        background-color: #f0f0f0;
    }
`;

const SemanaHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    background-color: #F5F7FA;
    border-radius: 6px;
    margin-bottom: 10px;
`;

const DiaSemana = styled.div`
    width: ${largura};
    text-align: center;
    font-weight: bold;
`;

/**
 * Componente AgendaPacienteResponsavel
 * 
 * Este componente exibe a agenda de um paciente para o responsável.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {Object} ref - Referência do componente.
 * 
 * @returns {JSX.Element} O componente AgendaPacienteResponsavel.
 * 
 * @component
 * 
 * @example
 * return (
 *   <AgendaPacienteResponsavel />
 * )
 * 
 * @requires useAuth - Hook para obter informações do usuário autenticado.
 * @requires useParams - Hook para obter parâmetros da URL.
 * @requires useSnackbar - Hook para exibir mensagens de snackbar.
 * @requires useState - Hook para gerenciar o estado do componente.
 * @requires useEffect - Hook para efeitos colaterais no componente.
 * @requires api - Instância do axios para fazer requisições à API.
 * @requires moment - Biblioteca para manipulação de datas e horários.
 * @requires Container - Componente de layout.
 * @requires Link - Componente de navegação.
 * @requires DetalhesDoAgendamento - Componente para exibir detalhes do agendamento.
 * 
 * @function modalDetalhesDoAtendimento - Função para abrir o modal de detalhes do atendimento.
 * @function abreviarNome - Função para abreviar o nome completo.
 * @function carregaPaciente - Função para carregar os dados do paciente.
 * 
 * @typedef {Object} Paciente - Objeto que representa um paciente.
 * @property {number} id - ID do paciente.
 * @property {string} nome - Nome do paciente.
 * @property {string} foto_perfil - URL da foto de perfil do paciente.
 * @property {Date} nascimento - Data de nascimento do paciente.
 * @property {Object} diagnostico1 - Primeiro diagnóstico do paciente.
 * @property {Object} diagnostico2 - Segundo diagnóstico do paciente.
 * 
 * @typedef {Object} Agendamento - Objeto que representa um agendamento.
 * @property {number} id - ID do agendamento.
 * @property {number} dia_semana - Dia da semana do agendamento.
 * @property {string} hora_agendamento - Hora do agendamento.
 * @property {number} reserva - Indica se o agendamento é uma reserva.
 * @property {number} tipo_atendimento_id - Tipo de atendimento do agendamento.
 * @property {string} sala - Sala do atendimento.
 * @property {string} estacao - Estação do atendimento.
 * @property {string} terapeuta_nome - Nome do terapeuta responsável pelo atendimento.
 */
export default function NewAgendaPacienteResponsavel(props, ref) {
    const diaSemanaIndex = moment().weekday();
    const { user } = useAuth();
    const { id } = useParams();
    const snackbar = useSnackbar();
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [horarios, setHorarios] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [atendimentoId, setAtendimentoId] = useState();
    const [currentDayIndex, setCurrentDayIndex] = useState(diaSemanaIndex);
    const [viewMode, setViewMode] = useState('day'); // 'day' or 'week'

    const LongLabelBase = (props) => {
        return (
            <span>
                <b className='font-12px'>{props.dayName[0]}</b>
                <span className='font-8px'>{props.dayName.slice(1, props.dayName.length)}</span>
            </span>
        );
    }

    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'S',
            longLabel: 'Segunda-Feira',
        },
        {
            value: 2,
            label: 'T',
            longLabel: 'Terça-Feira',
        },
        {
            value: 3,
            label: 'Q',
            longLabel: 'Quarta-Feira',
        },
        {
            value: 4,
            label: 'Q',
            longLabel: 'Quinta-Feira',
        },
        {
            value: 5,
            label: 'S',
            longLabel: 'Sexta-Feira',
        },
        {
            value: 6,
            label: 'S',
            longLabel: 'Sábado',
        },
    ];

    const handlePreviousDay = () => {
        setCurrentDayIndex((prevIndex) => (prevIndex === 0 ? diasDaSemanaArray.length - 1 : prevIndex - 1));
    };

    const handleNextDay = () => {
        setCurrentDayIndex((prevIndex) => (prevIndex === diasDaSemanaArray.length - 1 ? 0 : prevIndex + 1));
    };

    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === 'day' ? 'week' : 'day'));
    };

    //#region FUNCOES
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    //#endregion
    //#region CARREGADORES
    const carregaPaciente = () => {
        api.get(`/api/agenda/paciente/${id}`)
            .then((response) => {
                let paciente = response.data;
                const { agendamentos, lista_horarios } = response.data;
                if (response.status === 200) {
                    setHorarios(lista_horarios);
                    setAgendamentos(agendamentos);
                }
                setPaciente(paciente);
            })
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao carregar paciente', 'error');
            });
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        carregaPaciente();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Container>
            <div className='d-flex justify-content-center'>
                {paciente?.nome}
            </div>
            {viewMode === 'week' && (
                <SemanaHeader>
                    <DiaSemana key={'espaco'}></DiaSemana>
                    {diasDaSemanaArray.map((dia) => (
                        <DiaSemana key={dia.value}>{dia.label}</DiaSemana>
                    ))}
                </SemanaHeader>
            )}
            {/* AGENDA */}
            <ul className='w-100 m-0 p-0 overflow-y-no-scroll h-100vh-170px'>
                {horarios.map((horario, horarioIdx) => {
                    const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm:ss');
                    const horasPrint = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                    return (
                        <li className='d-flex' key={`linha-horario-${horarioIdx}`}>
                            <div align={'center'} style={trHora}>{horasPrint}</div>
                            {
                                agendamentos !== null
                                    ? agendamentos.map(atendimento => {
                                        if (viewMode === 'day') {
                                            if (Number(atendimento.dia_semana) === Number(diasDaSemanaArray[currentDayIndex].value) && atendimento.hora_agendamento === horasExtenso && Number(atendimento.reserva) === 0) {
                                                return (
                                                    <EspacoAgendamento
                                                        className={`bg-roxo-dark ${atendimento?.tipo_atendimento_id === 8 ? 'aguardando-terapeuta' : ''} modo-dia`} onClick={() => modalDetalhesDoAtendimento(atendimento)}
                                                        key={`linha-dia-${diasDaSemanaArray[currentDayIndex].value}-horario-${horarioIdx}`}>
                                                        <div className='faixa-tipo'></div>
                                                        <div className='coluna-dados'>
                                                            <span> Sala: {atendimento?.sala} </span>
                                                            <span>Estação: {atendimento?.estacao}</span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimento?.terapeuta_nome}
                                                            </span>
                                                        </div>
                                                    </EspacoAgendamento>
                                                );
                                            }
                                        } else {
                                            if (atendimento.hora_agendamento === horasExtenso && Number(atendimento.reserva) === 0) {
                                                return (
                                                    <EspacoAgendamento
                                                        className={`bg-roxo-dark ${atendimento?.tipo_atendimento_id === 8 ? 'aguardando-terapeuta' : ''} modo-semanal`} onClick={() => modalDetalhesDoAtendimento(atendimento)}
                                                        key={`linha-dia-${atendimento.dia_semana}-horario-${horarioIdx}`}>
                                                        <div className='faixa-tipo'></div>
                                                        <div className='coluna-dados'>
                                                            <span> Sala: {atendimento?.sala} </span>
                                                            <span>Estação: {atendimento?.estacao}</span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimento?.terapeuta_nome}
                                                            </span>
                                                        </div>
                                                    </EspacoAgendamento>
                                                );
                                            }
                                        }
                                        return null;
                                    })
                                    : <></>
                            }
                            {
                                agendamentos.filter(atendimento =>
                                    (viewMode === 'day'
                                        ? Number(atendimento.dia_semana) === Number(diasDaSemanaArray[currentDayIndex].value)
                                        : true) &&
                                    atendimento.hora_agendamento === horasExtenso &&
                                    Number(atendimento.reserva) === 0
                                ).length === 0 && (
                                    <EspacoAgendamento className='vazio modo-dia'>
                                        <div className='faixa-tipo'></div>
                                        <div className='coluna-dados'>
                                            <span>Sem agendamento</span>
                                        </div>
                                    </EspacoAgendamento>
                                )
                            }
                        </li>
                    );
                })}
            </ul>
            {/* CONTROLES DE PAGINAÇÃO */}
            <Navegador className='d-flex justify-content-between align-items-center'>
                <button className='btn-padrao quadrado-50px' onClick={handlePreviousDay}><FaArrowLeft /></button>
                <div className='nome-dia'>
                    {viewMode === 'day' ? diasDaSemanaArray[currentDayIndex].longLabel : 'Semana'}
                </div>
                <button className='btn-padrao quadrado-50px' onClick={handleNextDay}><FaArrowRight /></button>
            </Navegador>
            <div className='d-flex justify-content-between'>
                <button onClick={toggleViewMode}>
                    {viewMode === 'day' ? <FaCalendarWeek /> : <FaCalendarDay />}
                </button>
            </div>
            {isModalDetalhesDoAtendimentoVisible
                ? <DetalhesDoAgendamento
                    agendamentoId={atendimentoId}
                    onClose={() => { setIsModalDetalhesDoAtendimentoVisible(false); carregaPaciente(); }}
                /> : (<></>)}
        </Container>
    );
    //#endregion
}