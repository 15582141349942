import axios from 'axios';
import { baseURL } from './baseUrl';
import React, { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment';

const token = sessionStorage.getItem('@Neuro:access_token');

const api = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // TODO: Retirar envio de token no header, pra substituir por cookies com HTTPOnly
        'Authorization': `Bearer ${token}`, 
        //'X-App-User-Agent': 'front-d78d2b07227a375066a68d5106ab8607-agent',
    },
});

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const [networkLogs, setNetworkLogs] = useState([]);
    const [activeRequests, setActiveRequests] = useState(0);

    useEffect(() => {
        if (networkLogs.length > 0) {
            setNetworkLogs([]);
            setActiveRequests(0);
        }
    }, [window.location.href]);

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(request => {
            setActiveRequests(prev => prev + 1);
            setNetworkLogs(prevLogs => [
                ...prevLogs,
                {
                    type: 'request',
                    method: request.method,
                    url: request.url,
                    params: request.params,
                    timestamp: moment().format('DD/MM/YYYY HH:mm:ss'),
                    // Remova informações sensíveis do log
                    // ...request
                }
            ]);
            return request;
        });

        const responseInterceptor = api.interceptors.response.use(
            response => {
                setActiveRequests(prev => prev - 1);
                setNetworkLogs(prevLogs => [
                    ...prevLogs,
                    {
                        type: 'response',
                        method: response.config.method,
                        url: response.config.url,
                        params: response.config.params,
                        duration: response.duration,
                        timestamp: moment().format('DD/MM/YYYY HH:mm:ss'),
                        // Remova informações sensíveis do log
                        // ...response
                    }
                ]);
                return response;
            },
            error => {
                setActiveRequests(prev => prev - 1);
                setNetworkLogs(prevLogs => [
                    ...prevLogs,
                    {
                        type: 'error',
                        method: error.config?.method,
                        url: error.config?.url,
                        params: error.config?.params,
                        timestamp: moment().format('DD/MM/YYYY HH:mm:ss'),
                        // Remova informações sensíveis do log
                        // ...error
                    }
                ]);
                if (error.response && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = '/';
                }
                return Promise.reject(error);
            }
        );

        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return (
        <ApiContext.Provider value={{ api, networkLogs, activeRequests }}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => useContext(ApiContext);

export default api;