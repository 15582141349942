import styled from 'styled-components';

export const BalaoDicaStyle = styled.div`
    position: absolute;
    background-color: #ffffe0;
    border: 1px solid #ccc;
    border-radius: 30px;
    z-index: 9998;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 20px 20px 20px;
    user-select: none;
    cursor: help;

    transition: visibility 0.5s, opacity 0.5s, transform 0.3s;

    &:hover {
        transform: scale(1.1);
    }

    animation: pulse-yellow 1.5s ease-in-out;
    @keyframes pulse-yellow {
        0% {
            box-shadow: 0 0 0 0 rgba(255, 255, 100, 0.9);
        }
        70% {
            box-shadow: 0 0 0 60px rgba(255, 255, 100, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(255, 255, 100, 0);
        }
    }
`;

export const BotaoFechar = styled.button`
    position: absolute;
    top: 5;
    right: 15;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-size: 12px;
    color: #000;
    &:hover {
        background-color: #f0f0f0;
    }
`;

export const ConteudoBalao = styled.p`
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #000;
    line-height: 1.5;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-word;
    word-break: break-word;
    max-width: 300px;
`;

export const TituloBalao = styled.h3`
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin: 0 0 0 0px;
    padding: 0;
    margin-bottom: 10px;
    text-align: left;
`;

export const BalaoDicaSeta = styled.div`
    position: absolute;
    z-index: 9999;
    color: #ffffe0;
    transform: translateX(-50%) rotate(-34deg);

    transition: visibility 0.5s, opacity 0.5s;
`;

export const SvgSeta = styled.svg`
    position: absolute;
    width: 16px;
    height: 30px;
    fill: currentColor;
    stroke: #ccc;
`;