//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Button from 'components/Button';
import Carregamento from 'components/Carregamento';
import Input from 'components/Input';
import ComentariosModal from 'components/Modais/Comentarios';
import Select from 'components/Select';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import { useSnackbar } from 'context/snackbar';
import useStatusAtendimentosData from 'context/status-atendimento';
import useTerapeutasData from 'context/terapeuta';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { BsEye, BsSearch } from 'react-icons/bs';
import { FaBroom, FaSave } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';
//#endregion

//#region ESTILOS
const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;
//#endregion

/**
 * Componente AtendimentosEGlosas.
 *
 * Este componente é responsável por exibir e gerenciar a tela de atendimentos e glosas.
 * Permite visualizar os atendimentos, aplicar filtros, glosar manualmente um atendimento e salvar alterações.
 *
 * @component
 * @returns {JSX.Element} O componente AtendimentosEGlosas.
 *
 * @example
 * <AtendimentosEGlosas />
 *
 * @description
 * O componente utiliza diversos hooks e funções para gerenciar o estado e as ações da tela.
 * Inclui filtros por data, paciente e terapeuta, além de permitir a atualização e salvamento dos atendimentos.
 *
 * @hook
 * @name usePageBase
 * @description Hook para configurar a base da página, incluindo título e descrição.
 *
 * @hook
 * @name useSnackbar
 * @description Hook para exibir mensagens de feedback ao usuário.
 *
 * @hook
 * @name useStatusAtendimentosData
 * @description Hook para buscar e gerenciar os dados de status dos atendimentos.
 *
 * @hook
 * @name usePacienteData
 * @description Hook para buscar e gerenciar os dados dos pacientes.
 *
 * @hook
 * @name useTerapeutasData
 * @description Hook para buscar e gerenciar os dados dos terapeutas.
 *
 * @hook
 * @name useCallback
 * @description Hook para memorizar funções e evitar recriações desnecessárias.
 *
 * @hook
 * @name useMemo
 * @description Hook para memorizar valores e evitar recalculações desnecessárias.
 *
 * @hook
 * @name useEffect
 * @description Hook para executar efeitos colaterais em componentes funcionais.
 *
 * @hook
 * @name useRef
 * @description Hook para criar referências mutáveis que persistem entre renderizações.
 *
 * @param {Object} atendimento - Objeto contendo os dados do atendimento.
 * @param {string} atendimento.terapeuta_nome - Nome do terapeuta.
 * @param {number} atendimento.paciente_id - ID do paciente.
 * @param {string} atendimento.paciente_identificador - Identificador do paciente.
 * @param {string} atendimento.paciente_nome - Nome do paciente.
 * @param {string} atendimento.terapia_nome - Nome da terapia.
 * @param {string} atendimento.especialidade_nome - Nome da especialidade.
 * @param {string} atendimento.nome_reduzido - Nome reduzido do plano de saúde.
 * @param {Object} atendimento.terapia - Objeto contendo informações da terapia.
 * @param {string} atendimento.terapia.terapia - Nome da terapia.
 * @param {Object} atendimento.especialidade - Objeto contendo informações da especialidade.
 * @param {string} atendimento.especialidade.especialidade - Nome da especialidade.
 * @param {string} atendimento.sala - Sala do atendimento.
 * @param {Object} atendimento.estacao - Objeto contendo informações da estação.
 * @param {string} atendimento.estacao.estacao - Nome da estação.
 * @param {boolean} atendimento.reposicao - Indica se é uma reposição.
 * @param {Object} atendimento.tipo_atendimento - Objeto contendo informações do tipo de atendimento.
 * @param {string} atendimento.tipo_atendimento.tipo_atendimento - Tipo de atendimento.
 * @param {number} atendimento.id - ID do atendimento.
 * @param {string} atendimento.sugestao_terapeuta_name - Nome da sugestão do terapeuta.
 * @param {string} atendimento.sugestao_recepcao_name - Nome da sugestão da recepção.
 * @param {number} atendimento.sugestao_terapeuta - Sugestão do terapeuta.
 * @param {number} atendimento.sugestao_recepcao - Sugestão da recepção.
 * @param {boolean} atendimento.liberado - Indica se o atendimento está liberado.
 * @param {boolean} atendimento.checkin - Indica se o check-in foi realizado.
 * @param {string} atendimento.data_atendimento_inicial - Data e hora inicial do atendimento.
 * @param {string} atendimento.data_atendimento_final - Data e hora final do atendimento.
 * @param {string} atendimento.comentario - Comentário do atendimento.
 * @param {boolean} atendimento.executado - Indica se o atendimento foi executado.
 * @param {string} atendimento.codigo_1 - Código 1 do atendimento.
 * @param {string} atendimento.codigo_2 - Código 2 do atendimento.
 */
const AtendimentosEGlosas = () => {
    const pageBase = usePageBase({
        title: 'Atendimentos e Glosas',
        description: 'Tela onde é possível visualizar os atendimentos e glosas. Além de glosar manualmente um atendimento.',
        hasTitle: true,
        hasSubMenu: false
    });
    //#region VARIAVEIS
    const snackbar = useSnackbar();
    const { navigator } = React.useContext(UNSAFE_NavigationContext);
    const modalComentariosRef = useRef();
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const filterDefault = {
        data_inicio: '',
        data_fim: '',
        paciente_id: '',
        terapeuta_id: ''
    };
    const [filters, setFilters] = useState(filterDefault);
    const statusDeGlosaOptions = [
        { label: 'Pendente', value: 0 },
        { label: 'Realizada', value: 1 },
        { label: 'Glosa Auditoria', value: 2 },
        { label: 'Glosa Aceita', value: 3 },
        { label: 'Glosa Negada', value: 4 },
        { label: 'Glosa Interna', value: 5 },
    ];
    const [filtroAlterado, setFiltroAlterado] = useState(false);
    const [dataAlterado, setDataAlterado] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    const memoAtendimentos = useMemo(() => atendimentos, [atendimentos]);
    const [atendimentosAlterados, setAtendimentosAlterados] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const memoAtendimentosOriginal = useMemo(() => atendimentosOriginal, [atendimentosOriginal]);
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const memostatusAtendimentosOptions = useMemo(() => statusAtendimentosOptions, [statusAtendimentosOptions]);
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const [pacienteId, setPacienteId] = useState('');
    const [terapeutaId, setTerapeutaId] = useState('');
    const [salvando, setSalvando] = useState(false);
    const [loading, setloading] = useState(false);
    const [pendenteDeSave, setPendenteDeSave] = useState(false);
    //#endregion

    //#region FUNCOES
    /**
     * Filtra a lista de atendimentos com base nos dados fornecidos ou na lista original de atendimentos.
     * 
     * @function
     * @param {Array|null} data - Dados opcionais para filtrar a lista de atendimentos. Se não fornecido, usa a lista original de atendimentos.
     * @returns {void}
     */
    const filtrar = useCallback((data = null) => {
        const atendimentosLocal = data ? data : memoAtendimentosOriginal;
        const filteredAtendimentos = atendimentosLocal.filter((item) => {
            return true;
        });
        setAtendimentos(filteredAtendimentos);
        setFiltroAlterado(false);
    }, [memoAtendimentosOriginal]);

    /**
     * Função para carregar atendimentos com base nos filtros fornecidos.
     * Utiliza a API para buscar os dados filtrados e atualiza o estado do componente.
     *
     * @function carregarAtendimentos
     * @async
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando os atendimentos são carregados.
     *
     * @example
     * // Exemplo de uso
     * carregarAtendimentos();
     */
    const carregarAtendimentos = useCallback(async () => {
        setloading(true);
        setAtendimentos([]);
        const obj = { ...filters, duplica_alteracoes: false };
        const response = await api.post('api/atendimento/relatorio/filtrado/puro', obj);
        const data = response?.data;
        setAtendimentosOriginal(data);
        filtrar(data);
        setloading(false);
        setDataAlterado(false);
    }, [dataInicio, dataFim, filtrar, filters]);
    //#endregion

    //#region HANDLES
    /**
     * Função de callback para o evento de clique no botão de consulta.
     * Verifica se os parâmetros de consulta (dataInicio, dataFim, pacienteId, terapeutaId) estão vazios.
     * Se todos os parâmetros estiverem vazios, exibe um alerta ao usuário informando que todos os atendimentos serão consultados.
     * Caso existam alterações não salvas, pergunta ao usuário se deseja sair sem salvar.
     * Se o usuário confirmar, chama a função carregarAtendimentos para realizar a consulta.
     *
     * @function handleClickConsultar
     * @async
     * @returns {Promise<void>}
     */
    const handleClickConsultar = useCallback(async () => {
        let passa = true;

        // Verifica se pelo menos um filtro está preenchido
        if (!dataInicio && !dataFim && !pacienteId && !terapeutaId) {
            await Swal.fire({
                title: 'Atenção',
                icon: 'warning',
                html: 'Por favor, selecione pelo menos um filtro para realizar a consulta.',
                showConfirmButton: true,
                confirmButtonText: 'Ok'
            });
            return; // Impede a consulta se nenhum filtro estiver preenchido
        }

        if (pendenteDeSave) {
            passa = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
        }

        if (passa) {
            carregarAtendimentos();
        }
    }, [dataInicio, dataFim, pacienteId, terapeutaId, pendenteDeSave]);

    /**
     * Função para salvar as alterações nos atendimentos financeiros.
     * Utiliza a API para atualizar os dados e exibe mensagens de sucesso ou erro.
     * 
     * @function handleSalva
     * @returns {void}
     */
    const handleSalva = useCallback(() => {
        setSalvando(true);
        api.put('api/atendimento/atualiza/financeiro', atendimentosAlterados)
            .then((response) => {
                if (response.status === 200) {
                    snackbar.displayMessage('Atendimentos atualziados com sucesso!', 'success');
                    carregarAtendimentos();
                    setPendenteDeSave(false);
                    setSalvando(false);
                }
            }).catch((error) => {
                setSalvando(false);
                console.error(error);
                snackbar.displayMessage('Não foi possível atualizar os atendimentos.', 'error');
            });
    }, [atendimentosAlterados, carregarAtendimentos, snackbar]);

    /**
     * Manipula as mudanças nos inputs da tabela.
     *
     * @param {Object} event - O evento de mudança do input.
     * @param {string} event.target.id - O ID do input no formato 'key=id'.
     * @param {string} event.target.value - O valor do input.
     *
     * @throws {Error} Se ocorrer um erro durante a manipulação.
     */
    const handleChangeTableInputs = useCallback((event) => {
        try {
            const splitedId = event.target.id.split('=');
            const key = String(splitedId[0]);
            const id = Number(splitedId[1]);
            const value = Number(event.target.value);
            let listaAF = atendimentosAlterados;
            const afIndex = listaAF.findIndex(find => find.id === id);
            if (afIndex !== -1) {
                listaAF[afIndex] = {
                    ...listaAF[afIndex],
                    [key]: value
                };
            } else {
                const newFinded = {
                    id: id,
                    [key]: value
                };
                listaAF.push(newFinded);
            }
            setAtendimentosAlterados(listaAF);
            setPendenteDeSave(listaAF.length > 0);
        } catch (error) {
            console.error(error);
        }
    }, [atendimentosAlterados]);

    /**
     * Limpa os parâmetros de data e os arrays de atendimentos.
     *
     * @param {boolean} [limpa_parametro=true] - Indica se os parâmetros de data devem ser limpos.
     */
    const handleLimpaParametros = (limpa_parametro = true) => {
        if (limpa_parametro) {
            setDataInicio('');
            setDataFim('');
            setPacienteId('');
            setTerapeutaId('');
            setFilters(filterDefault);
        }
        setAtendimentosOriginal([]);
        setAtendimentos([]);
    };

    /**
     * Manipula a mudança dos filtros de atendimento e glosas.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.id - O ID do alvo do evento.
     * @param {string} e.target.name - O nome do alvo do evento.
     * @param {Object} e.target.value - O valor do alvo do evento.
     * @param {string} e.target.value.value - O valor específico do alvo do evento.
     */
    const handleChangeFilters = useCallback((e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value.value ? e.target.value.value : e.target.value;

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                handleLimpaParametros(false);
                break;
            case 'data_fim':
                setDataFim(value);
                handleLimpaParametros(false);
                break;
            case 'paciente_id':
                setPacienteId(e.target.value);
                handleLimpaParametros(false);
                break;
            case 'terapeuta_id':
                setTerapeutaId(e.target.value);
                handleLimpaParametros(false);
                break;
            default:
                break;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    /**
     * Manipula a abertura do modal de comentários com os dados do atendimento.
     *
     * @param {Object} atendimento - Objeto contendo os dados do atendimento.
     * @param {string} atendimento.terapeuta_nome - Nome do terapeuta.
     * @param {number} atendimento.paciente_id - ID do paciente.
     * @param {string} atendimento.paciente_identificador - Identificador do paciente.
     * @param {string} atendimento.paciente_nome - Nome do paciente.
     * @param {string} atendimento.terapia_nome - Nome da terapia.
     * @param {string} atendimento.especialidade_nome - Nome da especialidade.
     * @param {string} atendimento.nome_reduzido - Nome reduzido do plano de saúde.
     * @param {Object} atendimento.terapia - Objeto contendo informações da terapia.
     * @param {string} atendimento.terapia.terapia - Nome da terapia.
     * @param {Object} atendimento.especialidade - Objeto contendo informações da especialidade.
     * @param {string} atendimento.especialidade.especialidade - Nome da especialidade.
     * @param {string} atendimento.sala - Sala do atendimento.
     * @param {Object} atendimento.estacao - Objeto contendo informações da estação.
     * @param {string} atendimento.estacao.estacao - Nome da estação.
     * @param {boolean} atendimento.reposicao - Indica se é uma reposição.
     * @param {Object} atendimento.tipo_atendimento - Objeto contendo informações do tipo de atendimento.
     * @param {string} atendimento.tipo_atendimento.tipo_atendimento - Tipo de atendimento.
     * @param {number} atendimento.id - ID do atendimento.
     * @param {string} atendimento.sugestao_terapeuta_name - Nome da sugestão do terapeuta.
     * @param {string} atendimento.sugestao_recepcao_name - Nome da sugestão da recepção.
     * @param {number} atendimento.sugestao_terapeuta - Sugestão do terapeuta.
     * @param {number} atendimento.sugestao_recepcao - Sugestão da recepção.
     * @param {boolean} atendimento.liberado - Indica se o atendimento está liberado.
     * @param {boolean} atendimento.checkin - Indica se o check-in foi realizado.
     * @param {string} atendimento.data_atendimento_inicial - Data e hora inicial do atendimento.
     * @param {string} atendimento.data_atendimento_final - Data e hora final do atendimento.
     * @param {string} atendimento.comentario - Comentário do atendimento.
     * @param {boolean} atendimento.executado - Indica se o atendimento foi executado.
     * @param {string} atendimento.codigo_1 - Código 1 do atendimento.
     * @param {string} atendimento.codigo_2 - Código 2 do atendimento.
     */
    const handleOpenComentario = useCallback((atendimento) => {
        let dados = [];
        dados['terapeuta'] = atendimento.terapeuta_nome;
        dados['paciente'] = atendimento.paciente_identificador;
        dados['paciente_nome'] = atendimento.paciente_nome;
        dados['paciente_id'] = atendimento.paciente_id;
        dados['terapia'] = atendimento.terapia_nome;
        dados['especialidade'] = atendimento.especialidade_nome;
        dados['plano_saude'] = atendimento.nome_reduzido;
        dados['terapia'] = atendimento.terapia.terapia;
        dados['especialidade'] = atendimento.especialidade.especialidade;
        dados['sala'] = atendimento.sala;
        dados['estacao'] = atendimento.estacao;
        dados['reposicao'] = atendimento.tipo_atendimento.tipo_atendimento;
        dados['modalidade'] = atendimento.modalidade;
        dados['tipo_atendimento'] = atendimento.tipo_atendimento;
        dados['id'] = atendimento.id;
        dados['terapeuta_status'] = atendimento.sugestao_terapeuta_name;
        dados['terapeuta_status_nome'] = atendimento.sugestao_terapeuta_name;
        dados['recepcao_status'] = atendimento.sugestao_recepcao_name;
        dados['recepcao_status_nome'] = atendimento.sugestao_recepcao_name;
        dados['liberado'] = atendimento.liberado;
        dados['checkin'] = atendimento.checkin;
        dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
        dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
        dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
        dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
        dados['comentario'] = atendimento.comentario;
        dados['executado'] = atendimento.executado;
        dados['codigo_1'] = atendimento.codigo_1;
        dados['codigo_2'] = atendimento.codigo_2;

        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    }, []);
    //#endregion

    //#region COLUNAS
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Data Hora Atendimento',
                accessor: 'data_atendimento_inicial',
                filtrable: true,
                Cell: ({ cell }) => {
                    const horario = cell.row.original.hora_atendimento;
                    const sliced = horario.slice(0, -3);
                    return (
                        <span>
                            {cell.row.original.data_do_atendimento || 'vazio'}
                            <br></br>
                            {sliced || 'vazio'}
                        </span>
                    );
                }
            },
            {
                Header: 'Paciente',
                accessor: 'paciente_nome',
                filtrable: true,
                Cell: ({ cell }) => (<div className="text-nowrap">{cell.value}</div>),
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta_nome',
                filtrable: true,
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_atendimento',
                filtrable: true,
            },
            {
                Header: <span>
                    <span> Terapia </span><br></br>
                    <span> Especialidade </span><br></br>
                    <span> Plano </span>
                </span>,
                accessor: 'terapia',
                filtrable: true,
                Cell: ({ cell }) => (
                    <div className="w-150px font-12px mx-auto">
                        {cell?.row?.original?.terapia}<br></br>
                        {cell?.row?.original?.especialidade}<br></br>
                        {cell?.row?.original?.nome_reduzido}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'sugestao_recepcao_name',
                filtrable: true,
            },
            {
                Header: 'Liberação',
                accessor: 'liberado',
                Cell: ({ cell }) => {
                    return <div className="m-0 p-0">
                        {cell.value ? '✅' : '❌'}
                    </div>;
                },
                filtrable: true,
            },
            {
                Header: 'Assinado',
                accessor: 'assinado',
                Cell: ({ cell }) => {
                    return <div className="m-0 p-0">
                        {cell.value ? '✅' : '❌'}
                    </div>;
                },
                filtrable: true,
            },
            {
                Header: 'Status Evolução',
                accessor: 'status_evolucao_nome',
                Cell: ({ cell }) => {
                    return <div className="m-0 p-0">
                        <Select
                            id={`status_evolucao=${cell.row.original.id}`}
                            name={`status_evolucao[]`}
                            options={statusDeGlosaOptions}
                            defaultValue={statusDeGlosaOptions.find((find) => find.value === cell.value || find.label === cell.value)}
                            onChange={(e) => handleChangeTableInputs({ target: { id: `status_evolucao=${cell.row.original.id}`, name: 'status_evolucao[]', value: e.value } })}
                        />
                    </div>;
                }
            },
            {
                Header: 'Detalhes',
                accessor: 'comentario',
                Cell: ({ cell }) => {
                    return <button className="btn-icone hl-laranja" onClick={() => handleOpenComentario(cell.row.original)}>
                        <BsEye size={22} />
                    </button>;
                }
            },
        ], [handleChangeTableInputs, handleOpenComentario, statusDeGlosaOptions, atendimentos]);
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (!pendenteDeSave) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [pendenteDeSave, navigator]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (pendenteDeSave) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pendenteDeSave]);

    useEffect(() => {
        if (!filtroAlterado) {
            setFiltroAlterado(true);
        }
    }, [filters]);

    useEffect(() => {
        if (!dataAlterado && (dataInicio || dataFim || pacienteId || terapeutaId)) {
            setDataAlterado(true);
        }
    }, [dataInicio, dataFim, pacienteId, terapeutaId]);

    useEffect(() => {
        fetchStatusAtendimentosData();
        fetchPacienteOptionsData();
        fetchTerapeutasData();
    }, []);
    //#endregion

    //#region HTML
    return (
        <>
            <div className="row p-0 m-0">
                <Form id="div-filtro" className='row justify-content-center'>
                    <div className='row col-10'>
                        <fieldset id="fieldset-periodo" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-5">
                            <legend className="mb-0">Período</legend>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-12'>
                                    <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                                </div>
                                <div className='col-lg-6 col-sm-12 d-flex'>
                                    <div className="w-90per">
                                        <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                                    </div>
                                    <div className="mt-1">
                                        <button type={'button'} className="btn-icone mt-4" onClick={handleLimpaParametros}><AiOutlineClear className="icone" size={30} /></button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset id="fieldset-paciente" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-3">
                            <legend className="mb-0">Paciente</legend>
                            <div className='row'>
                                <div className='col-12'>
                                    <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} isClearable={true} value={pacienteId} onChange={(e) => handleChangeFilters({ target: { id: 'paciente_id', name: 'paciente_id', value: e } })} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset id="fieldset-terapeuta" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-2">
                            <legend className="mb-0">Terapeuta</legend>
                            <div className='row'>
                                <div className='col-12'>
                                    <Select id="terapeuta_id" name="terapeuta_id" label="Terapeuta:" title="Terapeuta:" options={terapeutasOptions} isClearable={true} value={terapeutaId} onChange={(e) => handleChangeFilters({ target: { id: 'terapeuta_id', name: 'terapeuta_id', value: e } })} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset id="fieldset-acoes" name="parametros-acoes" className="ns-fieldset mt-4 col-sm-12 col-lg-2">
                            <legend className="mb-0">Ações</legend>
                            <div className='d-grid'>
                                <Button className={`searching${dataAlterado ? ' btn-marca-vermelha' : ''}`} type={'button'} onClick={() => { handleClickConsultar(); }}>
                                    <BsSearch size={22} className="icone me-3" /> Consultar Atendimentos
                                </Button>
                                <Button type={'button'} className={`mt-2 broom-sweep ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} onClick={handleLimpaParametros} >
                                    <FaBroom size={30} type="button" className="icone me-2" /> Limpar Filtros
                                </Button>
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-12 mt-2 d-flex flex-row justify-content-center">
                        {(loading || salvando) &&
                            <div className='p-0 m-0 mx-auto'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                    <BsSearch className='icone manual-searching' size={30} />
                                </div>
                            </div>
                        }
                    </div>
                </Form >
                <div className="col-12 p-0 m-0 d-flex justify-content-between mt-4">
                    <b></b>
                    <b className="font-roxo font-20px">Listagem de Atendimentos</b>
                    <Button className={`w-fit-content-i ${salvando ? 'cursor-block' : 'cursor-pointer'}`} type={'button'} onClick={() => handleSalva()} disabled={salvando}>
                        <FaSave className={`me-2 ${pendenteDeSave ? 'font-laranja' : ''}`} size={22} /> Salvar Alterações
                    </Button>
                </div>
                <Form className="col-12 p-0 m-0 overflow-auto">
                    <TableDefaultPagination source={memoAtendimentos} columns={columns} tableTitle={pageBase.title} prefix_id={'pendencias-pagamento'} />
                </Form>
                {loading && <Carregamento />}
                <ComentariosModal ref={modalComentariosRef} />
            </div >
        </>
    );
    //#endregion
};

export default AtendimentosEGlosas;
