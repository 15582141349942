import styled from 'styled-components';

export const Container = styled.div`
  background: #F5F7FA;
  max-width: 1280px;
  margin: 0 auto;
`;

export const AtendimentoCard = styled.div`
    width: 95%;
    border: 1px solid black;
    padding: 0px;
    transition: transform 0.2s ease-in-out;
    user-select: none;
    cursor: pointer;
    margin-top: 10px;

    .card-body {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
    }
    span {
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span:first-child {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        text-align: center;
    }
    span:nth-child(2) {
        border-bottom: 1px solid black;
        padding-left: 5px;
        display: flex;
        justify-content: between;

        p{
            margin: 0;
            padding: 0;
            width: fit-content;
            height: fit-content;
        }

        .status{
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: green;
            position: absolute;
            right: -6px;
            top: -6px;
        }
    }

    &:hover {
        background-color: #f8f9fa;
        transform: scale(1.04);
    }
`;

export const CardList = styled.div`
    width: 95%;
    height: 70vh;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
    gap: 10px;
    padding: 10px 5px;
    background-color: white;
`;