import ModalTitulo from 'components/ModalTitulo';
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineDownload, AiOutlineForm } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';
import Webcam from 'react-webcam';
import Arquivos from 'services/ArquivosService';
import styled from 'styled-components';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import user_default from 'assets/img_avatar.png';

const Modal = styled.div`
    flex-direction: column; 
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

const PerfilUsuario = () => {
    usePageBase({
        title: 'Perfil do Usuário'
    })
    const { user } = useAuth();
    const [dados, setDados] = useState([]);
    const [alterarFotoPerfil, setAlterarFotoPerfil] = useState(false);
    const [modalFoto, setModalFoto] = useState(false);
    const [fotoPerfil, setFotoPerfil] = useState(null);
    const [fotoPerfil64, setFotoPerfil64] = useState(null);
    const webcamRef = useRef(null);
    const videoConstraints = { width: 1280, height: 720, facingMode: "user" };

    function formatarString(valor, formato) {
        if (!valor) return '';
        let valorFormatado = '';
        let j = 0;
        for (let i = 0; i < formato.length; i++) {
            if (formato[i] === '?') {
                if (valor[j] !== undefined) {
                    valorFormatado += valor[j];
                    j++;
                }
            } else {
                valorFormatado += formato[i];
            }
        }
        return valorFormatado;
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                const file = dataURLtoFile(imageSrc, 'webcam-photo.jpg');
                setFotoPerfil(file);
            } else {
                setFotoPerfil(imageSrc);
            }
            setFotoPerfil64(imageSrc);
            setModalFoto(false);
            setAlterarFotoPerfil(false);
        },
        [webcamRef]
    );

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const handleChangePerfil = (e) => {
        const file = e.target.files[0];
        let ext = ['jpg', 'png', 'jpeg', 'img'];
        let inputImg = document.querySelector('#image');
        if (Arquivos.validaArquivo(ext, 5242880, file)) {
            const reader = new FileReader();
            let base64;
            reader.addEventListener('load', (e) => {
                const readerTarget = e.target;
                inputImg.src = base64;
                base64 = readerTarget.result;
                setFotoPerfil64(base64);
                setFotoPerfil(file);
            });
            reader.readAsDataURL(file);
        } else {
            inputImg.value = '';
        }
        e.preventDefault();
    };

    const downloadFotoPerfil = () => {
        const link = document.createElement('a');
        link.href = fotoPerfil64;
        link.download = 'foto_perfil.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const WebcamCapture = () => {
        return (
            <>
                <div className={modalFoto ? 'flex-col align-items-center justify-center d-flex' : 'd-none'} >
                    <div className="h-auto">
                        <div className="webcam-background pointer" onClick={capture}>
                            <Webcam
                                audio={false}
                                style={{ height: '300px' }}
                                disablePictureInPicture={true}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <button className="btn-padrao mt-10px mx-2" type="button" onClick={(e) => setModalFoto(false)}>Voltar</button>
                        <button className="btn-padrao mt-10px mx-2" type="button" onClick={capture}>Tirar Foto</button>
                    </div>
                </div>
            </>
        );
    };

    useEffect(() => {
        async function coletaDados() {
            let extra = [];
            let fotoPerfil = null;
            switch (user.tipo_user) {
                case 'responsavel':
                    extra = await api.get('api/user/perfil/' + 0).then(({ data }) => data);
                    break;
                case 'terapeuta':
                    extra = await api.get('api/terapeuta?filter[user_id]=' + user.id).then(({ data }) => data ? data[0] : []);
                    fotoPerfil = extra?.foto_perfil_full;
                    break;
                default:
                    break;
            }
            let dadosLocal = {
                ...user,
                ...extra,
                fotoPerfil: fotoPerfil,
            };
            dadosLocal.data_nascimento = dadosLocal.data_nascimento ? moment.utc(dadosLocal.data_nascimento.split('T')[0]).format('DD/MM/YYYY') : moment.utc(dadosLocal.nascimento.split('T')[0]).format('DD/MM/YYYY');
            if (dadosLocal.telefone) {
                dadosLocal.telefone = dadosLocal.telefone.replace(/\D/g, '');
            }
            if (dadosLocal.telefone1) {
                dadosLocal.telefone1 = dadosLocal.telefone1.replace(/\D/g, '');
            }
            if (dadosLocal.telefone2) {
                dadosLocal.telefone2 = dadosLocal.telefone2.replace(/\D/g, '');
            }
            if (dadosLocal.telefone3) {
                dadosLocal.telefone3 = dadosLocal.telefone3.replace(/\D/g, '');
            }
            if (dadosLocal.cpf) {
                dadosLocal.cpf = dadosLocal.cpf.replace(/\D/g, '');
            }
            if (dadosLocal.rg) {
                dadosLocal.rg = dadosLocal.rg.replace(/\D/g, '');
            }
            setDados(dadosLocal);
        }
        coletaDados();
    }, [user]);

    useEffect(() => {
        if (dados.fotoPerfil) {
            setFotoPerfil64(dados.fotoPerfil);
        }
    }, [dados.fotoPerfil]);

    return (
        <div className="container mt-4">
            {dados && (
                <div>
                    <form className='row'>
                        <div className="accordion accordion-flush col-lg-3 col-sm-12" id="accordionFlushExample1">
                            <div className="accordion-item">
                                <h2 className="accordion-header d-flex align-center">
                                    <button className="accordion-button font-bold font-20px word-break p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="true" aria-controls="flush-collapseOne1">
                                        {dados?.nome ? dados?.nome : dados.name ? dados.name : 'Usuário'}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne1" className="accordion-collapse collapse show pb-0" data-bs-parent="#accordionFlushExample1">
                                    <div className="accordion-body text-center justify-content-center">
                                        <img className='perfil-image quadrado-150px mx-auto mb-0' style={{ minHeight: '150px', minWidth: '150px' }} src={fotoPerfil64 ? fotoPerfil64 : user.foto_perfil_full ? user.foto_perfil_full : user_default} alt="" id="image" />
                                        {user.tipo_user !== 'terapeuta' && user.tipo_user !== 'responsavel' && (
                                            <div className="col-12 d-flex align-center justify-center m-0">
                                                <button className="btn-outline" type="button" onClick={() => setAlterarFotoPerfil(true)}>
                                                    <AiOutlineForm style={{ marginRight: 3, marginBottom: 4 }} />
                                                    Alterar
                                                </button>
                                                <button className="btn-outline" type="button" onClick={downloadFotoPerfil}>
                                                    <AiOutlineDownload style={{ marginRight: 3, marginBottom: 4 }} />
                                                    Baixar
                                                </button>
                                                <Modal className={alterarFotoPerfil ? '' : 'd-none'} onClick={(e) => {
                                                    if (e.target.contains(e.currentTarget)) {
                                                        setModalFoto(false); setAlterarFotoPerfil(false);
                                                    }
                                                }}>
                                                    <div className="div-modal p-0" style={{ height: '60vh' }}>
                                                        <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                                                            <div className="container-fluid d-flex align-items-center justify-content-between">
                                                                <div></div>
                                                                <ModalTitulo> Alterar foto de perfil </ModalTitulo>
                                                                <label className="btn-outline text-right pointer" onClick={() => { setModalFoto(false); setAlterarFotoPerfil(false); }}>
                                                                    <CgClose size={22} className="poiner" color="red" />
                                                                </label>
                                                            </div>
                                                        </nav>
                                                        <br></br>
                                                        <div className="d-flex justify-center">
                                                            {!modalFoto &&
                                                                <div className="d-flex flex-row text-center justify-center align-center">
                                                                    <label className="btn-padrao m-2" type="button" htmlFor="foto_perfil" style={{ cursor: 'pointer' }}>
                                                                        Upload
                                                                        <input type="file" name="foto_perfil" id="foto_perfil" className="some" onChange={(e) => { handleChangePerfil(e); setAlterarFotoPerfil(false); setModalFoto(false); }} />
                                                                    </label>
                                                                    <button className="btn-padrao m-2" type="button" onClick={() => { setModalFoto(true); }}>
                                                                        Tirar Foto
                                                                    </button>
                                                                </div>}
                                                            <div className="row justify-center">
                                                                {modalFoto && WebcamCapture()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion accordion-flush col-lg-9 col-sm-12" id="accordionFlushExample1">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button font-20px p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                        Dados Pessoais
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body row">
                                        <div className={user.tipo_user !== 'responsavel' ? 'mb-3 col-sm-12 col-lg-4' : 'mb-3 col-sm-12 col-lg-6'}>
                                            <label htmlFor="name" className="form-label">Nome:</label>
                                            <input type="text" id="name" className="form-control" value={dados.name ? dados.name : dados.nome} disabled />
                                        </div>
                                        {user.tipo_user !== 'responsavel' && (
                                            <div className="mb-3 col-sm-12 col-lg-4">
                                                <label htmlFor="nome_curto" className="form-label">Nome Curto:</label>
                                                <input type="text" id="nome_curto" className="form-control" value={dados.nome_curto} disabled />
                                            </div>
                                        )}
                                        <div className={user.tipo_user !== 'responsavel' ? 'mb-3 col-sm-12 col-lg-4' : 'mb-3 col-sm-12 col-lg-6'}>
                                            <label htmlFor="nascimento" className="form-label">Data de Nascimento:</label>
                                            <input type="text" id="nascimento" className="form-control" value={dados.data_nascimento} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-6">
                                            <label htmlFor="cpf" className="form-label">CPF:</label>
                                            <input type="text" id="cpf" className="form-control" value={formatarString(dados.cpf, '???.???.???-??')} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-6">
                                            <label htmlFor="rg" className="form-label">RG:</label>
                                            <input type="text" id="rg" className="form-control" value={formatarString(dados.rg, '????????-?')} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed font-20px p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Dados de Contato
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body row">
                                        {user.tipo_user === 'responsavel'
                                            ? <>
                                                <div className="mb-3 col-sm-12 col-lg-6">
                                                    <label htmlFor="telefone" className="form-label">Telefone1:</label>
                                                    <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone1, '(??) ?????-????')} disabled />
                                                </div>
                                                <div className="mb-3 col-sm-12 col-lg-6">
                                                    <label htmlFor="telefone" className="form-label">Telefone2:</label>
                                                    <input type="text" id="telfefone" className="form-control" value={formatarString(dados.telefone2, '(??) ?????-????')} disabled />
                                                </div>
                                            </>
                                            : user.tipo_user === 'terapeuta'
                                                ? <>
                                                    <div className="mb-3 col-sm-12 col-lg-4">
                                                        <label htmlFor="telefone" className="form-label">Telefone1:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone1, '(??) ?????-????')} disabled />
                                                    </div>
                                                    <div className="mb-3 col-sm-12 col-lg-4">
                                                        <label htmlFor="telefone" className="form-label">Telefone2:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone2, '(??) ?????-????')} disabled />
                                                    </div>
                                                    <div className="mb-3 col-sm-12 col-lg-4">
                                                        <label htmlFor="telefone" className="form-label">Telefone3:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone3, '(??) ?????-????')} disabled />
                                                    </div>
                                                </>
                                                : <>
                                                    <div className="mb-3 col-12">
                                                        <label htmlFor="telefone" className="form-label">Telefone:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone, '(??) ?????-????')} disabled />
                                                    </div>
                                                </>
                                        }
                                        <div className="mb-3 col-12">
                                            <label htmlFor="email" className="form-label">Email:</label>
                                            <input type="email" id="email" className="form-control" value={dados.email} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed font-20px p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Dados Residênciais
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body row">
                                        <div className="mb-3 col-sm-12 col-lg-2">
                                            <label htmlFor="cep" className="form-label">CEP:</label>
                                            <input type="text" id="cep" className="form-control" value={dados.cep} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-4">
                                            <label htmlFor="endereco" className="form-label">Endereço:</label>
                                            <input type="text" id="endereco" className="form-control" value={dados.endereco} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-1">
                                            <label htmlFor="numero" className="form-label">Número:</label>
                                            <input type="text" id="numero" className="form-control" value={dados.numero} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-2">
                                            <label htmlFor="bairro" className="form-label">Bairro:</label>
                                            <input type="text" id="bairro" className="form-control" value={dados.bairro} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-2">
                                            <label htmlFor="cidade" className="form-label">Cidade:</label>
                                            <input type="text" id="cidade" className="form-control" value={dados.cidade} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-1">
                                            <label htmlFor="estado" className="form-label">Estado:</label>
                                            <input type="text" id="estado" className="form-control" value={dados.estado} disabled />
                                        </div>
                                        <div className="mb-3 col-12">
                                            <label htmlFor="complemento" className="form-label">Complemento:</label>
                                            <textarea id="complemento" className="form-control" value={dados.complemento} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default PerfilUsuario;
