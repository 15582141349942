import styled from 'styled-components';


// Estilos
const MainContainer = styled.main`
    flex: 1 0 auto;
    overflow-y: auto;
    z-index: 1;
    position: relative;
`;
const Container = ({ children }) => {
    //#region RENDER
    /*
    return (
        <MainContainer id={'main-container'}>
        {children}
        </MainContainer>
        )
        */
    return (
        <main className="app-container">
            {children}
        </main>
    )
    //#endregion
};

export default Container;